import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined } from 'lodash';
import { Subject } from 'rxjs';
import { validatePasswordMatch } from '../../../../application/validators/validate-password-match';
import { TranslateService } from '@ngx-translate/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(formBuilder, userService, activatedRoute, router, translateService) {
        this.requestedPasswordReset = false;
        this.resetPerforming = false;
        this.unSubscribeUsersOnDestroy = new Subject();
        this.userService = userService;
        this.router = router;
        this.setResetKey(activatedRoute.queryParams);
        this.createResetPasswordForm(formBuilder);
        this.translateService = translateService;
    }
    ResetPasswordComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeUsersOnDestroy.next();
        this.unSubscribeUsersOnDestroy.complete();
    };
    ResetPasswordComponent.prototype.resetPassword = function (form, isValid) {
        var _this = this;
        if (isValid) {
            this.resetPerforming = true;
            this.userService.resetPassword(this.key, form.password).pipe(takeUntil(this.unSubscribeUsersOnDestroy))
                .subscribe(function () {
                _this.requestedPasswordReset = true;
            }, function (error) {
                _this.resetPerforming = false;
                var parsedError = JSON.parse(error.error);
                var errorMessage = parsedError.message;
                _this.translateService.get("ERRORS." + error.error.type).pipe(takeUntil(_this.unSubscribeUsersOnDestroy))
                    .subscribe(function (translation) {
                    if (translation !== "ERRORS." + error.error.type) {
                        errorMessage = translation;
                    }
                });
                throw new Error(errorMessage);
            });
        }
    };
    ResetPasswordComponent.prototype.canResetPassword = function () {
        return this.resetPasswordForm.valid && !this.resetPerforming;
    };
    ResetPasswordComponent.prototype.createResetPasswordForm = function (formBuilder) {
        this.resetPasswordForm = formBuilder.group({
            'password': ['', [
                    Validators.required,
                    Validators.minLength(6)
                ]],
            'passwordConfirm': ['', [
                    Validators.required,
                    Validators.minLength(6)
                ]]
        }, {
            validator: validatePasswordMatch
        });
    };
    ResetPasswordComponent.prototype.setResetKey = function (queryParams) {
        var _this = this;
        queryParams.subscribe(function (params) {
            _this.key = params.key;
            if (isUndefined(_this.key)) {
                _this.router.navigateByUrl('/login');
            }
        });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
