import {HttpLoaderFactory} from './application/translation/httpLoaderFactory';
import {TruncateDigitsPipe} from './application/truncate-digits/truncate-digits.pipe';
import { valueToDecimalDirective } from './infrastructure/directive/valueToDecimalDirective';
import {MaterialModule} from './material.module';
import {ChartComponent} from './presentation/components/chart/chart.component';
import {ActionTilesComponent} from './presentation/components/details/details-actions/action-tiles/action-tiles.component';
import {DetailsActionsComponent} from './presentation/components/details/details-actions/details-actions.component';
import {MessageComponent} from './presentation/components/message/message.component';
import {NavigationToolbarComponent} from './presentation/components/navigation-toolbar/navigation-toolbar.component';
import {NavigationComponent} from './presentation/pages/navigation/navigation.component';
import {ProgressSpinnerComponent} from './presentation/progress-spinner/progress-spinner.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {Daterangepicker} from 'ng2-daterangepicker';
import {StatusTileComponent} from './presentation/components/status-tile/status-tile.component';
import {MomentModule} from 'angular2-moment';
import {OverviewCardComponent} from './presentation/components/overview-card/overview-card.component';
import {ViewCardComponent} from './presentation/components/view-card/view-card.component';
import {MatButtonModule, MatExpansionModule, MatBadgeModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FooterComponent} from './presentation/components/footer/footer.component';
import { DetailsUsersComponent } from './presentation/components/details/details-users/details-users.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FlexLayoutModule,
        Daterangepicker,
        MomentModule,
        MatButtonModule,
        MatExpansionModule,
        FormsModule,
        ReactiveFormsModule,
        MatBadgeModule
    ],
    declarations:
        [
            NavigationComponent,
            NavigationToolbarComponent,
            ProgressSpinnerComponent,
            MessageComponent,
            TruncateDigitsPipe,
            valueToDecimalDirective,
            ChartComponent,
            StatusTileComponent,
            OverviewCardComponent,
            ViewCardComponent,
            DetailsActionsComponent,
            ActionTilesComponent,
            FooterComponent,
            DetailsUsersComponent
        ],
    exports: [
        NavigationComponent,
        NavigationToolbarComponent,
        ProgressSpinnerComponent,
        MessageComponent,
        TruncateDigitsPipe,
        valueToDecimalDirective,
        FlexLayoutModule,
        Daterangepicker,
        ChartComponent,
        StatusTileComponent,
        OverviewCardComponent,
        ViewCardComponent,
        DetailsActionsComponent,
        FooterComponent,
        DetailsUsersComponent,
        MatBadgeModule
    ]
})
export class SharedModule {

}
