import {Component, Inject} from '@angular/core';
import {View} from "../../../../domain/view/view";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material";
import {ConfirmationDialogComponent} from "../../../pages/confirmation-dialog/confirmation-dialog.component";
import {Router} from "@angular/router";
import {NotificationService} from "../../../../application/notification/notification.service";

@Component({
  selector: 'app-grid-delete-view',
  templateUrl: './grid-delete-view.component.html',
  styleUrls: ['./grid-delete-view.component.scss']
})
export class GridDeleteViewComponent  {

    public view: View;
    private dialogConfig: MatDialogConfig = <MatDialogConfig>{
        panelClass: 'confirmation-dialog'
    };

    public constructor(@Inject('Views') private viewsService, private dialog: MatDialog, private router: Router,private notificationService: NotificationService) {

    }

    public deleteView(): void {

        const currentDialogConfig: MatDialogConfig = <MatDialogConfig> {
            panelClass: this.dialogConfig.panelClass,
            data: {
                textContent: 'VIEW.DELETE_VIEW_QUESTION',
                confirmText: 'GENERAL.OK',
                cancelText: 'GENERAL.CANCEL'
            }
        };
        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.viewsService.deleteView(this.view.id)
                    .subscribe(() => {
                        this.notificationService.show('VIEW.DELETED_CONFIRMATION');
                        this.router.navigateByUrl('/playgrounds');
                    });
            }
        });
    }

    public agInit(params: any): void {
        this.view = params.data;
    }

}
