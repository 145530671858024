import { OnInit } from '@angular/core';
import { isEqual, isEmpty, size, isNil } from 'lodash';
import SensorStatus from '../../../domain/monitor/sensor/sensor-status';
import DeviceStatus from '../../../domain/monitor/deviceStatus';
import RelayStatus from '../../../domain/monitor/relayStatus';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var StatusTileComponent = /** @class */ (function () {
    function StatusTileComponent(monitorService) {
        this.monitorService = monitorService;
    }
    StatusTileComponent.prototype.ngOnInit = function () {
    };
    StatusTileComponent.prototype.getClassStatusForDeviceStatus = function (deviceStatus) {
        deviceStatus = deviceStatus || this.statusInfo.status;
        return isEqual(deviceStatus, DeviceStatus.Online) || isEqual(deviceStatus, SensorStatus.Ok) ? 'on' : 'off';
    };
    StatusTileComponent.prototype.getClassStatusForSensorStatus = function (sensor) {
        return (!sensor.isConnected) ? 'disc' : this.getClassStatusForDeviceStatus(sensor.status);
    };
    StatusTileComponent.prototype.getClassStatusForRelayStatus = function (relayStatus) {
        relayStatus = relayStatus || this.statusInfo.relayState;
        return relayStatus.toLowerCase();
    };
    StatusTileComponent.prototype.getRelayStatusString = function (relayStatus) {
        relayStatus = relayStatus || this.statusInfo.relayState;
        return isEqual(relayStatus, RelayStatus.Alarm) ? '' : relayStatus.toLowerCase();
    };
    StatusTileComponent.prototype.hasSensorErrors = function (sensor) {
        return size(sensor.errors) > 0;
    };
    StatusTileComponent.prototype.hasSensors = function (sensors) {
        return !isEmpty(sensors);
    };
    StatusTileComponent.prototype.hasMonitorErrors = function () {
        if (isNil(this.statusInfo)) {
            return false;
        }
        return size(this.statusInfo.errors) > 0;
    };
    return StatusTileComponent;
}());
export { StatusTileComponent };
