/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./action.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../progress-spinner/progress-spinner.component.ngfactory";
import * as i5 from "../../../progress-spinner/progress-spinner.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./action.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../../infrastructure/data/monitor-data.service";
var styles_ActionComponent = [i0.styles];
var RenderType_ActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionComponent, data: {} });
export { RenderType_ActionComponent as RenderType_ActionComponent };
function View_ActionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 3, 0, currVal_1); }); }
function View_ActionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-progress-spinner", [["fxFlex", ""]], null, null, null, i4.View_ProgressSpinnerComponent_0, i4.RenderType_ProgressSpinnerComponent)), i1.ɵdid(1, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(2, 49152, null, 0, i5.ProgressSpinnerComponent, [], { progressSpinnerText: [0, "progressSpinnerText"] }, null), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACTIONS.LOADING")); _ck(_v, 2, 0, currVal_1); }, null); }
export function View_ActionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.message; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-action", [], null, null, null, View_ActionComponent_0, RenderType_ActionComponent)), i1.ɵdid(1, 114688, null, 0, i8.ActionComponent, [i9.ActivatedRoute, i9.Router, i10.MonitorDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionComponentNgFactory = i1.ɵccf("app-action", i8.ActionComponent, View_ActionComponent_Host_0, {}, {}, []);
export { ActionComponentNgFactory as ActionComponentNgFactory };
