import { OnInit } from '@angular/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(userService) {
        this.userService = userService;
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getNotifications().subscribe(function (not) { return _this.notifications = not; });
    };
    return NotificationsComponent;
}());
export { NotificationsComponent };
