/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./monitor-serialnumber.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./monitor-serialnumber.component";
var styles_GridMonitorSerialnumberComponent = [i0.styles];
var RenderType_GridMonitorSerialnumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridMonitorSerialnumberComponent, data: {} });
export { RenderType_GridMonitorSerialnumberComponent as RenderType_GridMonitorSerialnumberComponent };
export function View_GridMonitorSerialnumberComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.monitor.serialNumber; _ck(_v, 0, 0, currVal_0); }); }
export function View_GridMonitorSerialnumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_GridMonitorSerialnumberComponent_0, RenderType_GridMonitorSerialnumberComponent)), i1.ɵdid(1, 49152, null, 0, i2.GridMonitorSerialnumberComponent, [], null, null)], null, null); }
var GridMonitorSerialnumberComponentNgFactory = i1.ɵccf("ng-component", i2.GridMonitorSerialnumberComponent, View_GridMonitorSerialnumberComponent_Host_0, {}, {}, []);
export { GridMonitorSerialnumberComponentNgFactory as GridMonitorSerialnumberComponentNgFactory };
