/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./monitor-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./monitor-information.component";
var styles_GridMonitorInformationComponent = [i0.styles];
var RenderType_GridMonitorInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridMonitorInformationComponent, data: {} });
export { RenderType_GridMonitorInformationComponent as RenderType_GridMonitorInformationComponent };
export function View_GridMonitorInformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "card-details"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_2 = "card-details"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.monitor.details.system; _ck(_v, 2, 0, currVal_3); }); }
export function View_GridMonitorInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_GridMonitorInformationComponent_0, RenderType_GridMonitorInformationComponent)), i1.ɵdid(1, 49152, null, 0, i4.GridMonitorInformationComponent, [], null, null)], null, null); }
var GridMonitorInformationComponentNgFactory = i1.ɵccf("ng-component", i4.GridMonitorInformationComponent, View_GridMonitorInformationComponent_Host_0, {}, {}, []);
export { GridMonitorInformationComponentNgFactory as GridMonitorInformationComponentNgFactory };
