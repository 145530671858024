import { MonitorSensor } from '../../../domain/monitor/monitorSensor';
import { Component, OnInit, Input } from '@angular/core';
import { isEqual, isEmpty, size, isNil } from 'lodash';
import SensorStatus from '../../../domain/monitor/sensor/sensor-status';
import DeviceStatus from '../../../domain/monitor/deviceStatus';
import RelayStatus from '../../../domain/monitor/relayStatus';
import { MonitorStatusOverview } from '../../../domain/monitor/monitorStatusOverview';
import { MonitorStatusInfo } from 'app/domain/monitor/monitor-status-info';
import { Observable } from 'rxjs/Rx';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-status-tile',
  templateUrl: './status-tile.component.html',
  styleUrls: ['./status-tile.component.scss']
})
export class StatusTileComponent implements OnInit {
  @Input() sensors: MonitorSensor[];
  @Input() lastMeasuredDateTime: Date;
  @Input() statusInfo;
  @Input() isMonitor: boolean

  constructor(private monitorService: MonitorDataService) { }

  ngOnInit() {
        
   }

  public getClassStatusForDeviceStatus(deviceStatus?: DeviceStatus | SensorStatus): string {
    deviceStatus = deviceStatus || this.statusInfo.status;
    return isEqual(deviceStatus, DeviceStatus.Online) || isEqual(deviceStatus, SensorStatus.Ok) ? 'on' : 'off';
  }

  public getClassStatusForSensorStatus(sensor: MonitorSensor): string {
    return (!sensor.isConnected) ? 'disc':this.getClassStatusForDeviceStatus(sensor.status);
  }

  public getClassStatusForRelayStatus(relayStatus?: RelayStatus): string {
    relayStatus = relayStatus || this.statusInfo.relayState;   
    return relayStatus.toLowerCase();
  }

  public getRelayStatusString(relayStatus?: RelayStatus): string {
    relayStatus = relayStatus || this.statusInfo.relayState;
    return isEqual(relayStatus, RelayStatus.Alarm) ? '' : relayStatus.toLowerCase();
  }
  
    public hasSensorErrors(sensor) {
    return size(sensor.errors) > 0;
  }
  
    public hasSensors(sensors): boolean {
    return !isEmpty(sensors);
  }
  
    public hasMonitorErrors() {
      if(isNil(this.statusInfo)) {
        return false;
      }
    return size(this.statusInfo.errors) > 0;
  }


}
