/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grid-edit-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/router";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./grid-edit-view.component";
var styles_GridEditViewComponent = [i0.styles];
var RenderType_GridEditViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridEditViewComponent, data: {} });
export { RenderType_GridEditViewComponent as RenderType_GridEditViewComponent };
export function View_GridEditViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "button", [["class", "btn-edit-playground"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(4, 16384, null, 0, i8.RouterLink, [i8.Router, i8.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵted(6, 0, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "center center"; _ck(_v, 1, 0, currVal_0); var currVal_3 = "primary"; _ck(_v, 3, 0, currVal_3); var currVal_4 = _ck(_v, 5, 0, "/playgrounds", _co.view.id, "edit"); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 3).disabled || null); var currVal_2 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("GENERAL.EDIT")); _ck(_v, 6, 0, currVal_5); }); }
export function View_GridEditViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_GridEditViewComponent_0, RenderType_GridEditViewComponent)), i1.ɵdid(1, 49152, null, 0, i10.GridEditViewComponent, [], null, null)], null, null); }
var GridEditViewComponentNgFactory = i1.ɵccf("ng-component", i10.GridEditViewComponent, View_GridEditViewComponent_Host_0, {}, {}, []);
export { GridEditViewComponentNgFactory as GridEditViewComponentNgFactory };
