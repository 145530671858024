var MonitorSensor = /** @class */ (function () {
    function MonitorSensor(id, channel, lastMeasuredDateTime, unit, value, name, status, openClosedStatus, valueText, valueDescription, inputType, errors, order, unitName, sensorType, isConnected, threshold, sensorhealth, pollingRate, monitorId) {
        this._id = id;
        this._channel = channel;
        this._lastMeasuredDateTime = lastMeasuredDateTime;
        this._unit = unit;
        this._name = name;
        this._value = value;
        this._status = status;
        this._openClosedStatus = openClosedStatus;
        this._valueText = valueText;
        this._valueDescription = valueDescription;
        this._inputType = inputType;
        this._errors = errors;
        this._order = order;
        this._unitName = unitName;
        this._sensorType = sensorType;
        this._isConnected = isConnected;
        this._threshold = threshold;
        this._sensorhealth = sensorhealth;
        this._pollingrate = pollingRate;
        this._monitorId = monitorId;
    }
    MonitorSensor.fromJSON = function (monitorSensorDTO, order) {
        return new MonitorSensor(monitorSensorDTO.id, monitorSensorDTO.channel, monitorSensorDTO.dateTime ? monitorSensorDTO.dateTime : monitorSensorDTO.lastMeasuredDateTime, monitorSensorDTO.unit, monitorSensorDTO.value, monitorSensorDTO.name, monitorSensorDTO.status, monitorSensorDTO.openClosedStatus, monitorSensorDTO.valueText, monitorSensorDTO.valueDescription, monitorSensorDTO.inputType, monitorSensorDTO.errors, monitorSensorDTO.order, monitorSensorDTO.unitName, monitorSensorDTO.sensorType, monitorSensorDTO.isConnected, monitorSensorDTO.threshold, monitorSensorDTO.sensorhealth, monitorSensorDTO.pollingRate, monitorSensorDTO.monitorId);
    };
    Object.defineProperty(MonitorSensor.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "channel", {
        get: function () {
            return this._channel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "lastMeasuredDateTime", {
        get: function () {
            return this._lastMeasuredDateTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "unit", {
        get: function () {
            return this._unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "openClosedStatus", {
        get: function () {
            return this._openClosedStatus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "status", {
        get: function () {
            return this._status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "valueText", {
        get: function () {
            return this._valueText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "valueDescription", {
        get: function () {
            return this._valueDescription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "inputType", {
        get: function () {
            return this._inputType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "errors", {
        get: function () {
            return this._errors;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "order", {
        get: function () {
            return this._order;
        },
        set: function (val) {
            this._order = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "isAlarmIgnored", {
        get: function () {
            return this._isAlarmIgnored;
        },
        set: function (val) {
            this._isAlarmIgnored = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "monitorId", {
        get: function () {
            return this._monitorId;
        },
        set: function (val) {
            this._monitorId = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "detailsId", {
        get: function () {
            return this._detailsId;
        },
        set: function (val) {
            this._detailsId = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "lowerLimits", {
        get: function () {
            return this._lowerLimits;
        },
        set: function (val) {
            this._lowerLimits = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "upperLimits", {
        get: function () {
            return this._upperLimits;
        },
        set: function (val) {
            this._upperLimits = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "unitName", {
        get: function () {
            return this._unitName;
        },
        set: function (value) {
            this._unitName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "sensorType", {
        get: function () {
            return this._sensorType;
        },
        set: function (value) {
            this._sensorType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "isConnected", {
        get: function () {
            return this._isConnected;
        },
        set: function (value) {
            this._isConnected = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "threshold", {
        get: function () {
            return this._threshold;
        },
        set: function (value) {
            this._threshold = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "sensorhealth", {
        get: function () {
            return this._sensorhealth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorSensor.prototype, "pollingRate", {
        get: function () {
            return this._pollingrate;
        },
        enumerable: true,
        configurable: true
    });
    return MonitorSensor;
}());
export { MonitorSensor };
