import {Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import {isNil} from 'lodash';
import FirmwareUpgradeState from '../../../../domain/monitor/firmware/firmware-upgrade-state';
import {FirmwareUpgradeStatus} from '../../../../domain/monitor/firmware/firmware-upgrade-status';
import {Monitor} from '../../../../domain/monitor/monitor';
import {Monitors} from '../../../../infrastructure/http/monitor/monitors';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
  selector: 'app-monitor-software',
  templateUrl: 'monitor-software.component.html',
  styleUrls: ['./monitor-software.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridMonitorSoftwareComponent {
  @Input() public monitor: Monitor;
  private monitors: Monitors;
  requestInProgress: boolean;

  constructor( monitors: MonitorDataService) {
    this.monitors = monitors;
  }

  public agInit(params: any): void {
    this.monitor = params.data;
  }

  public isFirmwareInfoAvailable(): boolean {
    return !isNil(this.monitor.firmwareUpgradeStatus);
  }

  public isFirmwareUpgradeAvailable(): boolean {
    return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Available;
  }

  public isFirmwareUpToDate(): boolean {
    return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Completed;
  }

  public isFirmwareUpgradePlanned(): boolean {
    return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Planned;
  }

  public isFirmwareUpgradeBusy(): boolean {
    return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Busy;
  }

  public isFirmwareUnknown(): boolean {
    return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Unknown;
  }

  public requestFirmwareUpgrade(): void {
    this.requestInProgress = true;
    this.monitors.requestFirmwareUpgrade(this.monitor.id).subscribe((status: FirmwareUpgradeStatus) => {
      this.monitor.firmwareUpgradeStatus = status;
      this.requestInProgress = false;
    });
  }

  public canUpgradeFirmware(): boolean {
    return this.isFirmwareUpgradeAvailable() && this.monitor.editableByCurrentUser;
  }
}
