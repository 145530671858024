
import {forkJoin as observableForkJoin, Observable, ReplaySubject, timer} from 'rxjs';

import {takeUntil, distinctUntilChanged, filter, map, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {View} from "../../domain/view/view";
import {HttpViewsService} from "../http/view/http-views.service";
import {RequestManagerService} from "./request-manager.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Moment} from "moment";
import {DetailStatusOverview} from "../../domain/monitor/sensor/DetailStatusOverview";
import * as _ from "lodash";
import * as moment from "moment";
import {EventSubject} from "./EventSubject";
import {AuthenticationService} from "../http/authentication/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class ViewDataService extends HttpViewsService {

    private allViews: ReplaySubject<View[]> = new ReplaySubject<View[]>(1);
    private allViewsForStatusOverview: ReplaySubject<View[]> = new ReplaySubject<View[]>(1);
    private detailOverviewSubject: EventSubject<{ [id: string]: DetailStatusOverview }> = new EventSubject({});
    private refresh: NodeJS.Timer;


    constructor(private httpClient: HttpClient, private requestManager: RequestManagerService, private authenticationService: AuthenticationService) {
        super(httpClient);
        this.authenticationService.getAuthenticatedUser()
            .pipe(distinctUntilChanged((obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)))
            .subscribe((user) => user ? this.initialize() : this.clear());
    }

    private initialize() {
        setTimeout(() => this.fetchStatus(), 200); // Prioritize monitor load. See bug 1667;
        //this.getAllForInit();
        if(environment.prefetchMeasurements) {
            this.initDetailOverviews();
        }  
        this.refresh = this.refresh || setInterval(() => this.fetchStatus(), environment.pollingIntervalInSeconds * 5000);
    }

    private clear() {
        clearInterval(this.refresh);
        this.refresh = null;
        this.allViews.next(null);
        this.allViewsForStatusOverview.next(null);
        this.detailOverviewSubject.next({});
    }

    private compareMeasurements = (one: DetailStatusOverview, two: DetailStatusOverview) => one.lastMeasuredDateTime === two.lastMeasuredDateTime;

    public getForDetailOverview(id: string, selectedDateRange: { start: Moment, end: Moment }, detailStatusOverview?: View): Observable<DetailStatusOverview> {
        if (ViewDataService.isDefault(selectedDateRange)) {
            // The default range is cached. We don't need to send a new request.
            this.startRefreshInterval(id);
            return this.detailOverviewSubject
                .pipe(
                    map(obj => obj[id]),
                    filter(obj => !_.isNil(obj)),
                    distinctUntilChanged(this.compareMeasurements),
                )
        } else {
            // It's outside of the default range. We must send a new request.
            return super.getForDetailOverview(id, selectedDateRange);
        }
    }

    public getAllForStatusOverview(): Observable<View[]> {
        return this.allViewsForStatusOverview.pipe(
            filter(obj => !_.isNil(obj)),
            distinctUntilChanged((one, two) => JSON.stringify(one) === JSON.stringify(two))
        );
    }

    getOneForStatusOverview(id): Observable<View> {
        return this.allViewsForStatusOverview
            .pipe(
                filter(monitors => !_.isNil(monitors)),
                distinctUntilChanged((one, two) => JSON.stringify(one) === JSON.stringify(two)),
                map((obj: View[]) => obj.filter(obj => obj.id == id)[0]),
                filter(obj => !_.isNil(obj)),
            );
    }

    public add(view: View): Observable<string> {
        return super.add(view).pipe(
            tap(() => this.clear()),
            tap(() => this.fetchStatus())
            );
    }

    public deleteView(id: string): Observable<string> {
        return super.deleteView(id).pipe(tap(() => this.fetchStatus()));
    }

    public setChangedOrder(id: string, order: { [key: string]: number }): Observable<View> {
        return super.setChangedOrder(id, order)
    }

    private fetchStatus() {
        this.requestManager.addToQueue({
            priority: 2,
            observable: super.getAllForStatusOverview(),
            subscriptionFunc: (monitors: View[]) => this.allViewsForStatusOverview.next(monitors)
        });
    }

    private startRefreshInterval(id) {
        timer(0, environment.pollingIntervalInSeconds * 1000).pipe(
        // We automatically unsubscribe to the timer (and thus stop updating) when we unsubscribe the measurement update source.
            takeUntil(this.detailOverviewSubject.onUnsubscribe()))
            .subscribe(() => this.updateMeasurements(id))
    }

    private updateMeasurements(id) {
        // Fetch the latest to update.
        observableForkJoin([
            this.getDetailOverviewForCache(),
            this.getOneForStatusOverview(id).pipe(take(1))
        ])
            .pipe(take(1))
            .subscribe(results => {
                super.getForDetailOverview(id, ViewDataService.defaultTime(), results[1])
                    .subscribe((updated) => {
                        results[0][id] = updated;
                        this.detailOverviewSubject.next(results[0])
                    })
            })
    }

    private getAllForInit() {
        this.requestManager.addToQueue({
            priority: 8,
            observable: super.getAll(),
            subscriptionFunc: (monitors: View[]) => this.allViews.next(monitors)
        });
    }

    private initDetailOverviews() {
        this.getAllForStatusOverview().subscribe(views => views.forEach(view => this.initDetailOverview(view.id, view)));
    }

    private initDetailOverview(viewId, view?: View) {
        const subscriptionFunc = detailOverview => this.getDetailOverviewForCache().pipe(take(1)).subscribe(obj => {
            obj[viewId] = detailOverview;
            this.detailOverviewSubject.next(obj);
        });

        // Request for the detail overview
        const observable = super.getForDetailOverview(viewId, ViewDataService.defaultTime(), view);


        this.requestManager.addToQueue({priority: 10, observable, subscriptionFunc});
    }

    private static defaultTime: () => { start: Moment, end: Moment } = () => ( {start: moment().subtract(1, 'month'), end: moment()} );

    private static isDefault(selectedDateRange: { start: Moment, end: Moment }) {
        return selectedDateRange.start.day() === this.defaultTime().start.day() && selectedDateRange.end.day() === this.defaultTime().end.day()
    }

    private getDetailOverviewForCache() {
        return this.detailOverviewSubject
            .pipe(
                take(1)
            )
    }
}
