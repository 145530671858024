import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, Observable, Subscription} from 'rxjs';

import {Page} from '../../../domain/page';
import {Monitors} from '../../../infrastructure/http/monitor/monitors';
import {filter, takeUntil, tap} from "rxjs/operators";
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { AuthenticationService } from 'app/infrastructure/http/authentication/authentication.service';

@Component({
    selector: 'app-monitors',
    templateUrl: './monitors.component.html',
    styleUrls: ['./monitors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonitorsComponent {
    public monitors: MonitorStatusOverview[];
    public subPages: Page[] = [];
    public isLoading = true;

    private monitorsService: Monitors;
    subscription: Subscription;

    constructor( monitorsService: MonitorDataService,
                route: ActivatedRoute,
                private cdr: ChangeDetectorRef,
                private auth: AuthenticationService) {
        this.monitorsService = monitorsService;
        this.subPages = route.snapshot.data.subPages;

    }

    ngOnInit() {
        const tapFunction = tap(() => setTimeout(() => {this.isLoading = false; this.detectChanges()}, 1));
        this.subscription = this.monitorsService.getAllForStatusOverview()
            .pipe(tapFunction)
            .subscribe((monitors: MonitorStatusOverview[]) => {this.monitors = monitors; this.detectChanges()})
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    public fetchDetails(monitor) {
        const details = {...monitor.details, info: monitor.monitorType, serialNumber: monitor.serialNumber};
        return details
    }

    private detectChanges() {
        if(this.cdr && !(this.cdr as ViewRef).destroyed) {
            this.cdr.detectChanges()
        }
    }
}
