
import {takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {isNil, find, remove, concat, isEmpty, isUndefined} from 'lodash';
import {Subject} from 'rxjs';
import {Notification} from '../../../../application/notification/notification';
import {NotificationService} from '../../../../application/notification/notification.service';
import {MonitorSensor} from '../../../../domain/monitor/monitorSensor';
import {Page} from '../../../../domain/page';
import {View} from '../../../../domain/view/view';
import {Monitors} from '../../../../infrastructure/http/monitor/monitors';
import {Views} from '../../../../infrastructure/http/view/views';
import {ConfirmationDialogComponent} from '../../confirmation-dialog/confirmation-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
  selector: 'app-view-add',
  templateUrl: './view-add.component.html',
  styleUrls: ['./view-add.component.scss']
})
export class ViewAddComponent implements OnInit, OnDestroy {
  public subPages: Page[] = [];
  public addViewDetailsForm: FormGroup;
  public monitors: MonitorStatusOverview[] = [];
  public viewSensors: MonitorSensor[] = [];
  public selectedSensorsOfMonitor: MonitorSensor[] = [];
  public selectedViewSensors: MonitorSensor[] = [];
  public dialog: MatDialog;
  public isLoading = true;


  private formBuilder: FormBuilder;
  private monitorsService: Monitors;
  private viewsService: Views;
  private router: Router;
  private notificationService: Notification;
  private unSubscribeAddViewOnDestroy: Subject<boolean> = new Subject<boolean>();
  private route: ActivatedRoute;
  private editMode = false;
  private view: View;
  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    panelClass: 'confirmation-dialog'
  };

  constructor(route: ActivatedRoute,
              formBuilder: FormBuilder,
              monitorsService: MonitorDataService,
              @Inject('Views') viewsService: Views,
              router: Router,
              notificationService: NotificationService,
              dialog: MatDialog) {
    this.route = route;
    this.formBuilder = formBuilder;
    this.monitorsService = monitorsService;
    this.viewsService = viewsService;
    this.router = router;
    this.notificationService = notificationService;
    this.dialog = dialog;

    this.addViewDetailsForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      elucidation: ['', Validators.maxLength(1000)]
    });
  }

  public ngOnInit() {

    this.route.data.pipe(
      takeUntil(this.unSubscribeAddViewOnDestroy))
      .subscribe((data) => {
        this.subPages = data.parentPage.subPages;
      });

    this.route.params.pipe(
      takeUntil(this.unSubscribeAddViewOnDestroy))
      .subscribe((params) => {
        const viewId: string = params.id;

        this.monitorsService.getAllForStatusOverview().pipe(
          takeUntil(this.unSubscribeAddViewOnDestroy))
          .subscribe((monitors: MonitorStatusOverview[]) => {
            this.monitors = monitors.filter(monitor => monitor.permissions.includes("USE_VIEW"));

            if (!isNil(viewId)) {
              this.editMode = true;
              this.isLoading = true;
              this.viewsService.get(viewId).pipe(
                takeUntil(this.unSubscribeAddViewOnDestroy))
                .subscribe((view: View) => {
                  this.view = view;
                  this.addViewDetailsForm.controls['name'].setValue(view.name);
                  this.addViewDetailsForm.controls['elucidation'].setValue(view.elucidation);
                  this.viewSensors = view.sensors;
                  this.isLoading = false;
                });
            } else {
              this.isLoading = false;
            }
          });
      });

  }

  public ngOnDestroy() {
    this.unSubscribeAddViewOnDestroy.next(true);
    this.unSubscribeAddViewOnDestroy.complete();
  }

  public isEditMode(): boolean {
    return this.editMode;
  }

  public toggleSensorOfMonitor(sensor: MonitorSensor): void {
    if (!this.isSensorInList(sensor, this.viewSensors)) {
      if (find(this.selectedSensorsOfMonitor, ['id', sensor.id])) {
        remove(this.selectedSensorsOfMonitor, sensor);
      } else {
        this.selectedSensorsOfMonitor.push(sensor);
      }
    }
  }

  public toggleAddedSensor(sensor: MonitorSensor): void {
    if (this.isSensorInList(sensor, this.selectedViewSensors)) {
      remove(this.selectedViewSensors, sensor);
    } else {
      this.selectedViewSensors.push(sensor);
    }
  }

  public addSensorsToView(sensors: MonitorSensor[]): void {
    this.viewSensors = concat(this.viewSensors, sensors);
    this.selectedSensorsOfMonitor = [];
  }

  public removeSensorsFromView(sensors: MonitorSensor[]): void {
    remove(this.viewSensors, (viewSensor: MonitorSensor) => find(sensors, ['id', viewSensor.id]));
    this.selectedViewSensors = [];
  }

  public isSensorInList(sensor: MonitorSensor, sensors: MonitorSensor[]): boolean {
    return !isEmpty(find(sensors, ['id', sensor.id]));
  }

  public hasSensors(sensors: MonitorSensor[]): boolean {
    return !isEmpty(sensors);
  }

  public getMonitorOfSensor(sensor: MonitorSensor): MonitorStatusOverview {
    return find(this.monitors, (monitor: MonitorStatusOverview) => !isUndefined(find(monitor.sensors, ['id', sensor.id])));
  }

  public canSaveView(): boolean {
    return !this.addViewDetailsForm.invalid && !isEmpty(this.viewSensors);
  }

  public saveView(): void {
    const viewToSave = new View(
      '',
      this.addViewDetailsForm.controls.name.value,
      this.addViewDetailsForm.controls.elucidation.value,
      this.viewSensors);

    if (this.isEditMode()) {
      viewToSave.id = this.view.id;
      this.viewsService.edit(viewToSave).pipe(
        takeUntil(this.unSubscribeAddViewOnDestroy))
        .subscribe(() => {
          this.notificationService.show('VIEW.EDITED_CONFIRMATION');
          this.router.navigateByUrl('/playgrounds');
        });
    } else {
      this.viewsService.add(viewToSave).pipe(
        takeUntil(this.unSubscribeAddViewOnDestroy))
        .subscribe(() => {
          this.notificationService.show('VIEW.ADDED_CONFIRMATION');
          this.router.navigateByUrl('/playgrounds');
        });
    }
  }

  public deleteView(): void {

    const currentDialogConfig: MatDialogConfig = <MatDialogConfig> {
      panelClass: this.dialogConfig.panelClass,
      data: {
        textContent: 'VIEW.DELETE_VIEW_QUESTION',
        confirmText: 'GENERAL.OK',
        cancelText: 'GENERAL.CANCEL'
      }
    };
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.viewsService.deleteView(this.view.id).pipe(
          takeUntil(this.unSubscribeAddViewOnDestroy))
          .subscribe(() => {
            this.notificationService.show('VIEW.DELETED_CONFIRMATION');
            this.router.navigateByUrl('/playgrounds');
          });
      }
    });


  }

  public cancel() {
    this.router.navigateByUrl('/playgrounds');
  }

}
