<article [fxFlex]="40" [fxFlex.md]="50" [fxFlex.lt-md]="100">
  <div class="title__content">
    <section [fxFlex]="75" fxLayout="column">
      <h1 fxLayoutAlign="none center">{{ (isEditMode() ? 'MONITOR.EDIT_MONITOR' : 'MONITOR.ADD_MONITOR') | translate }}
        <mat-icon>keyboard_arrow_right</mat-icon>
        {{ 'MONITOR.DETAILS' | translate }}
      </h1>
      <label *ngIf="!isEditMode()">
        {{ 'MONITOR.FOUND_MONITOR_OF_TYPE' | translate:{type: monitor.monitorType} }}
      </label>
    </section>

    <section fxFlex fxLayoutAlign="end center">
      <button mat-button
              *ngIf="isEditMode()"
              (click)="deleteMonitor()"
              color="primary">{{ 'MONITOR.DELETE_MONITOR' | translate }}</button>
    </section>
  </div>
  <mat-card>
    <mat-card-content>
      <app-message *ngIf="validateHttpError"
                   [messageText]="validateHttpError"
                   [messageStyle]="MESSAGE_STYLE.DANGER"></app-message>

      <app-message *ngIf="!canEditMonitor()"
                   [messageText]="'MONITOR.NON_EDITABLE_READ_ONLY' | translate"
                   [messageStyle]="MESSAGE_STYLE.INFO"></app-message>

      <form [formGroup]="addDetailsMonitorForm"
            novalidate
            (ngSubmit)="addDetailsToMonitor(addDetailsMonitorForm.value, addDetailsMonitorForm.valid)"
            autocomplete="off">
        <fieldset [disabled]="isMonitorPrivacyLevelReadOnly || !canEditMonitor()">
          <div class="input-row" *ngIf="isEditMode()">
            <app-message *ngIf="canShowWarningForAccessabilityOfMonitor()"
                         [messageText]="'MONITOR.PRIVACY_LEVEL_CHANGED_TO_PRIVATE' | translate"
                         [messageStyle]="MESSAGE_STYLE.WARNING"></app-message>
                         <!--
                          Privacy is disabled since we share monitors now

            <mat-form-field fxFill>
              <mat-select [placeholder]="'MONITOR.PRIVACY_LEVEL' | translate" formControlName="privacy">
                <mat-option *ngFor="let privacyLevel of privacyLevels()" [value]="privacyLevel">
                  {{ ('MONITOR.PRIVACY_LEVELS.'+privacyLevel) | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            -->
          </div>
          <div class="input-row">
            <mat-form-field fxFill>
              <!--Required error -->
              <mat-error *ngIf="addDetailsMonitorForm.get('name').errors?.required">
                {{'MONITOR.ERRORS.NAME' | translate}}
              </mat-error>

              <!-- MaxLength error -->
              <mat-error *ngIf="addDetailsMonitorForm.get('name').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.NAME' | translate, max: 255} }}
              </mat-error>

              <input matInput
                     type="text"
                     placeholder="{{'MONITOR.NAME' | translate}} *" formControlName="name">
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFill>
              <mat-error *ngIf="addDetailsMonitorForm.get('address').errors?.required">
                {{'MONITOR.ERRORS.ADDRESS' | translate}}
              </mat-error>

              <!-- MaxLength error -->
              <mat-error *ngIf="addDetailsMonitorForm.get('address').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.ADDRESS' | translate, max: 1000} }}
              </mat-error>

              <textarea matInput rows="6"
                        formControlName="address"
                        placeholder="{{'MONITOR.ADDRESS' | translate}}"></textarea>
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFill>
              <!--Required error -->
              <mat-error *ngIf="addDetailsMonitorForm.get('system').errors?.required">
                {{'MONITOR.ERRORS.SYSTEM' | translate}}
              </mat-error>

              <!-- MaxLength error -->
              <mat-error *ngIf="addDetailsMonitorForm.get('system').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.SYSTEM' | translate, max: 255} }}
              </mat-error>
              <input matInput
                     type="text"
                     placeholder="{{'MONITOR.SYSTEM' | translate}}" formControlName="system">
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFill>
              <mat-error *ngIf="addDetailsMonitorForm.get('circuit').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.CIRCUIT' | translate, max: 255} }}
              </mat-error>
              <input matInput
                     type="text"
                     placeholder="{{'MONITOR.CIRCUIT' | translate}}" formControlName="circuit">
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFill>
              <!-- MaxLength error -->
              <mat-error *ngIf="addDetailsMonitorForm.get('details').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.DETAILS' | translate, max: 255} }}
              </mat-error>
              <input matInput
                     type="text"
                     placeholder="{{'MONITOR.DETAILS' | translate}}" formControlName="details">
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFill>
              <!-- MaxLength error -->
              <mat-error *ngIf="addDetailsMonitorForm.get('comments').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.COMMENTS' | translate, max: 1000} }}
              </mat-error>
              <textarea matInput rows="6" formControlName="comments" placeholder="{{'MONITOR.COMMENTS' | translate}}"></textarea>
            </mat-form-field>
          </div>
        </fieldset>

        <div class="input-row" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
          <button (click)="goBackToStepOne()"
                  [color]="isEditMode() ? 'warn' : 'accent'" type="button"
                  mat-raised-button>
            {{ (isEditMode() ? 'MONITOR.CANCEL' : 'MONITOR.BACK') | translate }}
          </button>

          <button mat-raised-button color="primary" type="submit" [disabled]="!canAddDetails()">
              <span>
                  {{'MONITOR.NEXT' | translate}}
              </span>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</article>
