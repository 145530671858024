import { isNil } from 'lodash';
import { StringComparator } from '../../../../../application/command/string-comparator';
import { GridMonitorInformationComponent } from '../../../../components/grid/monitor-information/monitor-information.component';
import { GridMonitorSoftwareComponent } from '../../../../components/grid/monitor-software/monitor-software.component';
import { GridHeaderNameComponent } from "../../../../components/grid/header-name/grid-header-name.component";
import { GridEditMonitorComponent } from "../../../../components/grid/edit-monitor/grid-edit-monitor.component";
import { GridDeleteMonitorComponent } from "../../../../components/grid/grid-delete-monitor/grid-delete-monitor.component";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { GridMonitorSerialnumberComponent } from 'app/presentation/components/grid/monitor-serialnumber/monitor-serialnumber.component';
var ManageMonitorsDesktopComponent = /** @class */ (function () {
    function ManageMonitorsDesktopComponent(monitorsService) {
        this.monitors = [];
        this.monitorsService = monitorsService;
        this.createMonitorsGridOptions();
    }
    ManageMonitorsDesktopComponent.prototype.createMonitorsGridOptions = function () {
        var _this = this;
        this.monitorsGridOptions = {
            rowHeight: 70,
            headerHeight: 33,
            enableSorting: true,
            alwaysShowVerticalScroll: true,
            onGridSizeChanged: function () {
                _this.resizeGrid();
            },
            icons: {
                sortAscending: '<i class="fa fa-angle-down"/>',
                sortDescending: '<i class="fa fa-angle-up"/>'
            },
            columnDefs: this.getColumnDefs(),
        };
    };
    ManageMonitorsDesktopComponent.prototype.getColumnDefs = function () {
        return [
            {
                headerName: 'MONITOR.NAME',
                headerComponentFramework: GridHeaderNameComponent,
                field: 'details.name',
                comparator: StringComparator
            },
            {
                headerName: 'MONITOR.INFORMATION',
                headerComponentFramework: GridHeaderNameComponent,
                cellRendererFramework: GridMonitorInformationComponent,
                maxWidth: 225
            },
            {
                headerName: 'MONITOR.SERIALNUMBER',
                headerComponentFramework: GridHeaderNameComponent,
                cellRendererFramework: GridMonitorSerialnumberComponent,
                maxWidth: 225
            },
            {
                headerName: 'MONITOR.SOFTWARE',
                headerComponentFramework: GridHeaderNameComponent,
                cellRendererFramework: GridMonitorSoftwareComponent,
                maxWidth: 225, minWidth: 160, cellClass: 'grid-monitor-software'
            },
            {
                headerName: '',
                cellRendererFramework: GridEditMonitorComponent,
                width: 108
            },
            {
                headerName: '',
                cellRendererFramework: GridDeleteMonitorComponent,
                width: 108
            }
        ];
    };
    ManageMonitorsDesktopComponent.prototype.resizeGrid = function () {
        if (!isNil(this.monitorsGridOptions.api)) {
            this.monitorsGridOptions.api.sizeColumnsToFit();
        }
    };
    ManageMonitorsDesktopComponent.prototype.onGridReady = function (params) {
        this.overlayAPI = params.api;
        // This can be used to hide (in a hacky way) the 'no rows to show' popover on edge, because it glitches. Should be fixed after edge uses chromium. See bug 1552.
        // if(window.navigator.userAgent.indexOf("Edge") > -1) {
        //     this.overlayAPI.hideOverlay();
        // }
    };
    return ManageMonitorsDesktopComponent;
}());
export { ManageMonitorsDesktopComponent };
