var Logger = /** @class */ (function () {
    function Logger() {
    }
    return Logger;
}());
export { Logger };
var LoggerService = /** @class */ (function () {
    function LoggerService() {
    }
    LoggerService.prototype.invokeConsoleMethod = function (type, args) {
    };
    return LoggerService;
}());
export { LoggerService };
