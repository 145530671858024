import { Component, OnInit, Input } from '@angular/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notification: SystemNotification;
  expanded: boolean = true;
  hover= false;
  
  constructor(private userService: HttpUsersService) {

   }

  ngOnInit() {
  }

  deleteNotification() {
    this.expanded = false;
    this.userService.setNotificationRead(this.notification.id, true).subscribe();
  }

}
