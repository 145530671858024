<div fxLayout="column" fxFlex>
  <app-navigation-toolbar [subPages]="subPages"></app-navigation-toolbar>

  <app-progress-spinner
    *ngIf="isLoading" fxFlex
    [progressSpinnerText]="'NAVIGATION.VIEWS' | translate">
  </app-progress-spinner>

  <section class="view-list" fxFlex>
    <app-view-card *ngFor="let view of getViews() | async" [sensors]="view.sensors" [details]="fetchDetails(view)" [link] ="['/playgrounds', view.id, 'detail']"></app-view-card>
  </section>

  <app-footer></app-footer>
</div>
