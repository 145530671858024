/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../progress-spinner/progress-spinner.component.ngfactory";
import * as i3 from "../../progress-spinner/progress-spinner.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../message/message.component.ngfactory";
import * as i6 from "../message/message.component";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "@angular/common";
import * as i12 from "./chart.component";
import * as i13 from "../../../application/truncate-digits/truncate-digits.pipe";
import * as i14 from "@amcharts/amcharts3-angular";
import * as i15 from "../../../infrastructure/chart/chart.service";
import * as i16 from "./chartconfig.service";
var styles_ChartComponent = [i0.styles];
var RenderType_ChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartComponent, data: {} });
export { RenderType_ChartComponent as RenderType_ChartComponent };
function View_ChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-progress-spinner", [], null, null, null, i2.View_ProgressSpinnerComponent_0, i2.RenderType_ProgressSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProgressSpinnerComponent, [], { progressSpinnerText: [0, "progressSpinnerText"] }, null), i1.ɵpod(2, { sensor: 0 }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("CHART.LOADING_MEASUREMENTS", _ck(_v, 2, 0, "view"))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "id", 0], [4, "height", "px"], [4, "width", "%"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("chartdiv_" + _co.chart_id); var currVal_1 = 500; var currVal_2 = 100; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ChartComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-message", [], null, null, null, i5.View_MessageComponent_0, i5.RenderType_MessageComponent)), i1.ɵdid(1, 49152, null, 0, i6.MessageComponent, [], { messageText: [0, "messageText"], messageStyle: [1, "messageStyle"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("MONITOR.WARNINGS.NO_MEASUREMENTS")); var currVal_1 = _co.MESSAGE_STYLE.WARNING; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChartComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleScrollbar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("CHART.TOGGLE_SCROLLBAR_OFF")); _ck(_v, 2, 0, currVal_3); }); }
function View_ChartComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleScrollbar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("CHART.TOGGLE_SCROLLBAR_ON")); _ck(_v, 2, 0, currVal_3); }); }
function View_ChartComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMostRecent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("CHART.SHOW_LATEST")); _ck(_v, 2, 0, currVal_3); }); }
export function View_ChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_3)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_4)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_5)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_6)), i1.ɵdid(11, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isFetchingNewData || _co.isFirstLoad); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.canShowChart() && !_co.isFirstLoad); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.hasMeasurements() && !_co.isFetchingNewData); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.enableScrollbar; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.enableScrollbar; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.canShowLastAvailable; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_ChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart", [], null, null, null, View_ChartComponent_0, RenderType_ChartComponent)), i1.ɵdid(1, 4964352, null, 0, i12.ChartComponent, [i4.TranslateService, i13.TruncateDigitsPipe, i14.AmChartsService, i15.ChartService, i16.ChartconfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartComponentNgFactory = i1.ɵccf("app-chart", i12.ChartComponent, View_ChartComponent_Host_0, { isFetchingNewData: "isFetchingNewData", selectedDateRange: "selectedDateRange", measurements: "measurements", sensors: "sensors", monitorId: "monitorId", selectedDateRangeUpdater: "selectedDateRangeUpdater" }, { resetHasSelectedBetweenTwoDates: "resetHasSelectedBetweenTwoDates" }, []);
export { ChartComponentNgFactory as ChartComponentNgFactory };
