import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-registration-content',
  styleUrls: ['./registration-content.component.scss'],
  templateUrl: './registration-content.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RegistrationContentComponent {
}
