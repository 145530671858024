import FirmwareUpgradeState from './firmware-upgrade-state';

export class FirmwareUpgradeStatus {
  private _state: FirmwareUpgradeState;
  private _currentVersion: number;
  private _availableVersion: number;

  public static fromJSON(firmwareUpgradeStatusDTO: any): FirmwareUpgradeStatus {
    return new FirmwareUpgradeStatus(
      firmwareUpgradeStatusDTO.state,
      firmwareUpgradeStatusDTO.currentVersion,
      firmwareUpgradeStatusDTO.availableVersion
    );
  }

  constructor(state: FirmwareUpgradeState, currentVersion: number, availableVersion: number) {
    this._state = state;
    this._currentVersion = currentVersion;
    this._availableVersion = availableVersion;
  }

  get state(): FirmwareUpgradeState {
    return this._state;
  }


  get currentVersion(): number {
    return this._currentVersion;
  }


  get availableVersion(): number {
    return this._availableVersion;
  }
}
