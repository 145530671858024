
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Notification} from '../../../../application/notification/notification';
import {NotificationService} from '../../../../application/notification/notification.service';
import {validatePasswordMatch} from '../../../../application/validators/validate-password-match';
import {Users} from '../../../../infrastructure/http/user/users';
import {ErrorTranslator} from '../../../../application/command/error-translator/error-translator';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-reset-own-password',
  templateUrl: './reset-own-password.component.html',
  styleUrls: ['./reset-own-password.component.scss']
})

export class ResetOwnPasswordComponent implements OnDestroy {
  public resetPasswordForm: FormGroup;
  private usersService: Users;
  private unSubscribeUsersOnDestroy: Subject<boolean> = new Subject<boolean>();
  private formBuilder: FormBuilder;
  private updatePerforming = false;
  private notificationService: Notification;
  private route: Router;
  private errorTranslator: ErrorTranslator;

  constructor(usersService: HttpUsersService,
              formBuilder: FormBuilder,
              notificationService: NotificationService,
              route: Router,
              @Inject('ErrorTranslator') errorTranslator: ErrorTranslator) {
    this.usersService = usersService;
    this.formBuilder = formBuilder;
    this.notificationService = notificationService;
    this.route = route;
    this.errorTranslator = errorTranslator;

    this.resetPasswordForm = this.formBuilder.group({
      oldPassword: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      passwordConfirm: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
    }, {
      validator: validatePasswordMatch
    });
  }

  public ngOnDestroy() {
    this.unSubscribeUsersOnDestroy.next(true);
    this.unSubscribeUsersOnDestroy.complete();
  }

  public saveNewPassword(form: FormGroup): void {
    if (form.valid) {
      this.updatePerforming = true;
      const formValues = form.value;
      this.usersService.editPassword(formValues.oldPassword, formValues.password).pipe(
        takeUntil(this.unSubscribeUsersOnDestroy))
        .subscribe(() => {
          this.notificationService.show('USER.PASWORD_CHANGED_CONFIRMATION');
          this.route.navigateByUrl('/user-profile');
        }, (error: HttpErrorResponse) => {
          this.updatePerforming = false;

          this.errorTranslator.execute(error).pipe(
            takeUntil(this.unSubscribeUsersOnDestroy))
            .subscribe((errorMessage: string) => {
              throw new Error(errorMessage);
            });
        });
    }
  }

  public canSaveNewPassword(): boolean {
    return this.resetPasswordForm.valid && !this.updatePerforming;
  }

  public goBackToUserProfile() {
    this.route.navigateByUrl('/user-profile');
  }

}
