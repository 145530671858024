
import {map,  tap, filter, distinctUntilChanged } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable,  Subject, ReplaySubject, of } from 'rxjs';

import {User} from '../../../domain/user';
import {Users} from './users';
import { isNil } from 'lodash';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class HttpUsersService implements Users {
  private http: HttpClient;
  private notificationsSubject: Subject<SystemNotification[]> = new ReplaySubject(1);
  interval: NodeJS.Timeout;

  constructor(http: HttpClient, private authenticationService: AuthenticationService) {
    this.http = http;
    this.ngOnInit();
  }

  ngOnInit() {
    this.authenticationService.getAuthenticatedUser()
    .pipe(filter(a => !isNil(a)),
      distinctUntilChanged((obj1, obj2) => obj1 && obj2 && obj1.id === obj2.id))
    .subscribe((user) => {
      this.clear();
      setTimeout(() => this.getNotificationsDirect().subscribe(), 200)
      this.interval = setInterval(() => this.getNotificationsDirect().subscribe(), 60000)
    });
  }

  private clear() {
    clearInterval(this.interval);
    this.notificationsSubject.next([]);
  }

  public register(email: string,
                  password: string,
                  firstName: string,
                  lastName: string,
                  phone: string,
                  jobCategory: string,
                  jobTitle: string,
                  agreeToTermsAndConditions: boolean): Observable<Object> {
    return this.http.post('/api/users/', {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      jobCategory: jobCategory,
      jobTitle: jobTitle,
      agreeToTermsAndConditions: agreeToTermsAndConditions
    });
  }


  public verifyEmail(key: string): Observable<string> {
    return this.http.post('/api/users/verify-email', {key}, {responseType: 'text'});
  }

  public requestResetPassword(email: string): Observable<string> {
    return this.http.post('/api/users/password-reset', {
      email: email
    }, {responseType: 'text'});
  }

  public resetPassword(key: string, password: string): Observable<string> {
    return this.http.put(`/api/users/password-reset/${key}`, {
      password: password
    }, {responseType: 'text'});
  }

  public edit(user: User): Observable<User> {
    return this.http.put(`/api/users/me`, {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      jobCategory: user.jobCategory,
      jobTitle: user.jobTitle
    }).pipe(map(User.fromJSON));
  }
  
  public deleteUser(user: User): Observable<{}> {
  return this.http.post(`/api/users/me/delete`, {}).pipe(tap(() => this.authenticationService.logout()))
  }

  public editPassword(oldPassword: string, newPassword: string): Observable<User> {
    return this.http.put(`/api/users/me/password-change`, {
      oldPassword: oldPassword,
      newPassword: newPassword
    }).pipe(map(User.fromJSON));
  }

  public getNotificationsDirect(): Observable<SystemNotification[]> {
    return this.http.get<SystemNotification[]>("/api/notifications")
            .pipe(tap(obj => this.notificationsSubject.next(obj)))
  }

  public getNotifications(): Observable<SystemNotification[]> {
    return this.notificationsSubject
  }

  public setNotificationRead(id: string, read: boolean): Observable<SystemNotification> {
    return this.http.put<SystemNotification>(`/api/notifications/${id}/read`, {read})
          .pipe(tap(() => this.getNotificationsDirect().subscribe()))
  }

  public getSentNotifications(): Observable<SystemNotification[]> {
    return this.http.get<SystemNotification[]>(`/api/notifications/sent`)
  }
}
