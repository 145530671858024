import {AmChartsModule} from '@amcharts/amcharts3-angular';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule, NO_ERRORS_SCHEMA, ChangeDetectorRef} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AgGridModule} from 'ag-grid-angular';
import {LocalStorageModule} from 'angular-2-local-storage';
import {MomentModule} from 'angular2-moment';
import {ReCaptchaModule} from 'angular2-recaptcha';
import {NavigationComponent} from './presentation/pages/navigation/navigation.component';
import {NgxDnDModule} from '@swimlane/ngx-dnd';







import {APP_CONFIG, AppConfig} from './app.config';

import {ErrorTranslatorService} from './application/command/error-translator/error-translator.service';
import {GlobalErrorHandler} from './application/error/global-error-handler';
import {ConsoleLoggerService} from './application/logger/console-logger.service';
import {LoggerService} from './application/logger/logger.service';
import {NotificationService} from './application/notification/notification.service';
import {CanActivateLoginGuard} from './application/security/can-activate-login-guard';
import {CanActivateViaAuthGuard} from './application/security/can-activate-via-auth-guard';
import {HttpLoaderFactory} from './application/translation/httpLoaderFactory';
import {TruncateDigitsPipe} from './application/truncate-digits/truncate-digits.pipe';
import {AuthorizationHttpInterceptor} from './infrastructure/authorization-http-interceptor';
import {ChartService} from './infrastructure/chart/chart.service';
import {AuthenticationService} from './infrastructure/http/authentication/authentication.service';
import {InfoService} from './infrastructure/http/info/info.service';
import {HttpMonitorsService} from './infrastructure/http/monitor/http-monitors.service';
import {MockMonitorsService} from './infrastructure/http/monitor/mock-monitors.service';
import {HttpUsersService} from './infrastructure/http/user/http-users.service';
import {Views} from './infrastructure/http/view/views';
import {NavigationService} from './infrastructure/navigation/navigation.service';
import {MaterialModule} from './material.module';

import {AppComponent} from './presentation/app.component';
import {GridEditViewComponent} from './presentation/components/grid/edit-view/grid-edit-view.component';
import {GridHeaderNameComponent} from './presentation/components/grid/header-name/grid-header-name.component';
import {MessageComponent} from './presentation/components/message/message.component';
import {NavigationToolbarComponent} from './presentation/components//navigation-toolbar/navigation-toolbar.component';
import {ConfirmationDialogComponent} from './presentation/pages/confirmation-dialog/confirmation-dialog.component';
import {InfoComponent} from './presentation/pages/info/info.component';
import {InformationDialogComponent} from './presentation/pages/information-dialog/information-dialog.component';
import {LoginComponent} from './presentation/pages/login/login.component';
import {PrivacyStatementComponent} from './presentation/pages/login/privacy/privacy-statement.component';
import {RegistrationContentComponent} from './presentation/pages/login/registration-content/registration-content.component';
import {RequestResetPasswordComponent} from './presentation/pages/login/request-reset-password/request-reset-password.component';
import {ResetPasswordComponent} from './presentation/pages/login/reset-password/reset-password.component';
import {UserLoginComponent} from './presentation/pages/login/user-login/user-login.component';
import {UserRegistrationComponent} from './presentation/pages/login/user-registration/user-registration.component';
import {ResetOwnPasswordComponent} from './presentation/pages/user-profile/reset-own-password/reset-own-password.component';
import {UserProfileComponent} from './presentation/pages/user-profile/user-profile.component';
import {VerifyComponent} from './presentation/pages/verify/verify.component';
import {ManageViewsComponent} from './presentation/pages/views/manage-views/manage-views.component';
import {ViewAddComponent} from './presentation/pages/views/view-add/view-add.component';
import {ViewsComponent} from './presentation/pages/views/views.component';
import {ProgressSpinnerComponent} from './presentation/progress-spinner/progress-spinner.component';
import {MonitorsModule} from './presentation/pages/monitors/monitors.module';
import {ViewDndSortingComponent} from './presentation/pages/views/view-details/view-dnd-sorting/view-dnd-sorting.component';
import {SharedModule} from './shared.module';
import {ArraySummationPipe} from './application/array-summation/arraysummation.pipe';
import {ChartconfigService} from './presentation/components/chart/chartconfig.service';
import {DetailsComponent} from './presentation/components/details/details.component';
import {DnDItemComponent} from './presentation/pages/views/view-details/view-dnd-sorting/view-dnd-item/dnd-item.component';
import {ViewsModule} from './presentation/pages/views/views.module';
import {FlexLayoutModule} from "@angular/flex-layout";
import {ViewDataService} from "./infrastructure/data/view-data.service";
import {GridDeleteViewComponent} from './presentation/components/grid/grid-delete-view/grid-delete-view.component';
import {MonitorDataService} from "./infrastructure/data/monitor-data.service";
import {ErrorHttpInterceptor} from "./infrastructure/http/ErrorHttpInterceptor";
import { NotificationsComponent } from './presentation/pages/user-profile/notifications/notifications.component';
import { MatExpansionModule, MatDialogModule, MatChipsModule, MatSelectModule } from '@angular/material';
import { NotificationComponent } from './presentation/pages/user-profile/notifications/notification/notification.component';
import {MatBadgeModule} from '@angular/material/badge'; 
import { ActionComponent } from './presentation/pages/actions/action/action.component';
import { ShareComponent } from './presentation/components/overview-card/share/share.component';
import { DetailsUsersComponent } from './presentation/components/details/details-users/details-users.component';
import { DetailsChatComponent } from './presentation/components/details/details-chat/details-chat.component';
import { RequestComponent } from "./presentation/components/request/request.component";
import { PopUpModalComponent } from "./presentation/pages/popup-modal/popup-modal.component";


const appRoutes: Routes = [];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        PopUpModalComponent,
        UserLoginComponent,
        UserRegistrationComponent,
        RequestResetPasswordComponent,
        ResetPasswordComponent,
        VerifyComponent,
        InformationDialogComponent,
        ConfirmationDialogComponent,
        InfoComponent,
        RegistrationContentComponent,
        GridHeaderNameComponent,
        GridEditViewComponent,
        UserProfileComponent,
        ResetOwnPasswordComponent,
        PrivacyStatementComponent,
        ViewDndSortingComponent,
        ArraySummationPipe,
        DetailsComponent,
        DnDItemComponent,
        GridDeleteViewComponent,
        NotificationsComponent,
        NotificationComponent,
        ActionComponent,
        ShareComponent,
        DetailsChatComponent,
        RequestComponent
    ],
    imports: [
        BrowserModule,
        MonitorsModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        RouterModule.forRoot(appRoutes),
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LocalStorageModule.forRoot({
            prefix: 'ninix-app',
            storageType: 'localStorage'
        }),
        ReCaptchaModule,
        AgGridModule.withComponents([
            GridHeaderNameComponent,
            GridEditViewComponent,
        ]),
        MomentModule,
        AmChartsModule,
        NgxDnDModule,
        SharedModule,
        ViewsModule,
        FlexLayoutModule,
        MatExpansionModule,
        MatDialogModule,
        MatChipsModule,
        MatSelectModule
    ],
    providers: [
        {provide: APP_CONFIG, useValue: AppConfig},
        NavigationService,
        ChartService,
        AuthenticationService,
        HttpUsersService,
        InfoService,
        CanActivateViaAuthGuard,
        CanActivateLoginGuard,
        MockMonitorsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHttpInterceptor,
            multi: true
        },
        NotificationService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: LoggerService,
            useClass: ConsoleLoggerService
        },
        TruncateDigitsPipe,
        {
            provide: 'ErrorTranslator',
            useClass: ErrorTranslatorService
        },
        {
          provide: 'Views',
          useClass: ViewDataService
        },
        MonitorDataService,
        ChartconfigService,
        HttpMonitorsService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        LoginComponent,
        PopUpModalComponent,
        UserLoginComponent,
        UserRegistrationComponent,
        RequestResetPasswordComponent,
        ResetPasswordComponent,
        VerifyComponent,
        NavigationComponent,
        InformationDialogComponent,
        ConfirmationDialogComponent,
        InfoComponent,
        MessageComponent,
        NavigationToolbarComponent,
        ProgressSpinnerComponent,
        UserProfileComponent,
        PrivacyStatementComponent,
        ResetOwnPasswordComponent,
        ViewsComponent,
        ViewAddComponent,
        ManageViewsComponent,
        DetailsComponent,
        NotificationsComponent,
        ActionComponent,
        ShareComponent,
        RequestComponent
    ],
    schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule {
}
