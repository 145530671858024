import {AbstractControl} from '@angular/forms';

export function validatePasswordMatch(abstractControl: AbstractControl) {
  const passwordConfirmControl = abstractControl.get('passwordConfirm');
  const password = abstractControl.get('password').value;
  const confirmPassword = passwordConfirmControl.value;

  if (password !== confirmPassword) {
    passwordConfirmControl.setErrors({matchPassword: true});
  }
}
