import { takeUntil } from 'rxjs/operators';
import { EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { map } from 'lodash';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { validatePasswordMatch } from '../../../../application/validators/validate-password-match';
import JobCategory from '../../../../domain/jobCategory';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var UserRegistrationComponent = /** @class */ (function () {
    function UserRegistrationComponent(formBuilder, userService, router, errorTranslator) {
        var _this = this;
        this.loginRequested = new EventEmitter();
        this.jobCategories = JobCategory;
        this.hasRegistered = false;
        this.registerPerforming = false;
        this.ngUnsubscribe = new Subject();
        this.recaptchaValid = false;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.errorTranslator = errorTranslator;
        this.keyJobCategories = map(Object.keys(this.jobCategories), function (key) {
            return _this.jobCategories[key].toUpperCase();
        });
        this.registerForm = this.formBuilder.group({
            'firstName': ['', [Validators.required, Validators.maxLength(255)]],
            'lastName': ['', [Validators.required, Validators.maxLength(255)]],
            'email': ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
            'password': ['', [
                    Validators.required,
                    Validators.minLength(6)
                ]],
            'passwordConfirm': ['', [
                    Validators.required,
                    Validators.minLength(6)
                ]],
            'jobCategory': ['', Validators.required],
            'jobTitle': ['', Validators.maxLength(255)],
            'phone': ['', Validators.maxLength(255)],
            'agreeToTermsAndConditions': [false, Validators.requiredTrue]
        }, {
            validator: validatePasswordMatch
        });
        this.recaptchaKey = environment.recaptcha_key;
    }
    UserRegistrationComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    UserRegistrationComponent.prototype.register = function (form, isValid) {
        var _this = this;
        if (isValid) {
            this.registerPerforming = true;
            this.userEmail = this.registerForm.get('email').value;
            this.userService.register(form.email, form.password, form.firstName, form.lastName, form.phone, form.jobCategory, form.jobTitle, form.agreeToTermsAndConditions).pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function () {
                _this.hasRegistered = true;
                _this.registerForm.reset();
                //Causes errors on reset after completion: see bug 1551
                // this.resetCaptcha();
            }, function (error) {
                _this.registerPerforming = false;
                var interpolateParams = { email: form.email };
                _this.errorTranslator.execute(error, interpolateParams).pipe(takeUntil(_this.ngUnsubscribe))
                    .subscribe(function (errorMessage) {
                    throw new Error(errorMessage);
                });
            });
        }
    };
    UserRegistrationComponent.prototype.canRegister = function () {
        return this.registerForm.valid && !this.registerPerforming && this.recaptchaValid;
    };
    UserRegistrationComponent.prototype.goToLogin = function () {
        return this.loginRequested.emit(true);
    };
    UserRegistrationComponent.prototype.handleCorrectCaptcha = function () {
        this.recaptchaValid = true;
    };
    UserRegistrationComponent.prototype.resetCaptcha = function () {
        this.captcha.reset();
    };
    return UserRegistrationComponent;
}());
export { UserRegistrationComponent };
