import {Component} from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  private showRegistration = false;

  constructor() {
  }

  public canShowRegistration(): boolean {
    return this.showRegistration;
  }

  public registerRequested(): void {
    this.showRegistration = true;
  }

  public loginRequested(): void {
    this.showRegistration = false;
  }
}
