import { takeUntil } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash';
import { Subject } from 'rxjs';
import { StringComparator } from '../../../../application/command/string-comparator';
import { GridEditViewComponent } from '../../../components/grid/edit-view/grid-edit-view.component';
import { GridHeaderNameComponent } from '../../../components/grid/header-name/grid-header-name.component';
import { GridDeleteViewComponent } from "../../../components/grid/grid-delete-view/grid-delete-view.component";
import { MediaObserver } from '@angular/flex-layout';
var ManageViewsComponent = /** @class */ (function () {
    function ManageViewsComponent(route, viewsService, media) {
        var _this = this;
        this.views = [];
        this.subPages = [];
        this.unSubscribeViewsOnDestroy = new Subject();
        this.subPages = route.snapshot.data.parentPage.subPages;
        this.viewsService = viewsService;
        this.media = media;
        this.createViewsGridOptions();
        this.updateGridParametersByMediaChanged();
        this.mediaSubscriber = this.media.asObservable().subscribe(function () {
            _this.updateGridParametersByMediaChanged();
        });
    }
    ManageViewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.viewsService.getAllForStatusOverview().pipe(takeUntil(this.unSubscribeViewsOnDestroy))
            .subscribe(function (views) {
            _this.views = views;
        });
    };
    ManageViewsComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeViewsOnDestroy.next(true);
        this.unSubscribeViewsOnDestroy.complete();
    };
    ManageViewsComponent.prototype.canShowGrid = function () {
        return this.gridMode;
    };
    ManageViewsComponent.prototype.createViewsGridOptions = function () {
        var _this = this;
        this.viewsGridOptions = {
            rowHeight: 70,
            headerHeight: 33,
            enableSorting: true,
            onGridSizeChanged: function () {
                _this.resizeGrid();
            },
            icons: {
                sortAscending: '<i class="fa fa-angle-down"/>',
                sortDescending: '<i class="fa fa-angle-up"/>'
            },
            columnDefs: this.getColumnDefs()
        };
    };
    ManageViewsComponent.prototype.getColumnDefs = function () {
        return [
            {
                headerName: 'VIEW.NAME',
                headerComponentFramework: GridHeaderNameComponent,
                field: 'name',
                comparator: StringComparator
            },
            {
                headerName: '',
                cellRendererFramework: GridEditViewComponent,
                width: 108
            },
            {
                headerName: '',
                cellRendererFramework: GridDeleteViewComponent,
                width: 108
            }
        ];
    };
    ManageViewsComponent.prototype.resizeGrid = function () {
        if (!isNil(this.viewsGridOptions.api)) {
            this.viewsGridOptions.api.sizeColumnsToFit();
        }
    };
    ManageViewsComponent.prototype.updateGridParametersByMediaChanged = function () {
        this.gridMode = this.media.isActive('gt-xs');
    };
    return ManageViewsComponent;
}());
export { ManageViewsComponent };
