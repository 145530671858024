import {MonitorSensor} from '../monitor/monitorSensor';
import { StatusOverview } from '../StatusOverview';

export class View implements StatusOverview {
    private _id: string;
    private _name: string;
    private _elucidation: string;
    private _sensors: MonitorSensor[];

    public static fromJSON(viewDTO: any): View {
        const sensors: MonitorSensor[] = viewDTO.sensors.map(MonitorSensor.fromJSON);
        if (viewDTO.order) {
            for (let key in viewDTO.order) {
                const sensor = sensors.find(sensor => sensor.id === key);
                if (sensor) {
                    sensor.order = viewDTO.order[key];
                }
            }
        }

        return new View(
            viewDTO.id,
            viewDTO.name,
            viewDTO.elucidation,
            sensors
        );
    }

    constructor(id: string, name: string, elucidation: string, sensors: MonitorSensor[]) {
        this._id = id;
        this._name = name;
        this._elucidation = elucidation;
        this._sensors = sensors;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }


    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }


    get elucidation(): string {
        return this._elucidation;
    }

    set elucidation(value: string) {
        this._elucidation = value;
    }


    get sensors(): MonitorSensor[] {
        return this._sensors;
    }

    set sensors(value: MonitorSensor[]) {
        this._sensors = value;
    }

}
