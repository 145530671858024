var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { filter } from 'lodash';
var ChartService = /** @class */ (function () {
    function ChartService() {
        this.charts = [];
    }
    // Collection manipulation
    ChartService.prototype.addChart = function (chart) {
        this.removeZoomedListenerFromCharts();
        var isCopyed = false;
        (this.charts || []).forEach(function (x) {
            if (x === chart) {
                x = chart;
                isCopyed = true;
            }
        });
        if (!isCopyed)
            this.charts.push(chart);
        if (this.lastZoomedTo) {
            chart.ignoreZoom = false;
            this.zoomOnRender(chart);
        }
        this.addZoomedListenerToCharts();
    };
    ChartService.prototype.updateChart = function (chart) {
        this.removeZoomedListenerFromCharts();
        (this.charts || []).forEach(function (x) {
            if (x === chart) {
                x = chart;
            }
        });
        this.addZoomedListenerToCharts();
    };
    ChartService.prototype.isChartExist = function (chart, customChartId) {
        return filter(this.charts, function (arrchart) { return arrchart === chart && arrchart.chartCustomId === customChartId; }).length > 0;
    };
    ChartService.prototype.removeChart = function (chart) {
        this.removeZoomedListenerFromCharts();
        this.charts = filter(this.charts, function (arrchart) { return arrchart !== chart; });
        this.addZoomedListenerToCharts();
    };
    ;
    ChartService.prototype.pickedANewDate = function () {
        this.lastZoomedTo = null;
    };
    ChartService.prototype.onDestroy = function () {
        this.removeZoomedListenerFromCharts();
        this.charts = [];
        this.lastZoomedTo = null;
    };
    // Listner and event handling
    ChartService.prototype.addZoomedListenerToCharts = function () {
        if (this.charts.length > 1) {
            for (var i = 0; i < this.charts.length; i++) {
                this.charts[i].addListener('zoomed', this.handleZoomedEvent.bind(this));
            }
        }
    };
    ChartService.prototype.removeZoomedListenerFromCharts = function () {
        if (this.charts.length > 1) {
            for (var i = 0; i < this.charts.length; i++) {
                this.charts[i].removeListener(this.charts[i], 'zoomed', this.handleZoomedEvent.bind(this));
            }
        }
    };
    ChartService.prototype.handleZoomedEvent = function (event) {
        this.charts.forEach(function (chart) {
            return event.chart !== chart && chart.zoomToDates(event.startDate, event.endDate);
        });
        this.lastZoomedTo = { startDate: event.startDate, endDate: event.endDate };
    };
    ChartService.prototype.zoomOnRender = function (chart) {
        // When a new chart is added, a 'zoom' event is instantly broadcast and resets the class var. This is prevented by copying the current state before said event occurs.
        var preserved = __assign({}, this.lastZoomedTo);
        chart.addListener('rendered', function (event) { return chart.zoomToDates(preserved.startDate, preserved.endDate); });
    };
    return ChartService;
}());
export { ChartService };
