import {Component, Inject, Input} from '@angular/core';
import {ColDef, GridOptions} from 'ag-grid';
import {isNil} from 'lodash';
import {StringComparator} from '../../../../../application/command/string-comparator';
import {Monitor} from '../../../../../domain/monitor/monitor';
import {HttpMonitorsService} from '../../../../../infrastructure/http/monitor/http-monitors.service';
import {Monitors} from '../../../../../infrastructure/http/monitor/monitors';
import {GridMonitorInformationComponent} from '../../../../components/grid/monitor-information/monitor-information.component';
import {GridMonitorSoftwareComponent} from '../../../../components/grid/monitor-software/monitor-software.component';
import {GridHeaderNameComponent} from "../../../../components/grid/header-name/grid-header-name.component";
import {GridEditMonitorComponent} from "../../../../components/grid/edit-monitor/grid-edit-monitor.component";
import {GridDeleteMonitorComponent} from "../../../../components/grid/grid-delete-monitor/grid-delete-monitor.component";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { GridMonitorSerialnumberComponent } from 'app/presentation/components/grid/monitor-serialnumber/monitor-serialnumber.component';

@Component({
    selector: 'app-desktop-manage-monitors',
    templateUrl: './manage-monitors.component.html',
    styleUrls: ['./manage-monitors.component.scss']
})
export class ManageMonitorsDesktopComponent {
    @Input() public monitors: Monitor[] = [];
    public monitorsGridOptions: GridOptions;
    private monitorsService: Monitors;
    private overlayAPI;


    constructor(monitorsService: MonitorDataService) {
        this.monitorsService = monitorsService;

        this.createMonitorsGridOptions();
    }

    private createMonitorsGridOptions(): void {
        this.monitorsGridOptions = <GridOptions>{
            rowHeight: 70,
            headerHeight: 33,
            enableSorting: true,
            alwaysShowVerticalScroll: true,
            onGridSizeChanged: () => {
                this.resizeGrid();
            },
            icons: {
                sortAscending: '<i class="fa fa-angle-down"/>',
                sortDescending: '<i class="fa fa-angle-up"/>'
            },
            columnDefs: this.getColumnDefs(),
        };
    }

    private getColumnDefs(): ColDef[] {
        return [
            {
                headerName: 'MONITOR.NAME',
                headerComponentFramework: <{ new(): GridHeaderNameComponent }>GridHeaderNameComponent,
                field: 'details.name',
                comparator: StringComparator
            },
            {
                headerName: 'MONITOR.INFORMATION',
                headerComponentFramework: <{ new(): GridHeaderNameComponent }>GridHeaderNameComponent,
                cellRendererFramework: GridMonitorInformationComponent,
                maxWidth: 225
            },
            {
                headerName: 'MONITOR.SERIALNUMBER',
                headerComponentFramework: <{ new(): GridHeaderNameComponent }>GridHeaderNameComponent,
                cellRendererFramework: GridMonitorSerialnumberComponent,
                maxWidth: 225
            },
            {
                headerName: 'MONITOR.SOFTWARE',
                headerComponentFramework: <{ new(): GridHeaderNameComponent }>GridHeaderNameComponent,
                cellRendererFramework: GridMonitorSoftwareComponent,
                maxWidth: 225, minWidth: 160, cellClass: 'grid-monitor-software'
            },
            {
                headerName: '',
                cellRendererFramework: GridEditMonitorComponent,
                width: 108
            },
            {
                headerName: '',
                cellRendererFramework: GridDeleteMonitorComponent,
                width: 108
            }
        ];
    }

    private resizeGrid(): void {
        if (!isNil(this.monitorsGridOptions.api)) {
            this.monitorsGridOptions.api.sizeColumnsToFit();
        }

    }

    public onGridReady(params) {
        this.overlayAPI = params.api;
        // This can be used to hide (in a hacky way) the 'no rows to show' popover on edge, because it glitches. Should be fixed after edge uses chromium. See bug 1552.
        // if(window.navigator.userAgent.indexOf("Edge") > -1) {
        //     this.overlayAPI.hideOverlay();
        // }
    }


}
