
import {timer as observableTimer, Observable, Subject, Subscription} from 'rxjs';

import {switchMap, takeUntil,  distinctUntilChanged, take } from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {ActivatedRoute} from '@angular/router';
import {each} from 'lodash';
import {FirmwareUpgradeStatus} from '../../../../domain/monitor/firmware/firmware-upgrade-status';
import {Monitor} from '../../../../domain/monitor/monitor';
import {Page} from '../../../../domain/page';
import {environment} from '../../../../../environments/environment';
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { HttpMonitorsService } from 'app/infrastructure/http/monitor/http-monitors.service';

@Component({
    selector: 'app-manage-monitors',
    templateUrl: './manage-monitors.component.html',
    styleUrls: ['./manage-monitors.component.scss']
})
export class ManageMonitorsComponent implements OnInit, OnDestroy {
    public subPages: Page[] = [];
    public monitors: MonitorStatusOverview[] = [];
    public isLoading: Boolean = true;
    private media: MediaObserver;
    private mediaWatcher: Subscription;
    private gridMode = false;
    private unSubscribeMonitorsOnDestroy: Subject<boolean> = new Subject<boolean>();
    private unSubscribeTimer: Subject<boolean> = new Subject<boolean>();


    constructor(route: ActivatedRoute,
                media: MediaObserver,
                private monitorsService: HttpMonitorsService) {
        this.subPages = route.snapshot.data.parentPage.subPages;
        this.media = media;
    }

    public ngOnInit() {
        this.updateGridParametersByMediaChanged();
        this.mediaWatcher = this.media.asObservable().subscribe(() => {
            this.updateGridParametersByMediaChanged();
        });
        this.monitorsService.getAllForStatusOverview().pipe(
            take(1),
            takeUntil(this.unSubscribeMonitorsOnDestroy))
            .pipe(distinctUntilChanged((a,b) => a.length != b.length))
            .subscribe((monitors: MonitorStatusOverview[]) => {
                this.unSubscribeTimer.next(true);
                this.monitors = monitors;
                each(this.monitors, (monitor: MonitorStatusOverview) => {
                    observableTimer(0, environment.pollingIntervalInSeconds * 1000).pipe(
                        switchMap(() => this.monitorsService.getFirmwareUpgradeStatus(monitor.id)),
                        takeUntil(this.unSubscribeTimer),)
                        .subscribe((status: FirmwareUpgradeStatus) => {
                            monitor.firmwareUpgradeStatus = status;
                        });
                });
                this.isLoading = false;
            });
    }

    public ngOnDestroy() {
        this.unSubscribeTimer.next(true);
        this.unSubscribeTimer.complete();
        this.unSubscribeMonitorsOnDestroy.next(true);
        this.unSubscribeMonitorsOnDestroy.complete();
    }

    public isDesktopView(): boolean {
        return this.gridMode;
    }

    private updateGridParametersByMediaChanged() {
        this.gridMode = this.media.isActive('gt-sm');
    }
}
