import { MonitorSensor } from '../monitor/monitorSensor';
var View = /** @class */ (function () {
    function View(id, name, elucidation, sensors) {
        this._id = id;
        this._name = name;
        this._elucidation = elucidation;
        this._sensors = sensors;
    }
    View.fromJSON = function (viewDTO) {
        var sensors = viewDTO.sensors.map(MonitorSensor.fromJSON);
        if (viewDTO.order) {
            var _loop_1 = function (key) {
                var sensor = sensors.find(function (sensor) { return sensor.id === key; });
                if (sensor) {
                    sensor.order = viewDTO.order[key];
                }
            };
            for (var key in viewDTO.order) {
                _loop_1(key);
            }
        }
        return new View(viewDTO.id, viewDTO.name, viewDTO.elucidation, sensors);
    };
    Object.defineProperty(View.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(View.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(View.prototype, "elucidation", {
        get: function () {
            return this._elucidation;
        },
        set: function (value) {
            this._elucidation = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(View.prototype, "sensors", {
        get: function () {
            return this._sensors;
        },
        set: function (value) {
            this._sensors = value;
        },
        enumerable: true,
        configurable: true
    });
    return View;
}());
export { View };
