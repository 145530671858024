import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
  message: String;

  actions = {
    "share": (segments: UrlSegment[]) => this.acceptMonitorShare(segments),
    "request": (segments: UrlSegment[]) => this.acceptShareRequest(segments)
  }

  constructor(private route: ActivatedRoute, private router: Router, private monitorService: MonitorDataService) {
    this.route.url.subscribe(segments => {
      const action = segments[1];
      if(action && this.actions[action.path]) {
        this.actions[action.path](segments);
      } else if(action) {
        this.message = `The action ${action.path} is not known.`;
      }
    })

   }

  acceptShareRequest(segments: UrlSegment[]) {
    if(segments.length < 6) {
      throw new Error("Illegal amount of arguments");
    }
    const serial = segments[2].path
    const key = segments[5].path
    const mail = segments[3].path
    const role = segments[4].path
    this.monitorService.addByShareRequest(serial, key, mail, role).subscribe(() => this.redirectToNotif());
  }
   
  acceptMonitorShare(segments: UrlSegment[]) {
    if(segments.length < 6) {
      throw new Error("Illegal amount of arguments");
    }
    const serial = segments[2].path
    const role = segments[5].path
    const key = segments[3].path
    const mail = atob(segments[4].path)
    this.monitorService.addByKey(serial, role, key, mail).subscribe(() => this.redirectToOverview());
  }

  redirectToOverview() {
    setTimeout(() =>  this.router.navigateByUrl("/monitors"), 1)
  }
  
  redirectToNotif() {
    setTimeout(() =>  this.router.navigateByUrl("/notifications"), 1)
  }
   
  ngOnInit() {}
}
