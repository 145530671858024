import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
var NotificationService = /** @class */ (function () {
    function NotificationService(snackBar, translate) {
        this.snackBar = snackBar;
        this.translate = translate;
    }
    NotificationService.prototype.show = function (message) {
        var _this = this;
        this.translate.get(message, {}).subscribe(function (res) {
            _this.snackBar.open(res, null, {
                duration: 2000
            });
        });
    };
    return NotificationService;
}());
export { NotificationService };
