import { isNil, last, sortBy } from 'lodash';
import { MonitorDetails } from './monitorDetails';
import { MonitorSensor } from './monitorSensor';
var MonitorStatusOverview = /** @class */ (function () {
    function MonitorStatusOverview(id, serialNumber, monitorType, statusInfo, monitorDetails, sensors, order, editableByCurrentUser, channels, privacy, numberOfLinkedUsers, backendId, permissions) {
        this._id = id;
        this._serialNumber = serialNumber;
        this._monitorType = monitorType;
        this._statusInfo = statusInfo;
        this._details = monitorDetails;
        this._sensors = sensors;
        this._order = order;
        this._editableByCurrentUser = editableByCurrentUser;
        this._channels = channels;
        this._privacy = privacy;
        this._numberOfLinkedUsers = numberOfLinkedUsers;
        this._backendId = backendId;
        this._permissions = permissions;
        if (order) {
            var _loop_1 = function (key) {
                var sensor = sensors.find(function (sensor) { return sensor.id === key; });
                if (sensor) {
                    sensor.order = order[key];
                }
            };
            for (var key in order) {
                _loop_1(key);
            }
        }
    }
    MonitorStatusOverview.fromJSON = function (monitorStatusOverviewDTO) {
        return new MonitorStatusOverview(monitorStatusOverviewDTO.id, monitorStatusOverviewDTO.serialNumber, monitorStatusOverviewDTO.monitorType, monitorStatusOverviewDTO.statusInfo, MonitorDetails.fromJSON(monitorStatusOverviewDTO.details), monitorStatusOverviewDTO.sensors.map(MonitorSensor.fromJSON), monitorStatusOverviewDTO.order, monitorStatusOverviewDTO.editableByCurrentUser, monitorStatusOverviewDTO.channels, monitorStatusOverviewDTO.privacy, monitorStatusOverviewDTO.numberOfLinkedUsers, monitorStatusOverviewDTO.backendId, monitorStatusOverviewDTO.permissions);
    };
    Object.defineProperty(MonitorStatusOverview.prototype, "order", {
        get: function () {
            return this._order;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "statusInfo", {
        get: function () {
            return this._statusInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "backendId", {
        get: function () {
            return this._backendId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "serialNumber", {
        get: function () {
            return this._serialNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "monitorType", {
        get: function () {
            return this._monitorType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "channels", {
        get: function () {
            return this._channels;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "privacy", {
        get: function () {
            return this._privacy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "dateTimeAdded", {
        get: function () {
            return this._dateTimeAdded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "firmwareUpgradeStatus", {
        get: function () {
            return this._firmwareUpgradeStatus;
        },
        set: function (value) {
            this._firmwareUpgradeStatus = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "details", {
        get: function () {
            return this._details;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "sensors", {
        get: function () {
            return this._sensors;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "editableByCurrentUser", {
        get: function () {
            return this._editableByCurrentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "numberOfLinkedUsers", {
        get: function () {
            return this._numberOfLinkedUsers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "lastMeasuredDateTime", {
        get: function () {
            var sensorOfLastMeasuredDateTime = last(sortBy(this.sensors, ['lastMeasuredDateTime']).filter(function (x) { return x.lastMeasuredDateTime != null; }));
            if (isNil(sensorOfLastMeasuredDateTime)) {
                return null;
            }
            else {
                return sensorOfLastMeasuredDateTime.lastMeasuredDateTime;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorStatusOverview.prototype, "permissions", {
        get: function () {
            return this._permissions;
        },
        enumerable: true,
        configurable: true
    });
    return MonitorStatusOverview;
}());
export { MonitorStatusOverview };
