import { ErrorHandler, Injector, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material';
import { InformationDialogComponent } from '../../presentation/pages/information-dialog/information-dialog.component';
import { LoggerService } from '../logger/logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
var GlobalErrorHandler = /** @class */ (function () {
    function GlobalErrorHandler(injector, ngZone, logger /*, router: Router*/) {
        this.dialogConfig = {
            panelClass: 'api-error-dialog'
        };
        this.UnauthorizedErrorCode = 401;
        this.injector = injector;
        this.ngZone = ngZone;
        this.logger = logger;
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
        var _this = this;
        this.logger.error(error);
        if (this.isOpen) {
            return;
        }
        if (error instanceof HttpErrorResponse && error.status === this.UnauthorizedErrorCode) {
            var router = this.injector.get(Router);
            router.navigateByUrl('/login');
        }
        else if (error instanceof HttpErrorResponse) {
            this.ngZone.run(function () {
                var dialog = _this.injector.get(MatDialog);
                var translation = _this.injector.get(TranslateService);
                translation.get("ERRORS.HTTPERROR")
                    .subscribe(function (text) {
                    _this.isOpen = true;
                    var dialogRef = dialog.open(InformationDialogComponent, _this.dialogConfig);
                    dialogRef.componentInstance.textContent = text;
                    dialogRef.afterClosed().subscribe(function () { return _this.isOpen = false; });
                });
            });
        }
        else {
            this.ngZone.run(function () {
                _this.isOpen = true;
                var dialog = _this.injector.get(MatDialog);
                var dialogRef = dialog.open(InformationDialogComponent, _this.dialogConfig);
                dialogRef.componentInstance.textContent = error.message;
                dialogRef.afterClosed().subscribe(function () { return _this.isOpen = false; });
            });
        }
    };
    return GlobalErrorHandler;
}());
export { GlobalErrorHandler };
