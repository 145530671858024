import { of as observableOf, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { isUndefined } from 'lodash';
var ErrorTranslatorService = /** @class */ (function () {
    function ErrorTranslatorService(translateService) {
        this.unSubscribeErrorTranslatorOnDestroy = new Subject();
        this.translateService = translateService;
    }
    ErrorTranslatorService.prototype.execute = function (httpErrorResponse, interpolateParams) {
        if (interpolateParams === void 0) { interpolateParams = {}; }
        var errorType;
        var errorMessage;
        var error = httpErrorResponse.error;
        if (typeof error === 'string') {
            error = JSON.parse(error);
        }
        if (!isUndefined(error.type) && !isUndefined(error.message)) {
            errorType = error.type;
            errorMessage = error.message;
        }
        else if (!isUndefined(error.error_description)) {
            errorType = error.error;
            errorMessage = error.error_description;
        }
        if (!isUndefined(errorType) && !isUndefined(errorMessage)) {
            return this.translateService.get("ERRORS." + errorType, interpolateParams).pipe(takeUntil(this.unSubscribeErrorTranslatorOnDestroy), map(function (translation) {
                if (translation !== "ERRORS." + errorType) {
                    errorMessage = translation;
                }
                return errorMessage;
            }));
        }
        else {
            return observableOf(httpErrorResponse.message);
        }
    };
    return ErrorTranslatorService;
}());
export { ErrorTranslatorService };
