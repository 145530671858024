<section fxFlex fxLayout="column" (keypress)="keyDownFunction($event)">
  <header class="primary-color">
    <button class="close-button" mat-icon-button type="button" mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </header>
  <mat-dialog-content fxFlex fxLayout="column" class="information-dialog-container">
    <h5>{{textContent}}</h5>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutGap="10px">
    <button fxFlex mat-raised-button color="primary" mat-dialog-close>OK
    </button>
  </mat-dialog-actions>
</section>
