import {isNil, last, sortBy} from 'lodash';
import {MonitorStatusInfo} from './monitor-status-info';
import {MonitorDetails} from './monitorDetails';
import {MonitorSensor} from './monitorSensor';
import MonitorChannel from './monitorChannel';
import MonitorPrivacy from './monitorPrivacy';
import { FirmwareUpgradeStatus } from './firmware/firmware-upgrade-status';
import { StatusOverview } from '../StatusOverview';

export class MonitorStatusOverview implements StatusOverview {
    private _id: string;
    private _serialNumber: number;
    private _monitorType: string;
    private _statusInfo: MonitorStatusInfo;
    private _details: MonitorDetails;
    private _sensors: MonitorSensor[];
    private _order: { [s: string]: number };
    private _editableByCurrentUser: boolean;
    private _channels: MonitorChannel[];
    private _privacy: MonitorPrivacy;
    private _numberOfLinkedUsers: number;
    private _dateTimeAdded: string;
    private _backendId: number;
    private _firmwareUpgradeStatus: FirmwareUpgradeStatus;
    private _permissions: string[];


  
    public static fromJSON(monitorStatusOverviewDTO): MonitorStatusOverview {
        return new MonitorStatusOverview(
            monitorStatusOverviewDTO.id,
            monitorStatusOverviewDTO.serialNumber,
            monitorStatusOverviewDTO.monitorType,
            monitorStatusOverviewDTO.statusInfo,
            MonitorDetails.fromJSON(monitorStatusOverviewDTO.details),
            monitorStatusOverviewDTO.sensors.map(MonitorSensor.fromJSON),
            monitorStatusOverviewDTO.order,
            monitorStatusOverviewDTO.editableByCurrentUser,
            monitorStatusOverviewDTO.channels,
            monitorStatusOverviewDTO.privacy,
            monitorStatusOverviewDTO.numberOfLinkedUsers,
            monitorStatusOverviewDTO.backendId,
            monitorStatusOverviewDTO.permissions
        );
    }

    constructor(id: string,
                serialNumber: number,
                monitorType: string,
                statusInfo: MonitorStatusInfo,
                monitorDetails: MonitorDetails,
                sensors: MonitorSensor[],
                order: { [s: string]: number }, editableByCurrentUser: boolean, channels: MonitorChannel[],  privacy: MonitorPrivacy, numberOfLinkedUsers: number, backendId: number, permissions: string[]) {
        this._id = id;
        this._serialNumber = serialNumber;
        this._monitorType = monitorType;
        this._statusInfo = statusInfo;
        this._details = monitorDetails;
        this._sensors = sensors;
        this._order = order;
        this._editableByCurrentUser = editableByCurrentUser;
        this._channels = channels;
        this._privacy = privacy;
        this._numberOfLinkedUsers = numberOfLinkedUsers;
        this._backendId = backendId;
        this._permissions = permissions;

        if (order) {
            for (let key in order) {
                const sensor = sensors.find(sensor => sensor.id === key);
                if (sensor) {
                    sensor.order = order[key];
                }
            }
        }
    
    }

    get order(): ({ [s: string]: number }) {
        return this._order;
    }

    get statusInfo(): MonitorStatusInfo {
        return this._statusInfo;
    }

    get id(): string {
        return this._id;
    }
    
    
    get backendId(): number {
        return this._backendId;
    }
    
    
    get serialNumber(): number {
        return this._serialNumber;
    }
    
    
    get monitorType(): string {
        return this._monitorType;
    }
    
    
      get channels(): MonitorChannel[] {
        return this._channels;
      }
    
    
      get privacy(): MonitorPrivacy {
        return this._privacy;
      }
    
    
      get dateTimeAdded(): string {
        return this._dateTimeAdded;
      }
    
    
      get firmwareUpgradeStatus(): FirmwareUpgradeStatus {
        return this._firmwareUpgradeStatus;
      }
    
      set firmwareUpgradeStatus(value: FirmwareUpgradeStatus) {
        this._firmwareUpgradeStatus = value;
      }
    
    
      get details(): MonitorDetails {
        return this._details;
      }
    
    
      get sensors(): MonitorSensor[] {
        return this._sensors;
      }
    
      get editableByCurrentUser(): boolean {
        return this._editableByCurrentUser;
      }
    
      get numberOfLinkedUsers(): number {
        return this._numberOfLinkedUsers;
      }
      get lastMeasuredDateTime(): Date {
        const sensorOfLastMeasuredDateTime: MonitorSensor = last(
            sortBy(this.sensors, ['lastMeasuredDateTime']).filter(x => x.lastMeasuredDateTime != null));
        if (isNil(sensorOfLastMeasuredDateTime)) {
            return null;
        } else {
            return sensorOfLastMeasuredDateTime.lastMeasuredDateTime;
        }
    }

    get permissions(): string[] {
      return this._permissions;
    }
}
