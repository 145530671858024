
import { takeUntil, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import { Router } from '@angular/router';
import { assign, clone, drop, each, gt, isEqual, isNull, isUndefined, remove } from 'lodash';
import * as moment from 'moment';
import * as _ from "lodash";
import 'moment-duration-format';
import { Subject, Subscription } from 'rxjs';
import { Notification } from '../../../../../application/notification/notification';
import { NotificationService } from '../../../../../application/notification/notification.service';
import { validateDuration } from '../../../../../application/validators/validate-duration';
import { validateDurationGreaterThan } from '../../../../../application/validators/validate-duration-greater-than';
import { Monitor } from '../../../../../domain/monitor/monitor';
import MonitorChannel from '../../../../../domain/monitor/monitorChannel';
import MonitorInputType from '../../../../../domain/monitor/monitorInputType';
import { SensorCategory } from '../../../../../domain/monitor/sensor/sensor-category/sensor-category';
import { SensorPredefined } from '../../../../../domain/monitor/sensor/sensor-predefined';
import { SensorPredefinedList } from '../../../../../domain/monitor/sensor/sensor-predefined-list/sensor-predefined-list';
import { SensorAnalog } from '../../../../../domain/monitor/sensor/sensorAnalog';
import { SensorDigitalOpenClosed } from '../../../../../domain/monitor/sensor/sensorDigitalOpenClosed';
import { SensorDigitalPulse } from '../../../../../domain/monitor/sensor/sensorDigitalPulse';
import { ErrorTranslator } from '../../../../../application/command/error-translator/error-translator';
import { MessageStyle } from 'app/domain/messageStyle';
import { Sensor } from 'app/domain/monitor/sensor/sensor';
import { MonitorSensor } from 'app/domain/monitor/monitorSensor';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { TruncateDigitsPipe } from '../../../../../application/truncate-digits/truncate-digits.pipe';
import { SensorAuto } from 'app/domain/monitor/sensor/sensor-auto';

interface FormInputFields {
    name: string,
    formControl: FormControl | FormGroup,
    validators?: Validators[]
}

@Component({
    selector: 'app-monitor-add-step-3',
    templateUrl: './monitor-add-step-3.component.html',
    styleUrls: ['./monitor-add-step-3.component.scss'],
})
export class MonitorAddStep3Component implements OnDestroy, OnInit, AfterContentInit {
    @Input()
    public monitor: Monitor;

    @Input()
    public editMode: boolean;

    @Output()
    public hasCompletedStepThree = new EventEmitter<boolean>();

    public isAddingSensor: boolean;
    public validateHttpError: string;
    public addSensorMonitorForm: FormGroup;
    public sensorProgressValue = 0;
    public selectedSensorIndex = 0;
    public analogInputTypeUnit: string;
    public analogInputTypeCurrent: string;
    public maxMeasurementValue: number;
    public allSensorCategories: SensorCategory[];
    public allPredefinedSensors: SensorPredefinedList[] = [];
    public selectedSensorCategoryIndex = 0;
    public selectedSensorTypeIndex = 0;
    public selectedUnitIndex = 0;
    public isLoadingAllSensorCategories = true;
    public isLoadingAllPredefinedSensors = false;

    public readonly SENSOR_SETTING_CUSTOM = 'CUSTOM';
    public readonly SENSOR_SETTING_PREDEFINED = 'PREDEFINED';
    public readonly MONITOR_INPUT_TYPE = MonitorInputType;
    public readonly MESSAGE_STYLE = MessageStyle;
    public readonly POLLING_RATE = 'pollingRate';
    public readonly CLOUD_STORAGE_RATE = 'cloudStorageRate';
    public readonly InValidLowerLimitType="TYPE_CXI_CORROSION";
    public readonly InValidLimitTypes=["TYPE_CXI_TEMPERATURE", "TYPE_PCXI"];

    private sensorMap: Map<number, {}> = new Map<number, {}>();
    private formBuilder: FormBuilder;
    private truncateDigitsPipe: TruncateDigitsPipe;
    private router: Router;
    private httpMonitorsService: any;
    private media: MediaObserver;
    private unSubscribeAddMonitorSensorOnDestroy = new Subject<boolean>();
    private canShowPlaceHolderWatcher: Subscription;
    private sensorSettingsWatcher: Subscription;
    private inputTypeWatcher: Subscription;
    private initialSensorProgressValue: number;
    private notificationService: Notification;
    private isEditingSensor = false;

    private readonly MAX_TEXT_FIELD_LENGTH = 255;
    private readonly MAX_TEXT_AREA_LENGTH = 1000;
    private readonly MAX_TEXT_OPEN_CLOSED_LENGTH = 20;
    private readonly ALL_AVAILABLE_FORM_CONTROLS = [
        'unitId',
        'value',
        'openText',
        'openTextDescription',
        'closedText',
        'closedTextDescription',
        'measurementMinimum',
        'valueMinimum',
        'measurementMaximum',
        'valueMaximum',
        this.POLLING_RATE,
        this.CLOUD_STORAGE_RATE,
        'upperlimit',
        'lowerlimit'
    ];
    private readonly SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS: FormInputFields[] = [
        {
            name: 'inputType',
            formControl: (new FormControl(null, [Validators.required]))
        },
        {
            name: 'categoryId',
            formControl: (new FormControl(null, [Validators.required]))
        },
        {
            name: 'sensorTypeId',
            formControl: (new FormControl(null, [Validators.required]))
        },
    ];
    private readonly SENSOR_SETTINGS_PREDEFINED_INPUT_FIELDS: FormInputFields[] = [
        {
            name: 'inputType',
            formControl: (new FormControl(null))
        },
        {
            name: 'predefinedSensorId',
            formControl: (new FormControl(null, [Validators.required]))
        }
    ];
    private readonly SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS: FormInputFields[] = [
        {
            name: 'value',
            formControl: (new FormControl(null, [
                Validators.required,
                Validators.maxLength(this.MAX_TEXT_FIELD_LENGTH)
            ]))
        },
        {
            name: 'unitId',
            formControl: (new FormControl(null, [Validators.required]))
        },
    ];
    private readonly SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS: FormInputFields[] = [
        {
            name: 'openText',
            formControl: (new FormControl(null, [
                Validators.required,
                Validators.maxLength(this.MAX_TEXT_OPEN_CLOSED_LENGTH)
            ]))
        },
        {
            name: 'openTextDescription',
            formControl: (new FormControl(null, [Validators.maxLength(this.MAX_TEXT_AREA_LENGTH)]))
        },
        {
            name: 'closedText',
            formControl: (new FormControl(null, [
                Validators.required,
                Validators.maxLength(this.MAX_TEXT_OPEN_CLOSED_LENGTH)
            ]))
        },
        {
            name: 'closedTextDescription',
            formControl: (new FormControl(null, [Validators.maxLength(this.MAX_TEXT_AREA_LENGTH)]))
        },
    ];
    private readonly SENSOR_SETTINGS_ANALOG_INPUT_FIELDS: FormInputFields[] = [
        {
            name: 'measurementMinimum',
            formControl: (new FormControl(null, [Validators.required]))
        },
        {
            name: 'valueMinimum',
            formControl: (new FormControl(null, [Validators.required]))
        },
        {
            name: 'measurementMaximum',
            formControl: (new FormControl(null, [Validators.required]))
        },
        {
            name: 'valueMaximum',
            formControl: (new FormControl(null, [Validators.required]))
        },
        {
            name: 'unitId',
            formControl: (new FormControl(null, [Validators.required]))
        }
    ];
    private readonly CLOUD_STORAGE_RATE_DEFAULTS = {
        HOURS: 1,
        MINUTES: 0,
        SECONDS: 0
    };
    private readonly POLLING_RATE_DEFAULTS = {
        HOURS: 0,
        MINUTES: 5,
        SECONDS: 0
    };
    private errorTranslator: ErrorTranslator;
    private sensorData;
    constructor(formBuilder: FormBuilder,
        truncateDigitsPipe: TruncateDigitsPipe,
        router: Router,
        httpMonitorsService: MonitorDataService,
        media: MediaObserver,
        notificationService: NotificationService,
        @Inject('ErrorTranslator') errorTranslator: ErrorTranslator) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.httpMonitorsService = httpMonitorsService;
        this.media = media;
        this.notificationService = notificationService;
        this.errorTranslator = errorTranslator;
        this.truncateDigitsPipe = truncateDigitsPipe;
    }

    private readonly INVALIDDELTA_CLAUSE = {
        "INPUTTYPE": ["AUTO", "DIGITAL_OPEN_CLOSED"],
        "NAME": ["Interval Temperature", "Yearly Corrosion Rate", "Service switch"]
    };

    private readonly ValidPCXIForCloudStorage = {
        channelType: "FIXED",
        sensorInputType:"AUTO",
        sensorType:"TYPE_PRESSURE",
        sensorTypeName:"Pressure"
    };

    public ngOnInit(): void {
        this.addSensorMonitorForm = this.formBuilder.group({
            name: [{
                value: null,
                disabled: !this.canEditMonitor()
            }, [Validators.required, Validators.maxLength(this.MAX_TEXT_FIELD_LENGTH)]],
            sensorSettings: [{
                value: null,
                disabled: !this.canEditMonitor()
            }, Validators.required],
        });

        this.getAllSensorCategories();
    }

    public ngAfterContentInit(): void {
        // Store the initial sensor progress value
        this.initialSensorProgressValue = 100 / this.monitor.channels.length;

        if (this.monitor.sensors.length > 0) {
            // Add all sensors to the sensor map
            for (const sensor of this.monitor.sensors) {
                this.sensorMap.set(sensor['channel'], sensor);
            }

            this.isEditingSensor = true;

            if (!this.isCurrentChannelFixed() && !isUndefined(this.sensorMap.get(this.selectedSensorIndex))) {
                this.setFormFieldsForSensorSettings(this.sensorMap.get(this.selectedSensorIndex)['inputType']);
                this.preFillFormData(this.sensorMap.get(this.selectedSensorIndex));
            }
        }

        // Calculate the progress
        this.sensorProgressValue = this.calculateCurrentSensorProgress();
    }

    public ngOnDestroy(): void {
        this.unSubscribeAddMonitorSensorOnDestroy.next(true);
        this.unSubscribeAddMonitorSensorOnDestroy.complete();

        // Unsubscribe the watchers
        this.unSubscribeWatchers([
            'inputTypeWatcher',
            'sensorSettingsWatcher',
            'canShowPlaceHolderWatcher'
        ]);
    }

    public canEditMonitor(): boolean {
        return this.monitor.editableByCurrentUser;
    }

    public canShowLowerLimit(): boolean {   
        const sensorObj:any=this.sensorMap.get(this.selectedSensorIndex);
        return sensorObj.sensorType!=this.InValidLowerLimitType;
    }

    public canShowLimit(): boolean {
        const sensorObj:any=this.sensorMap.get(this.selectedSensorIndex);
        if (isUndefined(sensorObj)) {
            return false;
        }
        else {
            return !this.InValidLimitTypes.includes(sensorObj.sensorType);
        }
    }

    public canGoToNextStep() {
        return this.canAddSensor() || this.isCurrentChannelFixed() || !this.canEditMonitor();
    }

    public skipSensorToMonitor(ngAddSensorMonitorForm: NgForm): Promise<void> | void | Promise<boolean | void> {
        return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
    }
     
    public saveLimitAndDeltaValue(lowerLimit,upperLimit,thresholdDelta,sensorId){
        if(sensorId){

            if ((lowerLimit != "" || upperLimit != "") && this.canShowLimit()) {
                const limits = {};
                if (this.canShowLowerLimit())
                                limits["lowerlimit"] = lowerLimit;
                limits["upperlimit"] = upperLimit;                            
                this.httpMonitorsService.setSensorLimits(this.monitor.id, sensorId, limits).subscribe();
            }
    
            if (thresholdDelta != "" && this.canShowDelta())
                this.httpMonitorsService.setPressureDelta(this.monitor.id, sensorId, { thresholdDelta: thresholdDelta }).subscribe();
        }                                    
    }

    public addSensorToMonitor(form: any, isValid: boolean, ngAddSensorMonitorForm: NgForm, lowerlimit, upperlimit, threshold): Promise<void> | void | Promise<boolean | void> {
        if (!this.canEditMonitor()) {
            return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
        } else {
            if (isValid || this.isCurrentChannelFixed()) {
                this.isAddingSensor = true;
                // Reset the http error
                this.validateHttpError = null;                
                const _lowerLimit=lowerlimit;
                const _upperLimit=upperlimit;
                let canAddSensor = true;
                let sensorObj:any=this.sensorMap.get(this.selectedSensorIndex);
                let sensorId: string=sensorObj.id;
                               
                if (isUndefined(this.addSensorMonitorForm.controls['inputType'])) {
                    canAddSensor = false;                    
                    this.saveLimitAndDeltaValue(_lowerLimit,_upperLimit,threshold,sensorId);
                    return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
                }

                const sensor:any = this.getSensorAccordingToInputType(
                    this.addSensorMonitorForm.controls['inputType'].value,
                    form,
                    sensorObj
                );                

                if (isUndefined(sensor)) {
                    canAddSensor = false;                    
                    this.saveLimitAndDeltaValue(_lowerLimit,_upperLimit,threshold,sensorId);
                    return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
                }
                else{                    
    
                    // Get the sensor ID, if present we are going to do an update instead of an insert
                    if (!isUndefined(this.sensorMap.get(this.selectedSensorIndex))) 
                        canAddSensor = false;
                                                           


                    if(this.isShowPollingCloudStorgeForPcxi() || 
                    this.addSensorMonitorForm.controls['inputType'].value===this.ValidPCXIForCloudStorage.sensorInputType){
                        sensor.unitId=sensorObj.unitId;                                              
                    }

                    this.httpMonitorsService
                        .addMonitorSensor(
                            this.monitor.id,
                            sensor,
                            canAddSensor,
                            sensorId
                        )
                        .takeUntil(this.unSubscribeAddMonitorSensorOnDestroy)
                        .subscribe((res: JSON) => {
    
                            if (res && res["id"]) 
                                sensorId = res["id"];                            

                            this.saveLimitAndDeltaValue(_lowerLimit,_upperLimit,threshold,sensorId);


                            return this.addedSensorToMonitor(sensor, ngAddSensorMonitorForm, res);
                        }, (error: HttpErrorResponse) => {
                            this.isAddingSensor = false;    
                            this.errorTranslator.execute(error)
                            .pipe(takeUntil(this.unSubscribeAddMonitorSensorOnDestroy))
                                .subscribe((errorMessage: string) => {
                                    this.validateHttpError = errorMessage;
                            });
                        });
                }
            }

        }

    }

    public goToPreviousSensor(): void {
        const channels = _.take(clone(this.monitor.channels), this.selectedSensorIndex);
        let i = channels.length;

        this.selectedSensorIndex--;

        while (i--) {
            if (isEqual(channels[i], MonitorChannel.FIXED)) 
                this.selectedSensorIndex--;
            else 
                break;            
        }

        if (isEqual(channels.length, 0) || isEqual(this.selectedSensorIndex, -1)) {
            this.selectedSensorIndex = 0;
            return this.hasCompletedStepThree.emit(false);
        }

        // Re-calculate the progress
        this.sensorProgressValue = this.calculateCurrentSensorProgress();

        this.isEditingSensor = true;

        if (this.sensorMap.get(this.selectedSensorIndex)['inputType'] === this.SENSOR_SETTING_PREDEFINED) {
            this.getAllPredefinedSensors();
        }

        this.preFillFormData(this.sensorMap.get(this.selectedSensorIndex));
    }

    public hasReachedLastSensorStep(): boolean {
        const channels = drop(this.monitor.channels, this.selectedSensorIndex + 1);

        return isEqual(this.selectedSensorIndex, this.monitor.channels.length - 1);
    }

    public hasSensorName(sensorIndex: number): boolean {
        return !isUndefined(this.sensorMap.get(sensorIndex));
    }

    public hasSensorStepBeenCompleted(sensorIndex: number): boolean {
        return !isUndefined(this.sensorMap.get(sensorIndex)) && !isEqual(sensorIndex, this.selectedSensorIndex);
    }

    public hasSelectedInputType(): boolean {
        return !isUndefined(this.addSensorMonitorForm.controls['inputType'])
            && !isNull(this.addSensorMonitorForm.controls['inputType'].value);
    }

    public hasSelectedSensorType(): boolean {
        return !isNull(this.addSensorMonitorForm.controls['sensorTypeId'].value);
    }

    public hasSelectedCategory(): boolean {
        return !isNull(this.addSensorMonitorForm.controls['categoryId'].value);
    }

    public canShowPlaceHolder(): boolean {
        return this.media.isActive('lt-lg');
    }

    public canAddPredefinedSettings(): boolean {
        return isEqual(
            this.addSensorMonitorForm.controls['sensorSettings'].value,
            this.SENSOR_SETTING_PREDEFINED);
    }

    public canAddCustomSettings(): boolean {
        return isEqual(this.addSensorMonitorForm.controls['sensorSettings'].value, this.SENSOR_SETTING_CUSTOM);
    }

    public canAddSensor(): boolean {
        return this.addSensorMonitorForm.valid && !this.isAddingSensor;
    }

    public canShowDigitalOpenClosed(): boolean {
        return this.hasSelectedInputType()
            && this.hasSelectedSensorType()
            && this.hasSelectedCategory()
            && isEqual(this.addSensorMonitorForm.controls['inputType'].value, this.MONITOR_INPUT_TYPE.DIGITAL_OPEN_CLOSED);
    }

    public canShowDigitalPulse(): boolean {
        return !isUndefined(this.addSensorMonitorForm.controls['inputType'])
            && isEqual(this.addSensorMonitorForm.controls['inputType'].value, this.MONITOR_INPUT_TYPE.DIGITAL_PULSE)
            && this.addSensorMonitorForm.controls['unitId'].value;
    }

    public canShowAnalog(): boolean | void {
        if (!isUndefined(this.addSensorMonitorForm.controls['inputType'])) {
            const inputType = this.addSensorMonitorForm.controls['inputType'].value;

            return (isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_MA) || isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_V))
                && this.addSensorMonitorForm.controls['unitId'].value;
        }
    }

    public isCurrentChannelAnalog(): boolean {
        return isEqual(this.monitor.channels[this.selectedSensorIndex], MonitorChannel.ANALOG);
    }

    public isCurrentChannelDigital(): boolean {
        return isEqual(this.monitor.channels[this.selectedSensorIndex], MonitorChannel.DIGITAL);
    }

    public isCurrentChannelFixed(): boolean {
        return isEqual(this.monitor.channels[this.selectedSensorIndex], MonitorChannel.FIXED);
    }

    public onChangeSensorCategory($event: MatOptionSelectionChange, index: number): void {
            if ($event.isUserInput) {
            if (!this.isEditingSensor) {
                const formControls = this.ALL_AVAILABLE_FORM_CONTROLS;
                formControls.push('sensorTypeId');

                this.resetFormControls(formControls);
            }

            this.selectedSensorCategoryIndex = index;
        }
    }

    public onChangeSensorType($event: MatOptionSelectionChange, index: number): void {
        if ($event.isUserInput) {
            if (!this.isEditingSensor) {
                this.resetFormControls(this.ALL_AVAILABLE_FORM_CONTROLS);
            }

            this.selectedSensorTypeIndex = index;
        }
    }

    public onChangeUnit($event: MatOptionSelectionChange, index: number, unitType: string): void {
        if ($event.isUserInput) {
            this.analogInputTypeUnit = unitType;
        }
    }

    public canShowUnitType(): boolean {
        if (isUndefined(this.allSensorCategories[this.selectedSensorCategoryIndex].types)) {
            this.selectedUnitIndex = 0;
        }

        return !this.canShowDigitalOpenClosed()
            && this.hasSelectedSensorType()
            && !isUndefined(this.allSensorCategories[this.selectedSensorCategoryIndex].types);
    }

    public canShowSensorType(): boolean {
        if (isUndefined(this.allSensorCategories[this.selectedSensorCategoryIndex].types)) {
            this.selectedSensorCategoryIndex = 0;
        }

        return this.hasSelectedCategory() && this.hasSelectedInputType();
    }

    public canShowDurationError(durationType: string, errorType: string): boolean {
        const durationErrors = this.addSensorMonitorForm.get(`${durationType}`).errors;

        if (!isNull(durationErrors)) {
            return durationErrors[errorType]
                && isNull(this.addSensorMonitorForm.get(`${durationType}.hours`).errors)
                && isNull(this.addSensorMonitorForm.get(`${durationType}.minutes`).errors)
                && isNull(this.addSensorMonitorForm.get(`${durationType}.seconds`).errors);
        }

        return false;
    }

    public canShowPollingRateError() {
        return this.canShowDurationError(this.POLLING_RATE, 'invalidDuration')
            || this.canShowDurationError(this.POLLING_RATE, 'invalidPollingRate')
    }

    public getName() {
        //return !isUndefined(this.sensorMap.get(this.selectedSensorIndex)) ? this.monitor.sensors[this.selectedSensorIndex].name:"";
        return !isUndefined(this.sensorMap.get(this.selectedSensorIndex)) ? this.monitor.sensors.find(sensor => sensor.channel === this.selectedSensorIndex).name : "";
    }

    public canShowCloudStorageRateError() {
        return this.canShowDurationError(this.CLOUD_STORAGE_RATE, 'invalidDuration')
            || this.canShowDurationError(this.CLOUD_STORAGE_RATE, 'invalidCloudStorageRate')
    }

    public canShowPollingAndCloudStorageRate(): boolean {
        let canShow = this.canShowAnalog() || this.canShowDigitalOpenClosed();

        const currentSensor:any=this.sensorMap.get(this.selectedSensorIndex);

        if(currentSensor && this.isCurrentChannelFixed())
             canShow=this.isShowPollingCloudStorgeForPcxi()?true:false;

        if (canShow && isUndefined(currentSensor)) {
            this.setPollingAndCloudStorageRateDefaults();
        }

        return canShow && !this.isCurrentChannelDigital();
    }

    public isShowPollingCloudStorgeForPcxi(): boolean {
        //return false;
        const canShow =this.isCurrentChannelFixed()        
        const sensor:any=this.sensorMap.get(this.selectedSensorIndex);

        if (!sensor) {            
            this.setPollingAndCloudStorageRateDefaults();                
        }                    

        return canShow && 
               sensor.name==this.ValidPCXIForCloudStorage.sensorTypeName &&
               sensor.sensorType==this.ValidPCXIForCloudStorage.sensorType &&
               sensor.inputType==this.ValidPCXIForCloudStorage.sensorInputType;
    }


    public updateRateValueAndValidity(rateType: string): void {
        this.addSensorMonitorForm.controls[rateType].updateValueAndValidity();
    }

    public trackByMonitorChannels(index, channel): number {
        return channel;
    }

    private addedSensorToMonitor(sensor, ngAddSensorMonitorForm: NgForm, res: JSON): Promise<boolean | void> | void | Promise<boolean> {
        if (this.hasReachedLastSensorStep()) {
            return this.router.navigateByUrl(!this.editMode
                ? '/monitors'
                : '/monitors/manage'
            ).then(() => {
                if (this.canEditMonitor()) {
                    this.notificationService.show(!this.editMode
                        ? 'MONITOR.ADDED_CONFIRMATION'
                        : 'MONITOR.UPDATED_CONFIRMATION'
                    );
                }
            });
        }

        if (!isNull(sensor)) {
            let sensorMapData = sensor.toJSON(sensor);
            if (!isNull(res)) {
                sensorMapData = assign(sensorMapData, res);
            } else {
                sensorMapData = assign(this.sensorMap.get(this.selectedSensorIndex), sensorMapData);
            }

            this.sensorMap.set(this.selectedSensorIndex, sensorMapData);
        }


        this.selectedSensorIndex++;

        this.allPredefinedSensors = [];

        if (isUndefined(this.sensorMap.get(this.selectedSensorIndex))) {
            this.isEditingSensor = false;
        } else if (this.sensorMap.get(this.selectedSensorIndex)['inputType'] === this.SENSOR_SETTING_PREDEFINED) {
            this.getAllPredefinedSensors();
        }

        // Re-calculate the progress
        this.sensorProgressValue = this.calculateCurrentSensorProgress();

        // Remove the dynamically added controls
        this.removeControlsFromForm(Array.prototype.concat.apply([], [
            this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS,
            this.SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS
        ]));


        remove(this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS, (currentObject) => {
            return currentObject.name === this.POLLING_RATE || currentObject.name === this.CLOUD_STORAGE_RATE;
        });

        remove(this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS, (currentObject) => {
            return currentObject.name === this.POLLING_RATE || currentObject.name === this.CLOUD_STORAGE_RATE;
        });

        ngAddSensorMonitorForm.resetForm(this.ALL_AVAILABLE_FORM_CONTROLS);

        // Pre-fill the form with sensor data
        this.preFillFormData(this.sensorMap.get(this.selectedSensorIndex));

        this.isAddingSensor = false;
    }

    private watchInputType(): void {
        this.inputTypeWatcher = this.addSensorMonitorForm.get('inputType').valueChanges.pipe(
            takeUntil(this.unSubscribeAddMonitorSensorOnDestroy))
            .subscribe((inputType: MonitorInputType) => {
                if (!isNull(inputType)) {
                    // Remove all custom fields from form
                    this.removeControlsFromForm(Array.prototype.concat.apply([], [
                        this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS,
                        this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS,
                        this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS
                    ]));

                    remove(this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS, (currentObject) => {
                        return currentObject.name === this.POLLING_RATE || currentObject.name === this.CLOUD_STORAGE_RATE;
                    });

                    remove(this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS, (currentObject) => {
                        return currentObject.name === this.POLLING_RATE || currentObject.name === this.CLOUD_STORAGE_RATE;
                    });

                    const  currentSensor:any=this.sensorMap.get(this.selectedSensorIndex);
                    
                    if (isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_MA) || isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_V)) {
                        this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS.push(
                            {
                                name: this.POLLING_RATE,
                                formControl: this.formBuilder.group({
                                    hours: [null, Validators.required],
                                    minutes: [null, Validators.required],
                                    seconds: [null, Validators.required]
                                }),
                                validators: [validateDuration(1, 86400), validateDurationGreaterThan('invalidPollingRate')]
                            },
                            {
                                name: this.CLOUD_STORAGE_RATE,
                                formControl: this.formBuilder.group({
                                    hours: [null, Validators.required],
                                    minutes: [null, Validators.required],
                                    seconds: [null, Validators.required],
                                }),
                                validators: [validateDuration(300, 432000), validateDurationGreaterThan('invalidCloudStorageRate')]
                            }
                        );

                        this.addControlsToForm(this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS);

                        const analogMeasurementValidators = [
                            Validators.required,
                            Validators.min(0)
                        ];

                        let maxMeasurementValue = 0;

                        // Set the placeholder
                        if (isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_MA)) {
                            this.analogInputTypeCurrent = 'mA';
                            maxMeasurementValue = 20;
                        } else if (isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_V)) {
                            this.analogInputTypeCurrent = 'V';
                            maxMeasurementValue = 10;
                        }

                        this.maxMeasurementValue = maxMeasurementValue;

                        analogMeasurementValidators.push(Validators.max(maxMeasurementValue));

                        this.addSensorMonitorForm.controls['measurementMinimum'].setValidators(analogMeasurementValidators);
                        this.addSensorMonitorForm.controls['measurementMaximum'].setValidators(analogMeasurementValidators);
                    } else {
                        if (isEqual(inputType, this.MONITOR_INPUT_TYPE.DIGITAL_OPEN_CLOSED) || this.isShowPollingCloudStorgeForPcxi()) {
                            this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS.push(
                                {
                                    name: this.POLLING_RATE,
                                    formControl: this.formBuilder.group({
                                        hours: [null, Validators.required],
                                        minutes: [null, Validators.required],
                                        seconds: [null, Validators.required]
                                    }),
                                    validators: [validateDuration(1, 86400), validateDurationGreaterThan('invalidPollingRate')]
                                },
                                {
                                    name: this.CLOUD_STORAGE_RATE,
                                    formControl: this.formBuilder.group({
                                        hours: [null, Validators.required],
                                        minutes: [null, Validators.required],
                                        seconds: [null, Validators.required],
                                    }),
                                    validators: [validateDuration(300, 432000), validateDurationGreaterThan('invalidCloudStorageRate')]
                                }
                            );

                            this.addControlsToForm(this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS);

                        } else if (isEqual(inputType, this.MONITOR_INPUT_TYPE.DIGITAL_PULSE)) {
                            this.addControlsToForm(this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS);
                        }
                    }
                }
            }
            );
    }

    private setPollingAndCloudStorageRateDefaults() {
        this.addSensorMonitorForm.patchValue({
            pollingRate: {
                hours: this.POLLING_RATE_DEFAULTS.HOURS,
                minutes: this.POLLING_RATE_DEFAULTS.MINUTES,
                seconds: this.POLLING_RATE_DEFAULTS.SECONDS
            },
            cloudStorageRate: {
                hours: this.CLOUD_STORAGE_RATE_DEFAULTS.HOURS,
                minutes: this.CLOUD_STORAGE_RATE_DEFAULTS.MINUTES,
                seconds: this.CLOUD_STORAGE_RATE_DEFAULTS.SECONDS
            }
        });
    }

    private watchSensorSettings(): void {
        this.sensorSettingsWatcher = this.addSensorMonitorForm.get('sensorSettings').valueChanges
            .subscribe((sensorSettings: string) => {
                if (!isNull(sensorSettings)) {
                    this.setFormFieldsForSensorSettings(sensorSettings);
                }
            });
    }

    private setFormFieldsForSensorSettings(sensorSettings: string): void {
        if (isEqual(sensorSettings, this.SENSOR_SETTING_PREDEFINED)) {
            this.setPredefinedSensorFormControls();

            if (this.allPredefinedSensors.length === 0) {
                this.getAllPredefinedSensors();
            }
        } else {
            this.removeControlsFromForm(this.SENSOR_SETTINGS_PREDEFINED_INPUT_FIELDS);
            this.addControlsToForm(this.SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS);

            // Watch the inputType field
            this.watchInputType();
        }
    }

    private setPredefinedSensorFormControls() {
        this.unSubscribeWatchers(['inputTypeWatcher']);

        this.removeControlsFromForm(Array.prototype.concat.apply([], [
            this.SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS,
            this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS,
        ]));

        this.addControlsToForm(this.SENSOR_SETTINGS_PREDEFINED_INPUT_FIELDS);
    }

    private addControlsToForm(controls: any): void {
        for (const control of controls) {
            this.addSensorMonitorForm.addControl(
                control.name, control.formControl
            );

            if (!this.canEditMonitor()) {
                this.addSensorMonitorForm.controls[control.name].disable();
            }

            if (!isUndefined(control.validators)) {
                this.addSensorMonitorForm.controls[control.name].setValidators(control.validators);
                this.addSensorMonitorForm.get(control.name).updateValueAndValidity();
            }
        }
    }

    private removeControlsFromForm(controls: any): void {
        for (const control of controls) {
            if (!isUndefined(this.addSensorMonitorForm.controls[control.name])) {
                this.addSensorMonitorForm.controls[control.name].reset();
                this.addSensorMonitorForm.removeControl(control.name);
            }
        }
    }

    private resetFormControls(controls: string[]) {
        for (const control of controls) {
            if (!isUndefined(this.addSensorMonitorForm.controls[control])) {
                this.addSensorMonitorForm.controls[control].reset();
            }
        }
    }

    private unSubscribeWatchers(watchers: string[]): void {
        for (const watcher of watchers) {
            if (!isUndefined(this[watcher])) {
                this[watcher].unsubscribe();
            }
        }
    }

    private preFillFormData(data: any): void {
        if (!isUndefined(data)) {
            let sensorSettings = this.SENSOR_SETTING_CUSTOM;

            if (this.sensorMap.get(this.selectedSensorIndex)['inputType'] === this.MONITOR_INPUT_TYPE.PREDEFINED) {
                sensorSettings = this.SENSOR_SETTING_PREDEFINED;
            }

            this.addSensorMonitorForm.controls['sensorSettings'].setValue(sensorSettings);
            data.pollingRate = this.returnDurationAsTime(data.pollingRate);
            data.cloudStorageRate = this.returnDurationAsTime(data.cloudStorageRate);

            this.addSensorMonitorForm.patchValue(data);

            if (!isUndefined(this.addSensorMonitorForm.controls[this.POLLING_RATE])
                && !isUndefined(this.addSensorMonitorForm.controls[this.CLOUD_STORAGE_RATE])
            ) {
                this.updateRateValueAndValidity(this.POLLING_RATE);
                this.updateRateValueAndValidity(this.CLOUD_STORAGE_RATE);
            }
        }
    }

    public getDefaultLowerLimit() {
        const sensor: MonitorSensor = this.sensorMap.get(this.selectedSensorIndex) as MonitorSensor
        if (sensor && this.sensorData[sensor.id]) {
            const lowerlimits = this.sensorData[sensor.id].lowerLimits
            const lastlimit = lowerlimits.sort((lim1, lim2) => lim2.datetime - lim1.datetime)[0];
            return lastlimit ? this.truncateDigitsPipe.transform(lastlimit.value,1) : null
        } else {
            return null
        }
    }

    public getDefaultUpperLimit() {
        const sensor: MonitorSensor = this.sensorMap.get(this.selectedSensorIndex) as MonitorSensor
        if (sensor && this.sensorData[sensor.id]) {
            const upperlimits = this.sensorData[sensor.id].upperLimits;
            const lastlimit = upperlimits.sort((lim1, lim2) => lim2.datetime - lim1.datetime)[0];
            if (lastlimit == null && sensor.sensorType=="TYPE_PRESSURE") {
                return this.truncateDigitsPipe.transform(6,1);
            }
            else if (lastlimit == null && sensor.sensorType=="TYPE_CXI_CORROSION") {
                return this.truncateDigitsPipe.transform(24,1);
            }
            else {
                return lastlimit ? this.truncateDigitsPipe.transform(lastlimit.value,1) : null
            }
        } else {
            return null
        }
    }

    public canShowDelta() {

        const convertTxtToCompare = (txtVal) => {
            return (txtVal || '').toString().toUpperCase().replace(/\s/g, '');
        }

        const sensor: MonitorSensor = this.sensorMap.get(this.selectedSensorIndex) as MonitorSensor
        return sensor &&
            this.sensorData[sensor.id] &&
            !(
                this.INVALIDDELTA_CLAUSE.INPUTTYPE.includes(convertTxtToCompare(sensor.inputType)) &&
                (this.INVALIDDELTA_CLAUSE.NAME || []).length > 0 &&
                (this.INVALIDDELTA_CLAUSE.NAME || []).filter(x => convertTxtToCompare(x) === convertTxtToCompare(sensor.name)).length > 0
            );
    }

    public getDefaultThreshold() {
        const sensor: MonitorSensor = this.sensorMap.get(this.selectedSensorIndex) as MonitorSensor

        if (sensor && this.sensorData[sensor.id]) {
            return this.truncateDigitsPipe.transform(this.sensorData[sensor.id].threshold,1);
        } else {
            return null
        }
    }

    private getSensorAccordingToInputType(inputType: MonitorInputType,
        form: any,sensorObj:any=null): SensorAnalog | SensorDigitalPulse | SensorDigitalOpenClosed | SensorPredefined | SensorAuto {
        let sensor: SensorAnalog | SensorDigitalPulse | SensorDigitalOpenClosed | SensorPredefined | SensorAuto;

        switch (inputType) {
            case this.MONITOR_INPUT_TYPE.ANALOG_V:
            case this.MONITOR_INPUT_TYPE.ANALOG_MA:
                sensor = new SensorAnalog(
                    this.selectedSensorIndex,
                    form.name,
                    form.categoryId,
                    form.inputType,
                    form.sensorTypeId,
                    form.unitId,
                    form.measurementMinimum,
                    form.measurementMaximum,
                    form.valueMinimum,
                    form.valueMaximum,
                    this.returnDurationAsISOString(
                        form.pollingRate.hours,
                        form.pollingRate.minutes,
                        form.pollingRate.seconds
                    ),
                    this.returnDurationAsISOString(
                        form.cloudStorageRate.hours,
                        form.cloudStorageRate.minutes,
                        form.cloudStorageRate.seconds
                    )
                );

                break;
            case this.MONITOR_INPUT_TYPE.DIGITAL_OPEN_CLOSED:
                sensor = new SensorDigitalOpenClosed(
                    this.selectedSensorIndex,
                    form.name,
                    form.categoryId,
                    form.inputType,
                    form.sensorTypeId,
                    form.openText,
                    form.openTextDescription,
                    form.closedText,
                    form.closedTextDescription,
                    this.returnDurationAsISOString(
                        form.pollingRate.hours,
                        form.pollingRate.minutes,
                        form.pollingRate.seconds
                    ),
                    this.returnDurationAsISOString(
                        form.cloudStorageRate.hours,
                        form.cloudStorageRate.minutes,
                        form.cloudStorageRate.seconds
                    )
                );

                break;
            case this.MONITOR_INPUT_TYPE.DIGITAL_PULSE:
                sensor = new SensorDigitalPulse(
                    this.selectedSensorIndex,
                    form.name,
                    form.categoryId,
                    form.inputType,
                    form.sensorTypeId,
                    form.unitId,
                    form.value
                );

                break;

            case this.MONITOR_INPUT_TYPE.PREDEFINED:
                sensor = new SensorPredefined(
                    this.selectedSensorIndex,
                    form.name,
                    form.predefinedSensorId,
                    form.inputType
                );
                break;            
            case this.MONITOR_INPUT_TYPE.AUTO:
                let measurementMinimum;
                let measurementMaximum;
                let valueMinimum;
                let valueMaximum;
                //Pressure
                if (form.sensorTypeId =="d935031a-9236-4b91-b912-3b5f4ce3bc80") {
                    measurementMinimum = 0;
                    measurementMaximum = 100;
                    valueMinimum = -1;
                    valueMaximum = 9;
                }
                //Corrosion
                else if (form.sensorTypeId =="0e5b8e9f-ac3b-47e0-92a4-29893c146653") {
                    measurementMinimum = 0;
                    measurementMaximum = 1;
                    valueMinimum = 0;
                    valueMaximum = 1000;
                }
                //Temperature
                else if (form.sensorTypeId =="F0316AA8-5231-FC4E-8E0B-F98BD74A9858") {
                    measurementMinimum = 0;
                    measurementMaximum = 1;
                    valueMinimum = 0;
                    valueMaximum = 100;
                }
                else {
                    measurementMinimum = 99;
                    measurementMaximum = 99;
                    valueMinimum = 99;
                    valueMaximum = 99;
                }
                    sensor = new SensorAuto(   
                        this.selectedSensorIndex,                     
                        form.name,   
                        form.categoryId,   
                        form.inputType,   
                        form.sensorTypeId,   
                        form.unitId,                                            
                        this.returnDurationAsISOString(
                            form.pollingRate?form.pollingRate.hours:(sensorObj.pollingRate?sensorObj.pollingRate.hours:0),
                            form.pollingRate?form.pollingRate.minutes:(sensorObj.pollingRate?sensorObj.pollingRate.minutes:0),
                            form.pollingRate?form.pollingRate.seconds:(sensorObj.pollingRate?sensorObj.pollingRate.seconds:0)
                        ),
                        measurementMinimum,
                        measurementMaximum,
                        valueMinimum,
                        valueMaximum,
                        this.returnDurationAsISOString(
                            form.cloudStorageRate?form.cloudStorageRate.hours:(sensorObj.cloudStorageRate?sensorObj.cloudStorageRate.hours:0),
                            form.cloudStorageRate?form.cloudStorageRate.minutes:(sensorObj.cloudStorageRate?sensorObj.cloudStorageRate.minutes:0),
                            form.cloudStorageRate?form.cloudStorageRate.seconds:(sensorObj.cloudStorageRate?sensorObj.cloudStorageRate.seconds:0)
                        )
                    );
                break;            
        }

        return sensor;
    }

    private returnDurationAsISOString(hours: number, minutes: number, seconds: number): string {
        return moment.duration({
            hours,
            minutes,
            seconds
        }).toISOString();
    }

    private returnDurationAsTime(data) {
        const dataAsStr = (data || '').toString().toUpperCase();
        const isDataValidNum = (dataAsStr.indexOf('H') < 0 && dataAsStr.indexOf('S') < 0 && dataAsStr.indexOf('M')) < 0;
        const duration = isDataValidNum ? moment.duration(data, 'seconds') : moment.duration(data);
        let hours = duration.hours();
        if (duration.days() !== 0) {
            hours = hours + moment.duration(duration.days(), 'days').asHours();
        }

        return {
            hours,
            minutes: duration.minutes(),
            seconds: duration.seconds()
        }
    }

    private calculateCurrentSensorProgress(): number {
        return this.initialSensorProgressValue * this.selectedSensorIndex;
    }

    private getAllSensorCategories() {
        this.httpMonitorsService
            .listAllSensorCategories()
            .takeUntil(this.unSubscribeAddMonitorSensorOnDestroy)
            .subscribe((allSensorCategories: SensorCategory[]) => {
                this.allSensorCategories = allSensorCategories;

                // Watch the sensorSettings field
                this.watchSensorSettings();

                this.canShowPlaceHolderWatcher = this.media.asObservable()
                    .subscribe(() => this.canShowPlaceHolder());

                this.httpMonitorsService._skipCacheDetailOverview(this.monitor.id, { start: moment().subtract(1, 'day'), end: moment() })
                    .pipe(take(1))
                    .subscribe(detailOverview => {
                        this.sensorData = _.keyBy(detailOverview.sensors, 'id');
                        this.isLoadingAllSensorCategories = false;
                        
                        (detailOverview.measurements||[]).forEach(element => {
                            if( this.sensorData[element.sensorId]){
                                this.sensorData[element.sensorId].lowerLimits=(element.lowerLimits||[]).length>0?element.lowerLimits:this.sensorData[element.sensorId].lowerLimits;
                                this.sensorData[element.sensorId].upperLimits=(element.upperLimits||[]).length>0?element.upperLimits:this.sensorData[element.sensorId].upperLimits;
                            }
                        });
                                                
                    })
            }, (err: HttpErrorResponse) => {
                console.error(err);
            })
    }

    private getAllPredefinedSensors(): void {
        this.isLoadingAllPredefinedSensors = true;

        this.httpMonitorsService
            .listAllPredefinedSensors(this.monitor.channels[this.selectedSensorIndex])
            .takeUntil(this.unSubscribeAddMonitorSensorOnDestroy)
            .subscribe((allPredefinedSensors: SensorPredefinedList[]) => {
                this.isLoadingAllPredefinedSensors = false;
                this.allPredefinedSensors = allPredefinedSensors;
            }, (err: HttpErrorResponse) => {
                console.error(err);
            })
    }
}
