import {isNil, isUndefined} from 'lodash';

export function StringComparator(valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: any): number {
  if ((isNil(valueA) && isNil(valueB)) || (isUndefined(valueA) && isUndefined(valueB))) {
    return 0;
  }

  if (isNil(valueA) || isUndefined(valueA)) {
    return -1;
  }
  if (isNil(valueB) || isUndefined(valueB)) {
    return 1;
  }

  const upperValueA = valueA.toUpperCase();
  const upperValueB = valueB.toUpperCase();
  return upperValueA === upperValueB ? 0 : (upperValueA > upperValueB) ? 1 : -1;
}
