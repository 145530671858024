
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {LoggerService} from '../../../application/logger/logger.service';
import {EnvironmentInformation} from '../../../domain/environmentInformation';

import {InfoService} from '../../../infrastructure/http/info/info.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  public environmentInformation: EnvironmentInformation;

  private infoService: InfoService;
  private ngUnsubscribe = new Subject<void>();
  private logger: LoggerService;

  constructor(infoService: InfoService, logger: LoggerService) {
    this.infoService = infoService;
    this.logger = logger;
  }

  public ngOnInit(): void {
    this.getEnvironmentInformation();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getEnvironmentInformation(): void {
    this.infoService.getEnvironmentInformationAndHealthStatus().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((environmentInformation: EnvironmentInformation) => {
        this.environmentInformation = environmentInformation;
      }, (error: HttpErrorResponse) => {
        this.logger.error(error);
      });
  }
}
