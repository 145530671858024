<section fxLayout="column">
  <h1>{{'USER.PROFILE' | translate}}</h1>
  <mat-card>
    <form [formGroup]="resetPasswordForm" fxLayout="column" (ngSubmit)="saveNewPassword(resetPasswordForm)">
      <div class="input-row" fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
        <!-- oldPassword -->
        <mat-form-field fxFlex>
          <!--Required error -->
          <mat-error *ngIf="resetPasswordForm.get('oldPassword').errors?.required">
            {{'REGISTER.ERROR_PASSWORD' | translate}}
          </mat-error>

          <!-- MinLength error -->
          <mat-error *ngIf="resetPasswordForm.get('oldPassword').errors?.minlength">
            {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'PASSWORD' | translate, min: 6} }}
          </mat-error>

          <input matInput
                 type="password"
                 placeholder="{{'OLD_PASSWORD' | translate}}" formControlName="oldPassword">
        </mat-form-field>

        <!-- Password -->
        <mat-form-field fxFlex>
          <!--Required error -->
          <mat-error *ngIf="resetPasswordForm.get('password').errors?.required">
            {{'REGISTER.ERROR_PASSWORD' | translate}}
          </mat-error>

          <!-- MinLength error -->
          <mat-error *ngIf="resetPasswordForm.get('password').errors?.minlength">
            {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'PASSWORD' | translate, min: 6} }}
          </mat-error>

          <input matInput
                 type="password"
                 placeholder="{{'NEW_PASSWORD' | translate}}" formControlName="password">
        </mat-form-field>

        <!-- Repeat Password -->
        <mat-form-field fxFlex>
          <!--Required error -->
          <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').errors?.required">
            {{'REGISTER.ERROR_PASSWORD_REPEAT' | translate}}
          </mat-error>

          <!-- MinLength error -->
          <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').errors?.minlength">
            {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'PASSWORD' | translate, min: 6} }}
          </mat-error>

          <!-- Match error -->
          <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').errors?.matchPassword">
            {{'REGISTER.ERROR_PASSWORDS_NOT_MATCHING' | translate}}
          </mat-error>

          <input matInput
                 type="password"
                 placeholder="{{'REGISTER.ERROR_PASSWORD_REPEAT' | translate}}" formControlName="passwordConfirm">
        </mat-form-field>
      </div>
      <div class="input-row submit">
        <button fxFill mat-raised-button color="primary" type="submit" [disabled]="!canSaveNewPassword()">
          {{'GENERAL.SAVE' | translate}}
        </button>
      </div>

      <div class="input-row additional-action">
        <a class="antialiased" (click)="goBackToUserProfile()">
          {{'USER.EDIT_PROFILE' | translate}}
        </a>
      </div>
    </form>
  </mat-card>
</section>
