var MonitorMeasurementData = /** @class */ (function () {
    function MonitorMeasurementData(id, datetime, measurementidx, rawvalue, measurementvalue, sensorstatus, monitorstatus) {
        this._id = id;
        this._datetime = datetime;
        this._measurementidx = measurementidx;
        this._rawvalue = rawvalue;
        this._measurementvalue = measurementvalue;
        this._sensorstatus = sensorstatus;
        this._monitorstatus = monitorstatus;
    }
    MonitorMeasurementData.fromJSON = function (monitorMeasurementDataDTO) {
        return new MonitorMeasurementData(monitorMeasurementDataDTO.id, monitorMeasurementDataDTO.datetime, monitorMeasurementDataDTO.measurementidx, monitorMeasurementDataDTO.rawvalue, monitorMeasurementDataDTO.measurementvalue, monitorMeasurementDataDTO.sensorstatus, monitorMeasurementDataDTO.monitorstatus);
    };
    Object.defineProperty(MonitorMeasurementData.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurementData.prototype, "datetime", {
        get: function () {
            return this._datetime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurementData.prototype, "measurementidx", {
        get: function () {
            return this._measurementidx;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurementData.prototype, "rawvalue", {
        get: function () {
            return this._rawvalue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurementData.prototype, "measurementvalue", {
        get: function () {
            return this._measurementvalue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurementData.prototype, "sensorstatus", {
        get: function () {
            return this._sensorstatus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurementData.prototype, "monitorstatus", {
        get: function () {
            return this._monitorstatus;
        },
        enumerable: true,
        configurable: true
    });
    return MonitorMeasurementData;
}());
export { MonitorMeasurementData };
