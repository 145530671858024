<mat-card [class.shaded]="isMonitor && monitor.statusInfo.status === 'OFFLINE'">
    <mat-card-header>
        <div mat-card-avatar>
            <mat-icon svgIcon="{{getKind()}}-blue"></mat-icon>
        </div>
        <mat-card-title
                class="antialiased" fxLayout="row" fxLayoutAlign="start start">
            <span fxFlex>{{details._name}}</span>
            <div class="monitor-status" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.xs="end end">
                <!-- TODO: The status of the monitor should be here.-->

            </div>
        </mat-card-title>
        <mat-card-subtitle class="antialiased" fxLayout="row" fxLayout.gt-xs="row">
            <div fxLayout="column">
                <span fxFlex>{{details.info}}</span>
                <div fxLayout="row" *ngIf="isMonitor">
                    <span [matTooltip]="hasMonitorErrors(monitor)" class="device-status"
                          [ngClass]="getClassStatusForDeviceStatus(monitor.statusInfo.status)"></span>
                    <span>{{'MONITOR.' + monitor.statusInfo.status | translate }}</span>                    
                </div>
                <div fxLayout="row" *ngIf="isMonitor">
                    <span [matTooltip]="hasMonitorErrors(monitor)" class="device-status"
                    [ngClass]="getClassStatusForRelayStatus(monitor.statusInfo.relayState)"></span>
                    <span>{{ 'Alarm ' + getRelayStatusString(monitor.statusInfo.relayState) }}</span>
                </div>
                <div *ngIf="isMonitor && this.monitor.statusInfo && hasMonitorErrors()">
                    <p class="error-list">{{getErrors()}}</p>
                </div>
            </div>
            <span class="title-right" *ngIf="isMonitor && canShowLastMeasuredDateTime()">
            {{'MONITOR.OVERVIEW.LAST_MEASURED' | translate}} {{monitor.lastMeasuredDateTime | amTimeAgo}}
          </span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

        <article class="sensors" fxLayout="row wrap" fxLayoutGap="45px"  fxLayoutAlign="center center"
                 fxLayoutAlign.xs="start center">
            <div *ngFor="let sensor of sensors" class="sensor" fxLayout="column" fxLayoutAlign="center center"
                 fxLayoutGap="5px">
                <div class="value">
                    <mat-icon *ngIf="canShowIconForSensor(sensor.openClosedStatus)"
                              [matTooltip]="getTooltipText(sensor)"
                              [svgIcon]="getIconForSensor(sensor.openClosedStatus)">
                    </mat-icon>
                    <span *ngIf="!canShowIconForSensor(sensor.openClosedStatus);">
								{{sensor.value  | truncateDigits }}
							</span>
                </div>
                <span class="unit">{{sensor.unit || sensor.valueText}}</span>
                <div class="status"
                     [matTooltip]="getBubbleTooltip(sensor)"
                     [ngClass]="getClassStatusForSensorStatus(sensor)"></div>
            </div>
        </article>


        <section fxLayout.gt-xs="row" fxLayout="row" fxLayoutAlign="space-between end">
            <article class="information" fxFlex="60" fxLayout="column" *ngIf="isMonitor">
                <header>
                    {{'MONITOR.INFORMATION' | translate}}
                </header>
                <mat-list>
                    <mat-list-item>
                        <mat-icon svgIcon="location" mat-list-icon></mat-icon>
                        <p mat-line>{{details._address}}</p>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon svgIcon="card-details" mat-list-icon></mat-icon>
                        <p mat-line>{{details._system}}</p>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon svgIcon="card-label" mat-list-icon></mat-icon>
                        <p mat-line>{{details.serialNumber}}</p>
                    </mat-list-item>
                </mat-list>
            </article>
            <article class="container-button-details">
                <div>
                    <a class="button-details antialiased" mat-raised-button (click)="showShareScreen()" style="margin-right:7px" *ngIf="monitor.permissions.includes('SHARE_VWR')">
                            {{ 'GENERAL.SHARE' | translate }}
                        </a>
                    <a class="button-details antialiased" mat-raised-button [routerLink]="link" *ngIf="monitor.permissions.includes('VIEW_DETAILS')">
                        {{ 'GENERAL.DETAILS' | translate }}
                    </a>
                </div>
            </article>
        </section>

    </mat-card-content>
</mat-card>
    