import { MessageStyle } from "../../../domain/messageStyle";
var MessageComponent = /** @class */ (function () {
    function MessageComponent() {
        this.MESSAGE_STYLE = MessageStyle;
    }
    MessageComponent.prototype.getStylingForMessage = function () {
        switch (this.messageStyle) {
            case this.MESSAGE_STYLE.SUCCESS:
                return 'is-success';
            case this.MESSAGE_STYLE.DANGER:
                return 'is-danger';
            case this.MESSAGE_STYLE.WARNING:
                return 'is-warning';
            case this.MESSAGE_STYLE.INFO:
                return 'is-info';
        }
    };
    MessageComponent.prototype.getIconForMessage = function () {
        switch (this.messageStyle) {
            case this.MESSAGE_STYLE.DANGER:
                return 'error_outline';
            case this.MESSAGE_STYLE.WARNING:
                return 'warning';
            case this.MESSAGE_STYLE.INFO:
                return 'info_outline';
        }
    };
    return MessageComponent;
}());
export { MessageComponent };
