var GridEditViewComponent = /** @class */ (function () {
    function GridEditViewComponent() {
    }
    GridEditViewComponent.prototype.agInit = function (params) {
        this.view = params.data;
    };
    GridEditViewComponent.prototype.editView = function () {
    };
    return GridEditViewComponent;
}());
export { GridEditViewComponent };
