import { Router } from '@angular/router';
import { AuthenticationService } from '../../infrastructure/http/authentication/authentication.service';
var CanActivateLoginGuard = /** @class */ (function () {
    function CanActivateLoginGuard(router, authenticationService) {
        this.router = router;
        this.authenticationService = authenticationService;
    }
    CanActivateLoginGuard.prototype.canActivate = function () {
        var result = false;
        if (this.authenticationService.hasToken()) {
            this.router.navigate(['/monitors']);
        }
        else {
            result = true;
        }
        return result;
    };
    return CanActivateLoginGuard;
}());
export { CanActivateLoginGuard };
