import { forkJoin as observableForkJoin } from 'rxjs';
import { OnInit } from '@angular/core';
import { MatDialogRef, MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER, TAB } from "@angular/cdk/keycodes";
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { AuthenticationService } from 'app/infrastructure/http/authentication/authentication.service';
var ShareComponent = /** @class */ (function () {
    function ShareComponent(dialogRef, monitor, monitorService, authService) {
        this.dialogRef = dialogRef;
        this.monitor = monitor;
        this.monitorService = monitorService;
        this.authService = authService;
        this.separatorKeysCodes = [ENTER, COMMA, TAB];
        this.removable = true;
        this.model = {
            emails: [],
            role: "VIEWER",
            text: ""
        };
        this.loading = false;
        this.showError = false;
    }
    ShareComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.getAuthenticatedUser().subscribe(function (user) { _this.model.text = "The user " + user.firstName + " " + user.lastName + " would like to share monitor " + _this.monitor.serialNumber + " with you as " + _this.model.role + ". If you accept, this monitor will be added to your monitor overview."; _this.user = user; });
    };
    ShareComponent.prototype.add = function (event) {
        if (event.input.value && !event.input.value.includes("@")) {
            event.input.style.color = "red";
            return;
        }
        if (event.input.value && event.input.value.trim()) {
            this.model.emails.push(event.input.value);
            event.input.value = "";
        }
    };
    ShareComponent.prototype.remove = function (a) {
        this.model.emails = this.model.emails.filter(function (s) { return a !== s; });
    };
    ShareComponent.prototype.submit = function () {
        var _this = this;
        if (this.model.emails && this.model.emails.length > 0) {
            this.loading = true;
            observableForkJoin(this.model.emails.map(function (email) { return _this.monitorService.addKey(email, _this.monitor.id, _this.model.role, _this.model.text || null); }))
                .subscribe(function () {
                _this.loading = false;
                return _this.dialogRef.close();
            }, function () { return _this.dialogRef.close(); });
        }
        else {
            this.showError = true;
        }
    };
    ShareComponent.prototype.removeStyle = function (obj) {
        obj.style.color = "";
        this.showError = false;
    };
    ShareComponent.prototype.dirty = function () {
        this.isDirty = true;
    };
    ShareComponent.prototype.dropBoxUpdate = function () {
        if (!this.isDirty) {
            this.model.text = "The user " + this.user.firstName + " " + this.user.lastName + " would like to share monitor " + this.monitor.serialNumber + " with you as " + this.model.role + ". If you accept, this monitor will be added to your monitor overview.";
        }
    };
    return ShareComponent;
}());
export { ShareComponent };
