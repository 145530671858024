<div id="modal-content-wrapper">
    <header id="modal-header">
        <!--<h1 id="modal-title">Are you sure you want to logout?</h1>-->
    </header>
    <section id="modal-body">
        <p>We extremely value the security of our customers.Therefore we do not support your current browser Internet Explorer, as no more security updates for new vulnerabilities will come.For more information on the security risk of using Internet Explorer in general
        <a href="https://portal.msrc.microsoft.com/en-US/security-guidance/advisory/CVE-2019-1367">follow this link.</a>
        </p>
    </section>
    <footer id="modal-footer">
        <button mat-raised-button id="modal-action-button" (click)="closeModal()">Ok</button>
    </footer>
</div>