import { takeUntil } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import MonitorChannel from '../../../../domain/monitor/monitorChannel';
import { MessageStyle } from "../../../../domain/messageStyle";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var MonitorAddComponent = /** @class */ (function () {
    function MonitorAddComponent(route, httpMonitorsService) {
        this.showStepOne = true;
        this.showStepTwo = false;
        this.showStepThree = false;
        this.isLoading = false;
        this.editMode = false;
        this.hasHttpError = false;
        this.MESSAGE_STYLE = MessageStyle;
        this.subPages = [];
        this.unSubscribeMonitorAddComponent = new Subject();
        this.route = route;
        this.httpMonitorService = httpMonitorsService;
    }
    /**
     * Checks whether the the given MonitorChannel array has all FIXED channels
     * @param {MonitorChannel[]} channels
     * @returns {boolean}
     */
    MonitorAddComponent.hasAllFixedChannels = function (channels) {
        return channels.filter(function (channel) {
            return (channel === MonitorChannel.FIXED);
        }).length === channels.length;
    };
    MonitorAddComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.pipe(takeUntil(this.unSubscribeMonitorAddComponent))
            .subscribe(function (data) {
            _this.subPages = data.parentPage.subPages;
        });
        this.route.params.pipe(takeUntil(this.unSubscribeMonitorAddComponent))
            .subscribe(function (params) {
            if (params.id) {
                _this.isLoading = true;
                _this.editMode = true;
                _this.hasHttpError = false;
                _this.httpMonitorService.singleMonitor(params.id).pipe(takeUntil(_this.unSubscribeMonitorAddComponent))
                    .subscribe(function (monitor) {
                    _this.showStepOne = false;
                    _this.showStepTwo = true;
                    _this.isLoading = false;
                    _this.monitor = monitor;
                }, function (error) {
                    _this.isLoading = false;
                    _this.hasHttpError = true;
                    _this.validateHttpError = error.error.error;
                });
            }
        });
    };
    MonitorAddComponent.prototype.canShowStepOne = function () {
        return this.showStepOne;
    };
    MonitorAddComponent.prototype.hasCompletedStepOne = function (monitor) {
        this.monitor = monitor;
        this.showStepOne = false;
        this.showStepTwo = true;
    };
    MonitorAddComponent.prototype.canShowToStepTwo = function () {
        return this.showStepTwo;
    };
    MonitorAddComponent.prototype.hasCompletedStepTwo = function (event) {
        this.monitor = event.monitor;
        this.showStepOne = !event.canNavigateToStepThree;
        this.showStepTwo = false;
        this.showStepThree = event.canNavigateToStepThree;
    };
    MonitorAddComponent.prototype.canShowStepThree = function () {
        return this.showStepThree;
    };
    MonitorAddComponent.prototype.hasCompletedStepThree = function (canShowStepThree) {
        this.showStepTwo = true;
        this.showStepThree = canShowStepThree;
    };
    return MonitorAddComponent;
}());
export { MonitorAddComponent };
