<mat-card>
    <mat-card-header>
        <div mat-card-avatar>
            <mat-icon svgIcon="view-blue"></mat-icon>
        </div>
        <mat-card-title
                class="antialiased" fxLayout="row" fxLayoutAlign="start start">
            <span fxFlex>{{details._name}}</span>
            <div class="monitor-status" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.xs="end end">
                <!-- TODO: The status of the monitor should be here.-->

            </div>
        </mat-card-title>
        <mat-card-subtitle class="antialiased" fxLayout="row" fxLayout.gt-xs="row">
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        {{details.info}}

        <section fxLayout.gt-xs="row" fxLayout="row" fxLayoutAlign="end end">
            <article class="container-button-details">
                <div>
                    <a class="button-details antialiased" mat-raised-button [routerLink]="link">
                        {{ 'GENERAL.DETAILS' | translate }}
                    </a>
                </div>
            </article>
        </section>

    </mat-card-content>
</mat-card>