import {Component} from '@angular/core';
import {Monitor} from '../../../../domain/monitor/monitor';

@Component({
  templateUrl: 'monitor-serialnumber.component.html',
  styleUrls: ['./monitor-serialnumber.component.scss']
})
export class GridMonitorSerialnumberComponent {
  public monitor: Monitor;

  public agInit(params: any): void {
    this.monitor = params.data;
  }
}
