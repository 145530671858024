<div *ngIf="isFirmwareInfoAvailable()">
  <button mat-raised-button class="antialiased"
          [disabled]="!canUpgradeFirmware() || requestInProgress"
          *ngIf="isFirmwareUpgradeAvailable()"
          (click)="requestFirmwareUpgrade()">
    {{'MONITOR.UPDATE_AVAILABLE' | translate}}
  </button>
  <span *ngIf="isFirmwareUpToDate()">{{'MONITOR.SOFTWARE_UPTODATE' | translate}}</span>
  <div class="update-pending" fxLayout="row" *ngIf="isFirmwareUpgradePlanned()">
    <mat-icon svgIcon="clock"></mat-icon>
    <div><span>{{'MONITOR.UPDATE_PENDING' | translate}}</span></div>
  </div>
  <div class="update-pending" fxLayout="row" *ngIf="isFirmwareUpgradeBusy()">
    <mat-icon svgIcon="clock"></mat-icon>
    <div><span>{{'MONITOR.UPDATE_BUSY' | translate}}</span></div>
  </div>
  <span *ngIf="isFirmwareUnknown()">{{'MONITOR.FIRMWARE_STATUS_UNKNOWN' | translate}}</span>
</div>

<span *ngIf="!isFirmwareInfoAvailable()">
  {{'MONITOR.FIRMWARE_STATUS_REQUESTING' | translate}}
</span>
