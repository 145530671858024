import { OnInit } from '@angular/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent(userService) {
        this.userService = userService;
        this.expanded = true;
        this.hover = false;
    }
    NotificationComponent.prototype.ngOnInit = function () {
    };
    NotificationComponent.prototype.deleteNotification = function () {
        this.expanded = false;
        this.userService.setNotificationRead(this.notification.id, true).subscribe();
    };
    return NotificationComponent;
}());
export { NotificationComponent };
