import {Component, Input, OnInit} from '@angular/core';
import {MessageStyle} from "../../../domain/messageStyle";
import { MatDialogRef, MatDialog } from '@angular/material';


@Component({
    selector: 'app-popup-modal',
    templateUrl: './popup-modal.component.html',
    styleUrls: ['./popup-modal.component.scss']
})
export class PopUpModalComponent implements OnInit{

    public dialogRef: MatDialogRef<PopUpModalComponent>

    constructor(public matDialog: MatDialog) {

    }
    ngOnInit() {
    }

    closeModal() {
        debugger;
        this.matDialog.closeAll();
    }
}
