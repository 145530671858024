import {SensorCategoryUnit} from './sensor-category-unit';

export class SensorCategoryType {
  private _id: string;
  private _name: string;
  private _units: SensorCategoryUnit[];

  constructor(id: string,
              name: string,
              units: SensorCategoryUnit[]) {
    this._id = id;
    this._name = name;
    this._units = units;
  }

  get name(): string {
    return this._name;
  }

  get id(): string {
    return this._id;
  }

  get units(): SensorCategoryUnit[] {
    return this._units;
  }
}
