/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./popup-modal.component";
import * as i7 from "@angular/material/dialog";
var styles_PopUpModalComponent = [i0.styles];
var RenderType_PopUpModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopUpModalComponent, data: {} });
export { RenderType_PopUpModalComponent as RenderType_PopUpModalComponent };
export function View_PopUpModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["id", "modal-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "header", [["id", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "section", [["id", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We extremely value the security of our customers.Therefore we do not support your current browser Internet Explorer, as no more security updates for new vulnerabilities will come.For more information on the security risk of using Internet Explorer in general "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "https://portal.msrc.microsoft.com/en-US/security-guidance/advisory/CVE-2019-1367"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["follow this link."])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "footer", [["id", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["id", "modal-action-button"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Ok"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 9).disabled || null); var currVal_1 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_0, currVal_1); }); }
export function View_PopUpModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-modal", [], null, null, null, View_PopUpModalComponent_0, RenderType_PopUpModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.PopUpModalComponent, [i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopUpModalComponentNgFactory = i1.ɵccf("app-popup-modal", i6.PopUpModalComponent, View_PopUpModalComponent_Host_0, {}, {}, []);
export { PopUpModalComponentNgFactory as PopUpModalComponentNgFactory };
