
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AfterContentInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {isEqual, isUndefined, map, values} from 'lodash';
import {Subject} from 'rxjs';
import {NotificationService} from '../../../../../application/notification/notification.service';
import {Monitor} from '../../../../../domain/monitor/monitor';
import {MonitorDetails} from '../../../../../domain/monitor/monitorDetails';

import MonitorPrivacy from '../../../../../domain/monitor/monitorPrivacy';
import {HttpMonitorsService} from '../../../../../infrastructure/http/monitor/http-monitors.service';
import {ConfirmationDialogComponent} from '../../../confirmation-dialog/confirmation-dialog.component';
import {MonitorAddComponent} from '../monitor-add.component';
import {ErrorTranslator} from '../../../../../application/command/error-translator/error-translator';
import {MessageStyle} from "../../../../../domain/messageStyle";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
  selector: 'app-monitor-add-step-2',
  templateUrl: './monitor-add-step-2.component.html',
  styleUrls: ['./monitor-add-step-2.component.scss'],
})
export class MonitorAddStep2Component implements OnDestroy, AfterContentInit, OnChanges {
  @Input() public monitor: Monitor;
  @Input() public editMode: boolean;
  @Output() public hasCompletedStepTwo = new EventEmitter<{ monitor: Monitor, canNavigateToStepThree: boolean }>();

  public hasAllFixedChannels: boolean;
  public readonly MESSAGE_STYLE = MessageStyle;
  public readonly MONITOR_PRIVACY_LEVELS = MonitorPrivacy;
  public isMonitorPrivacyLevelReadOnly = false;
  public dialog: MatDialog;
  public addDetailsMonitorForm: FormGroup;
  public validateHttpError: string;

  private formBuilder: FormBuilder;
  private httpMonitorsService: HttpMonitorsService;
  private unSubscribeUpdateMonitorDetailsOnDestroy = new Subject<boolean>();
  private isAddingDetails: boolean;
  private router: Router;
  private notificationService: NotificationService;
  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    panelClass: 'confirmation-dialog'
  };
  private errorTranslator: ErrorTranslator;

  constructor(formBuilder: FormBuilder,
              router: Router,
              httpMonitorsService: MonitorDataService,
              notificationService: NotificationService,
              dialog: MatDialog,
              @Inject('ErrorTranslator') errorTranslator: ErrorTranslator) {
    this.formBuilder = formBuilder;
    this.router = router;
    this.httpMonitorsService = httpMonitorsService;
    this.notificationService = notificationService;
    this.dialog = dialog;
    this.errorTranslator = errorTranslator;

    this.addDetailsMonitorForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.maxLength(255)]],
      address: [null, [Validators.maxLength(1000)]],
      system: [null, [Validators.maxLength(255)]],
      circuit: ['', Validators.maxLength(255)],
      details: ['', Validators.maxLength(255)],
      comments: ['', Validators.maxLength(1000)],
    });
  }

  ngOnChanges() {
      this.hasAllFixedChannels = MonitorAddComponent.hasAllFixedChannels(this.monitor.channels);
  }

  public ngAfterContentInit(): void {

    if (this.editMode) {
      this.addDetailsMonitorForm.addControl('privacy', (
          new FormControl(
            {value: this.monitor.privacy, disabled: !this.canEditMonitor()},
            []
            //[Validators.required]
          )
        )
      );
    } else {
      this.isMonitorPrivacyLevelReadOnly = isEqual(this.monitor.privacy, MonitorPrivacy.READ_ONLY);
    }

    // Pre-fill the form
    this.addDetailsMonitorForm.patchValue(this.monitor.details);
  }

  public ngOnDestroy(): void {
    this.unSubscribeUpdateMonitorDetailsOnDestroy.next(true);
    this.unSubscribeUpdateMonitorDetailsOnDestroy.complete();
  }

  public canEditMonitor(): boolean {
    return this.monitor.editableByCurrentUser;
  }

  public addDetailsToMonitor(form: any, isValid: boolean): void | Promise<boolean> {
    if (this.canEditMonitor()) {
      if (isValid) {
        this.isAddingDetails = true;

        // Reset the http error
        this.validateHttpError = null;

        this.httpMonitorsService.updateMonitorDetails(
          this.monitor.id,
          new MonitorDetails(
            form.name,
            form.address,
            form.system,
            form.circuit,
            form.details,
            form.comments
          ),
          form.privacy,
          this.editMode
        ).pipe(takeUntil(this.unSubscribeUpdateMonitorDetailsOnDestroy))
          .subscribe((monitor: Monitor) => {

            // Emit the response back to the parent component
            this.hasCompletedStepTwo.emit({
              monitor,
              canNavigateToStepThree: true
            });
          }, (error: HttpErrorResponse) => {
            this.isAddingDetails = false;

            this.errorTranslator.execute(error).pipe(
              takeUntil(this.unSubscribeUpdateMonitorDetailsOnDestroy))
              .subscribe((errorMessage: string) => {
                this.validateHttpError = errorMessage;
              });

          });
      }
    } else {
      this.hasCompletedStepTwo.emit({
        monitor: this.monitor,
        canNavigateToStepThree: true
      });
    }

  }

  public canShowWarningForAccessabilityOfMonitor() {
    const originalPrivacyLevel = this.monitor.privacy;
    const currentPrivacyLevel = this.addDetailsMonitorForm.controls['privacy'].value;

    return isEqual(currentPrivacyLevel, MonitorPrivacy.PRIVATE) && !isEqual(originalPrivacyLevel, currentPrivacyLevel) && (this.monitor.numberOfLinkedUsers > 1);
  }

  public goBackToStepOne(): void {
    if (!this.editMode) {
      this.hasCompletedStepTwo.emit({
        monitor: this.monitor,
        canNavigateToStepThree: false
      });
    } else {
      this.router.navigateByUrl('/monitors/manage');
    }
  }

  public canAddDetails(): boolean {
    return this.addDetailsMonitorForm.valid && !this.isAddingDetails;
  }

  public isEditMode(): boolean {
    return this.editMode;
  }

  public deleteMonitor() {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig> {
      panelClass: this.dialogConfig.panelClass,
      data: {
        textContent: 'MONITOR.DELETE_MONITOR_QUESTION',
        confirmText: 'GENERAL.OK',
        cancelText: 'GENERAL.CANCEL'
      }
    };
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.httpMonitorsService
          .deleteMonitor(this.monitor.id).pipe(
          takeUntil(this.unSubscribeUpdateMonitorDetailsOnDestroy))
          .subscribe(() => {
              this.router.navigateByUrl('/monitors/manage')
                .then(() => {
                  this.notificationService.show('MONITOR.DELETED_CONFIRMATION');
                });
            }, (error: any) => {
              const interpolateParams: any = {};
              const views = JSON.parse(error.error).views;

              if (!isUndefined(views)) {
                interpolateParams.views = map(views, 'name').join(', ');
              }

              this.errorTranslator.execute(error, interpolateParams).pipe(
                takeUntil(this.unSubscribeUpdateMonitorDetailsOnDestroy))
                .subscribe((errorMessage: string) => {
                  this.validateHttpError = errorMessage;
                });
            }
          );
      }
    });
  }

  public privacyLevels(): Array<string> {
    return values(this.MONITOR_PRIVACY_LEVELS);
  }
}
