<app-progress-spinner
  *ngIf="isFetchingNewData || isFirstLoad"
  [progressSpinnerText]="'CHART.LOADING_MEASUREMENTS' | translate:{sensor: 'view'}">
</app-progress-spinner>

<div *ngIf="canShowChart() && !isFirstLoad" [id]="'chartdiv_'+chart_id" [style.height.px]="500" [style.width.%]="100"></div>

<app-message *ngIf="!hasMeasurements() && !isFetchingNewData"
             [messageText]="'MONITOR.WARNINGS.NO_MEASUREMENTS' | translate"
             [messageStyle]="MESSAGE_STYLE.WARNING">
</app-message>

<button mat-button color="primary" (click)="toggleScrollbar()" *ngIf="enableScrollbar">{{'CHART.TOGGLE_SCROLLBAR_OFF' | translate}}</button>  
<button mat-button color="primary" (click)="toggleScrollbar()" *ngIf="!enableScrollbar">{{'CHART.TOGGLE_SCROLLBAR_ON' | translate}}</button>  

<button mat-button color="primary" (click)="showMostRecent()" *ngIf="canShowLastAvailable">{{'CHART.SHOW_LATEST' | translate}}</button>  

