import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
	private dialogRef: MatDialogRef<ConfirmationDialogComponent>;
	public textContent: string;
	public confirmText: string;
	public cancelText: string;

	constructor(dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) {
		this.dialogRef = dialogRef;
		this.textContent = data.textContent;
		this.confirmText = data.confirmText ? data.confirmText : 'OK';
		this.cancelText = data.cancelText ? data.cancelText : 'Cancel';
	}

	public confirm() {
		this.dialogRef.close(true);
	}

	public cancel() {
		this.dialogRef.close(false);
	}

	public keyPressed(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.confirm();
		}
	}
}
