import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {isEqual, size} from 'lodash';
import {includes} from 'lodash';
import {Subject, Observable} from 'rxjs';
import {Page} from '../../../domain/page';
import {View} from '../../../domain/view/view';
import {Views} from '../../../infrastructure/http/view/views';
import {environment} from '../../../../environments/environment';
import {MonitorSensor} from '../../../domain/monitor/monitorSensor';
import {tap} from "rxjs/operators";

@Component({
    selector: 'app-views',
    templateUrl: './views.component.html',
    styleUrls: ['./views.component.scss']
})
export class ViewsComponent {
    public subPages: Page[] = [];
    public isLoading = true;
    private viewsService: Views;

    constructor(route: ActivatedRoute,
                @Inject('Views') viewsService: Views) {
        this.subPages = route.snapshot.data.subPages;
        this.viewsService = viewsService;
    }

    public fetchDetails(view) {
        const details = {_name: view.name, info: view.elucidation};
        return details
    }

    private viewObservable;
    public getViews() {
        // Timeout to prevent 'changed after checked' bug
        const tapFunction = tap(() => setTimeout(() => this.isLoading = false, 1));
        this.viewObservable = this.viewObservable || this.viewsService.getAllForStatusOverview().pipe(tapFunction);
        return this.viewObservable;
    }
}
