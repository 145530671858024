import { EventEmitter, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import MonitorInputType from '../../../domain/monitor/monitorInputType';
var DetailsChartComponent = /** @class */ (function () {
    function DetailsChartComponent(translateService, truncateDigitsPipe, amChartsService) {
        this.resetHasSelectedBetweenTwoDates = new EventEmitter();
        this.unSubscribeMonitorDetailsChartOnDestroy = new Subject();
        this.MONITOR_INPUT_TYPE = MonitorInputType;
        this.SCROLL_BAR_HEIGHT = 60;
        this.SCROLL_BAR_OFFSET = 50;
        this.PREPEND_APPEND_PERIOD = 1800;
        this.translateService = translateService;
        this.truncateDigitsPipe = truncateDigitsPipe;
        this.amChartsService = amChartsService;
    }
    DetailsChartComponent.prototype.ngOnInit = function () {
    };
    DetailsChartComponent.prototype.ngAfterViewInit = function () {
        this.makeChart(true);
    };
    DetailsChartComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeMonitorDetailsChartOnDestroy.next(true);
        this.unSubscribeMonitorDetailsChartOnDestroy.complete();
        this.destroyChart();
    };
    DetailsChartComponent.prototype.destroyChart = function () {
        if (this.amChart) {
            this.amChartsService.destroyChart(this.amChart);
            this.amChart = null;
        }
    };
    return DetailsChartComponent;
}());
export { DetailsChartComponent };
