import {Pipe, PipeTransform} from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'truncateDigits'
})
export class TruncateDigitsPipe implements PipeTransform {
  /**
   * Truncates number to 2 digit
   * @param {number}  value
   * @param {number}  args    The number of digits. This is set default to 2
   * @returns {number}
   */
    transform(value: number, args: number = 2): any {
        return !_.isNil(value) ? (Math.trunc(value * Math.pow(10, args)) / Math.pow(10, args)).toFixed(args): "N/A";
  }
}
