import { filter, takeUntil } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { isNull, map } from 'lodash';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../application/notification/notification.service';
import JobCategory from '../../../domain/jobCategory';
import { AuthenticationService } from '../../../infrastructure/http/authentication/authentication.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(usersService, authenticationService, formBuilder, notificationService, errorTranslator, dialog) {
        var _this = this;
        this.jobCategories = JobCategory;
        this.unSubscribeUsersOnDestroy = new Subject();
        this.updatePerforming = false;
        this.dialogConfig = {
            panelClass: 'confirmation-dialog'
        };
        this.usersService = usersService;
        this.authenticationService = authenticationService;
        this.formBuilder = formBuilder;
        this.notificationService = notificationService;
        this.errorTranslator = errorTranslator;
        this.dialog = dialog;
        this.keyJobCategories = map(Object.keys(this.jobCategories), function (key) {
            return _this.jobCategories[key].toUpperCase();
        });
        this.userDetailsForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.maxLength(255)]],
            lastName: ['', [Validators.required, Validators.maxLength(255)]],
            email: [{ value: '', disabled: true }],
            jobCategory: ['', Validators.required],
            jobTitle: ['', Validators.maxLength(255)],
            phone: ['', Validators.maxLength(255)],
        });
    }
    UserProfileComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeUsersOnDestroy.next(true);
        this.unSubscribeUsersOnDestroy.complete();
    };
    UserProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticationService.getAuthenticatedUser().pipe(takeUntil(this.unSubscribeUsersOnDestroy), filter(function (a) { return !isNull(a); }))
            .subscribe(function (user) {
            _this.user = user;
            _this.userDetailsForm.controls['firstName'].setValue(user.firstName);
            _this.userDetailsForm.controls['lastName'].setValue(user.lastName);
            _this.userDetailsForm.controls['email'].setValue(user.email);
            _this.userDetailsForm.controls['jobCategory'].setValue(user.jobCategory);
            _this.userDetailsForm.controls['jobTitle'].setValue(user.jobTitle);
            _this.userDetailsForm.controls['phone'].setValue(user.phone);
        });
    };
    UserProfileComponent.prototype.saveUserProfile = function (form) {
        var _this = this;
        if (form.valid) {
            var formValues = form.value;
            this.updatePerforming = true;
            this.user.firstName = formValues.firstName;
            this.user.lastName = formValues.lastName;
            this.user.jobCategory = formValues.jobCategory;
            this.user.jobTitle = formValues.jobTitle;
            this.user.phone = formValues.phone;
            this.usersService.edit(this.user).pipe(takeUntil(this.unSubscribeUsersOnDestroy))
                .subscribe(function (editedUser) {
                _this.notificationService.show('USER.EDITED_CONFIRMATION');
                _this.authenticationService.setAuthenticatedUser(editedUser);
                _this.user.firstName = editedUser.firstName;
                _this.user.lastName = editedUser.lastName;
                _this.user.jobCategory = editedUser.jobCategory;
                _this.user.jobTitle = editedUser.jobTitle;
                _this.user.phone = editedUser.phone;
                _this.updatePerforming = false;
            }, function (error) {
                _this.updatePerforming = false;
                _this.errorTranslator.execute(error).pipe(takeUntil(_this.unSubscribeUsersOnDestroy))
                    .subscribe(function (errorMessage) {
                    throw new Error(errorMessage);
                });
            });
        }
    };
    UserProfileComponent.prototype.canSaveUserProfile = function () {
        return this.userDetailsForm.valid && !this.updatePerforming;
    };
    UserProfileComponent.prototype.deleteAccount = function () {
        var _this = this;
        var currentDialogConfig = {
            panelClass: this.dialogConfig.panelClass,
            data: {
                textContent: 'USER.DELETE_USER_QUESTION',
                confirmText: 'GENERAL.OK',
                cancelText: 'GENERAL.CANCEL'
            }
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.usersService.deleteUser(_this.user).subscribe();
                _this.authenticationService.logout();
            }
        });
    };
    return UserProfileComponent;
}());
export { UserProfileComponent };
