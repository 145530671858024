<app-registration-content>
  <div fxLayout="column" fxLayoutGap="40px" *ngIf="!requestedPasswordReset">
    <form class="reset__content antialiased" [formGroup]="requestResetPasswordForm" (ngSubmit)="requestResetPassword(requestResetPasswordForm.value, requestResetPasswordForm.valid)">
      <div fxLayout="column">
        <div class="input-row header">
          <p>{{'PASSWORD_RESET.RESET' | translate}}</p>
        </div>
        <div class="input-row hint">
          <p>{{'PASSWORD_RESET.HINT' | translate}}</p>
        </div>

        <div class="input-row">
          <!-- Email -->
          <mat-form-field fxFill>
            <mat-error>{{'REGISTER.ERROR_INVALID_EMAIL' | translate}}</mat-error>
            <input matInput
                   type="email"
                   placeholder="{{'EMAIL' | translate}}"
                   email
                   formControlName="email">
          </mat-form-field>
        </div>

        <div class="input-row submit">
          <button fxFill mat-raised-button color="primary" type="submit" [disabled]="!canRequestResetPassword()">
            {{'PASSWORD_RESET.RESET' | translate}}
          </button>
        </div>
      </div>

    </form>

    <div class="link-action" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch">
      <p class="antialiased">
        {{'REGISTER.ALREADY_HAVE_ACCOUNT' | translate}}
      </p>
      <p>
        <a mat-raised-button color="primary" routerLink="/login">{{'LOGIN.LOGIN' | translate}}</a>
      </p>
    </div>
  </div>


  <div *ngIf="requestedPasswordReset" class="reset__content reset">
    <div class="input-row">
      <h2>{{ 'PASSWORD_RESET.RESET_REQUESTED' | translate }}</h2>
    </div>
    <div class="input-row">
      <p>{{ 'PASSWORD_RESET.EMAIL_SENT_TO' | translate: {email: userEmail} }}</p>
    </div>
  </div>
</app-registration-content>
