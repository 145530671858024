<mat-expansion-panel>
    <mat-expansion-panel-header>
		{{sensor.name}}
    </mat-expansion-panel-header>
    
    <form (ngSubmit)="setNewLimits(lowerLimit.value, upperLimit.value)" class="action-form-width" *ngIf="canShowLimit()" fxLayout="column">
    	<mat-form-field [hidden]="!canShowLowerLimit()">
    		<input matInput type="number" placeholder="{{'ALARM.LOWERLIMIT' | translate}} ({{sensor.unit}})" value="{{getDefaultLowerVal()}}"  lang="nl-150" pattern="[0-9]+([\.,][0-9]+)?" #lowerLimit inputValToDecimal round="1">
		</mat-form-field>
    	<mat-form-field>
    		<input matInput type="number" placeholder="{{'ALARM.UPPERLIMIT' | translate}} ({{sensor.unit}})" value="{{getDefaultUpperVal()}}"  lang="nl-150" pattern="[0-9]+([\.,][0-9]+)?" #upperLimit inputValToDecimal round="1">
		</mat-form-field>
    	<button mat-stroked-button color="primary" type="submit">{{'ALARM.SUBMIT' | translate}}</button>
	</form>
	<app-message *ngIf="!canShowLimit()">No limits can be changed for this sensor.</app-message>	
    
    <form (ngSubmit)="setNewDelta(lowerLimit.value, upperLimitH.value, upperLimitM.value, upperLimitS.value)" class="action-form-width" *ngIf="canShowDelta()" fxLayout="column">
    	<mat-form-field >
    		<input matInput type="number" placeholder="{{'DELTA.LOWERLIMIT' | translate}} ({{sensor.unit}})" value="{{getDefaultThreshold()}}"  lang="nl-150" #lowerLimit inputValToDecimal round="1">
		</mat-form-field>
		<div fxLayout="row" fxLayoutGap="5px">
			<mat-form-field class="timeinput" >
				<input matInput type="number" step="1" placeholder="{{'DELTA.UPPERLIMIT' | translate}} ({{'MONITOR.DURATION.HOURS_SUFFIX' | translate}})" value="{{getDefaultPollingRate().hrs}}" lang="nl-150"  #upperLimitH >
			</mat-form-field>
			<mat-form-field class="timeinput" >
				<input matInput type="number" step="1" placeholder="{{'DELTA.UPPERLIMIT' | translate}} ({{'MONITOR.DURATION.MINUTES_SUFFIX' | translate}})" value="{{getDefaultPollingRate().mins}}" lang="nl-150"  #upperLimitM >
			</mat-form-field>
			<mat-form-field class="timeinput" >
				<input matInput type="number" step="1" placeholder="{{'DELTA.UPPERLIMIT' | translate}} ({{'MONITOR.DURATION.SECONDS_SUFFIX' | translate}})" value="{{getDefaultPollingRate().secs}}" lang="nl-150"  #upperLimitS >
			</mat-form-field>
		</div>
    	<button mat-stroked-button color="primary" type="submit" [disabled]="!isValidEntry(upperLimitH.valueAsNumber, lowerLimit.valueAsNumber)">{{'DELTA.SUBMIT' | translate}}</button>
    </form>

    <button id="ignore" color="{{getAlarmColor()}}" (click)="toggleAlarm()" [disabled]="this.sensor.isAlarmIgnored" mat-stroked-button>{{'ALARM.IGNORE' | translate}}</button>



  </mat-expansion-panel>