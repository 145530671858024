<mat-card>
	<mat-card-header>
		<mat-card-title
            class="antialiased" fxLayout.lt-md="column" fxLayout="row" fxLayout.xs="row" fxLayoutAlign="start start">
            <h3>{{'ACTIONS.DEFAULT' | translate}}</h3>
        </mat-card-title>
	</mat-card-header>
 	<mat-card-content fxLayoutAlign="center center">
 		<div class="actions-container" fxLayout="column" fxLayoutAlign="center center">
	 		<mat-accordion>
	 			<app-action-tile *ngFor="let sensor of sensors" [sensor]="sensor"> Something </app-action-tile>
	 		</mat-accordion>
 		</div>
	</mat-card-content>
</mat-card>