import { MonitorSensor } from '../../../../../domain/monitor/monitorSensor';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { floor, last } from "lodash";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { } from 'app/infrastructure/data/monitor-data.service';

@Component({
    selector: 'app-action-tile',
    templateUrl: './action-tiles.component.html',
    styleUrls: ['./action-tiles.component.scss']
})
export class ActionTilesComponent implements OnInit {

    @Input() sensor: MonitorSensor;
   
    constructor(private monitorService: MonitorDataService) { }

    private readonly INVALIDDELTA_CLAUSE = {
      "INPUTTYPE": "AUTO",
      "NAME": ["Interval Temperature", "Yearly Corrosion Rate"]
    };

    private readonly InValidLowerLimitType="TYPE_CXI_CORROSION";
    private readonly InValidLimitType="TYPE_CXI_TEMPERATURE";

    ngOnInit() {
    }
    public getAlarmColor() {
        return this.sensor.isAlarmIgnored ? 'warn' : 'primary';
    }

    public toggleAlarm() {
        this.sensor.isAlarmIgnored = !this.sensor.isAlarmIgnored;
        this.monitorService.setSensorAlarm(this.sensor.detailsId, this.sensor.id).subscribe();
    }

    public setNewLimits(lowerLimit, upperLimit) {
        const limits = {};
        if (lowerLimit && this.canShowLowerLimit()) {
            limits["lowerlimit"] = lowerLimit;
        }
        if (upperLimit) {
            limits["upperlimit"] = upperLimit;
        }
        this.monitorService.setSensorLimits(this.sensor.detailsId, this.sensor.id, limits).subscribe();
    }

    public setNewDelta(lowerLimit, upperLimitH, upperLimitM, upperLimitS) {
        const upperLimit = upperLimitH * 60 * 60 + upperLimitM * 60 + upperLimitS*1;
        const deltaLimits = {};
        if (lowerLimit) {
            deltaLimits["thresholdDelta"] = lowerLimit;
        }
        if (upperLimit) {
            deltaLimits["pollingRate"] = upperLimit;
        }
        this.monitorService.setPressureDelta(this.sensor.detailsId, this.sensor.id, deltaLimits).subscribe();
    }
    public getDefaultLowerVal() {
        return this.sensor.lowerLimits && this.sensor.lowerLimits.length > 0 ? last(this.sensor.lowerLimits).value : "";
    }

    public getDefaultUpperVal() {
        return this.sensor.upperLimits && this.sensor.upperLimits.length > 0 ? last(this.sensor.upperLimits).value : "";
    }

    public getDefaultThreshold() {
        return this.sensor.threshold ? this.sensor.threshold : "";
    }

    public getDefaultPollingRate(): {hrs: number, mins: number, secs: number} {
        if(!this.sensor.pollingRate) {
            return {hrs: 0, mins:0, secs:0}
        }

        const secs = Number(this.sensor.pollingRate) % 60
        const total_minutes = floor(Number(this.sensor.pollingRate) / 60)
        const mins = total_minutes % 60
        const hrs = floor(total_minutes / 60)
        return {hrs, mins, secs}
    }

    public canShowDelta() {
        const convertTxtToCompare = (txtVal) => {
            return (txtVal || '').toString().toUpperCase().replace(/\s/g, '');
        }

        return !(convertTxtToCompare(this.sensor.inputType) === this.INVALIDDELTA_CLAUSE.INPUTTYPE &&
            (this.INVALIDDELTA_CLAUSE.NAME || []).length > 0 &&
            (this.INVALIDDELTA_CLAUSE.NAME || []).filter(x => convertTxtToCompare(x) === convertTxtToCompare(this.sensor.name)).length > 0)
    }

    public canShowLowerLimit(): boolean {                
        return this.sensor.sensorType!=this.InValidLowerLimitType;
    }

    public canShowLimit(): boolean {        
        return this.sensor.sensorType!=this.InValidLimitType;
    }

    public isValidEntry(seconds, delta) {
        return seconds > 0 || delta > 0;
    }

}
