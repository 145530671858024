import {AbstractControl, ValidatorFn} from '@angular/forms';
import {isNull} from 'lodash';
import * as moment from 'moment';

export function validateDuration(min: number, max: number): ValidatorFn {
  return (abstractControl: AbstractControl): { [key: string]: any } => {
    const hours = abstractControl.get('hours').value;
    const minutes = abstractControl.get('minutes').value;
    const seconds = abstractControl.get('seconds').value;

    if (!isNull(hours) && !isNull(minutes) && !isNull(seconds)) {
      const durationAsSeconds = moment.duration({
        hours: hours,
        minutes: minutes,
        seconds: seconds
      }).asSeconds();

      if (durationAsSeconds > max || durationAsSeconds < min) {
        return {'invalidDuration': true};
      }
    }

    return null;
  };
}
