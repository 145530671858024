<section class="registration-content">
  <header></header>

  <article class="background-content" fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="column" class="container" fxLayoutAlign="start">
      <header fxLayout="row" fxHide fxShow.gt-sm>
        <img src="assets/img/resuslogo.svg"/>
        <div fxFlex fxLayout="row" fxLayoutAlign="end end">
          <span>Cloud Dashboard</span>
        </div>
      </header>
      <header fxLayout="row" fxHide.gt-sm fxShow>
        <img src="assets/img/resuslogo-tagline.svg"/>
      </header>
      <ng-content></ng-content>
    </div>

  </article>


</section>

