var FirmwareUpgradeStatus = /** @class */ (function () {
    function FirmwareUpgradeStatus(state, currentVersion, availableVersion) {
        this._state = state;
        this._currentVersion = currentVersion;
        this._availableVersion = availableVersion;
    }
    FirmwareUpgradeStatus.fromJSON = function (firmwareUpgradeStatusDTO) {
        return new FirmwareUpgradeStatus(firmwareUpgradeStatusDTO.state, firmwareUpgradeStatusDTO.currentVersion, firmwareUpgradeStatusDTO.availableVersion);
    };
    Object.defineProperty(FirmwareUpgradeStatus.prototype, "state", {
        get: function () {
            return this._state;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FirmwareUpgradeStatus.prototype, "currentVersion", {
        get: function () {
            return this._currentVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FirmwareUpgradeStatus.prototype, "availableVersion", {
        get: function () {
            return this._availableVersion;
        },
        enumerable: true,
        configurable: true
    });
    return FirmwareUpgradeStatus;
}());
export { FirmwareUpgradeStatus };
