import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {take, tap, catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
/* This class accepts requests (in observable form), puts them in a priority queue and runs one at a time.
*
*   Used to lazily preload data for caching.
* */

export class RequestManagerService {
    private priorityQueue: Array<priorityHandler> = [];
    private isWorking: boolean = false;

    constructor() {
    }

    public addToQueue(obj: priorityHandler) {
        this.priorityQueue.push(obj);
        console.log(this.priorityQueue)
        this.isWorking || this.next(); //Bug 593: Net na inloggen laden de monitors niet
    }

    private next() {
        if (!this.priorityQueue.length) {
            this.isWorking = false;
            return;
        }

        this.isWorking = true;
        this.priorityQueue = this.priorityQueue.sort((o1, o2) => o2.priority - o1.priority);
        this.consumeOne(this.priorityQueue.pop());
    }

    private consumeOne(prio: priorityHandler) {
        if(prio.name == "MonitorStatus") {
            console.log("first status")
        }
        prio.observable
            .pipe(
                take(1),
                tap(() => this.next()),
                catchError(e => {this.next(); throw e;})
            )
            .subscribe(prio.subscriptionFunc)
    }
}

export interface priorityHandler {
    priority: number,
    observable: Observable<any>,
    subscriptionFunc: (results: any) => void,
    name?: string
}
