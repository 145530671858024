import {Route, Routes} from '@angular/router';
import {each} from 'lodash';
import {Page} from '../../domain/page';
import {CanActivateLoginGuard} from '../security/can-activate-login-guard';
import {CanActivateViaAuthGuard} from '../security/can-activate-via-auth-guard';

export function convertPagesToRoutes(pages: Array<Page>): Routes {
  const routes: Routes = [];
  const LoginPath = 'login';

  each(pages, function (page: Page) {
    let route: Route;
    if(page.matcher) {
      route = {
        component: page.component,
        matcher: page.matcher
      }
    } else {
      route = {
        path: page.path,
        component: page.component,
        data: {
          'subPages': page.subPages,
          'parentPage': page.parentPage,
          'contains': page.path.match(/monitor/) ? "monitor":"view"
        }
      };
    }
    

    if (page.securedPage) {
      route.canActivate = [CanActivateViaAuthGuard];
    } else if (page.path === LoginPath) {
      route.canActivate = [CanActivateLoginGuard];
    }

    routes.push(route);

    if (page.isDefault) {
      routes.push({path: '', redirectTo: page.path, pathMatch: 'full'});
    }
  });
  return routes;
}
