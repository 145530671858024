import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var RequestResetPasswordComponent = /** @class */ (function () {
    function RequestResetPasswordComponent(formBuilder, userService, router, translateService) {
        this.requestedPasswordReset = false;
        this.resetPerforming = false;
        this.unSubscribeUsersOnDestroy = new Subject();
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.translateService = translateService;
        this.requestResetPasswordForm = this.formBuilder.group({
            'email': ['', [Validators.required, Validators.email]]
        });
    }
    RequestResetPasswordComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeUsersOnDestroy.next();
        this.unSubscribeUsersOnDestroy.complete();
    };
    RequestResetPasswordComponent.prototype.requestResetPassword = function (form, isValid) {
        var _this = this;
        if (isValid) {
            this.resetPerforming = true;
            this.userEmail = form.email;
            this.userService.requestResetPassword(form.email).pipe(takeUntil(this.unSubscribeUsersOnDestroy))
                .subscribe(function () {
                _this.requestedPasswordReset = true;
            }, function (error) {
                _this.resetPerforming = false;
                var parsedError = JSON.parse(error.error);
                var errorMessage = parsedError.message;
                _this.translateService.get("ERRORS." + error.error.type).pipe(takeUntil(_this.unSubscribeUsersOnDestroy))
                    .subscribe(function (translation) {
                    if (translation !== "ERRORS." + error.error.type) {
                        errorMessage = translation;
                    }
                });
                throw new Error(errorMessage);
            });
        }
    };
    RequestResetPasswordComponent.prototype.canRequestResetPassword = function () {
        return this.requestResetPasswordForm.valid && !this.resetPerforming;
    };
    return RequestResetPasswordComponent;
}());
export { RequestResetPasswordComponent };
