
import {forkJoin as observableForkJoin, Observable, ReplaySubject, Subject, timer, BehaviorSubject} from 'rxjs';

import {takeUntil, distinctUntilChanged, filter, map, take, tap, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Monitor} from "../../domain/monitor/monitor";
import {MonitorStatusOverview} from "../../domain/monitor/monitorStatusOverview";
import {HttpMonitorsService} from "../http/monitor/http-monitors.service";
import {RequestManagerService} from "./request-manager.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DetailStatusOverview} from "../../domain/monitor/sensor/DetailStatusOverview";
import * as moment from "moment";
import {Moment} from "moment";
import * as _ from "lodash";
import {EventSubject} from "./EventSubject";
import {AuthenticationService} from "../http/authentication/authentication.service";
import { FirmwareUpgradeStatus } from 'app/domain/monitor/firmware/firmware-upgrade-status';
import { Chat } from 'app/domain/Chat';

@Injectable({
    providedIn: 'root'
})
export class MonitorDataService extends HttpMonitorsService {

    // Caching subjects: These keep the fetched data.
    private allMonitors: Subject<Monitor[]> = new ReplaySubject<Monitor[]>(1);
    private allMonitorsForStatusOverview: Subject<MonitorStatusOverview[]> = new ReplaySubject<MonitorStatusOverview[]>(1);
    private detailOverviewSubject: EventSubject<{ [id: string]: DetailStatusOverview }> = new EventSubject({});
    private firmwareStatusSubject: Subject<FirmwareUpgradeStatus> = new BehaviorSubject(null);
    private chat: Subject<{[id: string] : Chat[]}> = new BehaviorSubject({});

    private refresh: NodeJS.Timer;


    constructor(private httpClient: HttpClient,
                private requestManager: RequestManagerService, private authenticationService: AuthenticationService) {
        super(httpClient);
        this.authenticationService.getAuthenticatedUser()
            .pipe(distinctUntilChanged((obj1, obj2) => obj1 && obj2 && obj1.id === obj2.id))
            .subscribe((user) => user ? this.initialize() : this.clear());
    }

    private initialize() {
        this.fetchStatus();
        if(environment.prefetchMeasurements) {
            this.initDetailOverviews();
        }        
        this.refresh = this.refresh || setInterval(() => this.fetchStatus(), environment.pollingIntervalInSeconds * 5000);
    }

    private clear() {
        clearInterval(this.refresh);
        this.refresh = null;
        this.allMonitors.next(null);
        this.allMonitorsForStatusOverview.next(null);
        this.detailOverviewSubject.next({});
    }

    /**
     * Accessor methods. These are overloaded from HttpMonitorsService, and utilize the caching before request.
     */
    // This function is no longer supposed to be used. Use 'getAllForStatusOverview' instead.
    getAll(): Observable<Monitor[]> {
        return this.allMonitors
            .pipe(filter(monitors => !_.isNil(monitors)))
    }

    getAllForStatusOverview(): Observable<MonitorStatusOverview[]> {
        return this.allMonitorsForStatusOverview
            .pipe(
                filter(monitors => !_.isNil(monitors)),
                //distinctUntilChanged((one, two) => JSON.stringify(one) === JSON.stringify(two)),
            );
    }
    getOneForStatusOverview(id): Observable<MonitorStatusOverview> {
        return this.allMonitorsForStatusOverview
            .pipe(
                filter(monitors => !_.isNil(monitors)),
                distinctUntilChanged((one, two) => JSON.stringify(one) === JSON.stringify(two)),
                map((obj: MonitorStatusOverview[]) => obj.filter(obj => obj.id == id)[0]),
                filter(obj => !_.isNil(obj)),
            );
    }

    private compareMeasurements = (one: DetailStatusOverview, two: DetailStatusOverview) => one.lastMeasuredDateTime === two.lastMeasuredDateTime && one.measurements.length !== two.measurements.length;

    getForDetailOverview(id: string, selectedDateRange: { start: Moment, end: Moment }, detailStatusOverview?: MonitorStatusOverview): Observable<DetailStatusOverview> {
            // The default range is cached. We don't need to send a new request.
            this.startRefreshInterval(id, selectedDateRange);
            return this.detailOverviewSubject
                .pipe(
                    map(obj => obj[id]),
                    filter(obj => !_.isNil(obj)),
                    //distinctUntilChanged(this.compareMeasurements),
                )
    }


    public addMonitor(activationCode: string, serialNumber: string): Observable<Monitor> {
        return super.addMonitor(activationCode, serialNumber)
            .pipe(
                tap(() => this.clear()),
                tap(() => this.fetchStatus()),
                //tap(() => this.fetchAllMonitors())
            )
    }

    public addByKey(serial: string, role: string, key: string, email: string): Observable<void> {
        return super.addByKey(serial, role, key, email)
            .pipe(
                tap(() => setTimeout(() => this.fetchStatus(), 1)),
            )
    }


    public deleteMonitor(id: string): Observable<string> {
        return super.deleteMonitor(id)
            .pipe(
                tap(() => this.fetchStatus()),
                )
    }

    public setChangedOrder(id: string, order: { [key: string]: number }): Observable<Monitor> {
        return super.setChangedOrder(id, order)
    }

    /**
     * These functions refresh the cached data.
     */
    private fetchStatus() {
        this.requestManager.addToQueue({
            priority: 1, observable: super.getAllForStatusOverview(), name:"MonitorStatus",
            subscriptionFunc: (monitors: MonitorStatusOverview[]) => {this.allMonitorsForStatusOverview.next(monitors)}
        });
    }

    private startRefreshInterval(id, selectedDateRange) {
        timer(0, environment.pollingIntervalInSeconds * 1000).pipe(
        // We automatically unsubscribe to the timer (and thus stop updating) when we unsubscribe the measurement update source.
            takeUntil(this.detailOverviewSubject.onUnsubscribe()))
            .subscribe(() => {
                this.updateMeasurements(id, selectedDateRange);
                this.updateChat(id);
                //this.initDetailOverview(id);
            })
    }

    private updateChat(id) {
        this.chat
            .pipe(take(1))
            .subscribe(chat => {
                super.getChatForMonitor(id).subscribe(m => {
                    chat[id] = m.concat().sort((a, b) => (new Date(a.creationtime)).getTime() - (new Date(b.creationtime)).getTime());
                    this.chat.next(chat);
                })
            })
    }

    private updateMeasurements(id, selectedDateRange) {
        if(selectedDateRange.end.isSame(moment(), "day")) {
            selectedDateRange.end = moment();
        }
        // Fetch the latest to update.
        observableForkJoin([
            this.getDetailOverviewForCache(),
            this.getOneForStatusOverview(id).pipe(take(1))
        ])
            .subscribe(results => {

                super.getForDetailOverview(id, selectedDateRange, results[1])
                    .subscribe((updated) => {
                        results[0][id] = updated;
                        this.detailOverviewSubject.next(results[0])
                    })
            })
    }

    public _skipCacheDetailOverview(id, selectedDateRange) {
        return super.getForDetailOverview(id, selectedDateRange)
    }


    /**
     * These functions initialize the needed data.
     */
    private initDetailOverviews() {
        this.getAllForStatusOverview().pipe(take(1))
            .subscribe(monitors => monitors.forEach(monitor => this.initDetailOverview(monitor.id, monitor)));
    }

    private initDetailOverview(monitorId, statusOverview? : MonitorStatusOverview) {
        const subscriptionFunc = detailOverview => this.getDetailOverviewForCache()
            .pipe(take(1))
            .subscribe(obj => {
                obj[monitorId] = detailOverview;
                this.detailOverviewSubject.next(obj);
            });

        // Request for the detail overview
        const observable = super.getForDetailOverview(monitorId, MonitorDataService.defaultTime(), statusOverview);

        this.requestManager.addToQueue({priority: 10, observable, subscriptionFunc});
    }

    // This function is no longer supposed to be used. All data is now in Statusoverview.
    private fetchAllMonitors() {
        this.requestManager.addToQueue({
            priority: 8,
            observable: super.getAll(),
            subscriptionFunc: (monitors: Monitor[]) => this.allMonitors.next(monitors)
        });
    }

    /**
     * Helper methods: these method are a shorthand for tasks.
     */

    private static defaultTime: () => { start: Moment, end: Moment } = () => ({ start: moment().subtract(1, 'month'), end: moment() });

    private static isDefault(selectedDateRange: { start: Moment, end: Moment }) {
        return selectedDateRange.start.isSame(this.defaultTime().start,"day")  && selectedDateRange.end.isSame(this.defaultTime().end,"day")
    }

    private getDetailOverviewForCache() {
        return this.detailOverviewSubject
            .pipe(
                take(1)
            )
    }

    public requestFirmwareUpgrade(id: string): Observable<FirmwareUpgradeStatus> {
        return super.requestFirmwareUpgrade(id)
        .pipe(tap((o) => this.firmwareStatusSubject.next(o)));
    }

    public getFirmwareUpgradeStatus(id: string): Observable<FirmwareUpgradeStatus> {
        return this.firmwareStatusSubject.pipe(switchMap(() => super.getFirmwareUpgradeStatus(id)));
    }

    public getChatForMonitor(id: string): Observable<Chat[]> {
        return this.chat.pipe(map(c => c[id]));
    }

    public postChatForMonitor(id, text) {
        if(!text) {
            return Observable.empty();
        }
        return super.postChatForMonitor(id, text).pipe(tap(() => this.updateChat(id)))
    }
}
