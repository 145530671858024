import { take, tap, catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
var RequestManagerService = /** @class */ (function () {
    function RequestManagerService() {
        this.priorityQueue = [];
        this.isWorking = false;
    }
    RequestManagerService.prototype.addToQueue = function (obj) {
        this.priorityQueue.push(obj);
        console.log(this.priorityQueue);
        this.isWorking || this.next(); //Bug 593: Net na inloggen laden de monitors niet
    };
    RequestManagerService.prototype.next = function () {
        if (!this.priorityQueue.length) {
            this.isWorking = false;
            return;
        }
        this.isWorking = true;
        this.priorityQueue = this.priorityQueue.sort(function (o1, o2) { return o2.priority - o1.priority; });
        this.consumeOne(this.priorityQueue.pop());
    };
    RequestManagerService.prototype.consumeOne = function (prio) {
        var _this = this;
        if (prio.name == "MonitorStatus") {
            console.log("first status");
        }
        prio.observable
            .pipe(take(1), tap(function () { return _this.next(); }), catchError(function (e) { _this.next(); throw e; }))
            .subscribe(prio.subscriptionFunc);
    };
    RequestManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestManagerService_Factory() { return new RequestManagerService(); }, token: RequestManagerService, providedIn: "root" });
    return RequestManagerService;
}());
export { RequestManagerService };
