/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grid-edit-monitor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "./grid-edit-monitor.component";
var styles_GridEditMonitorComponent = [i0.styles];
var RenderType_GridEditMonitorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridEditMonitorComponent, data: {} });
export { RenderType_GridEditMonitorComponent as RenderType_GridEditMonitorComponent };
function View_GridEditMonitorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("GENERAL.EDIT")); _ck(_v, 1, 0, currVal_0); }); }
function View_GridEditMonitorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("GENERAL.DETAILS")); _ck(_v, 1, 0, currVal_0); }); }
export function View_GridEditMonitorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i3.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "button", [["class", "antialiased btn-edit-monitor"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(4, 16384, null, 0, i9.RouterLink, [i9.Router, i9.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_GridEditMonitorComponent_1)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_GridEditMonitorComponent_2)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "center center"; _ck(_v, 1, 0, currVal_0); var currVal_3 = "primary"; _ck(_v, 3, 0, currVal_3); var currVal_4 = _ck(_v, 5, 0, "/monitors", _co.monitor.id, "edit"); _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.canEditMonitor(); _ck(_v, 7, 0, currVal_5); var currVal_6 = !_co.canEditMonitor(); _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 3).disabled || null); var currVal_2 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_GridEditMonitorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_GridEditMonitorComponent_0, RenderType_GridEditMonitorComponent)), i1.ɵdid(1, 49152, null, 0, i11.GridEditMonitorComponent, [], null, null)], null, null); }
var GridEditMonitorComponentNgFactory = i1.ɵccf("ng-component", i11.GridEditMonitorComponent, View_GridEditMonitorComponent_Host_0, {}, {}, []);
export { GridEditMonitorComponentNgFactory as GridEditMonitorComponentNgFactory };
