import {Component} from '@angular/core';
import {View} from '../../../../domain/view/view';

@Component({
  templateUrl: 'grid-edit-view.component.html',
  styleUrls: ['./grid-edit-view.component.scss']
})
export class GridEditViewComponent {
  public view: View;

  public agInit(params: any): void {
    this.view = params.data;
  }

  public editView() {

  }
}
