<div fxLayout="column" fxFlex>
  <app-navigation-toolbar [subPages]="subPages"></app-navigation-toolbar>
  <section fxLayout="column" fxFlex>
    <app-desktop-manage-monitors *ngIf="!isLoading && isDesktopView()" [monitors]="monitors"></app-desktop-manage-monitors>
    <app-mobile-manage-monitors *ngIf="!isLoading && !isDesktopView()" [monitors]="monitors"></app-mobile-manage-monitors>
    <app-progress-spinner
    *ngIf="isLoading" fxFlex
    [progressSpinnerText]="'NAVIGATION.MONITORS' | translate">
  </app-progress-spinner>
  </section>
  <app-footer></app-footer>

</div>
