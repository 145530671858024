import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { isUndefined, isNil } from 'lodash';
import { ReplaySubject } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../../environments/environment';
import { User } from '../../../domain/user';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(httpClient, localStorageService, router) {
        this.Token = 'token';
        this.AuthenticatedUser = 'authenticatedUser';
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.router = router;
        this.authenticatedUserSubject = new ReplaySubject(1);
    }
    AuthenticationService.prototype.login = function (email, password) {
        var _this = this;
        var body = new URLSearchParams();
        body.set('username', email);
        body.set('password', password);
        body.set('grant_type', 'password');
        body.set('client_id', environment.client_id);
        body.set('client_secret', environment.client_secret);
        return this.httpClient
            .post('/oauth/token', body.toString()).pipe(map(function (token) {
            _this.localStorageService.set(_this.Token, token);
            _this.getAuthenticatedUserHttp().subscribe(function (user) {
                _this.localStorageService.set(_this.AuthenticatedUser, user.toJSON());
                _this.setAuthenticatedUser(user);
            });
        }));
    };
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        var body = new URLSearchParams();
        body.set('grant_type', 'refresh_token');
        if (!isUndefined(this.getToken())) {
            body.set('refresh_token', this.getToken().refresh_token);
        }
        this.localStorageService.remove(this.Token);
        return this.httpClient.post('/oauth/token', body.toString()).pipe(map(function (token) {
            _this.localStorageService.set(_this.Token, token);
        }));
    };
    AuthenticationService.prototype.getAuthenticatedUser = function () {
        var result = null;
        if (this.isAuthenticated()) {
            result = User.fromJSON(this.localStorageService.get(this.AuthenticatedUser));
        }
        this.authenticatedUserSubject.next(result);
        return this.authenticatedUserSubject;
    };
    AuthenticationService.prototype.setAuthenticatedUser = function (user) {
        this.localStorageService.set(this.AuthenticatedUser, user.toJSON());
        this.authenticatedUserSubject.next(user);
    };
    AuthenticationService.prototype.logout = function () {
        this.authenticatedUserSubject.next(null);
        this.localStorageService.clearAll();
        this.router.navigate(['/login']);
    };
    AuthenticationService.prototype.hasToken = function () {
        return !isNil(this.localStorageService.get(this.Token));
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        return !isNil(this.localStorageService.get(this.AuthenticatedUser));
    };
    AuthenticationService.prototype.getToken = function () {
        var result;
        if (this.hasToken()) {
            result = this.localStorageService.get(this.Token);
        }
        return result;
    };
    AuthenticationService.prototype.getAuthenticatedUserHttp = function () {
        return this.httpClient.get('/api/users/me').pipe(map(User.fromJSON));
    };
    return AuthenticationService;
}());
export { AuthenticationService };
