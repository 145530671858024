import { takeUntil, take } from 'rxjs/operators';
import { AfterContentInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { assign, clone, drop, isEqual, isNull, isUndefined, remove } from 'lodash';
import * as moment from 'moment';
import * as _ from "lodash";
import 'moment-duration-format';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../../../application/notification/notification.service';
import { validateDuration } from '../../../../../application/validators/validate-duration';
import { validateDurationGreaterThan } from '../../../../../application/validators/validate-duration-greater-than';
import { Monitor } from '../../../../../domain/monitor/monitor';
import MonitorChannel from '../../../../../domain/monitor/monitorChannel';
import MonitorInputType from '../../../../../domain/monitor/monitorInputType';
import { SensorPredefined } from '../../../../../domain/monitor/sensor/sensor-predefined';
import { SensorAnalog } from '../../../../../domain/monitor/sensor/sensorAnalog';
import { SensorDigitalOpenClosed } from '../../../../../domain/monitor/sensor/sensorDigitalOpenClosed';
import { SensorDigitalPulse } from '../../../../../domain/monitor/sensor/sensorDigitalPulse';
import { MessageStyle } from 'app/domain/messageStyle';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { TruncateDigitsPipe } from '../../../../../application/truncate-digits/truncate-digits.pipe';
import { SensorAuto } from 'app/domain/monitor/sensor/sensor-auto';
var MonitorAddStep3Component = /** @class */ (function () {
    function MonitorAddStep3Component(formBuilder, truncateDigitsPipe, router, httpMonitorsService, media, notificationService, errorTranslator) {
        this.hasCompletedStepThree = new EventEmitter();
        this.sensorProgressValue = 0;
        this.selectedSensorIndex = 0;
        this.allPredefinedSensors = [];
        this.selectedSensorCategoryIndex = 0;
        this.selectedSensorTypeIndex = 0;
        this.selectedUnitIndex = 0;
        this.isLoadingAllSensorCategories = true;
        this.isLoadingAllPredefinedSensors = false;
        this.SENSOR_SETTING_CUSTOM = 'CUSTOM';
        this.SENSOR_SETTING_PREDEFINED = 'PREDEFINED';
        this.MONITOR_INPUT_TYPE = MonitorInputType;
        this.MESSAGE_STYLE = MessageStyle;
        this.POLLING_RATE = 'pollingRate';
        this.CLOUD_STORAGE_RATE = 'cloudStorageRate';
        this.InValidLowerLimitType = "TYPE_CXI_CORROSION";
        this.InValidLimitTypes = ["TYPE_CXI_TEMPERATURE", "TYPE_PCXI"];
        this.sensorMap = new Map();
        this.unSubscribeAddMonitorSensorOnDestroy = new Subject();
        this.isEditingSensor = false;
        this.MAX_TEXT_FIELD_LENGTH = 255;
        this.MAX_TEXT_AREA_LENGTH = 1000;
        this.MAX_TEXT_OPEN_CLOSED_LENGTH = 20;
        this.ALL_AVAILABLE_FORM_CONTROLS = [
            'unitId',
            'value',
            'openText',
            'openTextDescription',
            'closedText',
            'closedTextDescription',
            'measurementMinimum',
            'valueMinimum',
            'measurementMaximum',
            'valueMaximum',
            this.POLLING_RATE,
            this.CLOUD_STORAGE_RATE,
            'upperlimit',
            'lowerlimit'
        ];
        this.SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS = [
            {
                name: 'inputType',
                formControl: (new FormControl(null, [Validators.required]))
            },
            {
                name: 'categoryId',
                formControl: (new FormControl(null, [Validators.required]))
            },
            {
                name: 'sensorTypeId',
                formControl: (new FormControl(null, [Validators.required]))
            },
        ];
        this.SENSOR_SETTINGS_PREDEFINED_INPUT_FIELDS = [
            {
                name: 'inputType',
                formControl: (new FormControl(null))
            },
            {
                name: 'predefinedSensorId',
                formControl: (new FormControl(null, [Validators.required]))
            }
        ];
        this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS = [
            {
                name: 'value',
                formControl: (new FormControl(null, [
                    Validators.required,
                    Validators.maxLength(this.MAX_TEXT_FIELD_LENGTH)
                ]))
            },
            {
                name: 'unitId',
                formControl: (new FormControl(null, [Validators.required]))
            },
        ];
        this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS = [
            {
                name: 'openText',
                formControl: (new FormControl(null, [
                    Validators.required,
                    Validators.maxLength(this.MAX_TEXT_OPEN_CLOSED_LENGTH)
                ]))
            },
            {
                name: 'openTextDescription',
                formControl: (new FormControl(null, [Validators.maxLength(this.MAX_TEXT_AREA_LENGTH)]))
            },
            {
                name: 'closedText',
                formControl: (new FormControl(null, [
                    Validators.required,
                    Validators.maxLength(this.MAX_TEXT_OPEN_CLOSED_LENGTH)
                ]))
            },
            {
                name: 'closedTextDescription',
                formControl: (new FormControl(null, [Validators.maxLength(this.MAX_TEXT_AREA_LENGTH)]))
            },
        ];
        this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS = [
            {
                name: 'measurementMinimum',
                formControl: (new FormControl(null, [Validators.required]))
            },
            {
                name: 'valueMinimum',
                formControl: (new FormControl(null, [Validators.required]))
            },
            {
                name: 'measurementMaximum',
                formControl: (new FormControl(null, [Validators.required]))
            },
            {
                name: 'valueMaximum',
                formControl: (new FormControl(null, [Validators.required]))
            },
            {
                name: 'unitId',
                formControl: (new FormControl(null, [Validators.required]))
            }
        ];
        this.CLOUD_STORAGE_RATE_DEFAULTS = {
            HOURS: 1,
            MINUTES: 0,
            SECONDS: 0
        };
        this.POLLING_RATE_DEFAULTS = {
            HOURS: 0,
            MINUTES: 5,
            SECONDS: 0
        };
        this.INVALIDDELTA_CLAUSE = {
            "INPUTTYPE": ["AUTO", "DIGITAL_OPEN_CLOSED"],
            "NAME": ["Interval Temperature", "Yearly Corrosion Rate", "Service switch"]
        };
        this.ValidPCXIForCloudStorage = {
            channelType: "FIXED",
            sensorInputType: "AUTO",
            sensorType: "TYPE_PRESSURE",
            sensorTypeName: "Pressure"
        };
        this.formBuilder = formBuilder;
        this.router = router;
        this.httpMonitorsService = httpMonitorsService;
        this.media = media;
        this.notificationService = notificationService;
        this.errorTranslator = errorTranslator;
        this.truncateDigitsPipe = truncateDigitsPipe;
    }
    MonitorAddStep3Component.prototype.ngOnInit = function () {
        this.addSensorMonitorForm = this.formBuilder.group({
            name: [{
                    value: null,
                    disabled: !this.canEditMonitor()
                }, [Validators.required, Validators.maxLength(this.MAX_TEXT_FIELD_LENGTH)]],
            sensorSettings: [{
                    value: null,
                    disabled: !this.canEditMonitor()
                }, Validators.required],
        });
        this.getAllSensorCategories();
    };
    MonitorAddStep3Component.prototype.ngAfterContentInit = function () {
        // Store the initial sensor progress value
        this.initialSensorProgressValue = 100 / this.monitor.channels.length;
        if (this.monitor.sensors.length > 0) {
            // Add all sensors to the sensor map
            for (var _i = 0, _a = this.monitor.sensors; _i < _a.length; _i++) {
                var sensor = _a[_i];
                this.sensorMap.set(sensor['channel'], sensor);
            }
            this.isEditingSensor = true;
            if (!this.isCurrentChannelFixed() && !isUndefined(this.sensorMap.get(this.selectedSensorIndex))) {
                this.setFormFieldsForSensorSettings(this.sensorMap.get(this.selectedSensorIndex)['inputType']);
                this.preFillFormData(this.sensorMap.get(this.selectedSensorIndex));
            }
        }
        // Calculate the progress
        this.sensorProgressValue = this.calculateCurrentSensorProgress();
    };
    MonitorAddStep3Component.prototype.ngOnDestroy = function () {
        this.unSubscribeAddMonitorSensorOnDestroy.next(true);
        this.unSubscribeAddMonitorSensorOnDestroy.complete();
        // Unsubscribe the watchers
        this.unSubscribeWatchers([
            'inputTypeWatcher',
            'sensorSettingsWatcher',
            'canShowPlaceHolderWatcher'
        ]);
    };
    MonitorAddStep3Component.prototype.canEditMonitor = function () {
        return this.monitor.editableByCurrentUser;
    };
    MonitorAddStep3Component.prototype.canShowLowerLimit = function () {
        var sensorObj = this.sensorMap.get(this.selectedSensorIndex);
        return sensorObj.sensorType != this.InValidLowerLimitType;
    };
    MonitorAddStep3Component.prototype.canShowLimit = function () {
        var sensorObj = this.sensorMap.get(this.selectedSensorIndex);
        if (isUndefined(sensorObj)) {
            return false;
        }
        else {
            return !this.InValidLimitTypes.includes(sensorObj.sensorType);
        }
    };
    MonitorAddStep3Component.prototype.canGoToNextStep = function () {
        return this.canAddSensor() || this.isCurrentChannelFixed() || !this.canEditMonitor();
    };
    MonitorAddStep3Component.prototype.skipSensorToMonitor = function (ngAddSensorMonitorForm) {
        return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
    };
    MonitorAddStep3Component.prototype.saveLimitAndDeltaValue = function (lowerLimit, upperLimit, thresholdDelta, sensorId) {
        if (sensorId) {
            if ((lowerLimit != "" || upperLimit != "") && this.canShowLimit()) {
                var limits = {};
                if (this.canShowLowerLimit())
                    limits["lowerlimit"] = lowerLimit;
                limits["upperlimit"] = upperLimit;
                this.httpMonitorsService.setSensorLimits(this.monitor.id, sensorId, limits).subscribe();
            }
            if (thresholdDelta != "" && this.canShowDelta())
                this.httpMonitorsService.setPressureDelta(this.monitor.id, sensorId, { thresholdDelta: thresholdDelta }).subscribe();
        }
    };
    MonitorAddStep3Component.prototype.addSensorToMonitor = function (form, isValid, ngAddSensorMonitorForm, lowerlimit, upperlimit, threshold) {
        var _this = this;
        if (!this.canEditMonitor()) {
            return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
        }
        else {
            if (isValid || this.isCurrentChannelFixed()) {
                this.isAddingSensor = true;
                // Reset the http error
                this.validateHttpError = null;
                var _lowerLimit_1 = lowerlimit;
                var _upperLimit_1 = upperlimit;
                var canAddSensor = true;
                var sensorObj = this.sensorMap.get(this.selectedSensorIndex);
                var sensorId_1 = sensorObj.id;
                if (isUndefined(this.addSensorMonitorForm.controls['inputType'])) {
                    canAddSensor = false;
                    this.saveLimitAndDeltaValue(_lowerLimit_1, _upperLimit_1, threshold, sensorId_1);
                    return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
                }
                var sensor_1 = this.getSensorAccordingToInputType(this.addSensorMonitorForm.controls['inputType'].value, form, sensorObj);
                if (isUndefined(sensor_1)) {
                    canAddSensor = false;
                    this.saveLimitAndDeltaValue(_lowerLimit_1, _upperLimit_1, threshold, sensorId_1);
                    return this.addedSensorToMonitor(null, ngAddSensorMonitorForm, null);
                }
                else {
                    // Get the sensor ID, if present we are going to do an update instead of an insert
                    if (!isUndefined(this.sensorMap.get(this.selectedSensorIndex)))
                        canAddSensor = false;
                    if (this.isShowPollingCloudStorgeForPcxi() ||
                        this.addSensorMonitorForm.controls['inputType'].value === this.ValidPCXIForCloudStorage.sensorInputType) {
                        sensor_1.unitId = sensorObj.unitId;
                    }
                    this.httpMonitorsService
                        .addMonitorSensor(this.monitor.id, sensor_1, canAddSensor, sensorId_1)
                        .takeUntil(this.unSubscribeAddMonitorSensorOnDestroy)
                        .subscribe(function (res) {
                        if (res && res["id"])
                            sensorId_1 = res["id"];
                        _this.saveLimitAndDeltaValue(_lowerLimit_1, _upperLimit_1, threshold, sensorId_1);
                        return _this.addedSensorToMonitor(sensor_1, ngAddSensorMonitorForm, res);
                    }, function (error) {
                        _this.isAddingSensor = false;
                        _this.errorTranslator.execute(error)
                            .pipe(takeUntil(_this.unSubscribeAddMonitorSensorOnDestroy))
                            .subscribe(function (errorMessage) {
                            _this.validateHttpError = errorMessage;
                        });
                    });
                }
            }
        }
    };
    MonitorAddStep3Component.prototype.goToPreviousSensor = function () {
        var channels = _.take(clone(this.monitor.channels), this.selectedSensorIndex);
        var i = channels.length;
        this.selectedSensorIndex--;
        while (i--) {
            if (isEqual(channels[i], MonitorChannel.FIXED))
                this.selectedSensorIndex--;
            else
                break;
        }
        if (isEqual(channels.length, 0) || isEqual(this.selectedSensorIndex, -1)) {
            this.selectedSensorIndex = 0;
            return this.hasCompletedStepThree.emit(false);
        }
        // Re-calculate the progress
        this.sensorProgressValue = this.calculateCurrentSensorProgress();
        this.isEditingSensor = true;
        if (this.sensorMap.get(this.selectedSensorIndex)['inputType'] === this.SENSOR_SETTING_PREDEFINED) {
            this.getAllPredefinedSensors();
        }
        this.preFillFormData(this.sensorMap.get(this.selectedSensorIndex));
    };
    MonitorAddStep3Component.prototype.hasReachedLastSensorStep = function () {
        var channels = drop(this.monitor.channels, this.selectedSensorIndex + 1);
        return isEqual(this.selectedSensorIndex, this.monitor.channels.length - 1);
    };
    MonitorAddStep3Component.prototype.hasSensorName = function (sensorIndex) {
        return !isUndefined(this.sensorMap.get(sensorIndex));
    };
    MonitorAddStep3Component.prototype.hasSensorStepBeenCompleted = function (sensorIndex) {
        return !isUndefined(this.sensorMap.get(sensorIndex)) && !isEqual(sensorIndex, this.selectedSensorIndex);
    };
    MonitorAddStep3Component.prototype.hasSelectedInputType = function () {
        return !isUndefined(this.addSensorMonitorForm.controls['inputType'])
            && !isNull(this.addSensorMonitorForm.controls['inputType'].value);
    };
    MonitorAddStep3Component.prototype.hasSelectedSensorType = function () {
        return !isNull(this.addSensorMonitorForm.controls['sensorTypeId'].value);
    };
    MonitorAddStep3Component.prototype.hasSelectedCategory = function () {
        return !isNull(this.addSensorMonitorForm.controls['categoryId'].value);
    };
    MonitorAddStep3Component.prototype.canShowPlaceHolder = function () {
        return this.media.isActive('lt-lg');
    };
    MonitorAddStep3Component.prototype.canAddPredefinedSettings = function () {
        return isEqual(this.addSensorMonitorForm.controls['sensorSettings'].value, this.SENSOR_SETTING_PREDEFINED);
    };
    MonitorAddStep3Component.prototype.canAddCustomSettings = function () {
        return isEqual(this.addSensorMonitorForm.controls['sensorSettings'].value, this.SENSOR_SETTING_CUSTOM);
    };
    MonitorAddStep3Component.prototype.canAddSensor = function () {
        return this.addSensorMonitorForm.valid && !this.isAddingSensor;
    };
    MonitorAddStep3Component.prototype.canShowDigitalOpenClosed = function () {
        return this.hasSelectedInputType()
            && this.hasSelectedSensorType()
            && this.hasSelectedCategory()
            && isEqual(this.addSensorMonitorForm.controls['inputType'].value, this.MONITOR_INPUT_TYPE.DIGITAL_OPEN_CLOSED);
    };
    MonitorAddStep3Component.prototype.canShowDigitalPulse = function () {
        return !isUndefined(this.addSensorMonitorForm.controls['inputType'])
            && isEqual(this.addSensorMonitorForm.controls['inputType'].value, this.MONITOR_INPUT_TYPE.DIGITAL_PULSE)
            && this.addSensorMonitorForm.controls['unitId'].value;
    };
    MonitorAddStep3Component.prototype.canShowAnalog = function () {
        if (!isUndefined(this.addSensorMonitorForm.controls['inputType'])) {
            var inputType = this.addSensorMonitorForm.controls['inputType'].value;
            return (isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_MA) || isEqual(inputType, this.MONITOR_INPUT_TYPE.ANALOG_V))
                && this.addSensorMonitorForm.controls['unitId'].value;
        }
    };
    MonitorAddStep3Component.prototype.isCurrentChannelAnalog = function () {
        return isEqual(this.monitor.channels[this.selectedSensorIndex], MonitorChannel.ANALOG);
    };
    MonitorAddStep3Component.prototype.isCurrentChannelDigital = function () {
        return isEqual(this.monitor.channels[this.selectedSensorIndex], MonitorChannel.DIGITAL);
    };
    MonitorAddStep3Component.prototype.isCurrentChannelFixed = function () {
        return isEqual(this.monitor.channels[this.selectedSensorIndex], MonitorChannel.FIXED);
    };
    MonitorAddStep3Component.prototype.onChangeSensorCategory = function ($event, index) {
        if ($event.isUserInput) {
            if (!this.isEditingSensor) {
                var formControls = this.ALL_AVAILABLE_FORM_CONTROLS;
                formControls.push('sensorTypeId');
                this.resetFormControls(formControls);
            }
            this.selectedSensorCategoryIndex = index;
        }
    };
    MonitorAddStep3Component.prototype.onChangeSensorType = function ($event, index) {
        if ($event.isUserInput) {
            if (!this.isEditingSensor) {
                this.resetFormControls(this.ALL_AVAILABLE_FORM_CONTROLS);
            }
            this.selectedSensorTypeIndex = index;
        }
    };
    MonitorAddStep3Component.prototype.onChangeUnit = function ($event, index, unitType) {
        if ($event.isUserInput) {
            this.analogInputTypeUnit = unitType;
        }
    };
    MonitorAddStep3Component.prototype.canShowUnitType = function () {
        if (isUndefined(this.allSensorCategories[this.selectedSensorCategoryIndex].types)) {
            this.selectedUnitIndex = 0;
        }
        return !this.canShowDigitalOpenClosed()
            && this.hasSelectedSensorType()
            && !isUndefined(this.allSensorCategories[this.selectedSensorCategoryIndex].types);
    };
    MonitorAddStep3Component.prototype.canShowSensorType = function () {
        if (isUndefined(this.allSensorCategories[this.selectedSensorCategoryIndex].types)) {
            this.selectedSensorCategoryIndex = 0;
        }
        return this.hasSelectedCategory() && this.hasSelectedInputType();
    };
    MonitorAddStep3Component.prototype.canShowDurationError = function (durationType, errorType) {
        var durationErrors = this.addSensorMonitorForm.get("" + durationType).errors;
        if (!isNull(durationErrors)) {
            return durationErrors[errorType]
                && isNull(this.addSensorMonitorForm.get(durationType + ".hours").errors)
                && isNull(this.addSensorMonitorForm.get(durationType + ".minutes").errors)
                && isNull(this.addSensorMonitorForm.get(durationType + ".seconds").errors);
        }
        return false;
    };
    MonitorAddStep3Component.prototype.canShowPollingRateError = function () {
        return this.canShowDurationError(this.POLLING_RATE, 'invalidDuration')
            || this.canShowDurationError(this.POLLING_RATE, 'invalidPollingRate');
    };
    MonitorAddStep3Component.prototype.getName = function () {
        var _this = this;
        //return !isUndefined(this.sensorMap.get(this.selectedSensorIndex)) ? this.monitor.sensors[this.selectedSensorIndex].name:"";
        return !isUndefined(this.sensorMap.get(this.selectedSensorIndex)) ? this.monitor.sensors.find(function (sensor) { return sensor.channel === _this.selectedSensorIndex; }).name : "";
    };
    MonitorAddStep3Component.prototype.canShowCloudStorageRateError = function () {
        return this.canShowDurationError(this.CLOUD_STORAGE_RATE, 'invalidDuration')
            || this.canShowDurationError(this.CLOUD_STORAGE_RATE, 'invalidCloudStorageRate');
    };
    MonitorAddStep3Component.prototype.canShowPollingAndCloudStorageRate = function () {
        var canShow = this.canShowAnalog() || this.canShowDigitalOpenClosed();
        var currentSensor = this.sensorMap.get(this.selectedSensorIndex);
        if (currentSensor && this.isCurrentChannelFixed())
            canShow = this.isShowPollingCloudStorgeForPcxi() ? true : false;
        if (canShow && isUndefined(currentSensor)) {
            this.setPollingAndCloudStorageRateDefaults();
        }
        return canShow && !this.isCurrentChannelDigital();
    };
    MonitorAddStep3Component.prototype.isShowPollingCloudStorgeForPcxi = function () {
        //return false;
        var canShow = this.isCurrentChannelFixed();
        var sensor = this.sensorMap.get(this.selectedSensorIndex);
        if (!sensor) {
            this.setPollingAndCloudStorageRateDefaults();
        }
        return canShow &&
            sensor.name == this.ValidPCXIForCloudStorage.sensorTypeName &&
            sensor.sensorType == this.ValidPCXIForCloudStorage.sensorType &&
            sensor.inputType == this.ValidPCXIForCloudStorage.sensorInputType;
    };
    MonitorAddStep3Component.prototype.updateRateValueAndValidity = function (rateType) {
        this.addSensorMonitorForm.controls[rateType].updateValueAndValidity();
    };
    MonitorAddStep3Component.prototype.trackByMonitorChannels = function (index, channel) {
        return channel;
    };
    MonitorAddStep3Component.prototype.addedSensorToMonitor = function (sensor, ngAddSensorMonitorForm, res) {
        var _this = this;
        if (this.hasReachedLastSensorStep()) {
            return this.router.navigateByUrl(!this.editMode
                ? '/monitors'
                : '/monitors/manage').then(function () {
                if (_this.canEditMonitor()) {
                    _this.notificationService.show(!_this.editMode
                        ? 'MONITOR.ADDED_CONFIRMATION'
                        : 'MONITOR.UPDATED_CONFIRMATION');
                }
            });
        }
        if (!isNull(sensor)) {
            var sensorMapData = sensor.toJSON(sensor);
            if (!isNull(res)) {
                sensorMapData = assign(sensorMapData, res);
            }
            else {
                sensorMapData = assign(this.sensorMap.get(this.selectedSensorIndex), sensorMapData);
            }
            this.sensorMap.set(this.selectedSensorIndex, sensorMapData);
        }
        this.selectedSensorIndex++;
        this.allPredefinedSensors = [];
        if (isUndefined(this.sensorMap.get(this.selectedSensorIndex))) {
            this.isEditingSensor = false;
        }
        else if (this.sensorMap.get(this.selectedSensorIndex)['inputType'] === this.SENSOR_SETTING_PREDEFINED) {
            this.getAllPredefinedSensors();
        }
        // Re-calculate the progress
        this.sensorProgressValue = this.calculateCurrentSensorProgress();
        // Remove the dynamically added controls
        this.removeControlsFromForm(Array.prototype.concat.apply([], [
            this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS,
            this.SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS
        ]));
        remove(this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS, function (currentObject) {
            return currentObject.name === _this.POLLING_RATE || currentObject.name === _this.CLOUD_STORAGE_RATE;
        });
        remove(this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS, function (currentObject) {
            return currentObject.name === _this.POLLING_RATE || currentObject.name === _this.CLOUD_STORAGE_RATE;
        });
        ngAddSensorMonitorForm.resetForm(this.ALL_AVAILABLE_FORM_CONTROLS);
        // Pre-fill the form with sensor data
        this.preFillFormData(this.sensorMap.get(this.selectedSensorIndex));
        this.isAddingSensor = false;
    };
    MonitorAddStep3Component.prototype.watchInputType = function () {
        var _this = this;
        this.inputTypeWatcher = this.addSensorMonitorForm.get('inputType').valueChanges.pipe(takeUntil(this.unSubscribeAddMonitorSensorOnDestroy))
            .subscribe(function (inputType) {
            if (!isNull(inputType)) {
                // Remove all custom fields from form
                _this.removeControlsFromForm(Array.prototype.concat.apply([], [
                    _this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS,
                    _this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS,
                    _this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS
                ]));
                remove(_this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS, function (currentObject) {
                    return currentObject.name === _this.POLLING_RATE || currentObject.name === _this.CLOUD_STORAGE_RATE;
                });
                remove(_this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS, function (currentObject) {
                    return currentObject.name === _this.POLLING_RATE || currentObject.name === _this.CLOUD_STORAGE_RATE;
                });
                var currentSensor = _this.sensorMap.get(_this.selectedSensorIndex);
                if (isEqual(inputType, _this.MONITOR_INPUT_TYPE.ANALOG_MA) || isEqual(inputType, _this.MONITOR_INPUT_TYPE.ANALOG_V)) {
                    _this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS.push({
                        name: _this.POLLING_RATE,
                        formControl: _this.formBuilder.group({
                            hours: [null, Validators.required],
                            minutes: [null, Validators.required],
                            seconds: [null, Validators.required]
                        }),
                        validators: [validateDuration(1, 86400), validateDurationGreaterThan('invalidPollingRate')]
                    }, {
                        name: _this.CLOUD_STORAGE_RATE,
                        formControl: _this.formBuilder.group({
                            hours: [null, Validators.required],
                            minutes: [null, Validators.required],
                            seconds: [null, Validators.required],
                        }),
                        validators: [validateDuration(300, 432000), validateDurationGreaterThan('invalidCloudStorageRate')]
                    });
                    _this.addControlsToForm(_this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS);
                    var analogMeasurementValidators = [
                        Validators.required,
                        Validators.min(0)
                    ];
                    var maxMeasurementValue = 0;
                    // Set the placeholder
                    if (isEqual(inputType, _this.MONITOR_INPUT_TYPE.ANALOG_MA)) {
                        _this.analogInputTypeCurrent = 'mA';
                        maxMeasurementValue = 20;
                    }
                    else if (isEqual(inputType, _this.MONITOR_INPUT_TYPE.ANALOG_V)) {
                        _this.analogInputTypeCurrent = 'V';
                        maxMeasurementValue = 10;
                    }
                    _this.maxMeasurementValue = maxMeasurementValue;
                    analogMeasurementValidators.push(Validators.max(maxMeasurementValue));
                    _this.addSensorMonitorForm.controls['measurementMinimum'].setValidators(analogMeasurementValidators);
                    _this.addSensorMonitorForm.controls['measurementMaximum'].setValidators(analogMeasurementValidators);
                }
                else {
                    if (isEqual(inputType, _this.MONITOR_INPUT_TYPE.DIGITAL_OPEN_CLOSED) || _this.isShowPollingCloudStorgeForPcxi()) {
                        _this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS.push({
                            name: _this.POLLING_RATE,
                            formControl: _this.formBuilder.group({
                                hours: [null, Validators.required],
                                minutes: [null, Validators.required],
                                seconds: [null, Validators.required]
                            }),
                            validators: [validateDuration(1, 86400), validateDurationGreaterThan('invalidPollingRate')]
                        }, {
                            name: _this.CLOUD_STORAGE_RATE,
                            formControl: _this.formBuilder.group({
                                hours: [null, Validators.required],
                                minutes: [null, Validators.required],
                                seconds: [null, Validators.required],
                            }),
                            validators: [validateDuration(300, 432000), validateDurationGreaterThan('invalidCloudStorageRate')]
                        });
                        _this.addControlsToForm(_this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS);
                    }
                    else if (isEqual(inputType, _this.MONITOR_INPUT_TYPE.DIGITAL_PULSE)) {
                        _this.addControlsToForm(_this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS);
                    }
                }
            }
        });
    };
    MonitorAddStep3Component.prototype.setPollingAndCloudStorageRateDefaults = function () {
        this.addSensorMonitorForm.patchValue({
            pollingRate: {
                hours: this.POLLING_RATE_DEFAULTS.HOURS,
                minutes: this.POLLING_RATE_DEFAULTS.MINUTES,
                seconds: this.POLLING_RATE_DEFAULTS.SECONDS
            },
            cloudStorageRate: {
                hours: this.CLOUD_STORAGE_RATE_DEFAULTS.HOURS,
                minutes: this.CLOUD_STORAGE_RATE_DEFAULTS.MINUTES,
                seconds: this.CLOUD_STORAGE_RATE_DEFAULTS.SECONDS
            }
        });
    };
    MonitorAddStep3Component.prototype.watchSensorSettings = function () {
        var _this = this;
        this.sensorSettingsWatcher = this.addSensorMonitorForm.get('sensorSettings').valueChanges
            .subscribe(function (sensorSettings) {
            if (!isNull(sensorSettings)) {
                _this.setFormFieldsForSensorSettings(sensorSettings);
            }
        });
    };
    MonitorAddStep3Component.prototype.setFormFieldsForSensorSettings = function (sensorSettings) {
        if (isEqual(sensorSettings, this.SENSOR_SETTING_PREDEFINED)) {
            this.setPredefinedSensorFormControls();
            if (this.allPredefinedSensors.length === 0) {
                this.getAllPredefinedSensors();
            }
        }
        else {
            this.removeControlsFromForm(this.SENSOR_SETTINGS_PREDEFINED_INPUT_FIELDS);
            this.addControlsToForm(this.SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS);
            // Watch the inputType field
            this.watchInputType();
        }
    };
    MonitorAddStep3Component.prototype.setPredefinedSensorFormControls = function () {
        this.unSubscribeWatchers(['inputTypeWatcher']);
        this.removeControlsFromForm(Array.prototype.concat.apply([], [
            this.SENSOR_SETTINGS_CUSTOM_INPUT_FIELDS,
            this.SENSOR_SETTINGS_ANALOG_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_OPEN_CLOSED_INPUT_FIELDS,
            this.SENSOR_SETTINGS_DIGITAL_PULSE_INPUT_FIELDS,
        ]));
        this.addControlsToForm(this.SENSOR_SETTINGS_PREDEFINED_INPUT_FIELDS);
    };
    MonitorAddStep3Component.prototype.addControlsToForm = function (controls) {
        for (var _i = 0, controls_1 = controls; _i < controls_1.length; _i++) {
            var control = controls_1[_i];
            this.addSensorMonitorForm.addControl(control.name, control.formControl);
            if (!this.canEditMonitor()) {
                this.addSensorMonitorForm.controls[control.name].disable();
            }
            if (!isUndefined(control.validators)) {
                this.addSensorMonitorForm.controls[control.name].setValidators(control.validators);
                this.addSensorMonitorForm.get(control.name).updateValueAndValidity();
            }
        }
    };
    MonitorAddStep3Component.prototype.removeControlsFromForm = function (controls) {
        for (var _i = 0, controls_2 = controls; _i < controls_2.length; _i++) {
            var control = controls_2[_i];
            if (!isUndefined(this.addSensorMonitorForm.controls[control.name])) {
                this.addSensorMonitorForm.controls[control.name].reset();
                this.addSensorMonitorForm.removeControl(control.name);
            }
        }
    };
    MonitorAddStep3Component.prototype.resetFormControls = function (controls) {
        for (var _i = 0, controls_3 = controls; _i < controls_3.length; _i++) {
            var control = controls_3[_i];
            if (!isUndefined(this.addSensorMonitorForm.controls[control])) {
                this.addSensorMonitorForm.controls[control].reset();
            }
        }
    };
    MonitorAddStep3Component.prototype.unSubscribeWatchers = function (watchers) {
        for (var _i = 0, watchers_1 = watchers; _i < watchers_1.length; _i++) {
            var watcher = watchers_1[_i];
            if (!isUndefined(this[watcher])) {
                this[watcher].unsubscribe();
            }
        }
    };
    MonitorAddStep3Component.prototype.preFillFormData = function (data) {
        if (!isUndefined(data)) {
            var sensorSettings = this.SENSOR_SETTING_CUSTOM;
            if (this.sensorMap.get(this.selectedSensorIndex)['inputType'] === this.MONITOR_INPUT_TYPE.PREDEFINED) {
                sensorSettings = this.SENSOR_SETTING_PREDEFINED;
            }
            this.addSensorMonitorForm.controls['sensorSettings'].setValue(sensorSettings);
            data.pollingRate = this.returnDurationAsTime(data.pollingRate);
            data.cloudStorageRate = this.returnDurationAsTime(data.cloudStorageRate);
            this.addSensorMonitorForm.patchValue(data);
            if (!isUndefined(this.addSensorMonitorForm.controls[this.POLLING_RATE])
                && !isUndefined(this.addSensorMonitorForm.controls[this.CLOUD_STORAGE_RATE])) {
                this.updateRateValueAndValidity(this.POLLING_RATE);
                this.updateRateValueAndValidity(this.CLOUD_STORAGE_RATE);
            }
        }
    };
    MonitorAddStep3Component.prototype.getDefaultLowerLimit = function () {
        var sensor = this.sensorMap.get(this.selectedSensorIndex);
        if (sensor && this.sensorData[sensor.id]) {
            var lowerlimits = this.sensorData[sensor.id].lowerLimits;
            var lastlimit = lowerlimits.sort(function (lim1, lim2) { return lim2.datetime - lim1.datetime; })[0];
            return lastlimit ? this.truncateDigitsPipe.transform(lastlimit.value, 1) : null;
        }
        else {
            return null;
        }
    };
    MonitorAddStep3Component.prototype.getDefaultUpperLimit = function () {
        var sensor = this.sensorMap.get(this.selectedSensorIndex);
        if (sensor && this.sensorData[sensor.id]) {
            var upperlimits = this.sensorData[sensor.id].upperLimits;
            var lastlimit = upperlimits.sort(function (lim1, lim2) { return lim2.datetime - lim1.datetime; })[0];
            if (lastlimit == null && sensor.sensorType == "TYPE_PRESSURE") {
                return this.truncateDigitsPipe.transform(6, 1);
            }
            else if (lastlimit == null && sensor.sensorType == "TYPE_CXI_CORROSION") {
                return this.truncateDigitsPipe.transform(24, 1);
            }
            else {
                return lastlimit ? this.truncateDigitsPipe.transform(lastlimit.value, 1) : null;
            }
        }
        else {
            return null;
        }
    };
    MonitorAddStep3Component.prototype.canShowDelta = function () {
        var convertTxtToCompare = function (txtVal) {
            return (txtVal || '').toString().toUpperCase().replace(/\s/g, '');
        };
        var sensor = this.sensorMap.get(this.selectedSensorIndex);
        return sensor &&
            this.sensorData[sensor.id] &&
            !(this.INVALIDDELTA_CLAUSE.INPUTTYPE.includes(convertTxtToCompare(sensor.inputType)) &&
                (this.INVALIDDELTA_CLAUSE.NAME || []).length > 0 &&
                (this.INVALIDDELTA_CLAUSE.NAME || []).filter(function (x) { return convertTxtToCompare(x) === convertTxtToCompare(sensor.name); }).length > 0);
    };
    MonitorAddStep3Component.prototype.getDefaultThreshold = function () {
        var sensor = this.sensorMap.get(this.selectedSensorIndex);
        if (sensor && this.sensorData[sensor.id]) {
            return this.truncateDigitsPipe.transform(this.sensorData[sensor.id].threshold, 1);
        }
        else {
            return null;
        }
    };
    MonitorAddStep3Component.prototype.getSensorAccordingToInputType = function (inputType, form, sensorObj) {
        if (sensorObj === void 0) { sensorObj = null; }
        var sensor;
        switch (inputType) {
            case this.MONITOR_INPUT_TYPE.ANALOG_V:
            case this.MONITOR_INPUT_TYPE.ANALOG_MA:
                sensor = new SensorAnalog(this.selectedSensorIndex, form.name, form.categoryId, form.inputType, form.sensorTypeId, form.unitId, form.measurementMinimum, form.measurementMaximum, form.valueMinimum, form.valueMaximum, this.returnDurationAsISOString(form.pollingRate.hours, form.pollingRate.minutes, form.pollingRate.seconds), this.returnDurationAsISOString(form.cloudStorageRate.hours, form.cloudStorageRate.minutes, form.cloudStorageRate.seconds));
                break;
            case this.MONITOR_INPUT_TYPE.DIGITAL_OPEN_CLOSED:
                sensor = new SensorDigitalOpenClosed(this.selectedSensorIndex, form.name, form.categoryId, form.inputType, form.sensorTypeId, form.openText, form.openTextDescription, form.closedText, form.closedTextDescription, this.returnDurationAsISOString(form.pollingRate.hours, form.pollingRate.minutes, form.pollingRate.seconds), this.returnDurationAsISOString(form.cloudStorageRate.hours, form.cloudStorageRate.minutes, form.cloudStorageRate.seconds));
                break;
            case this.MONITOR_INPUT_TYPE.DIGITAL_PULSE:
                sensor = new SensorDigitalPulse(this.selectedSensorIndex, form.name, form.categoryId, form.inputType, form.sensorTypeId, form.unitId, form.value);
                break;
            case this.MONITOR_INPUT_TYPE.PREDEFINED:
                sensor = new SensorPredefined(this.selectedSensorIndex, form.name, form.predefinedSensorId, form.inputType);
                break;
            case this.MONITOR_INPUT_TYPE.AUTO:
                var measurementMinimum = void 0;
                var measurementMaximum = void 0;
                var valueMinimum = void 0;
                var valueMaximum = void 0;
                //Pressure
                if (form.sensorTypeId == "d935031a-9236-4b91-b912-3b5f4ce3bc80") {
                    measurementMinimum = 0;
                    measurementMaximum = 100;
                    valueMinimum = -1;
                    valueMaximum = 9;
                }
                //Corrosion
                else if (form.sensorTypeId == "0e5b8e9f-ac3b-47e0-92a4-29893c146653") {
                    measurementMinimum = 0;
                    measurementMaximum = 1;
                    valueMinimum = 0;
                    valueMaximum = 1000;
                }
                //Temperature
                else if (form.sensorTypeId == "F0316AA8-5231-FC4E-8E0B-F98BD74A9858") {
                    measurementMinimum = 0;
                    measurementMaximum = 1;
                    valueMinimum = 0;
                    valueMaximum = 100;
                }
                else {
                    measurementMinimum = 99;
                    measurementMaximum = 99;
                    valueMinimum = 99;
                    valueMaximum = 99;
                }
                sensor = new SensorAuto(this.selectedSensorIndex, form.name, form.categoryId, form.inputType, form.sensorTypeId, form.unitId, this.returnDurationAsISOString(form.pollingRate ? form.pollingRate.hours : (sensorObj.pollingRate ? sensorObj.pollingRate.hours : 0), form.pollingRate ? form.pollingRate.minutes : (sensorObj.pollingRate ? sensorObj.pollingRate.minutes : 0), form.pollingRate ? form.pollingRate.seconds : (sensorObj.pollingRate ? sensorObj.pollingRate.seconds : 0)), measurementMinimum, measurementMaximum, valueMinimum, valueMaximum, this.returnDurationAsISOString(form.cloudStorageRate ? form.cloudStorageRate.hours : (sensorObj.cloudStorageRate ? sensorObj.cloudStorageRate.hours : 0), form.cloudStorageRate ? form.cloudStorageRate.minutes : (sensorObj.cloudStorageRate ? sensorObj.cloudStorageRate.minutes : 0), form.cloudStorageRate ? form.cloudStorageRate.seconds : (sensorObj.cloudStorageRate ? sensorObj.cloudStorageRate.seconds : 0)));
                break;
        }
        return sensor;
    };
    MonitorAddStep3Component.prototype.returnDurationAsISOString = function (hours, minutes, seconds) {
        return moment.duration({
            hours: hours,
            minutes: minutes,
            seconds: seconds
        }).toISOString();
    };
    MonitorAddStep3Component.prototype.returnDurationAsTime = function (data) {
        var dataAsStr = (data || '').toString().toUpperCase();
        var isDataValidNum = (dataAsStr.indexOf('H') < 0 && dataAsStr.indexOf('S') < 0 && dataAsStr.indexOf('M')) < 0;
        var duration = isDataValidNum ? moment.duration(data, 'seconds') : moment.duration(data);
        var hours = duration.hours();
        if (duration.days() !== 0) {
            hours = hours + moment.duration(duration.days(), 'days').asHours();
        }
        return {
            hours: hours,
            minutes: duration.minutes(),
            seconds: duration.seconds()
        };
    };
    MonitorAddStep3Component.prototype.calculateCurrentSensorProgress = function () {
        return this.initialSensorProgressValue * this.selectedSensorIndex;
    };
    MonitorAddStep3Component.prototype.getAllSensorCategories = function () {
        var _this = this;
        this.httpMonitorsService
            .listAllSensorCategories()
            .takeUntil(this.unSubscribeAddMonitorSensorOnDestroy)
            .subscribe(function (allSensorCategories) {
            _this.allSensorCategories = allSensorCategories;
            // Watch the sensorSettings field
            _this.watchSensorSettings();
            _this.canShowPlaceHolderWatcher = _this.media.asObservable()
                .subscribe(function () { return _this.canShowPlaceHolder(); });
            _this.httpMonitorsService._skipCacheDetailOverview(_this.monitor.id, { start: moment().subtract(1, 'day'), end: moment() })
                .pipe(take(1))
                .subscribe(function (detailOverview) {
                _this.sensorData = _.keyBy(detailOverview.sensors, 'id');
                _this.isLoadingAllSensorCategories = false;
                (detailOverview.measurements || []).forEach(function (element) {
                    if (_this.sensorData[element.sensorId]) {
                        _this.sensorData[element.sensorId].lowerLimits = (element.lowerLimits || []).length > 0 ? element.lowerLimits : _this.sensorData[element.sensorId].lowerLimits;
                        _this.sensorData[element.sensorId].upperLimits = (element.upperLimits || []).length > 0 ? element.upperLimits : _this.sensorData[element.sensorId].upperLimits;
                    }
                });
            });
        }, function (err) {
            console.error(err);
        });
    };
    MonitorAddStep3Component.prototype.getAllPredefinedSensors = function () {
        var _this = this;
        this.isLoadingAllPredefinedSensors = true;
        this.httpMonitorsService
            .listAllPredefinedSensors(this.monitor.channels[this.selectedSensorIndex])
            .takeUntil(this.unSubscribeAddMonitorSensorOnDestroy)
            .subscribe(function (allPredefinedSensors) {
            _this.isLoadingAllPredefinedSensors = false;
            _this.allPredefinedSensors = allPredefinedSensors;
        }, function (err) {
            console.error(err);
        });
    };
    return MonitorAddStep3Component;
}());
export { MonitorAddStep3Component };
