import { Router } from '@angular/router';
import { AuthenticationService } from '../../infrastructure/http/authentication/authentication.service';
var CanActivateViaAuthGuard = /** @class */ (function () {
    function CanActivateViaAuthGuard(router, authenticationService) {
        this.router = router;
        this.authenticationService = authenticationService;
    }
    CanActivateViaAuthGuard.prototype.canActivate = function () {
        var result = false;
        if (this.authenticationService.hasToken()) {
            result = true;
        }
        else {
            this.router.navigate(['/login']);
        }
        return result;
    };
    return CanActivateViaAuthGuard;
}());
export { CanActivateViaAuthGuard };
