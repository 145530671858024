import { forkJoin as observableForkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { omit } from 'lodash';
import { FirmwareUpgradeStatus } from '../../../domain/monitor/firmware/firmware-upgrade-status';
import { Monitor } from '../../../domain/monitor/monitor';
import { MonitorDetailStatusOverview } from '../../../domain/monitor/monitorDetailStatusOverview';
import { MonitorStatusOverview } from '../../../domain/monitor/monitorStatusOverview';
import { SensorCategory } from '../../../domain/monitor/sensor/sensor-category/sensor-category';
import { SensorPredefinedList } from '../../../domain/monitor/sensor/sensor-predefined-list/sensor-predefined-list';
var HttpMonitorsService = /** @class */ (function () {
    function HttpMonitorsService(http) {
        this.http = http;
    }
    HttpMonitorsService.prototype.getAllForStatusOverview = function () {
        return this.http.get('/api/monitors/status').pipe(map(function (response) {
            return response.map(MonitorStatusOverview.fromJSON);
        }));
    };
    HttpMonitorsService.prototype.addMonitor = function (activationCode, serialNumber) {
        return this.http.post('/api/monitors', {
            activationCode: activationCode,
            serialNumber: serialNumber
        }).pipe(map(function (response) { return Monitor.fromJSON(response); }));
    };
    HttpMonitorsService.prototype.getAll = function () {
        return this.http.get('/api/monitors').pipe(map(function (response) {
            return response.map(Monitor.fromJSON);
        }));
    };
    HttpMonitorsService.prototype.updateMonitorDetails = function (id, monitorDetails, privacyLevel, isEditMode) {
        var joinedRequests = [];
        var expectedResponseIndex = 0;
        joinedRequests.push(this.http.put("/api/monitors/" + id, {
            name: monitorDetails.name,
            address: monitorDetails.address,
            system: monitorDetails.system,
            circuit: monitorDetails.circuit,
            details: monitorDetails.details,
            comments: monitorDetails.comments
        }));
        if (isEditMode) {
            joinedRequests.push(this.setMonitorPrivacyLevel(privacyLevel, id));
            expectedResponseIndex = 1;
        }
        return observableForkJoin(joinedRequests).pipe(map(function (responses) { return Monitor.fromJSON(responses[expectedResponseIndex]); }));
    };
    HttpMonitorsService.prototype.deleteMonitor = function (id) {
        return this.http.delete("/api/monitors/" + id, {
            responseType: 'text'
        });
    };
    HttpMonitorsService.prototype.singleMonitor = function (id) {
        return this.http.get("/api/monitors/" + id).pipe(map(function (response) { return Monitor.fromJSON(response); }));
    };
    HttpMonitorsService.prototype.addMonitorSensor = function (id, sensor, canAddSensor, sensorId) {
        if (sensorId === void 0) { sensorId = null; }
        if (canAddSensor) {
            return this.addSensor(id, sensor);
        }
        return this.updateSensor(id, sensor, sensorId);
    };
    HttpMonitorsService.prototype.getFirmwareUpgradeStatus = function (id) {
        return this.http.get("/api/monitors/" + id + "/upgrade-status").pipe(map(function (response) { return FirmwareUpgradeStatus.fromJSON(response); }));
    };
    HttpMonitorsService.prototype.requestFirmwareUpgrade = function (id) {
        return this.http.post("/api/monitors/" + id + "/upgrade", {}).pipe(map(function (response) { return FirmwareUpgradeStatus.fromJSON(response); }));
    };
    HttpMonitorsService.prototype.getMonitorMeasurements = function (id, from, to) {
        var fromString = encodeURIComponent(from.toISOString());
        var toString = encodeURIComponent(to.toISOString());
        return this.http.get("/api/monitors/" + id + "/measurements?from=" + fromString + "&to=" + toString);
    };
    /**
     * Returns either a new detail status overview, or updates the measurements if one is passed into the function.
     *
     * Always provides a copy, and does not modify in-place.
     * @param id
     * @param selectedDateRange
     * @param detailStatusOverview
     */
    HttpMonitorsService.prototype.getForDetailOverview = function (id, selectedDateRange, monitorStatusOverview) {
        // See bug 595
        if (monitorStatusOverview) {
            return this.getMonitorMeasurements(id, selectedDateRange.start, selectedDateRange.end).pipe(map(function (results) {
                return MonitorDetailStatusOverview.fromJSON(monitorStatusOverview, results);
            }));
        }
        else {
            return observableForkJoin([
                this.getSingleMonitorForStatusOverview(id),
                this.getMonitorMeasurements(id, selectedDateRange.start, selectedDateRange.end)
            ]).pipe(map(function (responses) {
                return MonitorDetailStatusOverview.fromJSON(responses[0], responses[1]);
            }));
        }
    };
    HttpMonitorsService.prototype.listAllSensorCategories = function () {
        return this.http.get('/api/sensorcategories').pipe(map(function (response) { return response.map(SensorCategory.fromJSON); }));
    };
    HttpMonitorsService.prototype.listAllPredefinedSensors = function (channelType) {
        return this.http.get("/api/predefined?channelType=" + channelType).pipe(map(function (response) { return response.map(SensorPredefinedList.fromJSON); }));
    };
    HttpMonitorsService.prototype.getSingleMonitorForStatusOverview = function (id) {
        return this.http.get("/api/monitors/" + id + "/status").pipe(map(MonitorStatusOverview.fromJSON));
    };
    HttpMonitorsService.prototype.setMonitorPrivacyLevel = function (privacyLevel, id) {
        return this.http.put("/api/monitors/" + id + "/privacy", { privacyLevel: privacyLevel });
    };
    HttpMonitorsService.prototype.setSensorLimits = function (monitorId, sensorId, limits) {
        return this.http.post("/api/monitors/" + monitorId + "/measurements/" + sensorId + "/limits", limits);
    };
    HttpMonitorsService.prototype.setPressureDelta = function (monitorId, sensorId, limits) {
        return this.http.post("/api/monitors/" + monitorId + "/measurements/" + sensorId + "/pressureDelta", limits);
    };
    HttpMonitorsService.prototype.setSensorAlarm = function (monitorId, sensorId) {
        return this.http.post("/api/monitors/" + monitorId + "/measurements/" + sensorId + "/ignoreAlarm", {});
    };
    HttpMonitorsService.prototype.addSensor = function (id, sensor) {
        return this.http.post("/api/monitors/" + id + "/sensors", omit(sensor.toJSON(sensor), ['categoryId']));
    };
    HttpMonitorsService.prototype.addKey = function (email, monitor, role, message) {
        return this.http.post("/api/monitors/" + monitor + "/key", { role: role, email: email, message: message });
    };
    HttpMonitorsService.prototype.updateSensor = function (id, sensor, sensorId) {
        return this.http.put("/api/monitors/" + id + "/sensors/" + sensorId, omit(sensor.toJSON(sensor), ['categoryId']));
    };
    HttpMonitorsService.prototype.setChangedOrder = function (id, order) {
        return this.http.put("/api/monitors/" + id + "/order", { order: order }).pipe(map(function (response) {
            return Monitor.fromJSON(response);
        }));
    };
    HttpMonitorsService.prototype.addByKey = function (serial, role, key, email) {
        return this.http.post("/api/monitors/" + serial + "/add", { email: email, role: role, key: key });
    };
    HttpMonitorsService.prototype.getUsersOnMonitor = function (id) {
        return this.http.get("/api/monitors/" + id + "/users");
    };
    HttpMonitorsService.prototype.removeUserRoleFromMonitor = function (monitorid, userid, role) {
        return this.http.delete("/api/monitors/" + monitorid + "/users/" + userid + "/" + role);
    };
    HttpMonitorsService.prototype.addByShareRequest = function (serial, role, key, email) {
        return this.http.post("/api/monitors/" + serial + "/acceptShareRequest", { email: email, role: role, key: key });
    };
    // Not finished: we don't know about any admins to send this to.
    HttpMonitorsService.prototype.sendShareRequest = function (monitorid, role) {
        return this.http.post("/api/monitors/" + monitorid + "/shareRequest", { role: role });
    };
    HttpMonitorsService.prototype.getChatForMonitor = function (id) {
        return this.http.get("/api/monitors/" + id + "/chat");
    };
    HttpMonitorsService.prototype.postChatForMonitor = function (monitorid, text) {
        return this.http.post("/api/monitors/" + monitorid + "/chat", { text: text });
    };
    return HttpMonitorsService;
}());
export { HttpMonitorsService };
