<article [fxFlex]="40" [fxFlex.md]="50" [fxFlex.lt-md]="100">
  <div class="title__content">
    <h1 fxFill>{{ 'MONITOR.ADD_MONITOR' | translate }}</h1>
  </div>

  <mat-card>
    <mat-card-content>
      <app-message *ngIf="validateHttpError"
                   [messageText]="validateHttpError"
                   [messageStyle]="MESSAGE_STYLE.DANGER"></app-message>

        <app-message *ngIf="isAlreadyInUseError"
                   [messageStyle]="MESSAGE_STYLE.DANGER"><p>{{'ERRORS.MONITOR_IS_PRIVATE' | translate }} Click <a href="" (click)="!!clickError()">here</a> to request an admin to share this monitor with you.</p></app-message>

      <form [formGroup]="addMonitorForm" novalidate (ngSubmit)="validateMonitor(addMonitorForm.value, addMonitorForm.valid)" autocomplete="off">
        <div class="input-row">
          <mat-form-field fxFill>
            <input matInput
                   type="text"
                   placeholder="{{'MONITOR.SERIAL_NUMBER' | translate}}"
                   formControlName="serialNumber">

            <!-- MaxLength error -->
            <mat-error *ngIf="addMonitorForm.get('serialNumber').errors?.maxlength">
              {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.SERIAL_NUMBER' | translate, max: 255} }}
            </mat-error>
            <!-- Required error -->
            <mat-error *ngIf="addMonitorForm.get('serialNumber').errors?.required">{{'MONITOR.ERRORS.SERIAL_NUMBER' | translate}}</mat-error>
          </mat-form-field>
        </div>

        <div class="input-row">
          <mat-form-field fxFill>
            <input matInput
                   type="text"
                   placeholder="{{'MONITOR.ACTIVATION_CODE' | translate}}"
                   formControlName="activationCode">

            <!-- MaxLength error -->
            <mat-error *ngIf="addMonitorForm.get('activationCode').errors?.maxlength">
              {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.ACTIVATION_CODE' | translate, max: 255} }}
            </mat-error>
            <mat-error *ngIf="addMonitorForm.get('activationCode').errors?.required">{{'MONITOR.ERRORS.ACTIVATION_CODE' | translate}}</mat-error>
          </mat-form-field>
        </div>

        <div class="input-row" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
          <button routerLink="/monitors" type="button" mat-raised-button color="warn">
            {{ 'MONITOR.CANCEL' | translate }}
          </button>
          <button mat-raised-button color="primary" type="submit" [disabled]="!canValidate() || isValidating">
            <span *ngIf="!isValidating; else _isValidating">
              {{'MONITOR.VALIDATE' | translate}}
            </span>
            <ng-template #_isValidating>
              {{'MONITOR.VALIDATING' | translate}}
            </ng-template>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</article>
