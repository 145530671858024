<section fxLayout="column" >
    <div class="head" *ngIf="canShowMenu()" fxLayout="row">
        <div fxLayoutAlign="start center" fxFlex>
            <a [routerLink]="['/monitors']">
                <mat-icon svgIcon="logo" class="logo"></mat-icon>
            </a>
            <h1 class="antialiased" *ngIf="canShowHeader()" [innerHTML]="getHeaderTitle()"></h1>
        </div>
        <div fxLayoutAlign="end center">
            <mat-menu #appMenu [overlapTrigger]="false" xPosition="before" yPosition="above">
                <a mat-menu-item routerLink="/user-profile">{{'USER.PROFILE' | translate}}</a>
                <a mat-menu-item routerLink="/notifications">{{'USER.NOTIFICATIONS' | translate}}</a>
                <button mat-menu-item (click)="logout()">{{'LOGIN.LOGOUT' | translate}}</button>

            </mat-menu>

            <button class="authenticated-user" fxHide fxShow.gt-sm mat-button [matMenuTriggerFor]="appMenu"
                    *ngIf="authenticatedUser" fxLayoutAlign="end center">
                <mat-icon class="user-account__icon" svgIcon="user-account"></mat-icon>
                <span class="authenticated-user-button-text" matBadge="{{notifNumber}}" matBadgeOverlap="false" matBadgeColor="warn" matBadgePosition="after" [matBadgeHidden]="!notifNumber">
					{{authenticatedUser.firstName.substr(0,25) + ' ' + authenticatedUser.lastName.substr(0,25)}}
                </span>
                <mat-icon class="chevron-down__icon" svgIcon="chevron-down"></mat-icon>
            </button>
            <mat-icon fxShow fxHide.gt-sm (click)="toggleSidenav()" svgIcon="menu"></mat-icon>
        </div>
    </div>

    <mat-sidenav-container class="main-container" fxFlex>
        <mat-sidenav mode="side" [opened]="opened" [mode]="mode" fxFlex #sidenav class="side-navigation"
                     [disableClose]="disableClose" *ngIf="canShowMenu()">
            <app-navigation (menuItemChosen)="chooseMenuItem()"></app-navigation>
        </mat-sidenav>
        <div class="main-content">
            <div class="content" fxFlex>
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-container>
</section>
