<section fxLayout="column" fxFlex>
  <mat-list fxFlex>
    <div fxShow fxHide.gt-sm *ngIf="authenticatedUser">
      <mat-list-item routerLink="/user-profile" (click)="chooseMenuItem()" [class.active]="isActive('user-profile')">
        <mat-icon mat-list-icon svgIcon="user-account-mobile"></mat-icon>
        <span mat-line class="menu-label">{{authenticatedUser.firstName + ' ' + authenticatedUser.lastName}}</span>
      </mat-list-item>
    </div>
    <div fxShow fxHide.gt-sm *ngIf="authenticatedUser">
      <mat-list-item routerLink="/notifications" (click)="chooseMenuItem()" [class.active]="isActive('notifications')">
        <div class="menu-label" mat-line>
            <mat-icon mat-list-icon fxShow fxHide.gt-sm></mat-icon>
          <span matBadge="{{notNumber}}" matBadgeOverlap="false" matBadgeColor="warn" matBadgePosition="after" [matBadgeHidden]="!notNumber" style="margin: auto">{{'USER.NOTIFICATIONS' | translate}}</span>
        </div>
      </mat-list-item>
    </div>
    <div *ngFor="let page of pages">
      <mat-list-item routerLink="{{page.path}}" (click)="chooseMenuItem()" [class.active]="isActive(page.path)">
        <mat-icon mat-line fxHide fxShow.gt-sm svgIcon="{{page.icon}}"></mat-icon>
        <mat-icon mat-list-icon fxShow fxHide.gt-sm svgIcon="{{page.icon}}"></mat-icon>
        <span mat-line class="menu-label">{{page.name.replace(' overview', 's')}}</span>
      </mat-list-item>

      <mat-list *ngFor="let subPage of page.subPages" fxShow fxHide.gt-sm class="sub-navigation">
        <mat-list-item routerLink="{{subPage.path}}" (click)="chooseMenuItem()" [class.active]="isActive(subPage.path)">
          <mat-icon mat-line fxHide fxShow.gt-sm svgIcon="{{subPage.icon}}"></mat-icon>
          <mat-icon mat-list-icon fxShow fxHide.gt-sm svgIcon="{{subPage.icon}}"></mat-icon>
          <span mat-line class="menu-label">{{subPage.name}}</span>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-list>
  <article class="container-logout" fxShow fxHide.gt-sm fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-raised-button color="primary" (click)="logout()">
      {{'LOGIN.LOGOUT' | translate}}
    </button>
  </article>
</section>


