import {Component, Inject, Input, OnInit} from "@angular/core";
import {MonitorSensor} from "../../../../domain/monitor/monitorSensor";
import { MonitorDataService } from "app/infrastructure/data/monitor-data.service";

@Component({
    selector: 'app-details-actions',
    templateUrl: './details-actions.component.html',
    styleUrls: ['./details-actions.component.scss']
})
export class DetailsActionsComponent implements OnInit {
    public sensors: MonitorSensor[];

    @Input()
    set monitor(val) {
        this.sensors = val.reduce((end, curr) => [...end, ...curr], []).filter(sensor => {
            return (sensor.sensorType != "TYPE_PCXI" && sensor.sensorType != "TYPE_RISYLOG");   
        });
    }

    constructor(public monitorService: MonitorDataService) {
    }

    ngOnInit() {
    }
}
