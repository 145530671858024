/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-login/user-login.component.ngfactory";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./user-login/user-login.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../infrastructure/http/authentication/authentication.service";
import * as i8 from "./user-registration/user-registration.component.ngfactory";
import * as i9 from "./user-registration/user-registration.component";
import * as i10 from "@angular/forms";
import * as i11 from "../../../infrastructure/http/user/http-users.service";
import * as i12 from "@angular/common";
import * as i13 from "./login.component";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-user-login", [["fxFlex", ""]], null, [[null, "registerRequested"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("registerRequested" === en)) {
        var pd_0 = (_co.registerRequested() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UserLoginComponent_0, i2.RenderType_UserLoginComponent)), i1.ɵdid(1, 671744, null, 0, i3.DefaultFlexDirective, [i1.ElementRef, i4.StyleUtils, i4.LAYOUT_CONFIG, i3.FlexStyleBuilder, i4.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(2, 245760, null, 0, i5.UserLoginComponent, [i6.Router, i7.AuthenticationService, "ErrorTranslator"], null, { registerRequested: "registerRequested" })], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, null); }
function View_LoginComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-user-registration", [["fxFlex", ""]], null, [[null, "loginRequested"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loginRequested" === en)) {
        var pd_0 = (_co.loginRequested() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_UserRegistrationComponent_0, i8.RenderType_UserRegistrationComponent)), i1.ɵdid(1, 671744, null, 0, i3.DefaultFlexDirective, [i1.ElementRef, i4.StyleUtils, i4.LAYOUT_CONFIG, i3.FlexStyleBuilder, i4.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(2, 180224, null, 0, i9.UserRegistrationComponent, [i10.FormBuilder, i11.HttpUsersService, i6.Router, "ErrorTranslator"], null, { loginRequested: "loginRequested" })], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_2)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canShowRegistration(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.canShowRegistration(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 49152, null, 0, i13.LoginComponent, [], null, null)], null, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i13.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
