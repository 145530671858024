import { OnInit } from '@angular/core';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'app/presentation/pages/confirmation-dialog/confirmation-dialog.component';
import { concat } from 'lodash';
var DetailsUsersComponent = /** @class */ (function () {
    function DetailsUsersComponent(monitorService, dialogService) {
        this.monitorService = monitorService;
        this.dialogService = dialogService;
    }
    DetailsUsersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.monitorService.getUsersOnMonitor(this.monitor.id).subscribe(function (users) { return _this.userRoleBindings = _this.usersToBindings(users); });
    };
    DetailsUsersComponent.prototype.usersToBindings = function (users) {
        return users.reduce(function (previous, current) { return concat(previous, current.roles.sort().map(function (role) { return ({ role: role, user: current }); })); }, []);
    };
    DetailsUsersComponent.prototype.canDelete = function (user) {
        return this.monitor.permissions && user.user.user && (user.role == ('ADMINISTRATOR') && this.monitor.permissions.includes('DELETE_ADM')) || (user.role == ('VIEWER') && this.monitor.permissions.includes('DELETE_VWR'));
    };
    DetailsUsersComponent.prototype.delete = function (user) {
        var _this = this;
        var dialog = this.dialogService.open(ConfirmationDialogComponent, { data: { textContent: "USER.DELETE_OTHER_USER" } });
        dialog.afterClosed().subscribe(function (confirm) {
            if (confirm) {
                _this.monitorService.removeUserRoleFromMonitor(_this.monitor.id, user.user.user.id, user.role).subscribe(function () { return _this.monitorService.getUsersOnMonitor(_this.monitor.id).subscribe(function (users) { return _this.userRoleBindings = _this.usersToBindings(users); }); });
            }
        });
    };
    return DetailsUsersComponent;
}());
export { DetailsUsersComponent };
