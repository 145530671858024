var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MonitorMeasurement } from '../monitor/monitorMeasurement';
import { View } from './view';
import { last, sortBy, isNil } from 'lodash';
var ViewDetailStatusOverview = /** @class */ (function (_super) {
    __extends(ViewDetailStatusOverview, _super);
    function ViewDetailStatusOverview(id, name, elucidation, sensors, measurements) {
        var _this = _super.call(this, id, name, elucidation, sensors) || this;
        _this._measurements = measurements;
        return _this;
    }
    ViewDetailStatusOverview.detailFromJSON = function (view, monitorMeasurementDTO) {
        return new ViewDetailStatusOverview(view.id, view.name, view.elucidation, view.sensors, monitorMeasurementDTO.map(MonitorMeasurement.fromJSON));
    };
    ViewDetailStatusOverview.copy = function (viewOverview) {
        var original = viewOverview;
        return new ViewDetailStatusOverview(original.id, original.name, original.elucidation, original.sensors, original.measurements);
    };
    Object.defineProperty(ViewDetailStatusOverview.prototype, "measurements", {
        get: function () {
            return this._measurements;
        },
        set: function (value) {
            this._measurements = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewDetailStatusOverview.prototype, "lastMeasuredDateTime", {
        get: function () {
            var sensorOfLastMeasuredDateTime = last(sortBy(this.sensors, ['lastMeasuredDateTime']));
            return !isNil(sensorOfLastMeasuredDateTime) ? sensorOfLastMeasuredDateTime.lastMeasuredDateTime : null;
        },
        enumerable: true,
        configurable: true
    });
    return ViewDetailStatusOverview;
}(View));
export { ViewDetailStatusOverview };
