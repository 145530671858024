import {last, sortBy, isNil} from 'lodash';
import {MonitorStatusInfo} from './monitor-status-info';
import {MonitorDetails} from './monitorDetails';
import {MonitorMeasurement} from './monitorMeasurement';
import {MonitorSensor} from './monitorSensor';
import {MonitorStatusOverview} from './monitorStatusOverview';
import {DetailStatusOverview} from './sensor/DetailStatusOverview';
import {isUndefined} from 'lodash';

export class MonitorDetailStatusOverview implements DetailStatusOverview {
    private _id: string;
    private _serialNumber: number;
    private _monitorType: string;
    private _statusInfo: MonitorStatusInfo;
    private _details: MonitorDetails;
    private _sensors: MonitorSensor[];
    private _measurements: MonitorMeasurement[];
    private _permissions: string[];

    public static fromJSON(monitor: MonitorStatusOverview, monitorMeasurementDTO: any): MonitorDetailStatusOverview {
        return new MonitorDetailStatusOverview(
            monitor.id,
            monitor.serialNumber,
            monitor.monitorType,
            monitor.statusInfo,
            MonitorDetails.fromJSON(monitor.details),
            monitor.sensors.map(MonitorSensor.fromJSON),
            monitorMeasurementDTO.map(MonitorMeasurement.fromJSON),
            monitor.order,
            monitorMeasurementDTO.map(x => x.alarmIgnored),
            monitor.permissions
        );
    }

    public static copy(monitorOverview: DetailStatusOverview) {
        const original = monitorOverview as MonitorDetailStatusOverview;
        return new MonitorDetailStatusOverview(
            original.id,
            original.serialNumber,
            original.monitorType,
            original.statusInfo,
            original.details,
            original.sensors,
            original.measurements,
        )
    }

    constructor(id: string,
                serialNumber: number,
                monitorType: string,
                statusInfo: MonitorStatusInfo,
                monitorDetails: MonitorDetails,
                sensors: MonitorSensor[],
                measurements: MonitorMeasurement[],
                order?: any,
                isAlarmIgnored?: boolean[],
                permissions?: string[]) {
        this._id = id;
        this._serialNumber = serialNumber;
        this._monitorType = monitorType;
        this._statusInfo = statusInfo;
        this._details = monitorDetails;
        this._sensors = sensors;
        this._measurements = measurements;

        this._sensors.forEach((sensor) => {
            const tempMeasurement = (this._measurements || []).filter(m => m.name === sensor.name)[0];
            if (tempMeasurement) {
                sensor.upperLimits = tempMeasurement.upperLimits;
                sensor.lowerLimits = tempMeasurement.lowerLimits
            }
        });

        this._permissions = permissions;

        if (!isUndefined(isAlarmIgnored)) {
            this._sensors.forEach((sensor, index) => sensor.isAlarmIgnored = isAlarmIgnored[index]);
        }
        if (order) {
            for (let key in order) {
                const sensor = sensors.find(sensor => sensor.id === key);
                if (sensor) {
                    sensor.order = order[key];
                }
            }
        }
    }

    get id(): string {
        return this._id;
    }

    get serialNumber(): number {
        return this._serialNumber;
    }

    get monitorType(): string {
        return this._monitorType;
    }

    get statusInfo(): MonitorStatusInfo {
        return this._statusInfo;
    }

    set statusInfo(value: MonitorStatusInfo) {
        this._statusInfo = value;
    }

    get details(): MonitorDetails {
        return this._details;
    }

    get sensors(): MonitorSensor[] {
        return this._sensors;
    }

    set sensors(value: MonitorSensor[]) {
        this._sensors = value;
    }

    get measurements(): MonitorMeasurement[] {
        return this._measurements;
    }

    set measurements(value: MonitorMeasurement[]) {
        this._measurements = value;
    }

    get lastMeasuredDateTime(): Date {
        const sensorOfLastMeasuredDateTime: MonitorSensor = last(
            sortBy(this.sensors, ['lastMeasuredDateTime']).filter(x => x.lastMeasuredDateTime != null));
        if (isNil(sensorOfLastMeasuredDateTime)) {
            return null;
        } else {
            return sensorOfLastMeasuredDateTime.lastMeasuredDateTime;
        }
    }

    get name(): string {
        return this.details.name;
    }

    get permissions(): string[] {
        return this._permissions;
    }
}
