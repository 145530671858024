import { environment } from '../../../environments/environment';
export var isDebugMode = environment.isDebugMode;
var noop = function () { return undefined; };
var ɵ0 = noop;
var ConsoleLoggerService = /** @class */ (function () {
    function ConsoleLoggerService() {
    }
    Object.defineProperty(ConsoleLoggerService.prototype, "info", {
        get: function () {
            if (isDebugMode) {
                return console.info.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLoggerService.prototype, "warn", {
        get: function () {
            if (isDebugMode) {
                return console.warn.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLoggerService.prototype, "error", {
        get: function () {
            return console.error.bind(console);
        },
        enumerable: true,
        configurable: true
    });
    ConsoleLoggerService.prototype.invokeConsoleMethod = function (type, args) {
        var logFn = (console)[type] || console.log || noop;
        logFn.apply(console, [args]);
    };
    return ConsoleLoggerService;
}());
export { ConsoleLoggerService };
export { ɵ0 };
