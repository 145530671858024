
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {EnvironmentInformation} from '../../../domain/environmentInformation';
import {Info} from './info';

@Injectable()
export class InfoService implements Info {
  private http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  public getEnvironmentInformationAndHealthStatus(): Observable<EnvironmentInformation> {
    const info = this.getBackendEnvironmentInformation();
    const health = this.getBackendHealthStatus();
    const clientInfo = this.getClientEnvironmentInformation();

    return observableForkJoin([info, health, clientInfo]).pipe(
      map((responses: any) => {
        return EnvironmentInformation.fromJSON({
          backendEnvironmentInformation: responses[0],
          backendHealth: responses[1]
        }, responses[2]);
      }));
  }

  private getBackendEnvironmentInformation(): Observable<any> {
    return this.http.get('/management/info');
  }

  private getBackendHealthStatus(): Observable<any> {
    return this.http.get('/management/health');
  }

  private getClientEnvironmentInformation(): Observable<any> {
    return observableOf({
      version: environment.app_version
    });
  }
}
