var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Sensor } from './sensor';
var SensorAnalog = /** @class */ (function (_super) {
    __extends(SensorAnalog, _super);
    function SensorAnalog(channel, name, category, inputType, sensorType, unit, measurementMinimum, measurementMaximum, valueMinimum, valueMaximum, pollingRate, cloudStorageRate) {
        var _this = _super.call(this, channel, name, category, inputType, sensorType) || this;
        _this._unit = unit;
        _this._measurementMinimum = measurementMinimum;
        _this._measurementMaximum = measurementMaximum;
        _this._valueMinimum = valueMinimum;
        _this._valueMaximum = valueMaximum;
        _this._pollingRate = pollingRate;
        _this._cloudStorageRate = cloudStorageRate;
        return _this;
    }
    SensorAnalog.prototype.toJSON = function (sensorAnalogDTO) {
        return {
            channel: sensorAnalogDTO.channel,
            name: sensorAnalogDTO.name,
            categoryId: sensorAnalogDTO.category,
            inputType: sensorAnalogDTO.inputType,
            sensorTypeId: sensorAnalogDTO.sensorType,
            unitId: sensorAnalogDTO.unit,
            measurementMinimum: sensorAnalogDTO.measurementMinimum,
            measurementMaximum: sensorAnalogDTO.measurementMaximum,
            valueMinimum: sensorAnalogDTO.valueMinimum,
            valueMaximum: sensorAnalogDTO.valueMaximum,
            pollingRate: sensorAnalogDTO.pollingRate,
            cloudStorageRate: sensorAnalogDTO.cloudStorageRate
        };
    };
    Object.defineProperty(SensorAnalog.prototype, "unit", {
        get: function () {
            return this._unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAnalog.prototype, "measurementMinimum", {
        get: function () {
            return this._measurementMinimum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAnalog.prototype, "measurementMaximum", {
        get: function () {
            return this._measurementMaximum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAnalog.prototype, "valueMinimum", {
        get: function () {
            return this._valueMinimum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAnalog.prototype, "valueMaximum", {
        get: function () {
            return this._valueMaximum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAnalog.prototype, "pollingRate", {
        get: function () {
            return this._pollingRate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAnalog.prototype, "cloudStorageRate", {
        get: function () {
            return this._cloudStorageRate;
        },
        enumerable: true,
        configurable: true
    });
    return SensorAnalog;
}(Sensor));
export { SensorAnalog };
