import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material";
import {Router} from "@angular/router";
import {NotificationService} from "../../../../application/notification/notification.service";
import {ConfirmationDialogComponent} from "../../../pages/confirmation-dialog/confirmation-dialog.component";
import {Monitor} from "../../../../domain/monitor/monitor";
import {HttpMonitorsService} from "../../../../infrastructure/http/monitor/http-monitors.service";
import {isUndefined, map} from "lodash";
import {ErrorTranslator} from "../../../../application/command/error-translator/error-translator";
import {InformationDialogComponent} from "../../../pages/information-dialog/information-dialog.component";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
    selector: 'app-grid-delete-monitor',
    templateUrl: './grid-delete-monitor.component.html',
    styleUrls: ['./grid-delete-monitor.component.scss']
})
export class GridDeleteMonitorComponent {

    public monitor: Monitor;
    private dialogConfig: MatDialogConfig = <MatDialogConfig>{
        panelClass: 'confirmation-dialog'
    };

    public constructor(private monitorService: MonitorDataService, private dialog: MatDialog, private router: Router, private notificationService: NotificationService, @Inject('ErrorTranslator') private errorTranslator: ErrorTranslator) {

    }

    public deleteMonitor() {
        const currentDialogConfig: MatDialogConfig = <MatDialogConfig> {
            panelClass: this.dialogConfig.panelClass,
            data: {
                textContent: 'MONITOR.DELETE_MONITOR_QUESTION',
                confirmText: 'GENERAL.OK',
                cancelText: 'GENERAL.CANCEL'
            }
        };
        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.monitorService
                    .deleteMonitor(this.monitor.id)
                    .subscribe(() => {
                            this.router.navigateByUrl('/monitors/manage')
                                .then(() => {
                                    this.notificationService.show('MONITOR.DELETED_CONFIRMATION');
                                });
                        }, (error: any) => {
                            const interpolateParams: any = {};
                            const views = JSON.parse(error.error).views;

                            if (!isUndefined(views)) {
                                interpolateParams.views = map(views, 'name').join(', ');
                            }

                            this.errorTranslator.execute(error, interpolateParams)
                                .subscribe((errorMessage: string) => {
                                    const currentDialogConfig: MatDialogConfig = <MatDialogConfig> {
                                        panelClass: this.dialogConfig.panelClass,
                                    };
                                    const dialogRef: MatDialogRef<InformationDialogComponent> = this.dialog.open(InformationDialogComponent, currentDialogConfig);
                                    dialogRef.componentInstance.textContent = errorMessage;
                                });
                        }
                    );
            }
        });
    }

    public agInit(params: any): void {
        this.monitor = params.data;
    }

    public editView() {

    }

}
