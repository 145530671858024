import { Location } from '@angular/common';
var NavigationToolbarComponent = /** @class */ (function () {
    function NavigationToolbarComponent(location) {
        this.subPages = [];
        this.location = location;
    }
    NavigationToolbarComponent.prototype.isActive = function (path) {
        return this.location.path() === "/" + path;
    };
    return NavigationToolbarComponent;
}());
export { NavigationToolbarComponent };
