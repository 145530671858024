<form  (ngSubmit)="submit()" #shareForm="ngForm" *ngIf="!loading">
<div class="sharecontainer" fxFlex fxLayout="column" >
  <div fxLayout="row">
      <h1>Share this monitor</h1>
  </div>
  <div [fxLayout.gt-sm]="row" [fxLayout.lt-sm]="column">
  <mat-form-field class="email-chip-list">
    <mat-chip-list #chipList aria-label="emails">
      <mat-chip *ngFor="let email of model.emails" [selectable]="selectable"
                [removable]="true" (removed)="remove(email)">
        {{email}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input placeholder="Emails"
              type="email"
              #emailinput
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="add($event)" [(ngModel)]="model.unused" name="emails" (ngModelChange)="removeStyle(emailinput)">
    </mat-chip-list>
  </mat-form-field>
  <mat-form-field class="role-picker">
    <mat-label>Role</mat-label>
        <mat-select [(ngModel)]="model.role" name="role" (ngModelChange)="dropBoxUpdate()">
          <mat-option value="VIEWER" selected>
            Viewer
          </mat-option>
          <mat-option value="ADMINISTRATOR" *ngIf="monitor.permissions.includes('SHARE_ADM')">
              Administrator
          </mat-option>
        </mat-select>
  </mat-form-field>
</div>
<p style="color: red;" *ngIf="showError">You need to add at least one email address.</p>
<div fxLayout="row" >
  <mat-form-field class="text-field">
      <textarea matInput placeholder="Add a message" [(ngModel)]="model.text" (ngModelChange)="dirty()" name="message"></textarea>
  </mat-form-field>
</div>
  <div fxLayout="row" class="submitbutton">
     <button mat-button color="primary"  type="submit">{{"GENERAL.SUBMIT" | translate}}</button>
  </div>
</div>
</form>

<app-progress-spinner
    *ngIf="loading" fxFlex
    [progressSpinnerText]="'ACTIONS.SHARING' | translate">
  </app-progress-spinner>