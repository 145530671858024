import {Location} from '@angular/common';
import {Component, EventEmitter, Output} from '@angular/core';
import {Page} from '../../../domain/page';
import {User} from '../../../domain/user';
import {AuthenticationService} from '../../../infrastructure/http/authentication/authentication.service';
import {NavigationService} from '../../../infrastructure/navigation/navigation.service';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent {
  @Output() public menuItemChosen: EventEmitter<boolean> = new EventEmitter<boolean>();
  public pages: Array<Page>;
  public authenticatedUser: User;
  public notNumber: number;
  private authenticationService: AuthenticationService;
  private location: Location;
  

  constructor(navigationService: NavigationService,
              authenticationService: AuthenticationService,
              location: Location, userService: HttpUsersService) {
    this.pages = navigationService.getMenuPages();
    this.authenticationService = authenticationService;
    this.location = location;
    userService.getNotifications().subscribe((notes) => this.notNumber = notes.length)
    this.setAuthenticatedUser();
  }

  public isActive(path: string): boolean {
    return this.location.path().includes(path);
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public chooseMenuItem(): void {
    this.menuItemChosen.emit(true);
  }

  private setAuthenticatedUser(): void {
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.getAuthenticatedUser().subscribe((user: User) => {
        this.authenticatedUser = user;
      });
    }
  }
}
