/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manage-monitors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i5 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i6 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i7 from "ag-grid-angular/dist/agGridAngular";
import * as i8 from "./manage-monitors.component";
import * as i9 from "../../../../../infrastructure/data/monitor-data.service";
var styles_ManageMonitorsDesktopComponent = [i0.styles];
var RenderType_ManageMonitorsDesktopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManageMonitorsDesktopComponent, data: {} });
export { RenderType_ManageMonitorsDesktopComponent as RenderType_ManageMonitorsDesktopComponent };
export function View_ManageMonitorsDesktopComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container-list"], ["fxFlex", "95"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 4, "ag-grid-angular", [], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AgGridAngular_0, i4.RenderType_AgGridAngular)), i1.ɵprd(512, null, i5.AngularFrameworkOverrides, i5.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i6.AngularFrameworkComponentWrapper, i6.AngularFrameworkComponentWrapper, []), i1.ɵdid(6, 4898816, null, 1, i7.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i5.AngularFrameworkOverrides, i6.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"] }, { gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["fxFlex", "5"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(9, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(10, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "95"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.monitorsGridOptions; var currVal_3 = _co.monitors; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = "column"; _ck(_v, 9, 0, currVal_4); var currVal_5 = "5"; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_ManageMonitorsDesktopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-desktop-manage-monitors", [], null, null, null, View_ManageMonitorsDesktopComponent_0, RenderType_ManageMonitorsDesktopComponent)), i1.ɵdid(1, 49152, null, 0, i8.ManageMonitorsDesktopComponent, [i9.MonitorDataService], null, null)], null, null); }
var ManageMonitorsDesktopComponentNgFactory = i1.ɵccf("app-desktop-manage-monitors", i8.ManageMonitorsDesktopComponent, View_ManageMonitorsDesktopComponent_Host_0, { monitors: "monitors" }, {}, []);
export { ManageMonitorsDesktopComponentNgFactory as ManageMonitorsDesktopComponentNgFactory };
