<form  (ngSubmit)="submit()" #shareForm="ngForm" *ngIf="!loading">
<div class="sharecontainer" fxFlex fxLayout="column">
  <div fxLayout="row">
      <h1>Request a share</h1>
  </div>
  <div [fxLayout.gt-sm]="row" [fxLayout.lt-sm]="column">
  
  <mat-form-field class="role-picker">
    <mat-label>Role</mat-label>
        <mat-select [(ngModel)]="model.role" name="role">
          <mat-option value="VIEWER" selected>
            Viewer
          </mat-option>
          <mat-option value="ADMINISTRATOR">
              Administrator
          </mat-option>
        </mat-select>
  </mat-form-field>
</div>
<div fxLayout="row">
  <mat-form-field class="text-field">
      <textarea matInput placeholder="Add a message" [(ngModel)]="model.text" name="message"></textarea>
  </mat-form-field>
</div>
  <div fxLayout="row" class="submitbutton">
     <button mat-button color="primary"  type="submit">{{"GENERAL.SUBMIT" | translate}}</button>
  </div>
</div>
</form>

<app-progress-spinner
    *ngIf="loading" fxFlex
    [progressSpinnerText]="'ACTIONS.SHARING' | translate">
  </app-progress-spinner>