<mat-card *ngFor="let monitor of monitors">
  <mat-card-header>
    <div fxFlex fxLayout="column" class="monitor-name">
      <span>{{monitor.details.name}}</span>
    </div>
    <app-monitor-software fxLayoutAlign="end center" [monitor]="monitor"></app-monitor-software>
  </mat-card-header>
  <mat-card-content fxLayout="row">
    <div fxLayout="column" fxFlex fxLayoutGap="10px">
      <span class="information">{{'MONITOR.INFORMATION' | translate}}</span>
      <div fxLayout="row" class="system">
        <mat-icon svgIcon="card-details"></mat-icon>
        <span [innerHTML]="monitor.details.system"></span>
      </div>
    </div>
    <button mat-raised-button
            [routerLink]="['/monitors/', monitor.id, 'edit']"
            color="primary">
      <span *ngIf="canEditMonitor(monitor)">
        {{'GENERAL.EDIT' | translate}}
      </span>
      <span *ngIf="!canEditMonitor(monitor)">
        {{'GENERAL.DETAILS' | translate}}
      </span>
    </button>
  </mat-card-content>

</mat-card>
<app-footer></app-footer>