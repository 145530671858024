<div class="ag-cell-label-container ag-header-cell-sorted-asc" (click)="onOwnSortRequested($event)"
     [ngStyle]="{'ag-header-cell-sorted-asc': sorted === 'asc', 'ag-header-cell-sorted-desc': sorted === 'desc', 'ag-header-cell-sorted-none': sorted === ''}"
     role="presentation">
  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true">1</span>
    <span *ngIf="params.enableSorting && sorted === 'asc'" ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" aria-hidden="true">
			<i class="fa fa-angle-down"></i>
		</span>
    <span *ngIf="params.enableSorting && sorted === 'desc'" ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" aria-hidden="true">
			<i class="fa fa-angle-up"></i>
		</span>
    <span ref="eFilter"
          class="ag-header-icon ag-filter-icon ag-hidden"
          aria-hidden="true">
			<svg width="10" height="10"><polygon
        points="0,0 4,4 4,10 6,10 6,4 10,0" class="ag-header-icon"></polygon></svg>
		</span>
    <span ref="eText" class="ag-header-cell-text" role="columnheader">{{params.displayName | translate}}</span></div>
</div>
