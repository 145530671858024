export class EnvironmentInformation {
  constructor(backendEnvironmentInformation: {
    'iot-backend': string,
    name: string,
    version: string
  }, backendHealth: {
    status: string
  }, clientEnvironmentInformation: {
    version: string
  }) {
    this._backendEnvironmentInformation = backendEnvironmentInformation;
    this._backendHealth = backendHealth;
    this._clientEnvironmentInformation = clientEnvironmentInformation;
  }

  private _backendEnvironmentInformation: { 'iot-backend': string; name: string; version: string };

  get backendEnvironmentInformation(): { 'iot-backend': string; name: string; version: string } {
    return this._backendEnvironmentInformation;
  }

  set backendEnvironmentInformation(value: { 'iot-backend': string; name: string; version: string }) {
    this._backendEnvironmentInformation = value;
  }

  private _backendHealth: { status: string };

  get backendHealth(): { status: string } {
    return this._backendHealth;
  }

  set backendHealth(value: { status: string }) {
    this._backendHealth = value;
  }

  private _clientEnvironmentInformation: { version: string };

  get clientEnvironmentInformation(): { version: string } {
    return this._clientEnvironmentInformation;
  }

  set clientEnvironmentInformation(value: { version: string }) {
    this._clientEnvironmentInformation = value;
  }

  public static fromJSON(backendEnvironmentInformationDTO: any, clientEnvironmentInformationDTO: any): EnvironmentInformation {
    return new EnvironmentInformation(
      backendEnvironmentInformationDTO.backendEnvironmentInformation,
      backendEnvironmentInformationDTO.backendHealth,
      clientEnvironmentInformationDTO
    );
  }
}
