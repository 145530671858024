import { of as observableOf, forkJoin as observableForkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { EnvironmentInformation } from '../../../domain/environmentInformation';
var InfoService = /** @class */ (function () {
    function InfoService(http) {
        this.http = http;
    }
    InfoService.prototype.getEnvironmentInformationAndHealthStatus = function () {
        var info = this.getBackendEnvironmentInformation();
        var health = this.getBackendHealthStatus();
        var clientInfo = this.getClientEnvironmentInformation();
        return observableForkJoin([info, health, clientInfo]).pipe(map(function (responses) {
            return EnvironmentInformation.fromJSON({
                backendEnvironmentInformation: responses[0],
                backendHealth: responses[1]
            }, responses[2]);
        }));
    };
    InfoService.prototype.getBackendEnvironmentInformation = function () {
        return this.http.get('/management/info');
    };
    InfoService.prototype.getBackendHealthStatus = function () {
        return this.http.get('/management/health');
    };
    InfoService.prototype.getClientEnvironmentInformation = function () {
        return observableOf({
            version: environment.app_version
        });
    };
    return InfoService;
}());
export { InfoService };
