<div class="ngx-dnd-container main-container" [ngxDroppable]="id" [model]="model" (drop)="onMainDrop($event)" (over)="onOverMain($event)">
  
  <div
    *ngFor="let displayObj of displayObjects; let first = first; let last = last"
    [class.first-item]="first"
    [class.last-item]="last"
    >
    
    <div *ngIf="isVisible(displayObj)"
        class="ngx-dnd-container sub-container"
        [ngxDroppable]="displayObj.content[0].unit"
        [model]="displayObj.content"
        (drop)="onDrop($event)"
        (over)="onOver($event)"
        (drag)="onDrag($event)"
        (out) = "onOut($event)"
        id="{{displayObj.id}}"
       >
	    	<app-dnd-item 
	    	*ngFor="let sensor of displayObj.content"
        [style.width]="getWidthOfItem(displayObj, sensor)"
        [style.height]="getHeight()"
	    	 [ngxDraggable]="[id, sensor.unit]" [model]="sensor" [item]="sensor[key]" [item2]='isMonitor? "":sensor.monitorId'>
	    	</app-dnd-item>
    </div>
    	
    </div>
</div>