var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject, Subject } from "rxjs";
/**
 * This is a BehaviorSubject that emits an event on subscription and on unsubscription.
 */
var EventSubject = /** @class */ (function (_super) {
    __extends(EventSubject, _super);
    function EventSubject(initialvalue) {
        var _this = _super.call(this, initialvalue) || this;
        _this.subscribed = new Subject();
        _this.unsubscribed = new Subject();
        return _this;
    }
    EventSubject.prototype.subscribe = function (next, err, complete) {
        var _this = this;
        this.subscribed.next(true);
        var subscription = _super.prototype.subscribe.call(this, next, err, complete);
        var proto = subscription.unsubscribe;
        subscription.unsubscribe = function () { _this.unsubscribe(); proto.bind(subscription)(); };
        return subscription;
    };
    EventSubject.prototype.unsubscribe = function () {
        this.unsubscribed.next(true);
    };
    EventSubject.prototype.onUnsubscribe = function () {
        return this.unsubscribed;
    };
    EventSubject.prototype.onSubscribe = function (func) {
        return this.subscribed;
    };
    return EventSubject;
}(BehaviorSubject));
export { EventSubject };
