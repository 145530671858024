import {InjectionToken} from '@angular/core';
import {Page} from './domain/page';
import { DetailsComponent } from './presentation/components/details/details.component';
import {InfoComponent} from './presentation/pages/info/info.component';
import {LoginComponent} from './presentation/pages/login/login.component';
import {PrivacyStatementComponent} from './presentation/pages/login/privacy/privacy-statement.component';
import {RequestResetPasswordComponent} from './presentation/pages/login/request-reset-password/request-reset-password.component';
import {ResetPasswordComponent} from './presentation/pages/login/reset-password/reset-password.component';
import {ManageMonitorsComponent} from './presentation/pages/monitors/manage-monitors/manage-monitors.component';
import {MonitorAddComponent} from './presentation/pages/monitors/monitor-add/monitor-add.component';
import {MonitorsComponent} from './presentation/pages/monitors/monitors.component';
import {ResetOwnPasswordComponent} from './presentation/pages/user-profile/reset-own-password/reset-own-password.component';
import {UserProfileComponent} from './presentation/pages/user-profile/user-profile.component';
import {VerifyComponent} from './presentation/pages/verify/verify.component';
import {ManageViewsComponent} from './presentation/pages/views/manage-views/manage-views.component';
import {ViewAddComponent} from './presentation/pages/views/view-add/view-add.component';
import {ViewsComponent} from './presentation/pages/views/views.component';
import {MonitorAddRedirectComponent} from './presentation/pages/monitors/monitor-add-redirect/monitor-add-redirect.component';
import { NotificationsComponent } from './presentation/pages/user-profile/notifications/notifications.component';
import { ActionComponent } from './presentation/pages/actions/action/action.component';

export let APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
  pages: Page[];
}

const loginPage: Page = new Page('Login', 'login', 'login', LoginComponent, true, false, false, []);

const requestResetPasswordPage: Page = new Page('Reset password', 'request-reset-pasword', 'request-reset-password', RequestResetPasswordComponent, false, false, false, []);
const resetPasswordPage: Page = new Page('Reset password', 'reset-pasword', 'reset-password', ResetPasswordComponent, false, false, false, []);



const addView: Page = new Page('Add playground', 'view-add', 'playgrounds/add', ViewAddComponent, false, false, true, []);
const editView: Page = new Page('Edit playground', 'view', 'playgrounds/:id/edit', ViewAddComponent, false, false, true, []);
const manageViews: Page = new Page('Manage playgrounds', 'view-manage', 'playgrounds/manage', ManageViewsComponent, false, false, true, []);
const viewOverview: Page = new Page('Playgrounds', 'view', 'playgrounds', ViewsComponent, false, true, true, [addView, manageViews]);
const viewDetail: Page = new Page('Detail playground', 'view', 'playgrounds/:id/detail', DetailsComponent, false, false, true, []);
addView.parentPage = viewOverview;
editView.parentPage = viewOverview;
manageViews.parentPage = viewOverview;
viewDetail.parentPage = viewOverview;

const addMonitor: Page = new Page('Add Risy-monitor', 'monitor-add', 'monitor/add', MonitorAddComponent, false, false, true, []);
const addMonitorRedirect: Page = new Page('Add Risy-monitor', 'monitor-add', 'monitor/add', MonitorAddRedirectComponent, false, false, true, []);
const editMonitor: Page = new Page('Edit Risy-monitor', 'view', 'monitors/:id/edit', MonitorAddComponent, false, false, true, []);
const manageMonitors: Page = new Page('Manage Risy-monitors', 'monitor-manage', 'monitors/manage', ManageMonitorsComponent, false, false, true, []);
const monitorOverview: Page = new Page('Risy-monitor overview', 'monitor-white', 'monitors', MonitorsComponent, false, true, true, [addMonitor, manageMonitors]);
const monitorDetail: Page = new Page('Details Risy-monitor', null, 'monitors/:id/detail', DetailsComponent, false, false, true, []);
addMonitor.parentPage = monitorOverview;
addMonitorRedirect.parentPage = monitorOverview;
editMonitor.parentPage = monitorOverview;
manageMonitors.parentPage = monitorOverview;
monitorDetail.parentPage = monitorOverview;

const verify: Page = new Page('Verify', '', 'verify', VerifyComponent, true, false, false, []);

const infoPage: Page = new Page('Info', 'info_outline', 'info', InfoComponent, false, false, true, []);

const userProfilePage: Page = new Page('User profile', '', 'user-profile', UserProfileComponent, false, false, true, []);

const privacyStatementPage: Page = new Page('Privacy statement', '', 'privacy-statement', PrivacyStatementComponent, false, false, false, []);

const changeOwnPasswordPage: Page = new Page('Change password', '', 'change-password', ResetOwnPasswordComponent, false, false, true, []);

const notificationPage: Page = new Page("Notifications", "", "notifications", NotificationsComponent, false, false, true, [])

// Variable amount of parameters in path.
const actionPageMatcher = (segments) => segments[0].path === "action" ? ({ consumed: segments }) : null
const actionPage: Page = new Page("Actions", "", "", ActionComponent, false, false, true, [], actionPageMatcher)


const pages: Page[] = [
  loginPage,
  requestResetPasswordPage,
  resetPasswordPage,
  addView,
  editView,
  manageViews,
  viewDetail,
  verify,
  infoPage,
  userProfilePage,
  privacyStatementPage,
  changeOwnPasswordPage,
  addMonitor,
  manageMonitors,
  monitorOverview,
  viewOverview,
  monitorDetail,
  editMonitor,
  notificationPage,
  actionPage
];

export const AppConfig: IAppConfig = {
  pages: pages
};
