import { PipeTransform } from '@angular/core';
import * as _ from "lodash";
var TruncateDigitsPipe = /** @class */ (function () {
    function TruncateDigitsPipe() {
    }
    /**
     * Truncates number to 2 digit
     * @param {number}  value
     * @param {number}  args    The number of digits. This is set default to 2
     * @returns {number}
     */
    TruncateDigitsPipe.prototype.transform = function (value, args) {
        if (args === void 0) { args = 2; }
        return !_.isNil(value) ? (Math.trunc(value * Math.pow(10, args)) / Math.pow(10, args)).toFixed(args) : "N/A";
    };
    return TruncateDigitsPipe;
}());
export { TruncateDigitsPipe };
