import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined } from 'lodash';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var VerifyComponent = /** @class */ (function () {
    function VerifyComponent(userService, router, activatedRoute, translateService) {
        var _this = this;
        this.isVerifying = true;
        this.errorHasHappened = false;
        this.ngUnsubscribe = new Subject();
        this.userService = userService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.key = params.key;
        });
        this.translateService = translateService;
        // Check if the required query param ist present, if not, redirect the user to the login page
        if (isUndefined(this.key)) {
            this.router.navigateByUrl('/login');
        }
        else {
            this.verify(this.key);
        }
    }
    VerifyComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    VerifyComponent.prototype.verify = function (key) {
        var _this = this;
        this.userService.verifyEmail(key).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            _this.isVerifying = false;
        }, function (error) {
            _this.isVerifying = false;
            _this.errorHasHappened = true;
            var parsedError = JSON.parse(error.error);
            var errorMessage = parsedError.message;
            _this.translateService.get("ERRORS." + error.error.type).pipe(takeUntil(_this.ngUnsubscribe))
                .subscribe(function (translation) {
                if (translation !== "ERRORS." + error.error.type) {
                    errorMessage = translation;
                }
            });
            throw new Error(errorMessage);
        });
    };
    return VerifyComponent;
}());
export { VerifyComponent };
