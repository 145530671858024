<div fxLayout="column" fxFlex>
  <app-navigation-toolbar [subPages]="subPages"></app-navigation-toolbar>
  <section class="view-add" fxLayout="column" *ngIf="!isLoading">
    <article class="setup-details" fxLayoutGap="20px">
      <header fxLayout="row">
        <h1 fxFlex *ngIf="!isEditMode()">{{ 'VIEW.ADD_VIEW' | translate }}</h1>
        <h1 fxFlex *ngIf="isEditMode()">{{ 'VIEW.EDIT_VIEW' | translate }}</h1>
        <button *ngIf="isEditMode()"
                mat-button
                color="primary"
                (click)="deleteView()">{{ 'VIEW.DELETE_VIEW' | translate }}
        </button>
      </header>

      <mat-card>
        <form [formGroup]="addViewDetailsForm">
          <div class="input-row">
            <mat-form-field fxFill>
              <input matInput
                     type="text"
                     placeholder="{{'VIEW.NAME' | translate}}"
                     formControlName="name">

              <mat-error *ngIf="addViewDetailsForm.get('name').errors?.required">
                {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'VIEW.NAME' | translate, min: 1} }}
              </mat-error>
              <mat-error *ngIf="addViewDetailsForm.get('name').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'VIEW.NAME' | translate, max: 255} }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="input-row">
            <mat-form-field fxFill>
              <input matInput
                     type="text"
                     placeholder="{{'VIEW.ELUCIDATION' | translate}}"
                     formControlName="elucidation">
              <mat-error *ngIf="addViewDetailsForm.get('elucidation').errors?.maxlength">
                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'VIEW.ELUCIDATION' | translate, max: 1000} }}
              </mat-error>
            </mat-form-field>
          </div>
        </form>
      </mat-card>
    </article>
    <article fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="36px" fxFlex="nogrow">
      <div fxFlex="0 0 auto" class="view-monitors-sensors">
        <header>
          <h1>{{'VIEW.MONITORS_SENSORS' | translate}}</h1>
        </header>
        <mat-card>
          <mat-accordion>
            <mat-expansion-panel *ngFor="let monitor of monitors">
              <mat-expansion-panel-header fxLayout="row">
                <div class="accordion-title" fxFlex fxLayoutAlign="start center">
                  <mat-icon svgIcon="monitor-list"></mat-icon>
                  <div class="monitor-name" fxFlex>
                    <span>
                      {{monitor.details.name}}
                    </span>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item *ngFor="let sensor of monitor.sensors"
                               [ngClass]="{'sensor-selected': isSensorInList(sensor, selectedSensorsOfMonitor), 'sensor-added': isSensorInList(sensor, viewSensors)}"
                               (click)="toggleSensorOfMonitor(sensor)">
                  <mat-icon svgIcon="sensor" mat-list-icon></mat-icon>
                  <span class="sensor-name" mat-line>{{sensor.name}}</span>
                  <mat-icon svgIcon="add" (click)="addSensorsToView([sensor])" *ngIf="!isSensorInList(sensor, viewSensors)" fxHide.gt-sm (click)="toggleSensorOfMonitor(sensor)"></mat-icon>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>
      </div>
      <div fxLayout="column" fxHide.lt-md fxLayoutAlign="start center" fxLayoutGap="35px" class="monitor-sensors-actions">
        <button mat-icon-button
                (click)="addSensorsToView(selectedSensorsOfMonitor)"
                [disabled]="!hasSensors(selectedSensorsOfMonitor)">
          <mat-icon svgIcon="view-add-action-add" class="icon-action-add">
          </mat-icon>
        </button>
        <button mat-icon-button
                (click)="removeSensorsFromView(selectedViewSensors)"
                [disabled]="!hasSensors(selectedViewSensors)">
          <mat-icon svgIcon="view-add-action-remove" class="icon-action-remove"></mat-icon>
        </button>
      </div>
      <div class="view-added">
        <header>
          <h1>{{'VIEW.ADDED' | translate}}</h1>
        </header>
        <mat-card>
          <mat-list>
            <mat-list-item *ngFor="let sensor of viewSensors"
                           (click)="toggleAddedSensor(sensor)"
                           [ngClass]="{'sensor-selected': isSensorInList(sensor, selectedViewSensors)}">
              <mat-icon mat-list-icon svgIcon="sensor"></mat-icon>
              <span class="sensor-name antialiased" mat-line>{{sensor.name}}</span>
              <span class="sensor-monitor antialiased" mat-line>From {{getMonitorOfSensor(sensor).details.name}}</span>
              <mat-icon svgIcon="delete" fxHide.gt-sm (click)="removeSensorsFromView([sensor])"></mat-icon>
            </mat-list-item>
          </mat-list>
        </mat-card>
      </div>
    </article>

    <footer fxLayoutAlign="end center" fxLayoutGap="28px" fxLayoutAlign.lt-sm="center center">
      <button mat-raised-button color="warn" (click)="cancel()">
        {{'GENERAL.CANCEL' | translate}}
      </button>
      <button mat-raised-button color="primary"
              (click)="saveView()"
              [disabled]="!canSaveView()">
        {{'GENERAL.SAVE' | translate}}
      </button>
    </footer>
  </section>
  <app-progress-spinner
    *ngIf="isLoading" fxFlex
    [progressSpinnerText]="'NAVIGATION.VIEWS' | translate">
  </app-progress-spinner>
  <app-footer></app-footer>

</div>
