var EnvironmentInformation = /** @class */ (function () {
    function EnvironmentInformation(backendEnvironmentInformation, backendHealth, clientEnvironmentInformation) {
        this._backendEnvironmentInformation = backendEnvironmentInformation;
        this._backendHealth = backendHealth;
        this._clientEnvironmentInformation = clientEnvironmentInformation;
    }
    Object.defineProperty(EnvironmentInformation.prototype, "backendEnvironmentInformation", {
        get: function () {
            return this._backendEnvironmentInformation;
        },
        set: function (value) {
            this._backendEnvironmentInformation = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvironmentInformation.prototype, "backendHealth", {
        get: function () {
            return this._backendHealth;
        },
        set: function (value) {
            this._backendHealth = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvironmentInformation.prototype, "clientEnvironmentInformation", {
        get: function () {
            return this._clientEnvironmentInformation;
        },
        set: function (value) {
            this._clientEnvironmentInformation = value;
        },
        enumerable: true,
        configurable: true
    });
    EnvironmentInformation.fromJSON = function (backendEnvironmentInformationDTO, clientEnvironmentInformationDTO) {
        return new EnvironmentInformation(backendEnvironmentInformationDTO.backendEnvironmentInformation, backendEnvironmentInformationDTO.backendHealth, clientEnvironmentInformationDTO);
    };
    return EnvironmentInformation;
}());
export { EnvironmentInformation };
