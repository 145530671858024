import { takeUntil } from 'rxjs/operators';
import { AfterContentInit, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { isEqual, isUndefined, map, values } from 'lodash';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../../../application/notification/notification.service';
import { Monitor } from '../../../../../domain/monitor/monitor';
import { MonitorDetails } from '../../../../../domain/monitor/monitorDetails';
import MonitorPrivacy from '../../../../../domain/monitor/monitorPrivacy';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { MonitorAddComponent } from '../monitor-add.component';
import { MessageStyle } from "../../../../../domain/messageStyle";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var MonitorAddStep2Component = /** @class */ (function () {
    function MonitorAddStep2Component(formBuilder, router, httpMonitorsService, notificationService, dialog, errorTranslator) {
        this.hasCompletedStepTwo = new EventEmitter();
        this.MESSAGE_STYLE = MessageStyle;
        this.MONITOR_PRIVACY_LEVELS = MonitorPrivacy;
        this.isMonitorPrivacyLevelReadOnly = false;
        this.unSubscribeUpdateMonitorDetailsOnDestroy = new Subject();
        this.dialogConfig = {
            panelClass: 'confirmation-dialog'
        };
        this.formBuilder = formBuilder;
        this.router = router;
        this.httpMonitorsService = httpMonitorsService;
        this.notificationService = notificationService;
        this.dialog = dialog;
        this.errorTranslator = errorTranslator;
        this.addDetailsMonitorForm = this.formBuilder.group({
            name: [null, [Validators.required, Validators.maxLength(255)]],
            address: [null, [Validators.maxLength(1000)]],
            system: [null, [Validators.maxLength(255)]],
            circuit: ['', Validators.maxLength(255)],
            details: ['', Validators.maxLength(255)],
            comments: ['', Validators.maxLength(1000)],
        });
    }
    MonitorAddStep2Component.prototype.ngOnChanges = function () {
        this.hasAllFixedChannels = MonitorAddComponent.hasAllFixedChannels(this.monitor.channels);
    };
    MonitorAddStep2Component.prototype.ngAfterContentInit = function () {
        if (this.editMode) {
            this.addDetailsMonitorForm.addControl('privacy', (new FormControl({ value: this.monitor.privacy, disabled: !this.canEditMonitor() }, []
            //[Validators.required]
            )));
        }
        else {
            this.isMonitorPrivacyLevelReadOnly = isEqual(this.monitor.privacy, MonitorPrivacy.READ_ONLY);
        }
        // Pre-fill the form
        this.addDetailsMonitorForm.patchValue(this.monitor.details);
    };
    MonitorAddStep2Component.prototype.ngOnDestroy = function () {
        this.unSubscribeUpdateMonitorDetailsOnDestroy.next(true);
        this.unSubscribeUpdateMonitorDetailsOnDestroy.complete();
    };
    MonitorAddStep2Component.prototype.canEditMonitor = function () {
        return this.monitor.editableByCurrentUser;
    };
    MonitorAddStep2Component.prototype.addDetailsToMonitor = function (form, isValid) {
        var _this = this;
        if (this.canEditMonitor()) {
            if (isValid) {
                this.isAddingDetails = true;
                // Reset the http error
                this.validateHttpError = null;
                this.httpMonitorsService.updateMonitorDetails(this.monitor.id, new MonitorDetails(form.name, form.address, form.system, form.circuit, form.details, form.comments), form.privacy, this.editMode).pipe(takeUntil(this.unSubscribeUpdateMonitorDetailsOnDestroy))
                    .subscribe(function (monitor) {
                    // Emit the response back to the parent component
                    _this.hasCompletedStepTwo.emit({
                        monitor: monitor,
                        canNavigateToStepThree: true
                    });
                }, function (error) {
                    _this.isAddingDetails = false;
                    _this.errorTranslator.execute(error).pipe(takeUntil(_this.unSubscribeUpdateMonitorDetailsOnDestroy))
                        .subscribe(function (errorMessage) {
                        _this.validateHttpError = errorMessage;
                    });
                });
            }
        }
        else {
            this.hasCompletedStepTwo.emit({
                monitor: this.monitor,
                canNavigateToStepThree: true
            });
        }
    };
    MonitorAddStep2Component.prototype.canShowWarningForAccessabilityOfMonitor = function () {
        var originalPrivacyLevel = this.monitor.privacy;
        var currentPrivacyLevel = this.addDetailsMonitorForm.controls['privacy'].value;
        return isEqual(currentPrivacyLevel, MonitorPrivacy.PRIVATE) && !isEqual(originalPrivacyLevel, currentPrivacyLevel) && (this.monitor.numberOfLinkedUsers > 1);
    };
    MonitorAddStep2Component.prototype.goBackToStepOne = function () {
        if (!this.editMode) {
            this.hasCompletedStepTwo.emit({
                monitor: this.monitor,
                canNavigateToStepThree: false
            });
        }
        else {
            this.router.navigateByUrl('/monitors/manage');
        }
    };
    MonitorAddStep2Component.prototype.canAddDetails = function () {
        return this.addDetailsMonitorForm.valid && !this.isAddingDetails;
    };
    MonitorAddStep2Component.prototype.isEditMode = function () {
        return this.editMode;
    };
    MonitorAddStep2Component.prototype.deleteMonitor = function () {
        var _this = this;
        var currentDialogConfig = {
            panelClass: this.dialogConfig.panelClass,
            data: {
                textContent: 'MONITOR.DELETE_MONITOR_QUESTION',
                confirmText: 'GENERAL.OK',
                cancelText: 'GENERAL.CANCEL'
            }
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === true) {
                _this.httpMonitorsService
                    .deleteMonitor(_this.monitor.id).pipe(takeUntil(_this.unSubscribeUpdateMonitorDetailsOnDestroy))
                    .subscribe(function () {
                    _this.router.navigateByUrl('/monitors/manage')
                        .then(function () {
                        _this.notificationService.show('MONITOR.DELETED_CONFIRMATION');
                    });
                }, function (error) {
                    var interpolateParams = {};
                    var views = JSON.parse(error.error).views;
                    if (!isUndefined(views)) {
                        interpolateParams.views = map(views, 'name').join(', ');
                    }
                    _this.errorTranslator.execute(error, interpolateParams).pipe(takeUntil(_this.unSubscribeUpdateMonitorDetailsOnDestroy))
                        .subscribe(function (errorMessage) {
                        _this.validateHttpError = errorMessage;
                    });
                });
            }
        });
    };
    MonitorAddStep2Component.prototype.privacyLevels = function () {
        return values(this.MONITOR_PRIVACY_LEVELS);
    };
    return MonitorAddStep2Component;
}());
export { MonitorAddStep2Component };
