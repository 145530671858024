
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-monitor-add-redirect',
  template: ''
})
export class MonitorAddRedirectComponent implements OnDestroy {
  private unSubscribeMonitorAddComponent: Subject<boolean> = new Subject<boolean>();


  constructor(router: Router, route: ActivatedRoute) {
    route
      .queryParams.pipe(
      takeUntil(this.unSubscribeMonitorAddComponent))
      .subscribe(params => {
        router.navigate(['/monitor/add'], { queryParams: { serial_number: params.serial_number,  activation_code: params.activation_code} });
      });
  }

  public ngOnDestroy(): void {
    this.unSubscribeMonitorAddComponent.next(true);
    this.unSubscribeMonitorAddComponent.complete();
  }

}
