var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Sensor } from './sensor';
var SensorDigitalPulse = /** @class */ (function (_super) {
    __extends(SensorDigitalPulse, _super);
    function SensorDigitalPulse(channel, name, category, inputType, sensorType, unit, value) {
        var _this = _super.call(this, channel, name, category, inputType, sensorType) || this;
        _this._unit = unit;
        _this._value = value;
        return _this;
    }
    Object.defineProperty(SensorDigitalPulse.prototype, "unit", {
        get: function () {
            return this._unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorDigitalPulse.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    SensorDigitalPulse.prototype.toJSON = function (sensorDigitalPulseDTO) {
        return {
            channel: sensorDigitalPulseDTO.channel,
            name: sensorDigitalPulseDTO.name,
            inputType: sensorDigitalPulseDTO.inputType,
            categoryId: sensorDigitalPulseDTO.category,
            sensorTypeId: sensorDigitalPulseDTO.sensorType,
            unitId: sensorDigitalPulseDTO.unit,
            value: sensorDigitalPulseDTO.value
        };
    };
    return SensorDigitalPulse;
}(Sensor));
export { SensorDigitalPulse };
