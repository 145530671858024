
import {filter, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {isNull, map} from 'lodash';
import {Subject} from 'rxjs';
import {Notification} from '../../../application/notification/notification';
import {NotificationService} from '../../../application/notification/notification.service';
import JobCategory from '../../../domain/jobCategory';
import {User} from '../../../domain/user';
import {Authentication} from '../../../infrastructure/http/authentication/authentication';
import {AuthenticationService} from '../../../infrastructure/http/authentication/authentication.service';
import {Users} from '../../../infrastructure/http/user/users';
import {ErrorTranslator} from '../../../application/command/error-translator/error-translator';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit, OnDestroy {
  public user: User;
  public userDetailsForm: FormGroup;
  public jobCategories = JobCategory;
  public keyJobCategories: any[];
  
  private usersService: Users;
  private unSubscribeUsersOnDestroy: Subject<boolean> = new Subject<boolean>();
  private formBuilder: FormBuilder;
  private updatePerforming = false;
  private notificationService: Notification;
  private authenticationService: Authentication;
  private errorTranslator: ErrorTranslator;
  private dialog: MatDialog;
  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    panelClass: 'confirmation-dialog'
  };

  constructor(usersService: HttpUsersService,
              authenticationService: AuthenticationService,
              formBuilder: FormBuilder,
              notificationService: NotificationService,
              @Inject('ErrorTranslator') errorTranslator: ErrorTranslator,
              dialog: MatDialog) {
    this.usersService = usersService;
    this.authenticationService = authenticationService;
    this.formBuilder = formBuilder;
    this.notificationService = notificationService;
    this.errorTranslator = errorTranslator;
    this.dialog = dialog;

    this.keyJobCategories = map(Object.keys(this.jobCategories), (key) => {
      return this.jobCategories[key].toUpperCase();
    });

    this.userDetailsForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(255)]],
      lastName: ['', [Validators.required, Validators.maxLength(255)]],
      email: [{value: '', disabled: true}],
      jobCategory: ['', Validators.required],
      jobTitle: ['', Validators.maxLength(255)],
      phone: ['', Validators.maxLength(255)],
    });

  }

  public ngOnDestroy() {
    this.unSubscribeUsersOnDestroy.next(true);
    this.unSubscribeUsersOnDestroy.complete();
  }

  public ngOnInit() {
    this.authenticationService.getAuthenticatedUser().pipe(
      takeUntil(this.unSubscribeUsersOnDestroy),
      filter(a => !isNull(a))
      )
      .subscribe((user: User) => {
        this.user = user;
        this.userDetailsForm.controls['firstName'].setValue(user.firstName);
        this.userDetailsForm.controls['lastName'].setValue(user.lastName);
        this.userDetailsForm.controls['email'].setValue(user.email);
        this.userDetailsForm.controls['jobCategory'].setValue(user.jobCategory);
        this.userDetailsForm.controls['jobTitle'].setValue(user.jobTitle);
        this.userDetailsForm.controls['phone'].setValue(user.phone);
      });
  }

  public saveUserProfile(form: FormGroup): void {
    if (form.valid) {
      const formValues = form.value;
      this.updatePerforming = true;
      this.user.firstName = formValues.firstName;
      this.user.lastName = formValues.lastName;
      this.user.jobCategory = formValues.jobCategory;
      this.user.jobTitle = formValues.jobTitle;
      this.user.phone = formValues.phone;
      this.usersService.edit(this.user).pipe(
        takeUntil(this.unSubscribeUsersOnDestroy))
        .subscribe((editedUser: User) => {
        this.notificationService.show('USER.EDITED_CONFIRMATION');

        this.authenticationService.setAuthenticatedUser(editedUser);

        this.user.firstName = editedUser.firstName;
        this.user.lastName = editedUser.lastName;
        this.user.jobCategory = editedUser.jobCategory;
        this.user.jobTitle = editedUser.jobTitle;
        this.user.phone = editedUser.phone;
        this.updatePerforming = false;
      }, (error: HttpErrorResponse) => {
        this.updatePerforming = false;

        this.errorTranslator.execute(error).pipe(
          takeUntil(this.unSubscribeUsersOnDestroy))
          .subscribe((errorMessage: string) => {
          throw new Error(errorMessage);
        });

      });
    }
  }

  public canSaveUserProfile(): boolean {
    return this.userDetailsForm.valid && !this.updatePerforming;
  }
  
  public deleteAccount(): void {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig> {
      panelClass: this.dialogConfig.panelClass,
      data: {
        textContent: 'USER.DELETE_USER_QUESTION',
        confirmText: 'GENERAL.OK',
        cancelText: 'GENERAL.CANCEL'
      }
    };
    
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.usersService.deleteUser(this.user).subscribe();
        this.authenticationService.logout();
      }
    });
  }

}
