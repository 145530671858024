var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { forkJoin as observableForkJoin, Observable, ReplaySubject, timer, BehaviorSubject } from 'rxjs';
import { takeUntil, distinctUntilChanged, filter, map, take, tap, switchMap } from 'rxjs/operators';
import { HttpMonitorsService } from "../http/monitor/http-monitors.service";
import { RequestManagerService } from "./request-manager.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import * as _ from "lodash";
import { EventSubject } from "./EventSubject";
import { AuthenticationService } from "../http/authentication/authentication.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./request-manager.service";
import * as i3 from "../http/authentication/authentication.service";
var MonitorDataService = /** @class */ (function (_super) {
    __extends(MonitorDataService, _super);
    function MonitorDataService(httpClient, requestManager, authenticationService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.requestManager = requestManager;
        _this.authenticationService = authenticationService;
        // Caching subjects: These keep the fetched data.
        _this.allMonitors = new ReplaySubject(1);
        _this.allMonitorsForStatusOverview = new ReplaySubject(1);
        _this.detailOverviewSubject = new EventSubject({});
        _this.firmwareStatusSubject = new BehaviorSubject(null);
        _this.chat = new BehaviorSubject({});
        _this.compareMeasurements = function (one, two) { return one.lastMeasuredDateTime === two.lastMeasuredDateTime && one.measurements.length !== two.measurements.length; };
        _this.authenticationService.getAuthenticatedUser()
            .pipe(distinctUntilChanged(function (obj1, obj2) { return obj1 && obj2 && obj1.id === obj2.id; }))
            .subscribe(function (user) { return user ? _this.initialize() : _this.clear(); });
        return _this;
    }
    MonitorDataService.prototype.initialize = function () {
        var _this = this;
        this.fetchStatus();
        if (environment.prefetchMeasurements) {
            this.initDetailOverviews();
        }
        this.refresh = this.refresh || setInterval(function () { return _this.fetchStatus(); }, environment.pollingIntervalInSeconds * 5000);
    };
    MonitorDataService.prototype.clear = function () {
        clearInterval(this.refresh);
        this.refresh = null;
        this.allMonitors.next(null);
        this.allMonitorsForStatusOverview.next(null);
        this.detailOverviewSubject.next({});
    };
    /**
     * Accessor methods. These are overloaded from HttpMonitorsService, and utilize the caching before request.
     */
    // This function is no longer supposed to be used. Use 'getAllForStatusOverview' instead.
    MonitorDataService.prototype.getAll = function () {
        return this.allMonitors
            .pipe(filter(function (monitors) { return !_.isNil(monitors); }));
    };
    MonitorDataService.prototype.getAllForStatusOverview = function () {
        return this.allMonitorsForStatusOverview
            .pipe(filter(function (monitors) { return !_.isNil(monitors); }));
    };
    MonitorDataService.prototype.getOneForStatusOverview = function (id) {
        return this.allMonitorsForStatusOverview
            .pipe(filter(function (monitors) { return !_.isNil(monitors); }), distinctUntilChanged(function (one, two) { return JSON.stringify(one) === JSON.stringify(two); }), map(function (obj) { return obj.filter(function (obj) { return obj.id == id; })[0]; }), filter(function (obj) { return !_.isNil(obj); }));
    };
    MonitorDataService.prototype.getForDetailOverview = function (id, selectedDateRange, detailStatusOverview) {
        // The default range is cached. We don't need to send a new request.
        this.startRefreshInterval(id, selectedDateRange);
        return this.detailOverviewSubject
            .pipe(map(function (obj) { return obj[id]; }), filter(function (obj) { return !_.isNil(obj); }));
    };
    MonitorDataService.prototype.addMonitor = function (activationCode, serialNumber) {
        var _this = this;
        return _super.prototype.addMonitor.call(this, activationCode, serialNumber)
            .pipe(tap(function () { return _this.clear(); }), tap(function () { return _this.fetchStatus(); }));
    };
    MonitorDataService.prototype.addByKey = function (serial, role, key, email) {
        var _this = this;
        return _super.prototype.addByKey.call(this, serial, role, key, email)
            .pipe(tap(function () { return setTimeout(function () { return _this.fetchStatus(); }, 1); }));
    };
    MonitorDataService.prototype.deleteMonitor = function (id) {
        var _this = this;
        return _super.prototype.deleteMonitor.call(this, id)
            .pipe(tap(function () { return _this.fetchStatus(); }));
    };
    MonitorDataService.prototype.setChangedOrder = function (id, order) {
        return _super.prototype.setChangedOrder.call(this, id, order);
    };
    /**
     * These functions refresh the cached data.
     */
    MonitorDataService.prototype.fetchStatus = function () {
        var _this = this;
        this.requestManager.addToQueue({
            priority: 1, observable: _super.prototype.getAllForStatusOverview.call(this), name: "MonitorStatus",
            subscriptionFunc: function (monitors) { _this.allMonitorsForStatusOverview.next(monitors); }
        });
    };
    MonitorDataService.prototype.startRefreshInterval = function (id, selectedDateRange) {
        var _this = this;
        timer(0, environment.pollingIntervalInSeconds * 1000).pipe(
        // We automatically unsubscribe to the timer (and thus stop updating) when we unsubscribe the measurement update source.
        takeUntil(this.detailOverviewSubject.onUnsubscribe()))
            .subscribe(function () {
            _this.updateMeasurements(id, selectedDateRange);
            _this.updateChat(id);
            //this.initDetailOverview(id);
        });
    };
    MonitorDataService.prototype.updateChat = function (id) {
        var _this = this;
        this.chat
            .pipe(take(1))
            .subscribe(function (chat) {
            _super.prototype.getChatForMonitor.call(_this, id).subscribe(function (m) {
                chat[id] = m.concat().sort(function (a, b) { return (new Date(a.creationtime)).getTime() - (new Date(b.creationtime)).getTime(); });
                _this.chat.next(chat);
            });
        });
    };
    MonitorDataService.prototype.updateMeasurements = function (id, selectedDateRange) {
        var _this = this;
        if (selectedDateRange.end.isSame(moment(), "day")) {
            selectedDateRange.end = moment();
        }
        // Fetch the latest to update.
        observableForkJoin([
            this.getDetailOverviewForCache(),
            this.getOneForStatusOverview(id).pipe(take(1))
        ])
            .subscribe(function (results) {
            _super.prototype.getForDetailOverview.call(_this, id, selectedDateRange, results[1])
                .subscribe(function (updated) {
                results[0][id] = updated;
                _this.detailOverviewSubject.next(results[0]);
            });
        });
    };
    MonitorDataService.prototype._skipCacheDetailOverview = function (id, selectedDateRange) {
        return _super.prototype.getForDetailOverview.call(this, id, selectedDateRange);
    };
    /**
     * These functions initialize the needed data.
     */
    MonitorDataService.prototype.initDetailOverviews = function () {
        var _this = this;
        this.getAllForStatusOverview().pipe(take(1))
            .subscribe(function (monitors) { return monitors.forEach(function (monitor) { return _this.initDetailOverview(monitor.id, monitor); }); });
    };
    MonitorDataService.prototype.initDetailOverview = function (monitorId, statusOverview) {
        var _this = this;
        var subscriptionFunc = function (detailOverview) { return _this.getDetailOverviewForCache()
            .pipe(take(1))
            .subscribe(function (obj) {
            obj[monitorId] = detailOverview;
            _this.detailOverviewSubject.next(obj);
        }); };
        // Request for the detail overview
        var observable = _super.prototype.getForDetailOverview.call(this, monitorId, MonitorDataService.defaultTime(), statusOverview);
        this.requestManager.addToQueue({ priority: 10, observable: observable, subscriptionFunc: subscriptionFunc });
    };
    // This function is no longer supposed to be used. All data is now in Statusoverview.
    MonitorDataService.prototype.fetchAllMonitors = function () {
        var _this = this;
        this.requestManager.addToQueue({
            priority: 8,
            observable: _super.prototype.getAll.call(this),
            subscriptionFunc: function (monitors) { return _this.allMonitors.next(monitors); }
        });
    };
    MonitorDataService.isDefault = function (selectedDateRange) {
        return selectedDateRange.start.isSame(this.defaultTime().start, "day") && selectedDateRange.end.isSame(this.defaultTime().end, "day");
    };
    MonitorDataService.prototype.getDetailOverviewForCache = function () {
        return this.detailOverviewSubject
            .pipe(take(1));
    };
    MonitorDataService.prototype.requestFirmwareUpgrade = function (id) {
        var _this = this;
        return _super.prototype.requestFirmwareUpgrade.call(this, id)
            .pipe(tap(function (o) { return _this.firmwareStatusSubject.next(o); }));
    };
    MonitorDataService.prototype.getFirmwareUpgradeStatus = function (id) {
        var _this = this;
        return this.firmwareStatusSubject.pipe(switchMap(function () { return _super.prototype.getFirmwareUpgradeStatus.call(_this, id); }));
    };
    MonitorDataService.prototype.getChatForMonitor = function (id) {
        return this.chat.pipe(map(function (c) { return c[id]; }));
    };
    MonitorDataService.prototype.postChatForMonitor = function (id, text) {
        var _this = this;
        if (!text) {
            return Observable.empty();
        }
        return _super.prototype.postChatForMonitor.call(this, id, text).pipe(tap(function () { return _this.updateChat(id); }));
    };
    /**
     * Helper methods: these method are a shorthand for tasks.
     */
    MonitorDataService.defaultTime = function () { return ({ start: moment().subtract(1, 'month'), end: moment() }); };
    MonitorDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MonitorDataService_Factory() { return new MonitorDataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.RequestManagerService), i0.ɵɵinject(i3.AuthenticationService)); }, token: MonitorDataService, providedIn: "root" });
    return MonitorDataService;
}(HttpMonitorsService));
export { MonitorDataService };
