var SensorCategoryType = /** @class */ (function () {
    function SensorCategoryType(id, name, units) {
        this._id = id;
        this._name = name;
        this._units = units;
    }
    Object.defineProperty(SensorCategoryType.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorCategoryType.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorCategoryType.prototype, "units", {
        get: function () {
            return this._units;
        },
        enumerable: true,
        configurable: true
    });
    return SensorCategoryType;
}());
export { SensorCategoryType };
