import MonitorInputType from '../monitorInputType';
import {Sensor} from './sensor';

export class SensorAuto extends Sensor {
private _unit: string;
private _pollingRate: string;
private _cloudStorageRate: string;
private _measurementMinimum: number;
private _measurementMaximum: number;
private _valueMinimum: number;
private _valueMaximum: number;

constructor(channel: number,
name: string,
category: string,
inputType: MonitorInputType,
sensorType: string,
unit: string,
pollingRate: string,
measurementMinimum: number,
measurementMaximum: number,
valueMinimum: number,
valueMaximum: number,
cloudStorageRate: string) {
super(channel, name, category, inputType, sensorType);

this._unit = unit;
this._pollingRate = pollingRate;
this._cloudStorageRate = cloudStorageRate;
this._measurementMinimum = measurementMinimum;
this._measurementMaximum = measurementMaximum;
this._valueMinimum = valueMinimum;
this._valueMaximum = valueMaximum;
}


public toJSON(sensorAuto: any): {} {
return {
channel: sensorAuto.channel,
name: sensorAuto.name,
categoryId: sensorAuto.category,
inputType: sensorAuto.inputType,
sensorTypeId: sensorAuto.sensorType,
unitId: sensorAuto.unitId,
measurementMinimum: sensorAuto.measurementMinimum,
measurementMaximum: sensorAuto.measurementMaximum,
valueMinimum: sensorAuto.valueMinimum,
valueMaximum: sensorAuto.valueMaximum,
pollingRate: sensorAuto.pollingRate,
cloudStorageRate: sensorAuto.cloudStorageRate
}
}

get unit(): string {
return this._unit;
}

get pollingRate(): string {
return this._pollingRate;
}

get cloudStorageRate(): string {
return this._cloudStorageRate;
}

get measurementMinimum(): number {
    return this._measurementMinimum;
  }

get measurementMaximum(): number {
    return this._measurementMaximum;
  }

get valueMinimum(): number {
    return this._valueMinimum;
  }

get valueMaximum(): number {
    return this._valueMaximum;
  }
}