import { MonitorMeasurementData } from './monitorMeasurementData';
import { SensorLimit } from './sensor/sensor-limit';
var MonitorMeasurement = /** @class */ (function () {
    function MonitorMeasurement(sensorId, backendId, channel, inputType, name, openText, closedText, measurements, upperLimits, lowerLimits) {
        this._sensorId = sensorId;
        this._backendId = backendId;
        this._channel = channel;
        this._inputType = inputType;
        this._name = name;
        this._openText = openText;
        this._closedText = closedText;
        this._measurements = measurements;
        this._upperLimits = upperLimits;
        this._lowerLimits = lowerLimits;
    }
    MonitorMeasurement.fromJSON = function (monitorMeasurementDTO) {
        return new MonitorMeasurement(monitorMeasurementDTO.id, monitorMeasurementDTO.backendId, monitorMeasurementDTO.channel, monitorMeasurementDTO.inputType, monitorMeasurementDTO.name, monitorMeasurementDTO.openText, monitorMeasurementDTO.closedText, monitorMeasurementDTO.measurements.map(MonitorMeasurementData.fromJSON), monitorMeasurementDTO.upperlimits.map(SensorLimit.fromJSON), monitorMeasurementDTO.lowerlimits.map(SensorLimit.fromJSON));
    };
    Object.defineProperty(MonitorMeasurement.prototype, "sensorId", {
        get: function () {
            return this._sensorId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "backendId", {
        get: function () {
            return this._backendId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "channel", {
        get: function () {
            return this._channel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "inputType", {
        get: function () {
            return this._inputType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "measurements", {
        get: function () {
            return this._measurements;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "openText", {
        get: function () {
            return this._openText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "closedText", {
        get: function () {
            return this._closedText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "upperLimits", {
        get: function () {
            return this._upperLimits;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorMeasurement.prototype, "lowerLimits", {
        get: function () {
            return this._lowerLimits;
        },
        enumerable: true,
        configurable: true
    });
    return MonitorMeasurement;
}());
export { MonitorMeasurement };
