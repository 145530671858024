import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../../infrastructure/http/authentication/authentication.service';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  private router: Router;
  private authenticationService: AuthenticationService;

  constructor(router: Router, authenticationService: AuthenticationService) {
    this.router = router;
    this.authenticationService = authenticationService;
  }

  canActivate() {
    let result = false;
    if (this.authenticationService.hasToken()) {
      result = true;
    } else {
      this.router.navigate(['/login']);
    }
    return result;

  }
}
