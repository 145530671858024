<div fxLayout="column" fxFlex>
  <app-navigation-toolbar [subPages]="subPages"></app-navigation-toolbar>
  <app-progress-spinner
    *ngIf="isLoading"
    [progressSpinnerText]="'NAVIGATION.MONITORS' | translate">
  </app-progress-spinner>

  <section class="monitor-add">
    <app-message *ngIf="hasHttpError"
                 [messageText]="validateHttpError"
                 [messageStyle]="MESSAGE_STYLE.DANGER"></app-message>

    <section *ngIf="!isLoading && !hasHttpError">
      <!-- Add-Monitor Step 1 -->
      <app-monitor-add-step-1
        *ngIf="canShowStepOne()"
        (hasCompletedStepOne)="hasCompletedStepOne($event)">
      </app-monitor-add-step-1>

      <!-- Add-Monitor Step 2 -->
      <app-monitor-add-step-2
        *ngIf="canShowToStepTwo()"
        [editMode]="editMode"
        [monitor]="monitor"
        (hasCompletedStepTwo)="hasCompletedStepTwo($event)">
      </app-monitor-add-step-2>

      <!-- Add-Monitor Step 3 -->
      <app-monitor-add-step-3
        *ngIf="canShowStepThree()"
        [editMode]="editMode"
        [monitor]="monitor"
        (hasCompletedStepThree)="hasCompletedStepThree($event)"
        class="add-monitor-sensors__content">
      </app-monitor-add-step-3>
    </section>
  </section>
  <app-footer></app-footer>

</div>
