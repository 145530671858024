import { MonitorSensor } from '../../../domain/monitor/monitorSensor';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-card',
  templateUrl: './view-card.component.html',
  styleUrls: ['./view-card.component.scss']
})
export class ViewCardComponent {
  @Input() details;
  @Input() info: string;
  @Input() sensors: MonitorSensor[];
  @Input() link: string[];

  constructor(private activatedRoute: ActivatedRoute) { }
}
