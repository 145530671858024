<div fxLayout="column" fxFlex>
  <app-navigation-toolbar [subPages]="subPages"></app-navigation-toolbar>
  <section fxLayout="column" fxFlex class="manage-sec">
    <ag-grid-angular *ngIf="canShowGrid()"
                     [gridOptions]="viewsGridOptions"
                     [rowData]="views">
    </ag-grid-angular>
    <article *ngIf="!canShowGrid()"
             class="list-views">
      <mat-card *ngFor="let view of views" fxLayout="row">
        <div fxFlex fxLayoutAlign="start center" class="view-name"><span>{{view.name}}</span></div>
        <button mat-raised-button color="primary">{{'GENERAL.EDIT' | translate}}</button>
      </mat-card>
    </article>

  </section>
  <app-footer></app-footer>

</div>
