
import { takeUntil } from 'rxjs/operators';
import { TruncateDigitsPipe } from '../../../application/truncate-digits/truncate-digits.pipe';
import { MonitorMeasurement } from '../../../domain/monitor/monitorMeasurement';
import { MonitorMeasurementData } from '../../../domain/monitor/monitorMeasurementData';
import { MonitorSensor } from '../../../domain/monitor/monitorSensor';
import { ChartService } from '../../../infrastructure/chart/chart.service';
import { DetailsChartComponent } from '../../../infrastructure/chart/details-chart/details-chart.component';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChartconfigService } from './chartconfig.service';
import MonitorInputType from "../../../domain/monitor/monitorInputType";
import { MessageStyle } from "../../../domain/messageStyle";
import { Subject } from 'rxjs';
import { Moment } from 'moment';
import { environment } from 'environments/environment';


@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent extends DetailsChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public measurements: MonitorMeasurement[];
    @Input() public sensors: MonitorSensor[];
    @Input() public monitorId: string;
    @Input() public selectedDateRangeUpdater: Subject<{ start: Moment, end: Moment }>;

    public enableScrollbar: boolean = false;

    public chart_id: number;
    public readonly MESSAGE_STYLE = MessageStyle;


    private noDataTranslation: string;
    private COLORS = ["#4CA3D3", "#6BC441", "#878786", "#69a470"];
    canShowLastAvailable: boolean;
    isShowingOnlyLatest: boolean;
    currentDates: any;
    public isFirstLoad = true;
    public isDateSelected = false;

    constructor(translateService: TranslateService,
        truncateDigitsPipe: TruncateDigitsPipe,
        amChartsService: AmChartsService,
        private zoomService: ChartService,
        private configService: ChartconfigService,
    ) {
        super(translateService, truncateDigitsPipe, amChartsService);
    }

    ngOnInit() {
        this.setId();
        this.currentDates = this.selectedDateRange;
    }

    public ngOnChanges(changes?: SimpleChanges) {
        if (changes && changes.selectedDateRange) {
            this.zoomService.pickedANewDate(); // For zooming purposes
            this.currentDates = changes.selectedDateRange.currentValue;
            this.isDateSelected = true;
            this.isFetchingNewData = true;
        }
        if (changes && changes.measurements) {
            this.isFetchingNewData = false;
        }
        
        if (!this.isFetchingNewData ||
            !this.isShowingOnlyLatest) {
            /*Not required to destroy the chart all time, makechart recreate same chart with new option values
            As we are using single chart component to each chart rendering, So Only distory when date range change*/
            if (this.isDateSelected && !this.isFetchingNewData) {
                this.destroyChart();
                this.isDateSelected = false;
            }
            this.makeChart(true);
        }

        if (!this.hasMeasurements() && this.sensors.length === 1) {
            this.canShowLastAvailable = true;
        }

        this.isFirstLoad = false;
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
    }

    public destroyChart() {
        this.zoomService.removeChart(this.amChart);
        super.destroyChart();
    }

    // START graph construction

    public makeChart(zoom: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.translateService.get([
                'MONITOR.LOWER_LIMIT',
                'MONITOR.UPPER_LIMIT',
                'CHART.NO_MEASUREMENTS_FOR_SELECTED_PERIOD'
            ]).pipe(takeUntil(this.unSubscribeMonitorDetailsChartOnDestroy))
                .subscribe((translations: any) => {
                    this.noDataTranslation = translations['CHART.NO_MEASUREMENTS_FOR_SELECTED_PERIOD'];
                    const values = this.getChartValues();
                    const chartConfig = this.configService.getStandardChartConfig(values.data, values.axes, values.graphs, this.enableScrollbar);
                    if (this.amChart && this.isChartExist(`chartdiv_${this.chart_id}`)) {
                        this.destroyChart()
                        this.amChart = this.amChartsService.makeChart(`chartdiv_${this.chart_id}`, chartConfig, 1);
                        this.amChart=Object.assign(this.amChart, chartConfig);
                        this.amChart.validateData();
                        this.amChart.animateAgain();
                        zoom && this.updateChart();
                        console.log("howmanyarecalled?")
                    }
                    else {
                        this.amChart = this.amChartsService.makeChart(`chartdiv_${this.chart_id}`, chartConfig, 1);
                        this.amChart.chartCustomId = `chartdiv_${this.chart_id}`;
                        zoom && this.addChart();
                    }
                    resolve();
                });
        });
    }

    public addChart() {
        this.zoomService.addChart(this.amChart);
    }

    public isChartExist(chartCustomId) {
        return this.zoomService.isChartExist(this.amChart, chartCustomId);
    }

    public updateChart() {
        this.zoomService.updateChart(this.amChart);
    }

    public toggleScrollbar() {
        this.enableScrollbar = !this.enableScrollbar;
        this.ngOnChanges();
    }

    private getChartValues() {
        const graphs = [this.getInvisibleGraphForOneSensor(this.sensors[0])];
        const data = [];
        let hasNegatives = false;

        // The extra date makes sure the graph is displayed from startdate to enddate, as chosen in datepicker.
        data.push({ date: this.currentDates.start.toDate() });
        this.sensors.forEach((sensor, index) => {

            this.addPerSensor(sensor, index, graphs, data);

            hasNegatives = hasNegatives || this.checkForNegativeValues(sensor);

        });
        data.push({ date: this.currentDates.end.toDate() });

        //We need to sort all items of data based on the date, regardless of sensor, else graph zooming will break
        data.sort((a, b) => {
            if (a.date < b.date) return -1;
            else if (a.date > b.date) return 1;
            else return 0;
        });

        const axes = [ChartComponent.getAxisConfig(this.sensors[0], hasNegatives)];

        return { graphs, axes, data };
    }

    private getInvisibleGraphForOneSensor(sensor) {
        if (!sensor) return;
        return this.configService.getInvisConfig(sensor)[0]

    }

    private checkForNegativeValues(sensor): boolean {
        const measurements = this.getMeasurementForSensor(sensor).measurements;
        return measurements.some((measurement) => measurement.measurementvalue < 0);
    }

    private addPerSensor(sensor, index, graphs, data) {
        const measurements = this.getMeasurementForSensor(sensor);
        const graphSettings = (measurement) => {
            const date = new Date(measurement.datetime);
            let description = "Date: " + date.toLocaleString() + "\n";

            const status = [...measurement.sensorstatus, ...measurement.monitorstatus]

            if (status.length > 0) {
                description += "[!] " + status.map((status) => status.errorEnum).join(", ");
            }

            return {
                bullet: status.length > 0 ? "triangleUp" : "round",
                bulletSize: status.length > 0 ? 6 : 0,
                bulletColor: status.length > 0 ? "#fbc02d" : "#00000000",
                description: description
            }
        };

        if (sensor.inputType === MonitorInputType.DIGITAL_OPEN_CLOSED) {
            data.push(...measurements.measurements.map(measurement => ({
                date: moment(measurement.datetime, moment.ISO_8601).toDate(),
                [sensor.id + 0]: this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits),
                ...graphSettings(measurement)
            })));
            graphs.push(this.configService.getDigitalOpenClosedConfig(sensor, 0, ChartComponent.getOpenOrClosedTextForValue(measurements)));
            return;
        }

        data.push(...measurements.measurements.map(measurement => ({
            date: moment(measurement.datetime, moment.ISO_8601).toDate(),
            [sensor.id + "invis"]: this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits),
        })))

        const grouped = this.groupMeasurementByLimit(measurements.measurements, sensor);
        const color = this.COLORS[index % this.COLORS.length];

        let id = 0;
        for (let limits of Object.keys(grouped)) {
            // Get the graph config for this group
            graphs.push(...(this.configService.getGraphConfig(sensor, id, JSON.parse(limits), color)));
            // To connect the graphs, they must share one measurement.
            // If you are not the first graph, you add your first measurement to the previous graph.
            if (id > 0) {
                const measurement = grouped[limits][0];
                data.push({
                    date: moment(measurement.datetime, moment.ISO_8601).toDate(),
                    [sensor.id + (id - 1)]: this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits),
                    ...graphSettings(measurement)
                })
            }

            // Get the appropriately set data for this group
            data.push(...(grouped[limits].map(measurement => ({
                date: moment(measurement.datetime, moment.ISO_8601).toDate(),
                [sensor.id + id]: this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits),
                ...graphSettings(measurement)
            }))));
            id += 1;
        }
    }

    private groupMeasurementByLimit(measurements: MonitorMeasurementData[], sensor) {
        return _.groupBy(measurements, (measurement) => JSON.stringify(ChartComponent.getLimitAtTimestamp(moment(measurement.datetime).toDate(), sensor)));
    }

    private static getAxisConfig(sensor, hasNegatives) {
        return {
            "position": "left",
            'id': 'v0',
            'title': sensor.unit && sensor.unitName ? `${sensor.unitName} [${sensor.unit}]` : null,
            'minimum': hasNegatives ? undefined : 0
        };
    }

    private static getLimitAtTimestamp(time: Date, sensor) {
        let upperLimits = sensor.upperLimits;
        let lowerLimits = sensor.lowerLimits;

        if (sensor.name === "Throughput") {
            upperLimits = [{ datetime: moment("2018-07-17T14:13:05Z").toDate(), value: 10.2 }];
            lowerLimits = [{ datetime: moment("2018-07-17T14:13:05Z").toDate(), value: 10.15 }];
        }

        const lowerLimit = ChartComponent.getLimitFromArray(time, lowerLimits);
        const upperLimit = ChartComponent.getLimitFromArray(time, upperLimits);
        return { lowerLimit, upperLimit };
    }

    private static getLimitFromArray(time: Date, limits) {
        if (!limits || limits.length === 0) return null;

        let i = 0;
        while (i < limits.length && limits[i].datetime < time) {
            i += 1;
        }
        return limits[i - 1];

    }


    // END graph construction

    private setId() {
        this.chart_id = Math.floor(Math.random() * 10000);
        console.log(this.chart_id)
    }

    private getMeasurementForSensor(sensor) {
        return this.measurements.find(measurement => measurement.sensorId === sensor.id);
    }

    private static getOpenOrClosedTextForValue(measurement): (value) => string {
        return (value) => value === 0 ? measurement.closedText : measurement.openText;
    }

    public hasMeasurements(): boolean {
        return this.measurements.filter(measurement => this.sensors.some(sensor => sensor.id === measurement.sensorId)).some(measurement => measurement.measurements.length > 0);
    }

    public canShowChart() {
        return this.hasMeasurements() && !this.isFetchingNewData && Array.isArray(this.sensors) && this.sensors.length > 0;
    }

    public showMostRecent() {
        const sensor = this.sensors[0];
        const lastmeasured = moment(sensor.lastMeasuredDateTime)
        const minusMonth = lastmeasured.clone().subtract(1, 'month')
        this.selectedDateRangeUpdater.next({ start: minusMonth, end: lastmeasured })
        this.isFetchingNewData = true;
    }

}
