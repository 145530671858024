var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Sensor } from './sensor';
var SensorDigitalOpenClosed = /** @class */ (function (_super) {
    __extends(SensorDigitalOpenClosed, _super);
    function SensorDigitalOpenClosed(channel, name, category, inputType, sensorType, openText, openTextDescription, closedText, closedTextDescription, pollingRate, cloudStorageRate) {
        var _this = _super.call(this, channel, name, category, inputType, sensorType) || this;
        _this._openText = openText;
        _this._openTextDescription = openTextDescription;
        _this._closedTextDescription = closedTextDescription;
        _this._closedText = closedText;
        _this._pollingRate = pollingRate;
        _this._cloudStorageRate = cloudStorageRate;
        return _this;
    }
    Object.defineProperty(SensorDigitalOpenClosed.prototype, "openText", {
        get: function () {
            return this._openText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorDigitalOpenClosed.prototype, "closedText", {
        get: function () {
            return this._closedText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorDigitalOpenClosed.prototype, "pollingRate", {
        get: function () {
            return this._pollingRate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorDigitalOpenClosed.prototype, "cloudStorageRate", {
        get: function () {
            return this._cloudStorageRate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorDigitalOpenClosed.prototype, "openTextDescription", {
        get: function () {
            return this._openTextDescription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorDigitalOpenClosed.prototype, "closedTextDescription", {
        get: function () {
            return this._closedTextDescription;
        },
        enumerable: true,
        configurable: true
    });
    SensorDigitalOpenClosed.prototype.toJSON = function (sensorDigitalOpenClosedDTO) {
        return {
            channel: sensorDigitalOpenClosedDTO.channel,
            name: sensorDigitalOpenClosedDTO.name,
            categoryId: sensorDigitalOpenClosedDTO.category,
            inputType: sensorDigitalOpenClosedDTO.inputType,
            sensorTypeId: sensorDigitalOpenClosedDTO.sensorType,
            openText: sensorDigitalOpenClosedDTO.openText,
            openTextDescription: sensorDigitalOpenClosedDTO.openTextDescription,
            closedText: sensorDigitalOpenClosedDTO.closedText,
            closedTextDescription: sensorDigitalOpenClosedDTO.closedTextDescription,
            pollingRate: sensorDigitalOpenClosedDTO.pollingRate,
            cloudStorageRate: sensorDigitalOpenClosedDTO.cloudStorageRate,
        };
    };
    return SensorDigitalOpenClosed;
}(Sensor));
export { SensorDigitalOpenClosed };
