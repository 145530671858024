import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dnd-item',
  templateUrl: './dnd-item.component.html',
  styleUrls: ['./dnd-item.component.scss']
})
export class DnDItemComponent implements OnInit {
  
  @Input() public item;
  @Input() public item2;

  @Input() public width;
  
  constructor() { }

  ngOnInit() {
  }

}
