import { MatDialogRef } from '@angular/material';
var ConfirmationDialogComponent = /** @class */ (function () {
    function ConfirmationDialogComponent(dialogRef, data) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.textContent = data.textContent;
        this.confirmText = data.confirmText ? data.confirmText : 'OK';
        this.cancelText = data.cancelText ? data.cancelText : 'Cancel';
    }
    ConfirmationDialogComponent.prototype.confirm = function () {
        this.dialogRef.close(true);
    };
    ConfirmationDialogComponent.prototype.cancel = function () {
        this.dialogRef.close(false);
    };
    ConfirmationDialogComponent.prototype.keyPressed = function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.confirm();
        }
    };
    return ConfirmationDialogComponent;
}());
export { ConfirmationDialogComponent };
