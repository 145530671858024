
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map as m} from 'lodash';
import {View} from '../../../domain/view/view';
import {Views} from './views';
import {ViewDetailStatusOverview} from '../../../domain/view/viewDetailStatusOverview';
import {Moment} from 'moment';
import {DetailStatusOverview} from "../../../domain/monitor/sensor/DetailStatusOverview";

@Injectable()
export class HttpViewsService implements Views {
    private http: HttpClient;

    constructor(http: HttpClient) {
        this.http = http;
    }

    public getAll(): Observable<View[]> {
        return null
    }

    public getAllForStatusOverview(): Observable<View[]> {
        return this.http.get('/api/sensorsviews/status').pipe(
            map((response: any) => {
                return response.map(View.fromJSON);
            }));
    }

    public add(view: View): Observable<string> {
        const sensorIds: string[] = m(view.sensors, 'id');

        return this.http.post('/api/sensorsviews', {
            name: view.name,
            elucidation: view.elucidation,
            sensorIds: sensorIds
        }, {responseType: 'text'});
    }

    public get(id: string): Observable<View> {
        return this.http.get(`/api/sensorsviews/${id}`).pipe(
            map((response: any) => {
                return View.fromJSON(response);
            }));
    }

    public edit(view: View): Observable<string> {
        const sensorIds: string[] = m(view.sensors, 'id');

        return this.http.put(`/api/sensorsviews/${view.id}`, {
            name: view.name,
            elucidation: view.elucidation,
            sensorIds: sensorIds
        }, {responseType: 'text'});
    }

    public deleteView(id: string): Observable<string> {
        return this.http.delete(`/api/sensorsviews/${id}`, {responseType: 'text'});
    }

    private getViewMeasurements(id: string, from: Moment, to: Moment): Observable<any> {
        const fromString = encodeURIComponent(from.toISOString());
        const toString = encodeURIComponent(to.toISOString());

        return this.http.get(`/api/sensorsviews/${id}/measurements?from=${fromString}&to=${toString}`);
    }

    /**
     * Returns either a new detail status overview, or updates the measurements if one is passed into the function.
     *
     * Always provides a copy, and does not modify in-place.
     * @param id
     * @param selectedDateRange
     * @param detailStatusOverview
     */
    public getForDetailOverview(id: string, selectedDateRange: { start: Moment, end: Moment }, detailStatusOverview?: View): Observable<DetailStatusOverview> {
        // See bug 595
        if (detailStatusOverview) {
            return this.getViewMeasurements(id, selectedDateRange.start, selectedDateRange.end).pipe(
                map(results => {
                    return ViewDetailStatusOverview.detailFromJSON(detailStatusOverview, results);
                }));
        } else {
            return observableForkJoin([
                this.getSingleViewForStatusOverview(id),
                this.getViewMeasurements(id, selectedDateRange.start, selectedDateRange.end)
            ]).pipe(map((responses: any) => {
                return ViewDetailStatusOverview.detailFromJSON(responses[0], responses[1]);
            }));
        }
    }

    public getSingleViewForStatusOverview(id: string): Observable<View> {
        return this.http.get(`/api/sensorsviews/${id}/status`).pipe(
            map((response: any) => {
                return View.fromJSON(response);
            }));
    }

    public setChangedOrder(id: string, order: { [key: string]: number }): Observable<View> {
        return this.http.put(`/api/sensorsviews/${id}/order`, {order}).pipe(
            map((response: any) => {
                return View.fromJSON(response);
            }));
    }
}
