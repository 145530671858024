import { Location } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../infrastructure/http/authentication/authentication.service';
import { NavigationService } from '../../../infrastructure/navigation/navigation.service';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var NavigationComponent = /** @class */ (function () {
    function NavigationComponent(navigationService, authenticationService, location, userService) {
        var _this = this;
        this.menuItemChosen = new EventEmitter();
        this.pages = navigationService.getMenuPages();
        this.authenticationService = authenticationService;
        this.location = location;
        userService.getNotifications().subscribe(function (notes) { return _this.notNumber = notes.length; });
        this.setAuthenticatedUser();
    }
    NavigationComponent.prototype.isActive = function (path) {
        return this.location.path().includes(path);
    };
    NavigationComponent.prototype.logout = function () {
        this.authenticationService.logout();
    };
    NavigationComponent.prototype.chooseMenuItem = function () {
        this.menuItemChosen.emit(true);
    };
    NavigationComponent.prototype.setAuthenticatedUser = function () {
        var _this = this;
        if (this.authenticationService.isAuthenticated()) {
            this.authenticationService.getAuthenticatedUser().subscribe(function (user) {
                _this.authenticatedUser = user;
            });
        }
    };
    return NavigationComponent;
}());
export { NavigationComponent };
