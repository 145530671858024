import { timer as observableTimer, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil, distinctUntilChanged, take } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute } from '@angular/router';
import { each } from 'lodash';
import { environment } from '../../../../../environments/environment';
import { HttpMonitorsService } from 'app/infrastructure/http/monitor/http-monitors.service';
var ManageMonitorsComponent = /** @class */ (function () {
    function ManageMonitorsComponent(route, media, monitorsService) {
        this.monitorsService = monitorsService;
        this.subPages = [];
        this.monitors = [];
        this.isLoading = true;
        this.gridMode = false;
        this.unSubscribeMonitorsOnDestroy = new Subject();
        this.unSubscribeTimer = new Subject();
        this.subPages = route.snapshot.data.parentPage.subPages;
        this.media = media;
    }
    ManageMonitorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateGridParametersByMediaChanged();
        this.mediaWatcher = this.media.asObservable().subscribe(function () {
            _this.updateGridParametersByMediaChanged();
        });
        this.monitorsService.getAllForStatusOverview().pipe(take(1), takeUntil(this.unSubscribeMonitorsOnDestroy))
            .pipe(distinctUntilChanged(function (a, b) { return a.length != b.length; }))
            .subscribe(function (monitors) {
            _this.unSubscribeTimer.next(true);
            _this.monitors = monitors;
            each(_this.monitors, function (monitor) {
                observableTimer(0, environment.pollingIntervalInSeconds * 1000).pipe(switchMap(function () { return _this.monitorsService.getFirmwareUpgradeStatus(monitor.id); }), takeUntil(_this.unSubscribeTimer))
                    .subscribe(function (status) {
                    monitor.firmwareUpgradeStatus = status;
                });
            });
            _this.isLoading = false;
        });
    };
    ManageMonitorsComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeTimer.next(true);
        this.unSubscribeTimer.complete();
        this.unSubscribeMonitorsOnDestroy.next(true);
        this.unSubscribeMonitorsOnDestroy.complete();
    };
    ManageMonitorsComponent.prototype.isDesktopView = function () {
        return this.gridMode;
    };
    ManageMonitorsComponent.prototype.updateGridParametersByMediaChanged = function () {
        this.gridMode = this.media.isActive('gt-sm');
    };
    return ManageMonitorsComponent;
}());
export { ManageMonitorsComponent };
