
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatChipInputEvent, MAT_DIALOG_DATA } from '@angular/material';
import {COMMA, ENTER, TAB} from "@angular/cdk/keycodes";
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  removable=true;
  model = {
    emails: [],
    role: "VIEWER",
    text:""
  }
  loading = false;

  public showError = false;
  @ViewChild("shareForm", {static: false}) form;
  

  constructor(private dialogRef: MatDialogRef<RequestComponent>, @Inject(MAT_DIALOG_DATA) private monitorId: {id: string}, private monitorService: MonitorDataService ) {
   }

  ngOnInit() {
  }
  
  submit() {
    this.loading = true;
    this.monitorService.sendShareRequest(this.monitorId.id, this.model.role).subscribe(() => {
        this.loading = false;
        return this.dialogRef.close();
      }, () => this.dialogRef.close()
    )
  }

  removeStyle(obj) {
    obj.style.color = "";
    this.showError = false;
  }
}

