
import {takeUntil} from 'rxjs/operators';
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Page} from '../../../../domain/page';
import {Subject} from 'rxjs';
import {Monitor} from '../../../../domain/monitor/monitor';
import MonitorChannel from '../../../../domain/monitor/monitorChannel';
import {HttpMonitorsService} from '../../../../infrastructure/http/monitor/http-monitors.service';
import {MessageStyle} from "../../../../domain/messageStyle";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
  selector: 'app-monitor-add',
  templateUrl: './monitor-add.component.html',
  styleUrls: ['./monitor-add.component.scss']
})
export class MonitorAddComponent implements OnInit {
  public monitor: Monitor;
  public showStepOne = true;
  public showStepTwo = false;
  public showStepThree = false;
  public isLoading = false;
  public editMode = false;
  public validateHttpError: string;
  public hasHttpError = false;
  public readonly MESSAGE_STYLE = MessageStyle;

  public subPages: Page[] = [];
  private route: ActivatedRoute;
  private unSubscribeMonitorAddComponent: Subject<boolean> = new Subject<boolean>();
  private httpMonitorService: HttpMonitorsService;

  /**
   * Checks whether the the given MonitorChannel array has all FIXED channels
   * @param {MonitorChannel[]} channels
   * @returns {boolean}
   */
  static hasAllFixedChannels(channels: MonitorChannel[]): boolean {
    return channels.filter((channel) =>
      (channel === MonitorChannel.FIXED)).length === channels.length;
  }

  constructor(route: ActivatedRoute,
              httpMonitorsService: MonitorDataService) {
    this.route = route;
    this.httpMonitorService = httpMonitorsService;
  }

  ngOnInit(): void {
    this.route.data.pipe(
      takeUntil(this.unSubscribeMonitorAddComponent))
      .subscribe((data) => {
        this.subPages = data.parentPage.subPages;
      });

    this.route.params.pipe(
      takeUntil(this.unSubscribeMonitorAddComponent))
      .subscribe((params) => {
        if (params.id) {
          this.isLoading = true;
          this.editMode = true;
          this.hasHttpError = false;

          this.httpMonitorService.singleMonitor(params.id).pipe(
            takeUntil(this.unSubscribeMonitorAddComponent))
            .subscribe((monitor) => {
              this.showStepOne = false;
              this.showStepTwo = true;
              this.isLoading = false;

              this.monitor = monitor;
            }, (error) => {
              this.isLoading = false;
              this.hasHttpError = true;

              this.validateHttpError = error.error.error;
            });
        }
      });
  }

  public canShowStepOne(): boolean {
    return this.showStepOne;
  }

  public hasCompletedStepOne(monitor: Monitor): void {
    this.monitor = monitor;

    this.showStepOne = false;
    this.showStepTwo = true;
  }

  public canShowToStepTwo(): boolean {
    return this.showStepTwo;
  }

  public hasCompletedStepTwo(event: { monitor: Monitor, canNavigateToStepThree: boolean }): void {
    this.monitor = event.monitor;

    this.showStepOne = !event.canNavigateToStepThree;
    this.showStepTwo = false;
    this.showStepThree = event.canNavigateToStepThree;
  }

  public canShowStepThree(): boolean {
    return this.showStepThree;
  }

  public hasCompletedStepThree(canShowStepThree: boolean): void {
    this.showStepTwo = true;
    this.showStepThree = canShowStepThree;
  }
}
