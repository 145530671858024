<app-registration-content>

  <div fxLayout="column" fxLayoutGap="40px" *ngIf="!requestedPasswordReset">
    <form class="reset__content antialiased" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm.value, resetPasswordForm.valid)">
      <div fxLayout="column">
        <div class="input-row header">
          <p>{{'PASSWORD_RESET.RESET' | translate}}</p>
        </div>
        <div class="input-row hint">
          <p>{{'PASSWORD_RESET.HINT' | translate}}</p>
        </div>

        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
          <!-- Password -->
          <mat-form-field fxFlex>
            <!--Required error -->
            <mat-error *ngIf="resetPasswordForm.get('password').errors?.required">
              {{'REGISTER.ERROR_PASSWORD' | translate}}
            </mat-error>

            <!-- MinLength error -->
            <mat-error *ngIf="resetPasswordForm.get('password').errors?.minlength">
              {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'PASSWORD' | translate, min: 6} }}
            </mat-error>

            <input matInput
                   type="password"
                   placeholder="{{'PASSWORD' | translate}}" formControlName="password">
          </mat-form-field>

          <!-- Repeat Password -->
          <mat-form-field fxFlex>
            <!--Required error -->
            <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').errors?.required">
              {{'REGISTER.ERROR_PASSWORD_REPEAT' | translate}}
            </mat-error>

            <!-- MinLength error -->
            <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').errors?.minlength">
              {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'PASSWORD' | translate, min: 6} }}
            </mat-error>

            <!-- Match error -->
            <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').errors?.matchPassword">
              {{'REGISTER.ERROR_PASSWORDS_NOT_MATCHING' | translate}}
            </mat-error>

            <input matInput
                   type="password"
                   placeholder="{{'REGISTER.ERROR_PASSWORD_REPEAT' | translate}}" formControlName="passwordConfirm">
          </mat-form-field>
        </div>

        <div class="input-row submit">
          <button fxFill mat-raised-button color="primary" type="submit" [disabled]="!canResetPassword()">
            {{'PASSWORD_RESET.RESET' | translate}}
          </button>
        </div>
      </div>

    </form>
    <div class="link-action" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch">
      <p class="antialiased">
        {{'REGISTER.ALREADY_HAVE_ACCOUNT' | translate}}
      </p>
      <p>
        <a mat-raised-button color="primary" routerLink="/login">{{'LOGIN.LOGIN' | translate}}</a>
      </p>
    </div>


  </div>


  <div *ngIf="requestedPasswordReset" class="reset__content reset">
    <div class="input-row">
      <h2>{{ 'PASSWORD_RESET.RESET_COMPLETED' | translate }}</h2>
      <a fxFill mat-button routerLink="/login">{{ 'VERIFY.GO_BACK_LOGIN' | translate }}</a>
    </div>
  </div>
</app-registration-content>
