<div fxFlex>
  <h1>{{ 'INFO.TITLE' | translate }}</h1>
  <mat-card *ngIf="environmentInformation">
    <h2>{{ 'INFO.CLIENT' | translate }}:</h2>
    <mat-card-content>
      <p>
        <b>{{ 'INFO.VERSION' | translate }}:</b> {{environmentInformation.clientEnvironmentInformation.version}}
      </p>
    </mat-card-content>

    <h2>{{ 'INFO.SERVER' | translate }}:</h2>
    <mat-card-content>
      <p>
        <b>{{ 'INFO.VERSION' | translate }}:</b> {{environmentInformation.backendEnvironmentInformation.version}}
      </p>
    </mat-card-content>

    <h2>{{ 'INFO.IOT.TITLE' | translate }}:</h2>
    <mat-card-content>
      <p>
        <b>{{ 'INFO.IOT.BACKEND' | translate }}:</b> {{environmentInformation.backendEnvironmentInformation['iot-backend']}}
      </p>
      <p>
        <b>{{ 'INFO.IOT.STATUS.TITLE' | translate }}:</b>
        <span class="status"
              [ngClass]="{'up': environmentInformation.backendHealth.status === 'UP', 'down' : environmentInformation.backendHealth.status === 'DOWN'}">
          {{ 'INFO.IOT.STATUS.'+environmentInformation.backendHealth.status | translate }}
        </span>
      </p>
    </mat-card-content>
  </mat-card>
</div>
