<mat-card *ngIf="userRoleBindings && userRoleBindings.length > 0">
    <mat-card-header>
        <mat-card-title
                class="antialiased" fxLayout.lt-md="column" fxLayout="row" fxLayout.xs="row"
                fxLayoutAlign="start start">
            <h3 fxFlex>{{ 'MONITOR.USERS' | translate }}</h3>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">
      <div *ngFor="let user of userRoleBindings" fxLayout="row" class="userelement">
        <div fxLayout="row">        
          <mat-icon class="user-account__icon" svgIcon="user-account"></mat-icon>
          <p *ngIf='user.user.user'>{{user.user.user.name}}</p>
          <p *ngIf='!user.user.user'>3rd Party User</p>
        </div>
        <div fxLayout="row">        
          <p>{{user.role}}</p>
          <mat-icon class="close__icon" svgIcon="close" *ngIf="canDelete(user)" (click)="delete(user)"></mat-icon>
        </div>

      </div>
    </mat-card-content>
</mat-card>