import { isNil } from 'lodash';
import FirmwareUpgradeState from '../../../../domain/monitor/firmware/firmware-upgrade-state';
import { Monitor } from '../../../../domain/monitor/monitor';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var GridMonitorSoftwareComponent = /** @class */ (function () {
    function GridMonitorSoftwareComponent(monitors) {
        this.monitors = monitors;
    }
    GridMonitorSoftwareComponent.prototype.agInit = function (params) {
        this.monitor = params.data;
    };
    GridMonitorSoftwareComponent.prototype.isFirmwareInfoAvailable = function () {
        return !isNil(this.monitor.firmwareUpgradeStatus);
    };
    GridMonitorSoftwareComponent.prototype.isFirmwareUpgradeAvailable = function () {
        return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Available;
    };
    GridMonitorSoftwareComponent.prototype.isFirmwareUpToDate = function () {
        return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Completed;
    };
    GridMonitorSoftwareComponent.prototype.isFirmwareUpgradePlanned = function () {
        return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Planned;
    };
    GridMonitorSoftwareComponent.prototype.isFirmwareUpgradeBusy = function () {
        return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Busy;
    };
    GridMonitorSoftwareComponent.prototype.isFirmwareUnknown = function () {
        return this.monitor.firmwareUpgradeStatus.state === FirmwareUpgradeState.Unknown;
    };
    GridMonitorSoftwareComponent.prototype.requestFirmwareUpgrade = function () {
        var _this = this;
        this.requestInProgress = true;
        this.monitors.requestFirmwareUpgrade(this.monitor.id).subscribe(function (status) {
            _this.monitor.firmwareUpgradeStatus = status;
            _this.requestInProgress = false;
        });
    };
    GridMonitorSoftwareComponent.prototype.canUpgradeFirmware = function () {
        return this.isFirmwareUpgradeAvailable() && this.monitor.editableByCurrentUser;
    };
    return GridMonitorSoftwareComponent;
}());
export { GridMonitorSoftwareComponent };
