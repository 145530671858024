import { Directive, ElementRef, HostListener, Input, OnInit, AfterContentInit} from '@angular/core';
import { TruncateDigitsPipe } from 'app/application/truncate-digits/truncate-digits.pipe';


@Directive({
    selector: '[inputValToDecimal]',
})
export class valueToDecimalDirective implements OnInit, AfterContentInit{
    @Input()
    inputValToDecimal: number;
    @Input() round: number=2;
    constructor(private el: ElementRef,
        private pip: TruncateDigitsPipe) { }

    ngOnInit() {
        this.onBlur();
    }

    ngAfterContentInit() {
        this.onChange();
    }
    
    @HostListener('blur', ['$event.target.value']) onBlur() {
        this.el.nativeElement.value = this.pip.transform(this.el.nativeElement.value,this.round);
    }

    @HostListener('change', ['$event.target.value']) onChange() {
        this.el.nativeElement.value = this.pip.transform(this.el.nativeElement.value,this.round);
    }
}

