var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Sensor } from './sensor';
var SensorAuto = /** @class */ (function (_super) {
    __extends(SensorAuto, _super);
    function SensorAuto(channel, name, category, inputType, sensorType, unit, pollingRate, measurementMinimum, measurementMaximum, valueMinimum, valueMaximum, cloudStorageRate) {
        var _this = _super.call(this, channel, name, category, inputType, sensorType) || this;
        _this._unit = unit;
        _this._pollingRate = pollingRate;
        _this._cloudStorageRate = cloudStorageRate;
        _this._measurementMinimum = measurementMinimum;
        _this._measurementMaximum = measurementMaximum;
        _this._valueMinimum = valueMinimum;
        _this._valueMaximum = valueMaximum;
        return _this;
    }
    SensorAuto.prototype.toJSON = function (sensorAuto) {
        return {
            channel: sensorAuto.channel,
            name: sensorAuto.name,
            categoryId: sensorAuto.category,
            inputType: sensorAuto.inputType,
            sensorTypeId: sensorAuto.sensorType,
            unitId: sensorAuto.unitId,
            measurementMinimum: sensorAuto.measurementMinimum,
            measurementMaximum: sensorAuto.measurementMaximum,
            valueMinimum: sensorAuto.valueMinimum,
            valueMaximum: sensorAuto.valueMaximum,
            pollingRate: sensorAuto.pollingRate,
            cloudStorageRate: sensorAuto.cloudStorageRate
        };
    };
    Object.defineProperty(SensorAuto.prototype, "unit", {
        get: function () {
            return this._unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAuto.prototype, "pollingRate", {
        get: function () {
            return this._pollingRate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAuto.prototype, "cloudStorageRate", {
        get: function () {
            return this._cloudStorageRate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAuto.prototype, "measurementMinimum", {
        get: function () {
            return this._measurementMinimum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAuto.prototype, "measurementMaximum", {
        get: function () {
            return this._measurementMaximum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAuto.prototype, "valueMinimum", {
        get: function () {
            return this._valueMinimum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorAuto.prototype, "valueMaximum", {
        get: function () {
            return this._valueMaximum;
        },
        enumerable: true,
        configurable: true
    });
    return SensorAuto;
}(Sensor));
export { SensorAuto };
