import MonitorInputType from '../../monitorInputType';
import {SensorPredefinedListAnalog} from './sensor-predefined-list-analog';
import {SensorPredefinedListDigitalPulse} from './sensor-predefined-list-digital-pulse';

export class SensorPredefinedList {
  private _id: string;
  private _name: string;
  private _inputType: MonitorInputType;
  private _categoryId: string;
  private _sensorTypeId: string;
  private _unitId: string;
  private _parameters: SensorPredefinedListAnalog | SensorPredefinedListDigitalPulse;

  public static fromJSON(sensorPredefinedDTO: any) {
    return new SensorPredefinedList(
      sensorPredefinedDTO.id,
      sensorPredefinedDTO.name,
      sensorPredefinedDTO.inputType,
      sensorPredefinedDTO.categoryId,
      sensorPredefinedDTO.sensorTypeId,
      sensorPredefinedDTO.unitId,
      sensorPredefinedDTO.parameters
    );
  }

  constructor(id: string,
              name: string,
              inputType: MonitorInputType,
              categoryId: string,
              sensorTypeId: string,
              unitId: string,
              parameters: SensorPredefinedListAnalog | SensorPredefinedListDigitalPulse) {
    this._id = id;
    this._name = name;
    this._inputType = inputType;
    this._categoryId = categoryId;
    this._sensorTypeId = sensorTypeId;
    this._unitId = unitId;
    this._parameters = parameters;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get inputType(): MonitorInputType {
    return this._inputType;
  }

  get categoryId(): string {
    return this._categoryId;
  }

  get sensorTypeId(): string {
    return this._sensorTypeId;
  }

  get unitId(): string {
    return this._unitId;
  }

  get parameters(): SensorPredefinedListAnalog | SensorPredefinedListDigitalPulse {
    return this._parameters;
  }
}
