import MonitorInputType from '../monitorInputType';

export class SensorPredefined {
  private _channel: number;
  private _name: string;
  private _predefinedSensorId: string;
  private _inputType: MonitorInputType;

  constructor(channel: number,
              name: string,
              predefinedSensorId: string,
              inputType: MonitorInputType) {
    this._channel = channel;
    this._name = name;
    this._predefinedSensorId = predefinedSensorId;
    this._inputType = inputType;
  }

  public toJSON(sensorPredefinedDTO: any) {
    return {
      channel: sensorPredefinedDTO.channel,
      name: sensorPredefinedDTO.name,
      inputType: sensorPredefinedDTO.inputType,
      predefinedSensorId: sensorPredefinedDTO.predefinedSensorId
    }
  }

  get channel(): number {
    return this._channel;
  }

  get name(): string {
    return this._name;
  }

  get predefinedSensorId(): string {
    return this._predefinedSensorId;
  }

  get inputType(): MonitorInputType {
    return this._inputType;
  }
}
