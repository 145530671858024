export class SensorLimit {
  private _datetime: Date;
  private _value: number;

  public static fromJSON(sensorLimitDTO: any) {
    return new SensorLimit(new Date(sensorLimitDTO.datetime), sensorLimitDTO.value);
  }

  constructor(datetime: Date, value: number) {
    this._datetime = datetime;
    this._value = value;
  }

  get datetime(): Date {
    return this._datetime;
  }

  get value(): number {
    return this._value;
  }
}
