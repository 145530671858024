import {Component, ElementRef} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular/main';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  templateUrl: 'grid-header-name.component.html'
})
export class GridHeaderNameComponent implements IHeaderAngularComp {
  public params: IHeaderParams;
  public sorted: string;
  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }

  onOwnSortRequested(event) {
    switch (this.sorted) {
      case '':
        this.params.setSort('asc', event.shiftKey);
        break;
      case 'asc':
        this.params.setSort('desc', event.shiftKey);
        break;
      case 'desc':
        this.params.setSort('', event.shiftKey);
    }
  }

  onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sorted = 'asc'
    } else if (this.params.column.isSortDescending()) {
      this.sorted = 'desc'
    } else {
      this.sorted = ''
    }
  };
}
