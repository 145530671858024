import { MatDialog } from "@angular/material";
import { ConfirmationDialogComponent } from "../../../pages/confirmation-dialog/confirmation-dialog.component";
import { Router } from "@angular/router";
import { NotificationService } from "../../../../application/notification/notification.service";
var GridDeleteViewComponent = /** @class */ (function () {
    function GridDeleteViewComponent(viewsService, dialog, router, notificationService) {
        this.viewsService = viewsService;
        this.dialog = dialog;
        this.router = router;
        this.notificationService = notificationService;
        this.dialogConfig = {
            panelClass: 'confirmation-dialog'
        };
    }
    GridDeleteViewComponent.prototype.deleteView = function () {
        var _this = this;
        var currentDialogConfig = {
            panelClass: this.dialogConfig.panelClass,
            data: {
                textContent: 'VIEW.DELETE_VIEW_QUESTION',
                confirmText: 'GENERAL.OK',
                cancelText: 'GENERAL.CANCEL'
            }
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === true) {
                _this.viewsService.deleteView(_this.view.id)
                    .subscribe(function () {
                    _this.notificationService.show('VIEW.DELETED_CONFIRMATION');
                    _this.router.navigateByUrl('/playgrounds');
                });
            }
        });
    };
    GridDeleteViewComponent.prototype.agInit = function (params) {
        this.view = params.data;
    };
    return GridDeleteViewComponent;
}());
export { GridDeleteViewComponent };
