import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { Chat } from 'app/domain/Chat';
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { User } from 'app/domain/user';
import { AuthenticationService } from 'app/infrastructure/http/authentication/authentication.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-details-chat',
  templateUrl: './details-chat.component.html',
  styleUrls: ['./details-chat.component.scss']
})
export class DetailsChatComponent implements OnInit, AfterViewInit {

  @Input("monitor") monitor: MonitorStatusOverview;
  messages: Chat[] = [];

  user: User;

  chatFormControl = new FormControl("",
    [Validators.maxLength(300)]);



  @ViewChild("element", {static: false}) element: ElementRef;
  constructor(private monitorsService: MonitorDataService, private userSerivce: AuthenticationService) { }

  ngAfterViewInit() {
    console.log(this.element)
    this.element.nativeElement.parentNode.scrollTop = this.element.nativeElement.offsetTop;
  }

  ngOnInit() {
    this.monitorsService.getChatForMonitor(this.monitor.id).subscribe(mons => this.messages = (mons||[]));
    this.userSerivce.getAuthenticatedUser().subscribe(user => this.user = user);

  }

  isYours(message: Chat) {
    return message.sourceemail && this.user && this.user.email == message.sourceemail;
  }

  sendMessage(text) {
    return this.monitorsService.postChatForMonitor(this.monitor.id, text.value).subscribe(() => {
      text.value = "";
    });
  }

  textTooLong(text) {
    return text.value.length > 300
  }

}
