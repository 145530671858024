var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from "rxjs/operators";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { AuthenticationService } from 'app/infrastructure/http/authentication/authentication.service';
var MonitorsComponent = /** @class */ (function () {
    function MonitorsComponent(monitorsService, route, cdr, auth) {
        this.cdr = cdr;
        this.auth = auth;
        this.subPages = [];
        this.isLoading = true;
        this.monitorsService = monitorsService;
        this.subPages = route.snapshot.data.subPages;
    }
    MonitorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var tapFunction = tap(function () { return setTimeout(function () { _this.isLoading = false; _this.detectChanges(); }, 1); });
        this.subscription = this.monitorsService.getAllForStatusOverview()
            .pipe(tapFunction)
            .subscribe(function (monitors) { _this.monitors = monitors; _this.detectChanges(); });
    };
    MonitorsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    MonitorsComponent.prototype.fetchDetails = function (monitor) {
        var details = __assign({}, monitor.details, { info: monitor.monitorType, serialNumber: monitor.serialNumber });
        return details;
    };
    MonitorsComponent.prototype.detectChanges = function () {
        if (this.cdr && !this.cdr.destroyed) {
            this.cdr.detectChanges();
        }
    };
    return MonitorsComponent;
}());
export { MonitorsComponent };
