var Page = /** @class */ (function () {
    function Page(name, icon, path, component, isDefault, showInMenu, securedPage, subPages, matcher) {
        this._name = name;
        this._icon = icon;
        this._path = path;
        this._component = component;
        this._isDefault = isDefault;
        this._showInMenu = showInMenu;
        this._securedPage = securedPage;
        this._subPages = subPages;
        this.matcher = matcher;
    }
    Object.defineProperty(Page.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "icon", {
        get: function () {
            return this._icon;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "path", {
        get: function () {
            return this._path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "component", {
        get: function () {
            return this._component;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "isDefault", {
        get: function () {
            return this._isDefault;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "showInMenu", {
        get: function () {
            return this._showInMenu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "securedPage", {
        get: function () {
            return this._securedPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "subPages", {
        get: function () {
            return this._subPages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "parentPage", {
        get: function () {
            return this._parentPage;
        },
        set: function (value) {
            this._parentPage = value;
        },
        enumerable: true,
        configurable: true
    });
    return Page;
}());
export { Page };
