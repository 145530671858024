var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { includes, isEqual, isNil, isUndefined, size } from 'lodash';
import DeviceStatus from '../../../domain/monitor/deviceStatus';
import { MonitorStatusOverview } from '../../../domain/monitor/monitorStatusOverview';
import SensorStatus from '../../../domain/monitor/sensor/sensor-status';
import { ActivatedRoute } from '@angular/router';
import * as moment from "moment";
import RelayStatus from 'app/domain/monitor/relayStatus';
import { MatDialog } from '@angular/material';
import { ShareComponent } from './share/share.component';
import { MediaObserver } from '@angular/flex-layout';
var OverviewCardComponent = /** @class */ (function () {
    function OverviewCardComponent(activatedRoute, dialog, media) {
        this.activatedRoute = activatedRoute;
        this.dialog = dialog;
        this.media = media;
    }
    OverviewCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.data.subscribe(function (data) {
            _this.isMonitor = data.contains === "monitor";
        });
        this.sensors = this.sensors.sort(function (a, b) { return a.order - b.order; });
    };
    OverviewCardComponent.prototype.canShowIconForSensor = function (sensorState) {
        return includes([
            'OPEN',
            'CLOSED'
        ], sensorState);
    };
    OverviewCardComponent.prototype.getTooltipText = function (sensor) {
        return sensor.valueDescription;
    };
    OverviewCardComponent.prototype.getIconForSensor = function (sensorState) {
        var stateMap = {
            'OPEN': 'sensor-open',
            'CLOSED': 'sensor-closed'
        };
        return stateMap[sensorState];
    };
    OverviewCardComponent.prototype.getClassStatusForDeviceStatus = function (deviceStatus) {
        return (isEqual(deviceStatus, DeviceStatus.Online) || isEqual(deviceStatus, SensorStatus.Ok)) ? 'on' : 'off';
    };
    OverviewCardComponent.prototype.getClassStatusForSensorStatus = function (sensor) {
        return (!sensor.isConnected) ? 'disc' : this.getClassStatusForDeviceStatus(sensor.status);
    };
    OverviewCardComponent.prototype.getClassStatusForRelayStatus = function (relayStatus) {
        relayStatus = relayStatus;
        return relayStatus && (isEqual(relayStatus, RelayStatus.Ok) || isEqual(relayStatus, RelayStatus.Ignored)) ? 'on' : 'off';
    };
    OverviewCardComponent.prototype.getRelayStatusString = function (relayStatus) {
        relayStatus = relayStatus;
        return !relayStatus || isEqual(relayStatus, RelayStatus.Alarm) ? '' : relayStatus.toLowerCase();
    };
    OverviewCardComponent.prototype.getBubbleTooltip = function (sensor) {
        var errors = sensor.errors.join('\n');
        if (size(sensor.errors) > 0) {
            return errors;
        }
        if (!sensor.isConnected) {
            return moment(sensor.lastMeasuredDateTime).fromNow();
        }
        return size(sensor.errors) > 0 ? errors : null;
    };
    OverviewCardComponent.prototype.getErrors = function () {
        return this.monitor.statusInfo.errors.join("; ");
    };
    OverviewCardComponent.prototype.canShowLastMeasuredDateTime = function () {
        return !isNil(this.monitor.lastMeasuredDateTime);
    };
    OverviewCardComponent.prototype.hasSensorValue = function (sensor) {
        return !isUndefined(sensor.value) && !this.canShowIconForSensor(sensor.openClosedStatus);
    };
    OverviewCardComponent.prototype.hasMonitorErrors = function () {
        var errors = this.monitor.statusInfo.errors.join('\n');
        return size(this.monitor.statusInfo.errors) > 0 ? errors : null;
    };
    OverviewCardComponent.prototype.getKind = function () {
        return this.isMonitor ? "monitor" : "view";
    };
    OverviewCardComponent.prototype.showShareScreen = function () {
        this.dialog.open(ShareComponent, __assign({}, this.getShareScreenVals(), { data: this.monitor }));
    };
    OverviewCardComponent.prototype.getShareScreenVals = function () {
        return this.media.isActive('gt-sm') ? { width: "50%", height: "70%" } : { width: "95vw", height: "95vh", 'maxWidth': "95vw!important" };
    };
    return OverviewCardComponent;
}());
export { OverviewCardComponent };
