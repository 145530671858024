
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Inject, OnDestroy, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ReCaptchaComponent} from 'angular2-recaptcha';
import {map} from 'lodash';
import {Subject} from 'rxjs';
import {environment} from '../../../../../environments/environment';

import {validatePasswordMatch} from '../../../../application/validators/validate-password-match';
import JobCategory from '../../../../domain/jobCategory';
import {Users} from '../../../../infrastructure/http/user/users';
import {ErrorTranslator} from '../../../../application/command/error-translator/error-translator';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

export interface UserRegistration {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  jobCategory: string;
  jobTitle: string;
  phone: string;
  agreeToTermsAndConditions: boolean;
}

@Component({
  selector: 'app-user-registration',
  styleUrls: ['./user-registration.component.scss'],
  templateUrl: './user-registration.component.html'
})
export class UserRegistrationComponent implements OnDestroy {
  @Output() public loginRequested: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(ReCaptchaComponent, {static: false}) public captcha: ReCaptchaComponent;

  public jobCategories = JobCategory;
  public keyJobCategories: any[];
  public registerForm: FormGroup;
  public hasRegistered = false;
  public userEmail: string;
  public recaptchaKey: string;

  private formBuilder: FormBuilder;
  private registerPerforming = false;
  private userService: Users;
  private ngUnsubscribe = new Subject<void>();
  private router: Router;
  private recaptchaValid = false;
  private errorTranslator: ErrorTranslator;

  constructor(formBuilder: FormBuilder,
              userService: HttpUsersService,
              router: Router,
              @Inject('ErrorTranslator') errorTranslator: ErrorTranslator) {
    this.formBuilder = formBuilder;
    this.userService = userService;
    this.router = router;
    this.errorTranslator = errorTranslator;


    this.keyJobCategories = map(Object.keys(this.jobCategories), (key) => {
      return this.jobCategories[key].toUpperCase();
    });

    this.registerForm = this.formBuilder.group({
      'firstName': ['', [Validators.required, Validators.maxLength(255)]],
      'lastName': ['', [Validators.required, Validators.maxLength(255)]],
      'email': ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      'password': ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      'passwordConfirm': ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      'jobCategory': ['', Validators.required],
      'jobTitle': ['', Validators.maxLength(255)],
      'phone': ['', Validators.maxLength(255)],
      'agreeToTermsAndConditions': [false, Validators.requiredTrue]
    }, {
      validator: validatePasswordMatch
    });

    this.recaptchaKey = environment.recaptcha_key;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public register(form: UserRegistration, isValid: boolean): void {
    if (isValid) {
      this.registerPerforming = true;
      this.userEmail = this.registerForm.get('email').value;

      this.userService.register(
        form.email,
        form.password,
        form.firstName,
        form.lastName,
        form.phone,
        form.jobCategory,
        form.jobTitle,
        form.agreeToTermsAndConditions).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.hasRegistered = true;
          this.registerForm.reset();
          //Causes errors on reset after completion: see bug 1551
         // this.resetCaptcha();
        }, (error: HttpErrorResponse) => {
          this.registerPerforming = false;
          const interpolateParams = {email: form.email};

          this.errorTranslator.execute(error, interpolateParams).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe((errorMessage: string) => {
              throw new Error(errorMessage);
            });
        });
    }
  }

  public canRegister(): boolean {
    return this.registerForm.valid && !this.registerPerforming && this.recaptchaValid;
  }

  public goToLogin(): void {
    return this.loginRequested.emit(true);
  }

  public handleCorrectCaptcha() {
    this.recaptchaValid = true;
  }

  public resetCaptcha(): void {
    this.captcha.reset();
  }
}
