<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{notification.title}}
    </mat-panel-title>
    <mat-panel-description >

      <!-- This must be visible for "space-between" flexbox rendering. -->
      <div [visible]="notification.kind">{{notification.kind}}</div>

      <a (click)="deleteNotification()" (mouseover)="hover=true" (mouseleave)="hover=false" class="delete">Delete</a>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <p>{{notification.text}}</p>

  <a *ngIf="notification.action" (click)="deleteNotification()" [routerLink]="notification.action">Accept</a>
</mat-expansion-panel>