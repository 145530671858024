import {TruncateDigitsPipe} from '../../../application/truncate-digits/truncate-digits.pipe';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import MonitorInputType from '../../../domain/monitor/monitorInputType';
import {Moment} from "moment";


export abstract class DetailsChartComponent  {
  
  @Input() public isFetchingNewData: boolean;
  @Input() public selectedDateRange: {start: Moment, end: Moment};

  @Output() public resetHasSelectedBetweenTwoDates = new EventEmitter<boolean>();
  
  public translateService: TranslateService;
  public truncateDigitsPipe: TruncateDigitsPipe;
  public amChartsService: AmChartsService;
  public amChart: AmChart;
  public unSubscribeMonitorDetailsChartOnDestroy: Subject<boolean> = new Subject<boolean>();
  
  
  public readonly MONITOR_INPUT_TYPE = MonitorInputType;
  public readonly SCROLL_BAR_HEIGHT = 60;
  public readonly SCROLL_BAR_OFFSET = 50;
  public readonly PREPEND_APPEND_PERIOD = 1800;



  constructor(translateService: TranslateService,
              truncateDigitsPipe: TruncateDigitsPipe,
              amChartsService: AmChartsService) {
    this.translateService = translateService;
    this.truncateDigitsPipe = truncateDigitsPipe;
    this.amChartsService = amChartsService;
  }

  ngOnInit() {
  }
  
  public ngAfterViewInit() {
    this.makeChart(true);
  }
  
   public ngOnDestroy() {
    this.unSubscribeMonitorDetailsChartOnDestroy.next(true);
    this.unSubscribeMonitorDetailsChartOnDestroy.complete();
    this.destroyChart();
  }
  
  public destroyChart() {
    if (this.amChart) {
      this.amChartsService.destroyChart(this.amChart);
      this.amChart = null;
    }
  }
     
  public abstract ngOnChanges(changes: SimpleChanges);
  public abstract makeChart(zoom: boolean);
  public abstract addChart();

}
