import { takeUntil } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoggerService } from '../../../application/logger/logger.service';
import { InfoService } from '../../../infrastructure/http/info/info.service';
var InfoComponent = /** @class */ (function () {
    function InfoComponent(infoService, logger) {
        this.ngUnsubscribe = new Subject();
        this.infoService = infoService;
        this.logger = logger;
    }
    InfoComponent.prototype.ngOnInit = function () {
        this.getEnvironmentInformation();
    };
    InfoComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    InfoComponent.prototype.getEnvironmentInformation = function () {
        var _this = this;
        this.infoService.getEnvironmentInformationAndHealthStatus().pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (environmentInformation) {
            _this.environmentInformation = environmentInformation;
        }, function (error) {
            _this.logger.error(error);
        });
    };
    return InfoComponent;
}());
export { InfoComponent };
