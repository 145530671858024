import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var ManageMonitorsMobileComponent = /** @class */ (function () {
    function ManageMonitorsMobileComponent(monitorsService) {
        this.monitors = [];
        this.monitorsService = monitorsService;
    }
    ManageMonitorsMobileComponent.prototype.canEditMonitor = function (monitor) {
        return monitor.editableByCurrentUser;
    };
    return ManageMonitorsMobileComponent;
}());
export { ManageMonitorsMobileComponent };
