var SensorCategory = /** @class */ (function () {
    function SensorCategory(id, name, types) {
        this._id = id;
        this._name = name;
        this._types = types;
    }
    SensorCategory.fromJSON = function (categoryDTO) {
        return new SensorCategory(categoryDTO.id, categoryDTO.name, categoryDTO.types);
    };
    Object.defineProperty(SensorCategory.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorCategory.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorCategory.prototype, "types", {
        get: function () {
            return this._types;
        },
        enumerable: true,
        configurable: true
    });
    return SensorCategory;
}());
export { SensorCategory };
