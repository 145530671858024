import { InjectionToken } from '@angular/core';
import { Page } from './domain/page';
import { DetailsComponent } from './presentation/components/details/details.component';
import { InfoComponent } from './presentation/pages/info/info.component';
import { LoginComponent } from './presentation/pages/login/login.component';
import { PrivacyStatementComponent } from './presentation/pages/login/privacy/privacy-statement.component';
import { RequestResetPasswordComponent } from './presentation/pages/login/request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './presentation/pages/login/reset-password/reset-password.component';
import { ManageMonitorsComponent } from './presentation/pages/monitors/manage-monitors/manage-monitors.component';
import { MonitorAddComponent } from './presentation/pages/monitors/monitor-add/monitor-add.component';
import { MonitorsComponent } from './presentation/pages/monitors/monitors.component';
import { ResetOwnPasswordComponent } from './presentation/pages/user-profile/reset-own-password/reset-own-password.component';
import { UserProfileComponent } from './presentation/pages/user-profile/user-profile.component';
import { VerifyComponent } from './presentation/pages/verify/verify.component';
import { ManageViewsComponent } from './presentation/pages/views/manage-views/manage-views.component';
import { ViewAddComponent } from './presentation/pages/views/view-add/view-add.component';
import { ViewsComponent } from './presentation/pages/views/views.component';
import { MonitorAddRedirectComponent } from './presentation/pages/monitors/monitor-add-redirect/monitor-add-redirect.component';
import { NotificationsComponent } from './presentation/pages/user-profile/notifications/notifications.component';
import { ActionComponent } from './presentation/pages/actions/action/action.component';
export var APP_CONFIG = new InjectionToken('app.config');
var loginPage = new Page('Login', 'login', 'login', LoginComponent, true, false, false, []);
var requestResetPasswordPage = new Page('Reset password', 'request-reset-pasword', 'request-reset-password', RequestResetPasswordComponent, false, false, false, []);
var resetPasswordPage = new Page('Reset password', 'reset-pasword', 'reset-password', ResetPasswordComponent, false, false, false, []);
var addView = new Page('Add playground', 'view-add', 'playgrounds/add', ViewAddComponent, false, false, true, []);
var editView = new Page('Edit playground', 'view', 'playgrounds/:id/edit', ViewAddComponent, false, false, true, []);
var manageViews = new Page('Manage playgrounds', 'view-manage', 'playgrounds/manage', ManageViewsComponent, false, false, true, []);
var viewOverview = new Page('Playgrounds', 'view', 'playgrounds', ViewsComponent, false, true, true, [addView, manageViews]);
var viewDetail = new Page('Detail playground', 'view', 'playgrounds/:id/detail', DetailsComponent, false, false, true, []);
addView.parentPage = viewOverview;
editView.parentPage = viewOverview;
manageViews.parentPage = viewOverview;
viewDetail.parentPage = viewOverview;
var addMonitor = new Page('Add Risy-monitor', 'monitor-add', 'monitor/add', MonitorAddComponent, false, false, true, []);
var addMonitorRedirect = new Page('Add Risy-monitor', 'monitor-add', 'monitor/add', MonitorAddRedirectComponent, false, false, true, []);
var editMonitor = new Page('Edit Risy-monitor', 'view', 'monitors/:id/edit', MonitorAddComponent, false, false, true, []);
var manageMonitors = new Page('Manage Risy-monitors', 'monitor-manage', 'monitors/manage', ManageMonitorsComponent, false, false, true, []);
var monitorOverview = new Page('Risy-monitor overview', 'monitor-white', 'monitors', MonitorsComponent, false, true, true, [addMonitor, manageMonitors]);
var monitorDetail = new Page('Details Risy-monitor', null, 'monitors/:id/detail', DetailsComponent, false, false, true, []);
addMonitor.parentPage = monitorOverview;
addMonitorRedirect.parentPage = monitorOverview;
editMonitor.parentPage = monitorOverview;
manageMonitors.parentPage = monitorOverview;
monitorDetail.parentPage = monitorOverview;
var verify = new Page('Verify', '', 'verify', VerifyComponent, true, false, false, []);
var infoPage = new Page('Info', 'info_outline', 'info', InfoComponent, false, false, true, []);
var userProfilePage = new Page('User profile', '', 'user-profile', UserProfileComponent, false, false, true, []);
var privacyStatementPage = new Page('Privacy statement', '', 'privacy-statement', PrivacyStatementComponent, false, false, false, []);
var changeOwnPasswordPage = new Page('Change password', '', 'change-password', ResetOwnPasswordComponent, false, false, true, []);
var notificationPage = new Page("Notifications", "", "notifications", NotificationsComponent, false, false, true, []);
// Variable amount of parameters in path.
var actionPageMatcher = function (segments) { return segments[0].path === "action" ? ({ consumed: segments }) : null; };
var ɵ0 = actionPageMatcher;
var actionPage = new Page("Actions", "", "", ActionComponent, false, false, true, [], actionPageMatcher);
var pages = [
    loginPage,
    requestResetPasswordPage,
    resetPasswordPage,
    addView,
    editView,
    manageViews,
    viewDetail,
    verify,
    infoPage,
    userProfilePage,
    privacyStatementPage,
    changeOwnPasswordPage,
    addMonitor,
    manageMonitors,
    monitorOverview,
    viewOverview,
    monitorDetail,
    editMonitor,
    notificationPage,
    actionPage
];
export var AppConfig = {
    pages: pages
};
export { ɵ0 };
