<article fxFill>
    <div class="title__content">
        <h1 *ngIf="!editMode" fxFill fxLayoutAlign="none center">
            {{ 'MONITOR.ADD_MONITOR' | translate }}
            <mat-icon>keyboard_arrow_right</mat-icon>
            {{ 'MONITOR.SENSORS' | translate }}
        </h1>
        <h1 *ngIf="editMode" fxFill fxLayoutAlign="none center">
            {{ 'MONITOR.EDIT_MONITOR' | translate }}
            <mat-icon>keyboard_arrow_right</mat-icon>
            {{ 'MONITOR.SENSORS' | translate }}
        </h1>
    </div>

    <div fxFill fxLayoutGap.gt-sm="20px" fxLayout="row" fxLayout.lt-md="column">
        <mat-card [fxFlex.gt-sm]="30" [fxFlex.gt-md]="20" class="progress__card">
            <mat-card-content>
                <h3>
                    {{ 'MONITOR.PROGRESS' | translate }}
                    <span fxHide fxShow.lt-md>({{ selectedSensorIndex + '/' + monitor.channels.length }})</span>
                </h3>
                <mat-progress-bar mode="determinate" [value]="sensorProgressValue"></mat-progress-bar>

                <ul class="configuration__list" fxHide fxShow.gt-sm>
                    <li *ngFor="let channel of monitor.channels; trackBy: trackByMonitorChannels; let i = index;">
                        <div [ngClass]="{'is-selected' : i === selectedSensorIndex}">
                            <span class="step-indicator"
                                  [ngClass]="{'is-open' : i > selectedSensorIndex, 'is-completed' : i < selectedSensorIndex}">
                                <span *ngIf="!hasSensorStepBeenCompleted(i); else matIconCheck">{{ i + 1 }}</span>

                                <ng-template #matIconCheck>
                                    <mat-icon>check</mat-icon>
                                </ng-template>
                            </span>
                            {{ hasSensorName(i) ? sensorMap.get(i).name : 'MONITOR.CHANNEL' | translate:{index: i + 1 } }}
                        </div>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>

        <mat-card [fxFlex.gt-md]="65" [fxFlex.gt-lg]="50" class="setup__card">
            <app-progress-spinner *ngIf="isLoadingAllSensorCategories"
                                  [progressSpinnerText]="'MONITOR.SENSOR_CATEGORIES' | translate">
            </app-progress-spinner>

            <mat-card-content *ngIf="!isLoadingAllSensorCategories">
                <h3>{{ 'MONITOR.SENSOR_DETAILS' | translate }}</h3>

                <app-message *ngIf="!canEditMonitor()"
                             [messageText]="'MONITOR.NON_EDITABLE_READ_ONLY' | translate"
                             [messageStyle]="MESSAGE_STYLE.INFO"></app-message>

                <app-message *ngIf="validateHttpError"
                             [messageText]="validateHttpError"
                             [messageStyle]="MESSAGE_STYLE.DANGER"></app-message>

                <form [formGroup]="addSensorMonitorForm"
                      novalidate
                      (ngSubmit)="addSensorToMonitor(addSensorMonitorForm.value, addSensorMonitorForm.valid, ngAddSensorMonitorForm, lowerLimit.value, upperLimit.value, threshold.value)"
                      *ngIf="!isAddingSensor"
                      #ngAddSensorMonitorForm="ngForm"
                      autocomplete="off">

                    <div class="input-row" fxLayout="row">
                        <mat-form-field [fxFlex.gt-sm]="60">
                            <input matInput
                                   type="text" autofocus
                                   [placeholder]="'MONITOR.NAME' | translate" value="{{getName()}}" formControlName="name">
                            <!--Required error -->
                            <mat-error *ngIf="addSensorMonitorForm.get('name').errors?.required">
                                {{'MONITOR.ERRORS.NAME' | translate}}
                            </mat-error>

                            <!-- MaxLength error -->
                            <mat-error *ngIf="addSensorMonitorForm.get('name').errors?.maxlength">
                                {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.NAME' | translate, max: MAX_TEXT_FIELD_LENGTH} }}
                            </mat-error>
                        </mat-form-field>


                    </div>
                    <div class="input-row" fxLayout="row" [hidden]="!canEditMonitor()">
                        <app-message [hidden]="canShowLimit()">No limits can be changed for this sensor.</app-message>
                        <mat-form-field style="margin-right: 5%" [hidden]="!(canShowLimit() && canShowLowerLimit())">
                            <input matInput type="number" placeholder="{{'ALARM.LOWERLIMIT' | translate}}"
                                   value={{getDefaultLowerLimit()}} #lowerLimit inputValToDecimal round="1">
                        </mat-form-field>
                        <mat-form-field [hidden]="!canShowLimit()">
                            <input matInput type="number" placeholder="{{'ALARM.UPPERLIMIT' | translate}}"
                                   value={{getDefaultUpperLimit()}} #upperLimit inputValToDecimal round="1">
                        </mat-form-field>
                    </div>
                    <mat-form-field [hidden]="!canShowDelta() || !canEditMonitor()">
                        <input matInput type="number" placeholder="{{'DELTA.LOWERLIMIT' | translate}}" value={{getDefaultThreshold()}} #threshold inputValToDecimal round="1">
                    </mat-form-field>

                    <app-message *ngIf="isCurrentChannelFixed()"
                                 [messageText]="'MONITOR.NON_EDITABLE_FIXED_SENSOR' | translate"
                                 [messageStyle]="MESSAGE_STYLE.INFO"></app-message>
                    
                    <!-- Cloud Storage Rate For PXPI -->
                    <div class="input-row" *ngIf="isShowPollingCloudStorgeForPcxi()" fxLayout="row">
                        <h3 [fxFlex]="20" fxHide fxShow.gt-sm [innerHTML]="'MONITOR.SENSOR_SETTINGS' | translate"></h3>

                        <mat-radio-group class="sensor-details-radio-group" formControlName="sensorSettings" fxFlex>                                                                                                          
                                <div *ngIf="canShowPollingAndCloudStorageRate()">
                                    <div fxLayout="row">
                                        <label fxHide fxShow.lt-md fxFlex>{{'MONITOR.POLLING_RATE' | translate}}</label>
                                    </div>
                                    <div>
                                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center">
                                            <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.POLLING_RATE' | translate}}</label>
                                            <div fxFlex fxLayoutGap="20px" formGroupName="pollingRate">
                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowPollingRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           (keyup)="updateRateValueAndValidity('cloudStorageRate')"
                                                           min="0"
                                                           [placeholder]="'MONITOR.DURATION.HOURS' | translate"
                                                           formControlName="hours">
                                                    <span matSuffix>{{'MONITOR.DURATION.HOURS_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('pollingRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowPollingRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           (keyup)="updateRateValueAndValidity('cloudStorageRate')"
                                                           min="0"
                                                           [placeholder]="'MONITOR.DURATION.MINUTES' | translate"
                                                           formControlName="minutes">
                                                    <span matSuffix>{{'MONITOR.DURATION.MINUTES_SUFFIX' | translate}}</span>
                                                    <mat-error *ngIf="addSensorMonitorForm.get('pollingRate.minutes').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.MINUTES' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowPollingRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           min="0"
                                                           (keyup)="updateRateValueAndValidity('cloudStorageRate')"
                                                           [placeholder]="'MONITOR.DURATION.SECONDS' | translate"
                                                           formControlName="seconds">
                                                    <span matSuffix>{{'MONITOR.DURATION.SECONDS_SUFFIX' | translate}}</span>
                                                    <mat-error *ngIf="addSensorMonitorForm.get('pollingRate.seconds').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.SECONDS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="custom-error__container" *ngIf="canShowPollingRateError()">
                                            <div fxLayout="row" *ngIf="canShowDurationError('pollingRate', 'invalidDuration')">
                                                <mat-error fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.POLLING_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                            <div fxLayout="row" *ngIf="canShowDurationError('pollingRate', 'invalidPollingRate')">
                                                <mat-error fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.INVALID_POLLING_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div fxLayout="row">
                                            <label fxHide fxShow.lt-md fxFlex>{{'MONITOR.CLOUD_STORAGE_RATE' | translate}}</label>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center">
                                            <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.CLOUD_STORAGE_RATE' | translate}}</label>
                                            <div fxFlex fxLayoutGap="20px" formGroupName="cloudStorageRate">
                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowCloudStorageRateError()}">
                                                    <input type="number" matInput min="0"
                                                           (keyup)="updateRateValueAndValidity('pollingRate')"
                                                           [placeholder]="'MONITOR.DURATION.HOURS' | translate"
                                                           formControlName="hours">
                                                    <span matSuffix>{{'MONITOR.DURATION.HOURS_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('cloudStorageRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowCloudStorageRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           min="0"
                                                           (keyup)="updateRateValueAndValidity('pollingRate')"
                                                           [placeholder]="'MONITOR.DURATION.MINUTES' | translate"
                                                           formControlName="minutes">
                                                    <span matSuffix>{{'MONITOR.DURATION.MINUTES_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('cloudStorageRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowCloudStorageRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           min="0"
                                                           (keyup)="updateRateValueAndValidity('pollingRate')"
                                                           [placeholder]="'MONITOR.DURATION.SECONDS' | translate" formControlName="seconds">
                                                    <span matSuffix>{{'MONITOR.DURATION.SECONDS_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('cloudStorageRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="custom-error__container"
                                             *ngIf="canShowCloudStorageRateError()">
                                            <div fxLayout="row">
                                                <mat-error *ngIf="canShowDurationError('cloudStorageRate', 'invalidDuration')"
                                                           fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.CLOUD_STORAGE_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                            <div fxLayout="row" *ngIf="canShowDurationError('cloudStorageRate', 'invalidCloudStorageRate')">
                                                <mat-error fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.INVALID_CLOUD_STORAGE_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                        </mat-radio-group>
                    </div>
                    <!-- Cloud Storage Rate For PXPI-->

                    <div class="input-row" *ngIf="!isCurrentChannelFixed()" fxLayout="row">
                        <h3 [fxFlex]="20" fxHide fxShow.gt-sm [innerHTML]="'MONITOR.SENSOR_SETTINGS' | translate"></h3>

                        <mat-radio-group class="sensor-details-radio-group" formControlName="sensorSettings" fxFlex>
                            <mat-radio-button color="primary"
                                              [value]="SENSOR_SETTING_PREDEFINED">
                                {{'MONITOR.PREDEFINED' | translate}}
                            </mat-radio-button>

                            <!-- Predefined sensor settings -->
                            <div *ngIf="canAddPredefinedSettings()">
                                <app-progress-spinner *ngIf="isLoadingAllPredefinedSensors"
                                                      [progressSpinnerText]="'MONITOR.PREDEFINED_SENSORS' | translate">
                                </app-progress-spinner>

                                <div *ngIf="!isLoadingAllPredefinedSensors" class="input-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
                                    <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.NAME' | translate}}</label>
                                    <mat-form-field fxFlex>
                                        <mat-select formControlName="predefinedSensorId" placeholder="Predefined Sensor">
                                            <mat-option *ngFor="let predefinedSensor of allPredefinedSensors"
                                                        [value]="predefinedSensor.id">
                                                {{predefinedSensor.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>
                                            {{'MONITOR.ERRORS.PREDEFINED_SENSOR' | translate}}
                                        </mat-error>
                                    </mat-form-field>

                                    <input type="hidden"
                                           formControlName="inputType"
                                           [ngModel]="MONITOR_INPUT_TYPE.PREDEFINED">
                                </div>
                            </div>

                            <mat-radio-button color="primary" [value]="SENSOR_SETTING_CUSTOM">
                                {{'MONITOR.CUSTOM' | translate}}
                            </mat-radio-button>

                            <!-- Custom sensor settings -->
                            <div *ngIf="canAddCustomSettings()" class="custom-settings__content" fxFlex>
                                <!-- SENSOR - INPUT TYPE -->
                                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center">
                                    <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.INPUT_TYPE' | translate}}</label>
                                    <mat-form-field fxFlex>
                                        <mat-select formControlName="inputType" placeholder="Input type">
                                            <div *ngIf="isCurrentChannelAnalog()">
                                                <mat-option [value]="MONITOR_INPUT_TYPE.ANALOG_MA">
                                                    {{'MONITOR.INPUT_TYPES.ANALOG_MA' | translate}}
                                                </mat-option>
                                                <mat-option [value]="MONITOR_INPUT_TYPE.ANALOG_V">
                                                    {{'MONITOR.INPUT_TYPES.ANALOG_V' | translate}}
                                                </mat-option>
                                            </div>
                                            <div *ngIf="isCurrentChannelDigital()">
                                                <mat-option [value]="MONITOR_INPUT_TYPE.DIGITAL_PULSE">
                                                    {{'MONITOR.INPUT_TYPES.DIGITAL_PULSE' | translate}}
                                                </mat-option>
                                                <mat-option [value]="MONITOR_INPUT_TYPE.DIGITAL_OPEN_CLOSED">
                                                    {{'MONITOR.INPUT_TYPES.DIGITAL_OPEN_CLOSED' | translate}}
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                        <mat-error>
                                            {{'MONITOR.ERRORS.INPUT_TYPE' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- SENSOR - CATEGORY -->
                                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="hasSelectedInputType()">
                                    <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.CATEGORY' | translate}}</label>
                                    <mat-form-field fxFlex>
                                        <mat-select formControlName="categoryId" placeholder="Category">
                                            <mat-option *ngFor="let sensorCategory of allSensorCategories; let i = index"
                                                        (onSelectionChange)="onChangeSensorCategory($event, i)"
                                                        [value]="sensorCategory.id">
                                                {{sensorCategory.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>
                                            {{'MONITOR.ERRORS.CATEGORY' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- SENSOR - SENSOR TYPE -->
                                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="canShowSensorType()">
                                    <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.SENSOR_TYPE' | translate}}</label>
                                    <mat-form-field fxFlex>
                                        <mat-select formControlName="sensorTypeId" placeholder="Sensor type">
                                            <mat-option *ngFor="let sensorType of allSensorCategories[selectedSensorCategoryIndex].types; let i = index"
                                                        (onSelectionChange)="onChangeSensorType($event, i)"
                                                        [value]="sensorType.id">
                                                {{sensorType.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>
                                            {{'MONITOR.ERRORS.SENSOR_TYPE' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- SENSOR - UNIT TYPE -->
                                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center"
                                     *ngIf="canShowUnitType()">
                                    <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.UNIT' | translate}}</label>
                                    <mat-form-field fxFlex *ngIf="allSensorCategories[selectedSensorCategoryIndex].types[selectedSensorTypeIndex]">
                                        <mat-select formControlName="unitId" placeholder="Unit">
                                            <mat-option *ngFor="let sensorUnit of allSensorCategories[selectedSensorCategoryIndex].types[selectedSensorTypeIndex].units; let i = index"
                                                        (onSelectionChange)="onChangeUnit($event, i, sensorUnit.name)"
                                                        [value]="sensorUnit.id">
                                                {{ sensorUnit.name }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>
                                            {{'MONITOR.ERRORS.UNIT' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                
                                <div *ngIf="canShowPollingAndCloudStorageRate()">
                                    <div fxLayout="row">
                                        <label fxHide fxShow.lt-md fxFlex>{{'MONITOR.POLLING_RATE' | translate}}</label>
                                    </div>
                                    <div>
                                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center">
                                            <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.POLLING_RATE' | translate}}</label>
                                            <div fxFlex fxLayoutGap="20px" formGroupName="pollingRate">
                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowPollingRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           (keyup)="updateRateValueAndValidity('cloudStorageRate')"
                                                           min="0"
                                                           [placeholder]="'MONITOR.DURATION.HOURS' | translate"
                                                           formControlName="hours">
                                                    <span matSuffix>{{'MONITOR.DURATION.HOURS_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('pollingRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowPollingRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           (keyup)="updateRateValueAndValidity('cloudStorageRate')"
                                                           min="0"
                                                           [placeholder]="'MONITOR.DURATION.MINUTES' | translate"
                                                           formControlName="minutes">
                                                    <span matSuffix>{{'MONITOR.DURATION.MINUTES_SUFFIX' | translate}}</span>
                                                    <mat-error *ngIf="addSensorMonitorForm.get('pollingRate.minutes').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.MINUTES' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowPollingRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           min="0"
                                                           (keyup)="updateRateValueAndValidity('cloudStorageRate')"
                                                           [placeholder]="'MONITOR.DURATION.SECONDS' | translate"
                                                           formControlName="seconds">
                                                    <span matSuffix>{{'MONITOR.DURATION.SECONDS_SUFFIX' | translate}}</span>
                                                    <mat-error *ngIf="addSensorMonitorForm.get('pollingRate.seconds').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.SECONDS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="custom-error__container" *ngIf="canShowPollingRateError()">
                                            <div fxLayout="row" *ngIf="canShowDurationError('pollingRate', 'invalidDuration')">
                                                <mat-error fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.POLLING_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                            <div fxLayout="row" *ngIf="canShowDurationError('pollingRate', 'invalidPollingRate')">
                                                <mat-error fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.INVALID_POLLING_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div fxLayout="row">
                                            <label fxHide fxShow.lt-md fxFlex>{{'MONITOR.CLOUD_STORAGE_RATE' | translate}}</label>
                                        </div>
                                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center">
                                            <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.CLOUD_STORAGE_RATE' | translate}}</label>
                                            <div fxFlex fxLayoutGap="20px" formGroupName="cloudStorageRate">
                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowCloudStorageRateError()}">
                                                    <input type="number" matInput min="0"
                                                           (keyup)="updateRateValueAndValidity('pollingRate')"
                                                           [placeholder]="'MONITOR.DURATION.HOURS' | translate"
                                                           formControlName="hours">
                                                    <span matSuffix>{{'MONITOR.DURATION.HOURS_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('cloudStorageRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowCloudStorageRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           min="0"
                                                           (keyup)="updateRateValueAndValidity('pollingRate')"
                                                           [placeholder]="'MONITOR.DURATION.MINUTES' | translate"
                                                           formControlName="minutes">
                                                    <span matSuffix>{{'MONITOR.DURATION.MINUTES_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('cloudStorageRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field fxFlex [ngClass]="{'mat-form-field-invalid': canShowCloudStorageRateError()}">
                                                    <input type="number"
                                                           matInput
                                                           min="0"
                                                           (keyup)="updateRateValueAndValidity('pollingRate')"
                                                           [placeholder]="'MONITOR.DURATION.SECONDS' | translate" formControlName="seconds">
                                                    <span matSuffix>{{'MONITOR.DURATION.SECONDS_SUFFIX' | translate}}</span>

                                                    <!--Required error -->
                                                    <mat-error *ngIf="addSensorMonitorForm.get('cloudStorageRate.hours').errors?.required">
                                                        {{'MONITOR.ERRORS.DURATION.HOURS' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="custom-error__container"
                                             *ngIf="canShowCloudStorageRateError()">
                                            <div fxLayout="row">
                                                <mat-error *ngIf="canShowDurationError('cloudStorageRate', 'invalidDuration')"
                                                           fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.CLOUD_STORAGE_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                            <div fxLayout="row" *ngIf="canShowDurationError('cloudStorageRate', 'invalidCloudStorageRate')">
                                                <mat-error fxFlex
                                                           class="custom-error__message"
                                                           [fxFlexOffset.gt-sm]="20">
                                                    {{ 'MONITOR.ERRORS.INVALID_CLOUD_STORAGE_RATE' | translate }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Start ANALOG -->
                                <div *ngIf="canShowAnalog()">
                                    <div fxLayout="row" fxHide.lt-lg fxLayoutGap="20px">
                                        <label fxFlex>{{'MONITOR.MEASUREMENT' | translate}}</label>
                                        <label fxFlex>{{'MONITOR.VALUE' | translate}}</label>
                                    </div>

                                    <div class="input-row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayout="row">
                                        <mat-form-field fxFlex>
                                            <span matPrefix>{{ 'MONITOR.MIN' | translate }}  &nbsp;</span>
                                            <input matInput
                                                   formControlName="measurementMinimum"
                                                   [placeholder]="canShowPlaceHolder() ? ('MONITOR.MEASUREMENT' | translate) : ''"
                                                   type="number"
                                                   inputValToDecimal round="1">
                                            <span matSuffix>{{analogInputTypeCurrent}}</span>
                                            <mat-error *ngIf="addSensorMonitorForm.get('measurementMinimum').errors?.required">
                                                {{'MONITOR.ERRORS.MIN_MEASUREMENT' | translate}}
                                            </mat-error>

                                            <mat-error *ngIf="addSensorMonitorForm.get('measurementMinimum').errors?.min || addSensorMonitorForm.get('measurementMinimum').errors?.max">
                                                {{ 'MONITOR.ERRORS.BETWEEN' | translate: {min: 0, max: maxMeasurementValue} }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field fxFlex>
                                            <span matPrefix>{{ 'MONITOR.MIN' | translate }} &nbsp;</span>
                                            <input matInput
                                                   formControlName="valueMinimum"
                                                   [placeholder]="canShowPlaceHolder() ? ('MONITOR.VALUE' | translate) : ''"
                                                   type="number"
                                                   inputValToDecimal round="1">
                                            <span matSuffix>{{analogInputTypeUnit}}</span>
                                            <mat-error>{{'MONITOR.ERRORS.MIN_VALUE' | translate}}</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-row" fxLayout.lt-lg="column" fxLayoutGap="20px" fxLayout="row">
                                        <mat-form-field fxFlex>
                                            <span matPrefix>{{ 'MONITOR.MAX' | translate }} &nbsp;</span>
                                            <input matInput
                                                   formControlName="measurementMaximum"
                                                   [placeholder]="canShowPlaceHolder() ? ('MONITOR.MEASUREMENT' | translate) : ''"
                                                   type="number"
                                                   inputValToDecimal round="1">
                                            <span matSuffix>{{analogInputTypeCurrent}}</span>
                                            <mat-error *ngIf="addSensorMonitorForm.get('measurementMaximum').errors?.required">
                                                {{'MONITOR.ERRORS.MAX_MEASUREMENT' | translate}}
                                            </mat-error>
                                            <mat-error *ngIf="addSensorMonitorForm.get('measurementMaximum').errors?.min || addSensorMonitorForm.get('measurementMaximum').errors?.max">
                                                {{ 'MONITOR.ERRORS.BETWEEN' | translate: {min: 0, max: maxMeasurementValue} }}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field fxFlex>
                                            <span matPrefix>{{ 'MONITOR.MAX' | translate }} &nbsp;</span>
                                            <input matInput
                                                   formControlName="valueMaximum"
                                                   [placeholder]="canShowPlaceHolder() ? ('MONITOR.VALUE' | translate) : ''"
                                                   type="number"
                                                   inputValToDecimal round="1">
                                            <span matSuffix>{{analogInputTypeUnit}}</span>
                                            <mat-error>{{'MONITOR.ERRORS.MAX_VALUE' | translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- End ANALOG -->
                                <!-- Start Digital Open/Closed -->
                                <div *ngIf="canShowDigitalOpenClosed()">
                                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center">
                                        <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.OPEN' | translate}}</label>
                                        <div fxFlex>
                                            <mat-form-field fxFill>
                                                <input matInput
                                                       type="text"
                                                       formControlName="openText"
                                                       [placeholder]="'MONITOR.OPEN_TEXT' | translate">
                                                <mat-error *ngIf="addSensorMonitorForm.get('openText').errors?.required">{{'MONITOR.ERRORS.OPEN_TEXT' | translate}}</mat-error>
                                                <mat-error *ngIf="addSensorMonitorForm.get('openText').errors?.maxlength">
                                                    {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.OPEN_TEXT' | translate, max: MAX_TEXT_OPEN_CLOSED_LENGTH} }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="input-row" fxLayout="row">
                                        <div fxFlex [fxFlexOffset.gt-sm]="20">
                                            <mat-form-field fxFill>
                                                <textarea matInput
                                                          rows="6"
                                                          formControlName="openTextDescription"
                                                          [placeholder]="'MONITOR.OPEN_TEXT_DESCRIPTION' | translate"></textarea>
                                                <mat-error *ngIf="addSensorMonitorForm.get('openTextDescription').errors?.maxlength">
                                                    {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.OPEN_TEXT_DESCRIPTION' | translate, max: MAX_TEXT_AREA_LENGTH} }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center">
                                        <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.CLOSED' | translate}}</label>
                                        <div fxFlex>
                                            <mat-form-field fxFill>
                                                <input matInput
                                                       type="text"
                                                       formControlName="closedText"
                                                       [placeholder]="'MONITOR.CLOSED_TEXT' | translate">
                                                <mat-error *ngIf="addSensorMonitorForm.get('closedText').errors?.required">{{'MONITOR.ERRORS.CLOSED_TEXT' | translate}}</mat-error>
                                                <mat-error *ngIf="addSensorMonitorForm.get('closedText').errors?.maxlength">
                                                    {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.CLOSED_TEXT' | translate, max: MAX_TEXT_OPEN_CLOSED_LENGTH} }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="input-row" fxLayout="row">
                                        <div fxFlex [fxFlexOffset.gt-sm]="20">
                                            <mat-form-field fxFill>
                                                <textarea matInput
                                                          rows="6"
                                                          formControlName="closedTextDescription"
                                                          [placeholder]="'MONITOR.CLOSED_TEXT_DESCRIPTION' | translate"></textarea>
                                                <mat-error *ngIf="addSensorMonitorForm.get('closedTextDescription').errors?.maxlength">
                                                    {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'MONITOR.CLOSED_TEXT_DESCRIPTION' | translate, max: MAX_TEXT_AREA_LENGTH} }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Digital On/Off -->
                                <!-- Start Digital Pulse -->
                                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="canShowDigitalPulse()">
                                    <label fxHide fxShow.gt-sm [fxFlex]="20">{{'MONITOR.VALUE' | translate}}</label>
                                    <mat-form-field fxFlex>
                                        <input matInput
                                               type="number"
                                               formControlName="value"
                                               [placeholder]="'MONITOR.VALUE' | translate" inputValToDecimal round="1">
                                        <span matSuffix>{{ 'MONITOR.VALUE_PER_PULSE' | translate:{value: analogInputTypeUnit} }}</span>
                                        <mat-error>
                                            {{'MONITOR.ERRORS.CATEGORY' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- End Digital Pulse -->
                            </div>
                        </mat-radio-group>
                    </div>

                    <div class="input-row" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">
                        <a (click)="skipSensorToMonitor(ngAddSensorMonitorForm)">{{'MONITOR.UNUSED' | translate }}</a>
                        <button (click)="goToPreviousSensor()" color="accent" type="button" mat-raised-button>Back</button>
                        <button [disabled]="!canGoToNextStep()" color="primary" type="submit" mat-raised-button>
                            <span *ngIf="!hasReachedLastSensorStep()">
                                {{'MONITOR.NEXT' | translate}}
                            </span>
                            <span *ngIf="hasReachedLastSensorStep()">
                                {{'MONITOR.COMPLETE' | translate}}
                            </span>

                        </button>
                    </div>
                </form>

                <app-progress-spinner *ngIf="isAddingSensor"
                                      [progressSpinnerText]="'MONITOR.PREDEFINED_SENSORS' | translate">
                </app-progress-spinner>
            </mat-card-content>
        </mat-card>
    </div>
</article>
