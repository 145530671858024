
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isUndefined} from 'lodash';
import {Subject} from 'rxjs';
import {Users} from '../../../infrastructure/http/user/users';
import {TranslateService} from '@ngx-translate/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnDestroy {
  public isVerifying = true;
  public errorHasHappened = false;
  private userService: Users;
  private ngUnsubscribe = new Subject<void>();
  private activatedRoute: ActivatedRoute;
  private key: string;
  private router: Router;
  private translateService: TranslateService;

  constructor(userService: HttpUsersService,
              router: Router,
              activatedRoute: ActivatedRoute,
              translateService: TranslateService) {
    this.userService = userService;
    this.router = router;
    this.activatedRoute = activatedRoute;

    this.activatedRoute.queryParams.subscribe((params) => {
      this.key = params.key;
    });
    this.translateService = translateService;

    // Check if the required query param ist present, if not, redirect the user to the login page
    if (isUndefined(this.key)) {
      this.router.navigateByUrl('/login');
    } else {
      this.verify(this.key);
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public verify(key: string): void {
    this.userService.verifyEmail(key).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.isVerifying = false;
      }, (error: HttpErrorResponse) => {
        this.isVerifying = false;
        this.errorHasHappened = true;
        const parsedError = JSON.parse(error.error);
        let errorMessage = parsedError.message;

        this.translateService.get(`ERRORS.${error.error.type}`).pipe(
          takeUntil(this.ngUnsubscribe))
          .subscribe((translation: any) => {
            if (translation !== `ERRORS.${error.error.type}`) {
              errorMessage = translation;
            }
          });
        throw new Error(errorMessage);
      });
  }
}
