import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent {
  public textContent: string;
  private dialogRef: MatDialogRef<InformationDialogComponent>;

  constructor(dialogRef: MatDialogRef<InformationDialogComponent>) {
    this.dialogRef = dialogRef;
  }

  public keyDownFunction(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.dialogRef.close();
    }
  }
}
