import {MonitorSensor} from '../monitor/monitorSensor';
import {MonitorMeasurement} from '../monitor/monitorMeasurement';
import {DetailStatusOverview} from '../monitor/sensor/DetailStatusOverview';
import {View} from './view';
import {last, sortBy, isNil} from 'lodash';

export class ViewDetailStatusOverview extends View implements DetailStatusOverview  {

    private _measurements: MonitorMeasurement[];

    public static detailFromJSON(view: View, monitorMeasurementDTO: any): ViewDetailStatusOverview {
        return new ViewDetailStatusOverview(
            view.id,
            view.name,
            view.elucidation,
            view.sensors,
            monitorMeasurementDTO.map(MonitorMeasurement.fromJSON)
        );
    }

    public static copy(viewOverview: DetailStatusOverview) {
        const original = viewOverview as ViewDetailStatusOverview;
        return new ViewDetailStatusOverview(
            original.id,
            original.name,
            original.elucidation,
            original.sensors,
            original.measurements,
        )
    }

    constructor(id: string, name: string, elucidation: string, sensors: MonitorSensor[], measurements: MonitorMeasurement[]) {
        super(id, name, elucidation, sensors)
        this._measurements = measurements;
    }

    get measurements(): MonitorMeasurement[] {
        return this._measurements;
    }

    set measurements(value: MonitorMeasurement[]) {
        this._measurements = value;
    }

    get lastMeasuredDateTime(): Date {
        const sensorOfLastMeasuredDateTime: MonitorSensor = last(sortBy(this.sensors, ['lastMeasuredDateTime']));
        return !isNil(sensorOfLastMeasuredDateTime) ? sensorOfLastMeasuredDateTime.lastMeasuredDateTime : null;
    }
}
