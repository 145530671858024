import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {Notification} from './notification';

@Injectable()
export class NotificationService implements Notification {
  private snackBar: MatSnackBar;
  private translate: TranslateService;

  constructor(snackBar: MatSnackBar, translate: TranslateService) {
    this.snackBar = snackBar;
    this.translate = translate;
  }

  public show(message: string): void {
    this.translate.get(message, {}).subscribe((res: string) => {
      this.snackBar.open(res, null, {
        duration: 2000
      });
    });

  }
}
