import {Component, Inject, Input} from '@angular/core';
import {Monitor} from '../../../../../domain/monitor/monitor';
import {HttpMonitorsService} from '../../../../../infrastructure/http/monitor/http-monitors.service';
import {Monitors} from '../../../../../infrastructure/http/monitor/monitors';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
  selector: 'app-mobile-manage-monitors',
  templateUrl: './manage-monitors.component.html',
  styleUrls: ['./manage-monitors.component.scss']
})
export class ManageMonitorsMobileComponent {
  @Input() public monitors: Monitor[] = [];
  private monitorsService: Monitors;

  constructor(monitorsService: MonitorDataService) {
    this.monitorsService = monitorsService;
  }

  public canEditMonitor(monitor: Monitor): boolean {
    return monitor.editableByCurrentUser;
  }

}
