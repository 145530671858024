import { MonitorSensor } from '../../../../../domain/monitor/monitorSensor';
import { OnInit } from '@angular/core';
import { floor, last } from "lodash";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var ActionTilesComponent = /** @class */ (function () {
    function ActionTilesComponent(monitorService) {
        this.monitorService = monitorService;
        this.INVALIDDELTA_CLAUSE = {
            "INPUTTYPE": "AUTO",
            "NAME": ["Interval Temperature", "Yearly Corrosion Rate"]
        };
        this.InValidLowerLimitType = "TYPE_CXI_CORROSION";
        this.InValidLimitType = "TYPE_CXI_TEMPERATURE";
    }
    ActionTilesComponent.prototype.ngOnInit = function () {
    };
    ActionTilesComponent.prototype.getAlarmColor = function () {
        return this.sensor.isAlarmIgnored ? 'warn' : 'primary';
    };
    ActionTilesComponent.prototype.toggleAlarm = function () {
        this.sensor.isAlarmIgnored = !this.sensor.isAlarmIgnored;
        this.monitorService.setSensorAlarm(this.sensor.detailsId, this.sensor.id).subscribe();
    };
    ActionTilesComponent.prototype.setNewLimits = function (lowerLimit, upperLimit) {
        var limits = {};
        if (lowerLimit && this.canShowLowerLimit()) {
            limits["lowerlimit"] = lowerLimit;
        }
        if (upperLimit) {
            limits["upperlimit"] = upperLimit;
        }
        this.monitorService.setSensorLimits(this.sensor.detailsId, this.sensor.id, limits).subscribe();
    };
    ActionTilesComponent.prototype.setNewDelta = function (lowerLimit, upperLimitH, upperLimitM, upperLimitS) {
        var upperLimit = upperLimitH * 60 * 60 + upperLimitM * 60 + upperLimitS * 1;
        var deltaLimits = {};
        if (lowerLimit) {
            deltaLimits["thresholdDelta"] = lowerLimit;
        }
        if (upperLimit) {
            deltaLimits["pollingRate"] = upperLimit;
        }
        this.monitorService.setPressureDelta(this.sensor.detailsId, this.sensor.id, deltaLimits).subscribe();
    };
    ActionTilesComponent.prototype.getDefaultLowerVal = function () {
        return this.sensor.lowerLimits && this.sensor.lowerLimits.length > 0 ? last(this.sensor.lowerLimits).value : "";
    };
    ActionTilesComponent.prototype.getDefaultUpperVal = function () {
        return this.sensor.upperLimits && this.sensor.upperLimits.length > 0 ? last(this.sensor.upperLimits).value : "";
    };
    ActionTilesComponent.prototype.getDefaultThreshold = function () {
        return this.sensor.threshold ? this.sensor.threshold : "";
    };
    ActionTilesComponent.prototype.getDefaultPollingRate = function () {
        if (!this.sensor.pollingRate) {
            return { hrs: 0, mins: 0, secs: 0 };
        }
        var secs = Number(this.sensor.pollingRate) % 60;
        var total_minutes = floor(Number(this.sensor.pollingRate) / 60);
        var mins = total_minutes % 60;
        var hrs = floor(total_minutes / 60);
        return { hrs: hrs, mins: mins, secs: secs };
    };
    ActionTilesComponent.prototype.canShowDelta = function () {
        var _this = this;
        var convertTxtToCompare = function (txtVal) {
            return (txtVal || '').toString().toUpperCase().replace(/\s/g, '');
        };
        return !(convertTxtToCompare(this.sensor.inputType) === this.INVALIDDELTA_CLAUSE.INPUTTYPE &&
            (this.INVALIDDELTA_CLAUSE.NAME || []).length > 0 &&
            (this.INVALIDDELTA_CLAUSE.NAME || []).filter(function (x) { return convertTxtToCompare(x) === convertTxtToCompare(_this.sensor.name); }).length > 0);
    };
    ActionTilesComponent.prototype.canShowLowerLimit = function () {
        return this.sensor.sensorType != this.InValidLowerLimitType;
    };
    ActionTilesComponent.prototype.canShowLimit = function () {
        return this.sensor.sensorType != this.InValidLimitType;
    };
    ActionTilesComponent.prototype.isValidEntry = function (seconds, delta) {
        return seconds > 0 || delta > 0;
    };
    return ActionTilesComponent;
}());
export { ActionTilesComponent };
