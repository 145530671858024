<div fxLayoutAlign="center center">
  <button mat-raised-button color="primary" class="antialiased btn-edit-monitor"
          [routerLink]="['/monitors', monitor.id, 'edit']">
    <span *ngIf="canEditMonitor()">
      {{'GENERAL.EDIT' | translate}}
    </span>
    <span *ngIf="!canEditMonitor()">
      {{'GENERAL.DETAILS' | translate}}
    </span>
  </button>
</div>

