export class MonitorDetails {
  private _name: string;
  private _address: string;
  private _system: string;
  private _circuit: string;
  private _details: string;
  private _comments: string;

  public static fromJSON(monitorDetailsDTO: any): MonitorDetails {
    return new MonitorDetails(
      monitorDetailsDTO.name,
      monitorDetailsDTO.address,
      monitorDetailsDTO.system,
      monitorDetailsDTO.circuit,
      monitorDetailsDTO.details,
      monitorDetailsDTO.comments
    );
  }

  constructor(name: string, address: string, system: string, circuit: string, details: string, comments: string) {
    this._name = name;
    this._address = address;
    this._system = system;
    this._circuit = circuit;
    this._details = details;
    this._comments = comments;
  }

  get name(): string {
    return this._name;
  }

  get address(): string {
    return this._address;
  }

  get system(): string {
    return this._system;
  }

  get circuit(): string {
    return this._circuit;
  }

  get details(): string {
    return this._details;
  }

  get comments(): string {
    return this._comments;
  }
}
