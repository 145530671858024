
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Moment} from 'moment';
import DeviceStatus from '../../../domain/monitor/deviceStatus';
import FirmwareUpgradeState from '../../../domain/monitor/firmware/firmware-upgrade-state';
import {FirmwareUpgradeStatus} from '../../../domain/monitor/firmware/firmware-upgrade-status';
import {Monitor} from '../../../domain/monitor/monitor';
import {MonitorStatusInfo} from '../../../domain/monitor/monitor-status-info';
import MonitorChannel from '../../../domain/monitor/monitorChannel';
import {MonitorDetails} from '../../../domain/monitor/monitorDetails';
import {MonitorDetailStatusOverview} from '../../../domain/monitor/monitorDetailStatusOverview';
import MonitorInputType from '../../../domain/monitor/monitorInputType';
import {MonitorMeasurement} from '../../../domain/monitor/monitorMeasurement';
import {MonitorMeasurementData} from '../../../domain/monitor/monitorMeasurementData';
import MonitorPrivacy from '../../../domain/monitor/monitorPrivacy';
import {MonitorSensor} from '../../../domain/monitor/monitorSensor';
import {MonitorStatusOverview} from '../../../domain/monitor/monitorStatusOverview';
import {DetailStatusOverview} from '../../../domain/monitor/sensor/DetailStatusOverview';
import {SensorCategory} from '../../../domain/monitor/sensor/sensor-category/sensor-category';
import {SensorCategoryType} from '../../../domain/monitor/sensor/sensor-category/sensor-category-type';
import {SensorCategoryUnit} from '../../../domain/monitor/sensor/sensor-category/sensor-category-unit';
import {SensorLimit} from '../../../domain/monitor/sensor/sensor-limit';
import {SensorPredefined} from '../../../domain/monitor/sensor/sensor-predefined';
import {SensorPredefinedList} from '../../../domain/monitor/sensor/sensor-predefined-list/sensor-predefined-list';
import {SensorPredefinedListAnalog} from '../../../domain/monitor/sensor/sensor-predefined-list/sensor-predefined-list-analog';
import {SensorPredefinedListDigitalPulse} from '../../../domain/monitor/sensor/sensor-predefined-list/sensor-predefined-list-digital-pulse';
import SensorStatus from '../../../domain/monitor/sensor/sensor-status';
import {SensorAnalog} from '../../../domain/monitor/sensor/sensorAnalog';
import {SensorDigitalOpenClosed} from '../../../domain/monitor/sensor/sensorDigitalOpenClosed';
import {SensorDigitalPulse} from '../../../domain/monitor/sensor/sensorDigitalPulse';
import {Monitors} from './monitors';
import { SensorAuto } from 'app/domain/monitor/sensor/sensor-auto';

@Injectable()
export class MockMonitorsService implements Monitors {
    removeUserRoleFromMonitor(monitorid: string, userid: string, role: string): Observable<void> {
        throw new Error("Method not implemented.");
    }
    getUsersOnMonitor(id: string): Observable<import("../../../domain/monitor/UserWithRole").UserWithRole[]> {
        throw new Error("Method not implemented.");
    }
    addKey(email: string, monitor: string, role: string, message: string) {
        throw new Error("Method not implemented.");
    }
    public getAllForStatusOverview(): Observable<MonitorStatusOverview[]> {
        return null;
    }


    public getAll(): Observable<Monitor[]> {
        return observableOf([]);
    }

    public singleMonitor(id: number): Observable<Monitor> {
        return observableOf(new Monitor(
            '2',
            987654321,
            0,
            'Risylog V5',
            [],
            MonitorPrivacy.PUBLIC,
            '2017-11-09T19:54:07Z',
            new MonitorDetails('', '', '', '', '', ''),
            [],
            false, 1
        ));
    }

    public deleteMonitor(id: string): Observable<string> {
        return observableOf('');
    }

    public addMonitor(activationCode: string, serialNumber: string): Observable<any> {
        return observableOf({
            'id': 'ae2221af-8db8-442f-9a98-eec30a9923ff',
            'backendId': 300,
            'serialNumber': 100000001,
            'monitorType': 'Risylog V5',
            'channels': [MonitorChannel.DIGITAL, MonitorChannel.DIGITAL],
            'privacy': 'PUBLIC',
            'dateTimeAdded': '2017-11-09T19:54:07Z',
            'details': {
                'name': 'test monitor name',
                'address': 'test monitor address',
                'system': 'test monitor system',
                'circuit': 'test monitor circuit',
                'details': 'test monitor details',
                'comments': 'test monitor comments'
            },
            'sensors': [
                new MonitorSensor('5', 0, new Date('2017-11-20T23:43:33.501+01:00'), 'Closed', 0, 'Sensor 5', SensorStatus.Ok, '', '', '', MonitorInputType.DIGITAL_OPEN_CLOSED, []),
                new MonitorSensor('5', 1, new Date('2017-11-20T23:43:33.501+01:00'), 'Closed', 0, 'Sensor 5', SensorStatus.Ok, '', '', '', MonitorInputType.DIGITAL_OPEN_CLOSED, [])
            ]
        });
    }

    public updateMonitorDetails(id: string,
                                monitorDetails: MonitorDetails): Observable<any> {
        return observableOf({
            'id': 'ae2221af-8db8-442f-9a98-eec30a9923ff',
            'backendId': 300,
            'serialNumber': 100000001,
            'monitorType': 'Risylog V5',
            'channels': [MonitorChannel.DIGITAL, MonitorChannel.DIGITAL],
            'privacy': 'PUBLIC',
            'dateTimeAdded': '2017-11-09T19:54:07Z',
            'details': {
                'name': 'test monitor name',
                'address': 'test monitor address',
                'system': 'test monitor system',
                'circuit': 'test monitor circuit',
                'details': 'test monitor details',
                'comments': 'test monitor comments'
            },
            'sensors': [
                new MonitorSensor('1', 0, new Date('2017-11-20T23:43:33.501+01:00'), 'Closed', 0, 'Sensor 1', SensorStatus.Ok, '', '', '', MonitorInputType.DIGITAL_OPEN_CLOSED, []),
                new MonitorSensor('2', 1, new Date('2017-11-20T23:43:33.501+01:00'), 'Closed', 0, 'Sensor 2', SensorStatus.Ok, '', '', '', MonitorInputType.DIGITAL_OPEN_CLOSED, [])
            ]
        });
    }


    public getFirmwareUpgradeStatus(id: string): Observable<FirmwareUpgradeStatus> {
        return observableOf(new FirmwareUpgradeStatus(FirmwareUpgradeState.Available, 1222, 1223));
    }

    public requestFirmwareUpgrade(id: string): Observable<FirmwareUpgradeStatus> {
        return observableOf(new FirmwareUpgradeStatus(FirmwareUpgradeState.Busy, 1222, 1223));
    }

    public getMonitorMeasurements(id: string, from: Moment, to: Moment): Observable<any> {
        throw new Error('not implemented yet');
    }

    public addMonitorSensor(id: string,
                            sensor: SensorAnalog | SensorDigitalPulse | SensorDigitalOpenClosed | SensorPredefined | SensorAuto,
                            canAddSensor: boolean,
                            sensorId: string): Observable<any> {
        return observableOf(true);
    }

    public getForDetailOverview(id: string): Observable<DetailStatusOverview> {
        const upperLimits = [
            {
                datetime: '2017-12-15T10:09:00.123+01:00',
                value: 5
            },
            {
                datetime: '2017-12-15T13:09:00.123+01:00',
                value: 7
            }
        ];

        const lowerLimits = [
            {
                datetime: '2017-12-15T10:09:00.123+01:00',
                value: 15
            },
            {
                datetime: '2017-12-15T13:09:00.123+01:00',
                value: 17
            }
        ];


        return observableOf(null);
    }

    public listAllSensorCategories(): Observable<SensorCategory[]> {
        return observableOf([
            new SensorCategory(
                'b0bcc149-c503-44b9-b563-30bf83639f09',
                'Water Chemistry',
                [
                    new SensorCategoryType(
                        '3163e2ec-847d-4c17-8ff4-dbf07f4af5f7',
                        'Acidity',
                        [
                            new SensorCategoryUnit(
                                'e45278b9-9903-4e25-a3a7-b723aac68492',
                                'pH'
                            )
                        ]
                    ),
                    new SensorCategoryType(
                        'a714e516-1541-4145-bb01-d3cd2bdb3c9c',
                        'Hardness',
                        [
                            new SensorCategoryUnit(
                                '7137542d-4b87-4d4a-bfb1-5506c4b947bb',
                                '°F'
                            ),
                            new SensorCategoryUnit(
                                'ef49e4b2-adff-4f17-9c78-2cae6401c4db',
                                '°D'
                            )
                        ]
                    ),
                    new SensorCategoryType(
                        '13c35a57-db98-4bfc-9d73-9496bcff44af',
                        'Conductivity',
                        [
                            new SensorCategoryUnit(
                                '1cdd8bf2-8c12-4b18-953e-24bbfae75c51',
                                'µS/cm'
                            )
                        ]
                    )
                ]
            ),
            new SensorCategory(
                '55428be8-c674-430f-8162-d84c51c2376b',
                'Replenishment',
                [
                    new SensorCategoryType(
                        'dd433237-e0e7-4f88-8e56-90819a2c7c4c7',
                        'Quantity',
                        [
                            new SensorCategoryUnit(
                                '6235fcb5-f27e-4d00-962a-79d43c595e60',
                                'l'
                            )
                        ]
                    )
                ]
            )
        ])
    }

    public listAllPredefinedSensors(channelType: MonitorChannel): Observable<SensorPredefinedList[]> {
        switch (channelType) {
            case MonitorChannel.ANALOG:
                return observableOf([
                    new SensorPredefinedList(
                        'e75027cf-26b5-4929-a71b-d5641547e6e6',
                        'EasyFerm Plus PHI Arc 120',
                        MonitorInputType.ANALOG_MA,
                        '4840ee71-334a-4f18-9646-b1c0f8bab9a2',
                        'f257dfbe-8189-4029-bb20-1e335f0062f3',
                        '92da89c6-fae2-45f9-8ed8-0a13a9ad6a23',
                        new SensorPredefinedListAnalog(
                            4,
                            20,
                            0,
                            14,
                            'PT20S',
                            'PT1H'
                        ),
                    ),
                    new SensorPredefinedList(
                        '8420cf18-4ef7-422b-9886-243b018ef598',
                        'Huba Control type 505.93B',
                        MonitorInputType.ANALOG_V,
                        '30277714-1722-48fe-83e0-145caee14697',
                        '44d769a1-3599-4a0b-a854-3b270abcf659',
                        'e00954ac-7abb-48ae-9a78-48eb7932acac',
                        new SensorPredefinedListAnalog(
                            .5,
                            3.5,
                            0,
                            14,
                            'PT5S',
                            'PT1H'
                        )
                    )
                ]);

            case MonitorChannel.DIGITAL:
                return observableOf([
                    new SensorPredefinedList(
                        'e75027cf-26b5-4929-a71b-d5641547e6e6',
                        'EasyFerm Plus PHI Arc 120',
                        MonitorInputType.DIGITAL_PULSE,
                        '4840ee71-334a-4f18-9646-b1c0f8bab9a2',
                        'f257dfbe-8189-4029-bb20-1e335f0062f3',
                        '92da89c6-fae2-45f9-8ed8-0a13a9ad6a23',
                        new SensorPredefinedListDigitalPulse(
                            1
                        )
                    )
                ])
        }
    }

    setMonitorPrivacyLevel(privacyLevel: MonitorPrivacy, id: string): Observable<any> {
        return observableOf({
            'id': 'ae2221af-8db8-442f-9a98-eec30a9923ff',
            'backendId': 300,
            'serialNumber': 100000001,
            'monitorType': 'Risylog V5',
            'channels': [MonitorChannel.DIGITAL, MonitorChannel.DIGITAL],
            'privacy': 'PUBLIC',
            'dateTimeAdded': '2017-11-09T19:54:07Z',
            'details': {
                'name': 'test monitor name',
                'address': 'test monitor address',
                'system': 'test monitor system',
                'circuit': 'test monitor circuit',
                'details': 'test monitor details',
                'comments': 'test monitor comments'
            },
            'sensors': [
                new MonitorSensor('1', 0, new Date('2017-11-20T23:43:33.501+01:00'), 'Closed', 0, 'Sensor 1', SensorStatus.Ok, '', '', '', MonitorInputType.DIGITAL_OPEN_CLOSED, []),
                new MonitorSensor('2', 1, new Date('2017-11-20T23:43:33.501+01:00'), 'Closed', 0, 'Sensor 2', SensorStatus.Ok, '', '', '', MonitorInputType.DIGITAL_OPEN_CLOSED, [])
            ]
        });
    }

    setPressureDelta(monitorId: string, sensorId: string, limits: { upperLimit?: { date; value }; lowerLimit?: { date; value } }): void {
    }

    setSensorAlarm(monitorId: string, sensorId: string, toggle: boolean): void {
    }

    setSensorLimits(monitorId: string, sensorId: string, limits: { upperLimit?: { date; value }; lowerLimit?: { date; value } }): void {
    }

    setChangedOrder(id: string, order: { [p: string]: number }): Observable<any> {
        return undefined;
    }

    getSingleMonitorForStatusOverview(id: string): Observable<MonitorStatusOverview> {
        return undefined;
    }

}

