import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry, MatSidenav, MatDialog, MatDialogConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Event, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { AuthenticationService } from '../infrastructure/http/authentication/authentication.service';
import { NavigationService } from '../infrastructure/navigation/navigation.service';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
import { MediaObserver } from '@angular/flex-layout';
import { PopUpModalComponent } from "../presentation/pages/popup-modal/popup-modal.component";
var AppComponent = /** @class */ (function () {
    function AppComponent(router, location, navigationService, translate, authenticationService, iconRegistry, sanitizer, media, matDialog, userService, viewData) {
        var _this = this;
        this.matDialog = matDialog;
        this.userService = userService;
        this.mode = 'side';
        this.opened = true;
        this.disableClose = true;
        this.router = router;
        this.location = location;
        this.navigationService = navigationService;
        this.authenticationService = authenticationService;
        this.media = media;
        router.events.subscribe(function (value) {
            if (value instanceof NavigationStart) {
                _this.setCurrentMenuTitle(value);
            }
        });
        this.userService.getNotifications().subscribe(function (s) {
            return _this.notifNumber = s.length;
        });
        this.setAuthenticatedUser();
        this.setTranslations(translate);
        this.setSvgIcons(iconRegistry, sanitizer);
        this.watcher = this.media.asObservable().subscribe(function () {
            _this.updateNavigationParametersByMediaChanged();
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var dialogConfig = new MatDialogConfig();
        var userAgent = navigator.userAgent;
        var isIE = userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
        if (isIE) {
            var modalDialog = this.matDialog.open(PopUpModalComponent, dialogConfig);
        }
        else {
            this.router.resetConfig(this.navigationService.getRoutes());
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.watcher.unsubscribe();
    };
    AppComponent.prototype.canShowMenu = function () {
        return this.authenticationService.isAuthenticated() && this.authenticationService.hasToken();
    };
    AppComponent.prototype.toggleSidenav = function () {
        this.sidenav.toggle();
    };
    AppComponent.prototype.logout = function () {
        this.authenticationService.logout();
    };
    AppComponent.prototype.chooseMenuItem = function () {
        if (!this.disableClose) {
            this.toggleSidenav();
        }
    };
    AppComponent.prototype.canShowHeader = function () {
        return !isNil(this.currentMenuTitle);
    };
    AppComponent.prototype.getHeaderTitle = function () {
        var result = '';
        if (this.canShowHeader()) {
            result = this.currentMenuTitle;
        }
        return result;
    };
    AppComponent.prototype.setCurrentMenuTitle = function (event) {
        var currentPath = event.url.replace('/', '');
        var currentPage = this.navigationService.getCurrentPage(currentPath);
        if (!isNil(currentPage)) {
            if (currentPage.showInMenu) {
                this.currentMenuTitle = currentPage.name;
            }
            else {
                if (!isNil(currentPage.parentPage) && currentPage.parentPage.showInMenu) {
                    this.currentMenuTitle = currentPage.name;
                }
                else {
                    this.currentMenuTitle = null;
                }
            }
        }
        else {
            this.currentMenuTitle = null;
        }
    };
    AppComponent.prototype.setAuthenticatedUser = function () {
        var _this = this;
        this.authenticationService.getAuthenticatedUser().subscribe(function (user) {
            _this.authenticatedUser = user;
        });
    };
    AppComponent.prototype.setTranslations = function (translate) {
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
    };
    AppComponent.prototype.setSvgIcons = function (iconRegistry, sanitizer) {
        iconRegistry
            .addSvgIcon('logo', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo.svg'))
            .addSvgIcon('monitor', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor.svg'))
            .addSvgIcon('monitor-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor.svg'))
            .addSvgIcon('monitor-white', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-white.svg'))
            .addSvgIcon('monitor-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-add.svg'))
            .addSvgIcon('monitor-manage', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-manage.svg'))
            .addSvgIcon('monitor-list', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-list.svg'))
            .addSvgIcon('setup', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-setup.svg'))
            .addSvgIcon('sensor-open', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sensor-open.svg'))
            .addSvgIcon('sensor-closed', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sensor-closed.svg'))
            .addSvgIcon('location', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-location.svg'))
            .addSvgIcon('card-details', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-card-details.svg'))
            .addSvgIcon('card-label', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-card-label.svg'))
            .addSvgIcon('card-comment', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-card-comment.svg'))
            .addSvgIcon('menu', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-menu.svg'))
            .addSvgIcon('user-account', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-user-account.svg'))
            .addSvgIcon('user-account-mobile', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-user-account-mobile.svg'))
            .addSvgIcon('chevron-down', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-chevron-down.svg'))
            .addSvgIcon('view', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view.svg'))
            .addSvgIcon('view-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-blue.svg'))
            .addSvgIcon('view-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-add.svg'))
            .addSvgIcon('view-manage', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-manage.svg'))
            .addSvgIcon('view-add-action-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-add-action-add.svg'))
            .addSvgIcon('view-add-action-remove', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-add-action-remove.svg'))
            .addSvgIcon('sensor', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sensor.svg'))
            .addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-add.svg'))
            .addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-delete.svg'))
            .addSvgIcon('handle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-handle.svg'))
            .addSvgIcon('clock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-clock.svg'))
            .addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-close.svg'))
            .addSvgIcon('open-with', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/open_with.svg'));
    };
    AppComponent.prototype.updateNavigationParametersByMediaChanged = function () {
        this.mode = this.getNavigationMode();
        this.opened = this.getNavigationOpened();
        this.disableClose = this.getDisableCloseNavigation();
    };
    AppComponent.prototype.getNavigationMode = function () {
        return this.media.isActive('gt-sm') ? 'side' : 'over';
    };
    AppComponent.prototype.getNavigationOpened = function () {
        return this.media.isActive('gt-sm');
    };
    AppComponent.prototype.getDisableCloseNavigation = function () {
        return this.media.isActive('gt-sm');
    };
    return AppComponent;
}());
export { AppComponent };
