import { Component, OnInit, Input } from '@angular/core';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { UserWithRole } from 'app/domain/monitor/UserWithRole';
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationDialogComponent } from 'app/presentation/pages/confirmation-dialog/confirmation-dialog.component';
import { concat } from 'lodash';

@Component({
  selector: 'app-details-users',
  templateUrl: './details-users.component.html',
  styleUrls: ['./details-users.component.scss']
})
export class DetailsUsersComponent implements OnInit {
  @Input("monitor") monitor: MonitorStatusOverview;

  userRoleBindings: {user: UserWithRole, role: string}[];

  constructor(private monitorService: MonitorDataService, private dialogService: MatDialog) {
    
   }

  ngOnInit() {
    this.monitorService.getUsersOnMonitor(this.monitor.id).subscribe(users => this.userRoleBindings = this.usersToBindings(users))
  }

  private usersToBindings(users: UserWithRole[]): {user: UserWithRole, role: string}[] {
    return users.reduce((previous, current) => concat(previous, current.roles.sort().map(role => ({role, user: current}))), [])
  }

  canDelete(user) {
    return this.monitor.permissions && user.user.user && (user.role == ('ADMINISTRATOR') && this.monitor.permissions.includes('DELETE_ADM')) || (user.role == ('VIEWER') && this.monitor.permissions.includes('DELETE_VWR'))
  }

  delete(user: {user: UserWithRole, role: string}) {
    const dialog: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(ConfirmationDialogComponent, {data: {textContent: "USER.DELETE_OTHER_USER"}})
    dialog.afterClosed().subscribe(confirm => {
      if(confirm) {
        this.monitorService.removeUserRoleFromMonitor(this.monitor.id, user.user.user.id, user.role).subscribe(() => this.monitorService.getUsersOnMonitor(this.monitor.id).subscribe(users => this.userRoleBindings =  this.usersToBindings(users)));

      }
    })
  }

}
