/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grid-delete-monitor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./grid-delete-monitor.component";
import * as i10 from "../../../../infrastructure/data/monitor-data.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "@angular/router";
import * as i13 from "../../../../application/notification/notification.service";
var styles_GridDeleteMonitorComponent = [i0.styles];
var RenderType_GridDeleteMonitorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridDeleteMonitorComponent, data: {} });
export { RenderType_GridDeleteMonitorComponent as RenderType_GridDeleteMonitorComponent };
export function View_GridDeleteMonitorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "btn-delete-monitor"], ["color", "warn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteMonitor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "center center"; _ck(_v, 1, 0, currVal_0); var currVal_3 = "warn"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 3).disabled || null); var currVal_2 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("GENERAL.DELETE")); _ck(_v, 4, 0, currVal_4); }); }
export function View_GridDeleteMonitorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid-delete-monitor", [], null, null, null, View_GridDeleteMonitorComponent_0, RenderType_GridDeleteMonitorComponent)), i1.ɵdid(1, 49152, null, 0, i9.GridDeleteMonitorComponent, [i10.MonitorDataService, i11.MatDialog, i12.Router, i13.NotificationService, "ErrorTranslator"], null, null)], null, null); }
var GridDeleteMonitorComponentNgFactory = i1.ɵccf("app-grid-delete-monitor", i9.GridDeleteMonitorComponent, View_GridDeleteMonitorComponent_Host_0, {}, {}, []);
export { GridDeleteMonitorComponentNgFactory as GridDeleteMonitorComponentNgFactory };
