import { AmChart } from '@amcharts/amcharts3-angular';
import { Injectable } from '@angular/core';
import { filter } from 'lodash';

@Injectable()
export class ChartService {

    private charts: AmChart = [];
    private lastZoomedTo: { startDate: Date, endDate: Date }


    // Collection manipulation
    public addChart(chart: AmChart) {

        this.removeZoomedListenerFromCharts();
        let isCopyed = false;
        (this.charts || []).forEach(x => {
            if (x === chart) {
                x = chart;
                isCopyed = true;
            }
        });
        if (!isCopyed)
            this.charts.push(chart);
        if (this.lastZoomedTo) {
            chart.ignoreZoom = false;
            this.zoomOnRender(chart);
        }
        this.addZoomedListenerToCharts();
    }


    public updateChart(chart: AmChart) {
        this.removeZoomedListenerFromCharts();        
        (this.charts || []).forEach(x => {
            if (x === chart) {
                x = chart;                
            }
        });        
        this.addZoomedListenerToCharts();
    }


    public isChartExist(chart: AmChart, customChartId: string) {
        return filter(this.charts, arrchart => arrchart === chart && arrchart.chartCustomId === customChartId).length>0;
    } 

    public removeChart(chart: AmChart) {
        this.removeZoomedListenerFromCharts()
        this.charts = filter(this.charts, arrchart => arrchart !== chart);
        this.addZoomedListenerToCharts();
    };

    public pickedANewDate() {
        this.lastZoomedTo = null;
    }

    public onDestroy() {
        this.removeZoomedListenerFromCharts();
        this.charts = [];
        this.lastZoomedTo = null;
    }


    // Listner and event handling

    private addZoomedListenerToCharts() {
        if (this.charts.length > 1) {
            for (let i = 0; i < this.charts.length; i++) {
                this.charts[i].addListener('zoomed', this.handleZoomedEvent.bind(this));
            }
        }
    }

    private removeZoomedListenerFromCharts() {
        if (this.charts.length > 1) {
            for (let i = 0; i < this.charts.length; i++) {
                this.charts[i].removeListener(this.charts[i], 'zoomed', this.handleZoomedEvent.bind(this));
            }
        }
    }

    private handleZoomedEvent(event) {
        this.charts.forEach(chart =>
            event.chart !== chart && chart.zoomToDates(event.startDate, event.endDate));
        this.lastZoomedTo = { startDate: event.startDate, endDate: event.endDate };
    }

    private zoomOnRender(chart) {
        // When a new chart is added, a 'zoom' event is instantly broadcast and resets the class var. This is prevented by copying the current state before said event occurs.
        const preserved = { ...this.lastZoomedTo }
        chart.addListener('rendered', (event) => chart.zoomToDates(preserved.startDate, preserved.endDate));
    }
}
