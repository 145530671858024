<app-registration-content>
  <div class="verify__div">
    <div *ngIf="isVerifying">
      <mat-progress-spinner strokeWidth="3" [diameter]="50" mode="indeterminate"></mat-progress-spinner>
      <p>{{ "VERIFY.VERIFYING" | translate }}</p>
    </div>

    <div *ngIf="!isVerifying">
      <div *ngIf="!errorHasHappened">
        <p fxFill>{{ 'VERIFY.EMAIL_VERIFIED' | translate }}</p>
        <br>
        <a fxFill mat-button routerLink="/login">{{ 'VERIFY.GO_TO_LOGIN' | translate }}</a>
      </div>
      <div *ngIf="errorHasHappened">
        <p fxFill>{{ 'ERRORS.EMAIL_NOT_VERIFIED' | translate }}</p>
        <br>
        <a fxFill mat-button routerLink="/login">{{ 'VERIFY.GO_TO_LOGIN' | translate }}</a>
      </div>

    </div>

  </div>
</app-registration-content>
