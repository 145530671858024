
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {isUndefined} from 'lodash';
import {Observable, Subject} from 'rxjs';

import {validatePasswordMatch} from '../../../../application/validators/validate-password-match';
import {Users} from '../../../../infrastructure/http/user/users';
import {TranslateService} from '@ngx-translate/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnDestroy {

  public resetPasswordForm: FormGroup;
  public requestedPasswordReset = false;
  private resetPerforming = false;
  private userService: Users;
  private unSubscribeUsersOnDestroy = new Subject<void>();
  private router: Router;
  private key: string;
  private translateService: TranslateService;

  constructor(formBuilder: FormBuilder,
              userService: HttpUsersService,
              activatedRoute: ActivatedRoute,
              router: Router,
              translateService: TranslateService) {
    this.userService = userService;
    this.router = router;

    this.setResetKey(activatedRoute.queryParams);

    this.createResetPasswordForm(formBuilder);
    this.translateService = translateService;
  }

  public ngOnDestroy(): void {
    this.unSubscribeUsersOnDestroy.next();
    this.unSubscribeUsersOnDestroy.complete();
  }

  public resetPassword(form: any, isValid: boolean): void {
    if (isValid) {
      this.resetPerforming = true;

      this.userService.resetPassword(
        this.key,
        form.password).pipe(
        takeUntil(this.unSubscribeUsersOnDestroy))
        .subscribe(() => {
          this.requestedPasswordReset = true;
        }, (error: HttpErrorResponse) => {
          this.resetPerforming = false;
          const parsedError = JSON.parse(error.error);
          let errorMessage = parsedError.message;
          this.translateService.get(`ERRORS.${error.error.type}`).pipe(
            takeUntil(this.unSubscribeUsersOnDestroy))
            .subscribe((translation: any) => {
              if (translation !== `ERRORS.${error.error.type}`) {
                errorMessage = translation;
              }
            });
          throw new Error(errorMessage);
        });
    }
  }

  public canResetPassword(): boolean {
    return this.resetPasswordForm.valid && !this.resetPerforming;
  }

  private createResetPasswordForm(formBuilder: FormBuilder) {
    this.resetPasswordForm = formBuilder.group({
      'password': ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      'passwordConfirm': ['', [
        Validators.required,
        Validators.minLength(6)
      ]]
    }, {
      validator: validatePasswordMatch
    });
  }

  private setResetKey(queryParams: Observable<Params>): void {
    queryParams.subscribe((params) => {
      this.key = params.key;

      if (isUndefined(this.key)) {
        this.router.navigateByUrl('/login');
      }
    });
  }

}
