var SensorPredefinedListAnalog = /** @class */ (function () {
    function SensorPredefinedListAnalog(measurementMinimum, measurementMaximum, valueMinimum, valueMaximum, pollingRate, cloudStorageRate) {
        this._measurementMinimum = measurementMinimum;
        this._measurementMaximum = measurementMaximum;
        this._valueMinimum = valueMinimum;
        this._valueMaximum = valueMaximum;
        this._pollingRate = pollingRate;
        this._cloudStorageRate = cloudStorageRate;
    }
    Object.defineProperty(SensorPredefinedListAnalog.prototype, "measurementMinimum", {
        get: function () {
            return this._measurementMinimum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedListAnalog.prototype, "measurementMaximum", {
        get: function () {
            return this._measurementMaximum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedListAnalog.prototype, "valueMinimum", {
        get: function () {
            return this._valueMinimum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedListAnalog.prototype, "valueMaximum", {
        get: function () {
            return this._valueMaximum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedListAnalog.prototype, "pollingRate", {
        get: function () {
            return this._pollingRate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedListAnalog.prototype, "cloudStorageRate", {
        get: function () {
            return this._cloudStorageRate;
        },
        enumerable: true,
        configurable: true
    });
    return SensorPredefinedListAnalog;
}());
export { SensorPredefinedListAnalog };
