
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MatChipInputEvent, MAT_DIALOG_DATA } from '@angular/material';
import {COMMA, ENTER, TAB} from "@angular/cdk/keycodes";
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { Users } from 'app/infrastructure/http/user/users';
import { AuthenticationService } from 'app/infrastructure/http/authentication/authentication.service';
import { delay, switchMap } from 'rxjs/operators';
import { User } from 'app/domain/user';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  removable=true;
  model = {
    emails: [],
    role: "VIEWER",
    text:""
  }
  loading = false;

  public showError = false;
  @ViewChild("shareForm", {static: false}) form;
  isDirty: boolean;
  user: User;
  

  constructor(private dialogRef: MatDialogRef<ShareComponent>, @Inject(MAT_DIALOG_DATA) private monitor: MonitorStatusOverview,
       private monitorService: MonitorDataService, private authService: AuthenticationService ) {
   }

  ngOnInit() {
    this.authService.getAuthenticatedUser().subscribe(user =>
        {this.model.text = `The user ${user.firstName} ${user.lastName} would like to share monitor ${this.monitor.serialNumber} with you as ${this.model.role}. If you accept, this monitor will be added to your monitor overview.`; this.user = user;});
  }

  add(event: MatChipInputEvent) {

    if(event.input.value && !event.input.value.includes("@")) {
      event.input.style.color = "red";
      return;
    }

    if(event.input.value && event.input.value.trim()) {
      this.model.emails.push(event.input.value)
      event.input.value = ""
    }
  }

  remove(a: string) {
    this.model.emails = this.model.emails.filter(s => a !== s)
  }


  submit() {
    if(this.model.emails && this.model.emails.length > 0) {
      this.loading = true;
      observableForkJoin(this.model.emails.map(email => this.monitorService.addKey(email, this.monitor.id, this.model.role, this.model.text || null)))
        .subscribe(() => {
          this.loading = false;
          return this.dialogRef.close();
        }, () => this.dialogRef.close())
    } else {
      this.showError = true;
    }
  }

  removeStyle(obj) {
    obj.style.color = "";
    this.showError = false;
  }

  dirty() {
    this.isDirty = true;
  }

  dropBoxUpdate() {
    if(!this.isDirty) {
      this.model.text = `The user ${this.user.firstName} ${this.user.lastName} would like to share monitor ${this.monitor.serialNumber} with you as ${this.model.role}. If you accept, this monitor will be added to your monitor overview.`;
    }
  }

}

