/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/router";
import * as i14 from "../login/registration-content/registration-content.component.ngfactory";
import * as i15 from "../login/registration-content/registration-content.component";
import * as i16 from "./verify.component";
import * as i17 from "../../../infrastructure/http/user/http-users.service";
var styles_VerifyComponent = [i0.styles];
var RenderType_VerifyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifyComponent, data: {} });
export { RenderType_VerifyComponent as RenderType_VerifyComponent };
function View_VerifyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"], ["strokeWidth", "3"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(2, 49152, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"], mode: [2, "mode"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_7 = 50; var currVal_8 = "3"; var currVal_9 = "indeterminate"; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; var currVal_3 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 100 : null); var currVal_5 = ((i1.ɵnov(_v, 2).mode === "determinate") ? i1.ɵnov(_v, 2).value : null); var currVal_6 = i1.ɵnov(_v, 2).mode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("VERIFY.VERIFYING")); _ck(_v, 4, 0, currVal_10); }); }
function View_VerifyComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["fxFill", ""]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i8.FlexFillDirective, [i1.ElementRef, i9.StyleUtils, i8.FlexFillStyleBuilder, i9.MediaMarshaller], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "a", [["fxFill", ""], ["mat-button", ""], ["routerLink", "/login"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_MatAnchor_0, i10.RenderType_MatAnchor)), i1.ɵdid(7, 180224, null, 0, i11.MatAnchor, [i12.FocusMonitor, i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 671744, null, 0, i13.RouterLinkWithHref, [i13.Router, i13.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(9, 671744, null, 0, i8.FlexFillDirective, [i1.ElementRef, i9.StyleUtils, i8.FlexFillStyleBuilder, i9.MediaMarshaller], null, null), (_l()(), i1.ɵted(10, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_7 = "/login"; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("VERIFY.EMAIL_VERIFIED")); _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 7).disabled ? (0 - 1) : (i1.ɵnov(_v, 7).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_5 = i1.ɵnov(_v, 8).target; var currVal_6 = i1.ɵnov(_v, 8).href; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("VERIFY.GO_TO_LOGIN")); _ck(_v, 10, 0, currVal_8); }); }
function View_VerifyComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["fxFill", ""]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i8.FlexFillDirective, [i1.ElementRef, i9.StyleUtils, i8.FlexFillStyleBuilder, i9.MediaMarshaller], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "a", [["fxFill", ""], ["mat-button", ""], ["routerLink", "/login"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_MatAnchor_0, i10.RenderType_MatAnchor)), i1.ɵdid(7, 180224, null, 0, i11.MatAnchor, [i12.FocusMonitor, i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 671744, null, 0, i13.RouterLinkWithHref, [i13.Router, i13.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(9, 671744, null, 0, i8.FlexFillDirective, [i1.ElementRef, i9.StyleUtils, i8.FlexFillStyleBuilder, i9.MediaMarshaller], null, null), (_l()(), i1.ɵted(10, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_7 = "/login"; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ERRORS.EMAIL_NOT_VERIFIED")); _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 7).disabled ? (0 - 1) : (i1.ɵnov(_v, 7).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_5 = i1.ɵnov(_v, 8).target; var currVal_6 = i1.ɵnov(_v, 8).href; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("VERIFY.GO_TO_LOGIN")); _ck(_v, 10, 0, currVal_8); }); }
function View_VerifyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.errorHasHappened; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errorHasHappened; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_VerifyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "app-registration-content", [], null, null, null, i14.View_RegistrationContentComponent_0, i14.RenderType_RegistrationContentComponent)), i1.ɵdid(1, 49152, null, 0, i15.RegistrationContentComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [["class", "verify__div"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVerifying; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.isVerifying; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_VerifyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verify", [], null, null, null, View_VerifyComponent_0, RenderType_VerifyComponent)), i1.ɵdid(1, 180224, null, 0, i16.VerifyComponent, [i17.HttpUsersService, i13.Router, i13.ActivatedRoute, i7.TranslateService], null, null)], null, null); }
var VerifyComponentNgFactory = i1.ɵccf("app-verify", i16.VerifyComponent, View_VerifyComponent_Host_0, {}, {}, []);
export { VerifyComponentNgFactory as VerifyComponentNgFactory };
