<mat-card>
	<mat-card-header>
		<mat-card-title
            class="antialiased" fxLayout.lt-md="column" fxLayout="row" fxLayout.xs="row" fxLayoutAlign="start start">
            <h3>{{'ACTIONS.CHAT' | translate}}</h3>
        </mat-card-title>
	</mat-card-header>
 	<mat-card-content fxLayout="column">
        <p *ngIf="(messages||[]).length <= 0">No messages have been sent so far.</p>

 		<div  *ngFor="let message of messages" fxLayout="row" [class.right]="isYours(message)" class="messageContainer">
             <div fxLayout="column">
                    <p class="bubble" [class.yours]="isYours(message)">
                        {{message.text}}
                    </p>
                    <small class="info">{{message.sourceName}} - {{message.creationtime | amDateFormat:"DD/MM/YYYY HH:mm" }}</small>
             </div>
         </div>

         <div fxLayout="row" #element>
            <mat-form-field >
                <mat-label>Chat</mat-label>
                <input matInput placeholder="{{'ACTIONS.SENDCHAT' | translate}}"  [formControl]="chatFormControl" #messageinput>
                <mat-error *ngIf="true">
                    Your text message cannot be longer than 300 characters.
                  </mat-error>
            </mat-form-field>
            <button  mat-stroked-button color="primary" type="submit" (click)="sendMessage(messageinput)"  >{{'ACTIONS.SEND' | translate}}</button>
        </div>
	</mat-card-content>
</mat-card>