var SensorCategoryUnit = /** @class */ (function () {
    function SensorCategoryUnit(id, name) {
        this._id = id;
        this._name = name;
    }
    Object.defineProperty(SensorCategoryUnit.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorCategoryUnit.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    return SensorCategoryUnit;
}());
export { SensorCategoryUnit };
