import {AbstractControl, ValidatorFn} from '@angular/forms';
import {isNull} from 'lodash';
import * as moment from 'moment';

export function validateDurationGreaterThan(errorType: string): ValidatorFn {
  return (abstractControl: AbstractControl): { [key: string]: any } => {
    const parentControl = abstractControl.parent;

    const pollingRate = parentControl.get('pollingRate');
    const cloudStorageRate = parentControl.get('cloudStorageRate');
    const errorBag = [];

    if (!isNull(pollingRate) && !isNull(cloudStorageRate)) {
      if (moment.duration(pollingRate.value).asSeconds() > moment.duration(cloudStorageRate.value).asSeconds()) {
        errorBag[errorType] = true;

        return errorBag;
      }
    }
  };
}


