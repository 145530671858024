var LoginComponent = /** @class */ (function () {
    function LoginComponent() {
        this.showRegistration = false;
    }
    LoginComponent.prototype.canShowRegistration = function () {
        return this.showRegistration;
    };
    LoginComponent.prototype.registerRequested = function () {
        this.showRegistration = true;
    };
    LoginComponent.prototype.loginRequested = function () {
        this.showRegistration = false;
    };
    return LoginComponent;
}());
export { LoginComponent };
