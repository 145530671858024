export const environment = {
  production: false,
  origin: 'https://resus-cloud-api-staging.azurewebsites.net',
  client_id: 'ninix-spa',
  client_secret: 'EzEQd27aIRDKgoK14q6W',
  app_version: require('../../package.json').version,
  recaptcha_key: '6LcnNzwUAAAAAFM5hjUzlpYiw5Tthk0r5-QpnkUm',
  isDebugMode: false,
  pollingIntervalInSeconds: 30,
  prefetchMeasurements: false,
  digits: 2
};