<mat-card *ngIf="monitorDetailStatusOverview">
    <mat-card-header>
        <mat-card-title
                class="antialiased" fxLayout="row">
            <h3 fxFlex>{{ 'MONITOR.INFORMATION' | translate }}</h3>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list [disableRipple]="true">
            <mat-list-item [disableRipple]="true" *ngIf="monitorDetailStatusOverview.details.address">
                <mat-icon svgIcon="location" mat-list-icon ></mat-icon>
                <p>{{monitorDetailStatusOverview.details.address}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="monitorDetailStatusOverview.details.system">
                <mat-icon svgIcon="card-details" mat-list-icon ></mat-icon>
                <p>{{monitorDetailStatusOverview.details.system}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="monitorDetailStatusOverview.serialNumber" >
                <mat-icon svgIcon="card-label" mat-list-icon></mat-icon>
                <p>{{monitorDetailStatusOverview.serialNumber}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="monitorDetailStatusOverview.details.circuit">
                <mat-icon svgIcon="card-label" mat-list-icon></mat-icon>
                <p>{{monitorDetailStatusOverview.details.circuit}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="monitorDetailStatusOverview.details.details">
                <mat-icon svgIcon="card-comment" mat-list-icon style="color: #d1d7e9"></mat-icon>
                <p>{{monitorDetailStatusOverview.details.details}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="monitorDetailStatusOverview.details.comments">
                <mat-icon svgIcon="card-comment" mat-list-icon style="color: #d1d7e9 "></mat-icon>
                <p>{{monitorDetailStatusOverview.details.comments}}</p>
            </mat-list-item>

        </mat-list>
    </mat-card-content>
</mat-card>