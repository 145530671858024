import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { NotificationService } from "../../../../application/notification/notification.service";
import { ConfirmationDialogComponent } from "../../../pages/confirmation-dialog/confirmation-dialog.component";
import { isUndefined, map } from "lodash";
import { InformationDialogComponent } from "../../../pages/information-dialog/information-dialog.component";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var GridDeleteMonitorComponent = /** @class */ (function () {
    function GridDeleteMonitorComponent(monitorService, dialog, router, notificationService, errorTranslator) {
        this.monitorService = monitorService;
        this.dialog = dialog;
        this.router = router;
        this.notificationService = notificationService;
        this.errorTranslator = errorTranslator;
        this.dialogConfig = {
            panelClass: 'confirmation-dialog'
        };
    }
    GridDeleteMonitorComponent.prototype.deleteMonitor = function () {
        var _this = this;
        var currentDialogConfig = {
            panelClass: this.dialogConfig.panelClass,
            data: {
                textContent: 'MONITOR.DELETE_MONITOR_QUESTION',
                confirmText: 'GENERAL.OK',
                cancelText: 'GENERAL.CANCEL'
            }
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === true) {
                _this.monitorService
                    .deleteMonitor(_this.monitor.id)
                    .subscribe(function () {
                    _this.router.navigateByUrl('/monitors/manage')
                        .then(function () {
                        _this.notificationService.show('MONITOR.DELETED_CONFIRMATION');
                    });
                }, function (error) {
                    var interpolateParams = {};
                    var views = JSON.parse(error.error).views;
                    if (!isUndefined(views)) {
                        interpolateParams.views = map(views, 'name').join(', ');
                    }
                    _this.errorTranslator.execute(error, interpolateParams)
                        .subscribe(function (errorMessage) {
                        var currentDialogConfig = {
                            panelClass: _this.dialogConfig.panelClass,
                        };
                        var dialogRef = _this.dialog.open(InformationDialogComponent, currentDialogConfig);
                        dialogRef.componentInstance.textContent = errorMessage;
                    });
                });
            }
        });
    };
    GridDeleteMonitorComponent.prototype.agInit = function (params) {
        this.monitor = params.data;
    };
    GridDeleteMonitorComponent.prototype.editView = function () {
    };
    return GridDeleteMonitorComponent;
}());
export { GridDeleteMonitorComponent };
