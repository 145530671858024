var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as _ from "lodash";
var ChartconfigService = /** @class */ (function () {
    function ChartconfigService() {
        this.LINE_THICKNESS = 2;
        this.SCROLL_BAR_HEIGHT = 60;
        this.SCROLL_BAR_OFFSET = 50;
        this.PREPEND_APPEND_PERIOD = 1800;
        this.SCROLLBAR_COLORS_NORMAL = {
            "selectedBackgroundColor": '#FFFFFF',
            "backgroundColor": '#FFFFFF',
            "selectedGraphFillColor": '#ddd',
            "graphFillColor": '#f5f5f5',
        };
        this.SCROLLBAR_COLORS_UPPERLIMIT = {
            "selectedBackgroundColor": '#d5d5d5',
            "backgroundColor": '#d5d5d5',
            "selectedGraphFillColor": '#FFFFFF',
            "graphFillColor": '#FFFFFF',
        };
    }
    // CONFIG DISPENSER FUNCTIONS
    ChartconfigService.prototype.getStandardChartConfig = function (dataprovider, valueAxes, graphs, enableScrollbar) {
        // We can only show one piece of possibly combined graphs when this is involved.
        var scrollbarGraph = graphs[0];
        var scrollbarColors = !_.isNil(scrollbarGraph) ? scrollbarGraph.scrollbarcolors : {};
        (graphs || []).forEach(function (x) { return x.precision = -1; });
        return {
            'type': 'serial',
            'theme': 'light',
            'dataProvider': dataprovider,
            'autoMarginOffset': 30,
            'valueAxes': valueAxes,
            'graphs': graphs,
            "zoomOutText": "Zoom out",
            "numberFormatter": {
                precision: -1
            },
            'chartScrollbar': __assign({ 'graph': _.isNil(scrollbarGraph) ? "" : scrollbarGraph.id, 'scrollbarHeight': this.SCROLL_BAR_HEIGHT, 'autoGridCount': true, 'oppositeAxis': false, 'enabled': enableScrollbar, 'offset': this.SCROLL_BAR_OFFSET, 'scrollDuration': 0, 'color': '#000000', "graphLineColor": "#ddebf3", "selectedGraphLineColor": "#ddebf3" }, scrollbarColors),
            'balloon': {
                'animationDuration': 0
            },
            "valueScrollbar": {
                "autoGridCount": true,
                "color": "#000000",
                "scrollbarHeight": 50
            },
            'categoryField': 'date',
            'categoryAxis': {
                'minPeriod': 'ss',
                'parseDates': true,
                'gridAlpha': 0,
                'prependPeriods': this.PREPEND_APPEND_PERIOD,
                'appendPeriods': this.PREPEND_APPEND_PERIOD
            },
            'zoomOutOnDataUpdate': false
        };
    };
    ChartconfigService.prototype.getDigitalOpenClosedConfig = function (sensor, id, openClosedText) {
        return {
            'balloonText': sensor.name + ': [[value]]',
            'balloonFunction': function (graphDataItem) {
                var value = graphDataItem.values.value;
                return sensor.name + ': ' + openClosedText(value);
            },
            'valueField': sensor.id + id,
            'type': 'step',
            'connect': false,
            'fillAlphas': 1,
            'lineThickness': this.LINE_THICKNESS,
            'balloonColor': '#6BC441',
            'id': 'g' + id,
            'valueAxis': 'v0',
            "bullet": "round"
        };
    };
    ChartconfigService.prototype.getGraphConfig = function (sensor, id, limits, color) {
        if (limits.upperLimit && limits.lowerLimit) {
            return this.getWithBothLimitsConfig(sensor, id, limits.lowerLimit, limits.upperLimit, color);
        }
        else if (limits.upperLimit) {
            return this.getWithUpperLimitConfig(sensor, id, limits.upperLimit, color);
        }
        else if (limits.lowerLimit) {
            return this.getWithLowerLimitConfig(sensor, id, limits.lowerLimit, color);
        }
        else {
            return this.getNormalConfig(sensor, id, color);
        }
    };
    ChartconfigService.prototype.getNormalConfig = function (sensor, id, color) {
        return [{
                'valueField': sensor.id + id,
                'balloonText': sensor.name + " [" + sensor.unit + "]" + ': [[value]]\n[[description]]',
                "balloonFunction": function (item, graph) {
                    var result = graph.balloonText;
                    for (var key in item.dataContext) {
                        if (item.dataContext.hasOwnProperty(key)) {
                            if (key == graph.valueField)
                                result = result.replace("[[value]]", item.dataContext[key]);
                            else
                                result = result.replace("[[" + key + "]]", item.dataContext[key]);
                        }
                    }
                    result = result.replace(/\n/g, '<br />');
                    return result;
                },
                'balloonColor': color,
                'id': 'g' + sensor.id + id,
                'valueAxis': 'v0',
                "lineColor": color,
                'lineThickness': this.LINE_THICKNESS,
                "descriptionField": "description",
                "bulletField": "bullet",
                "bulletSizeField": "bulletSize",
                "colorField": "bulletColor",
                "bullet": "round",
                "bulletColor": "#00000000",
                "bulletSize": 0,
                'bulletHitAreaSize': 10,
                scrollbarcolors: this.SCROLLBAR_COLORS_NORMAL
            }];
    };
    ChartconfigService.prototype.getWithBothLimitsConfig = function (sensor, id, lowerLimit, upperLimit, color) {
        return [{
                "valueField": sensor.id + id,
                "balloonText": sensor.name + " [" + sensor.unit + "]" + ': [[value]]\n[[description]]',
                "balloonFunction": function (item, graph) {
                    var result = graph.balloonText;
                    for (var key in item.dataContext) {
                        if (item.dataContext.hasOwnProperty(key)) {
                            if (key == graph.valueField)
                                result = result.replace("[[value]]", item.dataContext[key]);
                            else
                                result = result.replace("[[" + key + "]]", item.dataContext[key]);
                        }
                    }
                    result = result.replace(/\n/g, '<br />');
                    return result;
                },
                "balloonColor": color,
                "id": 'g' + id + 1,
                'valueAxis': 'v0',
                "lineColor": "#ff0000",
                'lineThickness': this.LINE_THICKNESS,
                "negativeLineColor": color,
                "negativeBase": upperLimit._value,
                "descriptionField": "description",
                "bulletField": "bullet",
                "bulletSizeField": "bulletSize",
                "colorField": "bulletColor",
                "bullet": "round",
                "bulletColor": "#00000000",
                "bulletSize": 0,
                'bulletHitAreaSize': 10,
                scrollbarcolors: this.SCROLLBAR_COLORS_UPPERLIMIT
            },
            {
                "showBalloon": true,
                "balloonText": sensor.name + " [" + sensor.unit + "]" + ': [[value]]\n[[description]]',
                "balloonFunction": function (item, graph) {
                    var result = graph.balloonText;
                    for (var key in item.dataContext) {
                        if (item.dataContext.hasOwnProperty(key)) {
                            if (key == graph.valueField)
                                result = result.replace("[[value]]", item.dataContext[key]);
                            else
                                result = result.replace("[[" + key + "]]", item.dataContext[key]);
                        }
                    }
                    result = result.replace(/\n/g, '<br />');
                    return result;
                },
                "balloonColor": color,
                "valueField": sensor.id + id,
                "id": 'g' + id + 2,
                "valueAxis": 'v0',
                "lineAlpha": 0,
                "lineColor": color,
                'lineThickness': this.LINE_THICKNESS,
                "negativeLineColor": "#ff0000",
                "negativeBase": lowerLimit._value,
                "negativeLineAlpha": 1,
                "descriptionField": "description",
                "bulletField": "bullet",
                "bulletSizeField": "bulletSize",
                "colorField": "bulletColor",
                "bullet": "round",
                "bulletColor": "#00000000",
                "bulletSize": 0,
                'bulletHitAreaSize': 10
            }
        ];
    };
    ChartconfigService.prototype.getWithUpperLimitConfig = function (sensor, id, limit, color) {
        return [{
                'valueField': sensor.id + id,
                'balloonText': sensor.name + " [" + sensor.unit + "]" + ': [[value]]\n[[description]]',
                "balloonFunction": function (item, graph) {
                    var result = graph.balloonText;
                    for (var key in item.dataContext) {
                        if (item.dataContext.hasOwnProperty(key)) {
                            if (key == graph.valueField)
                                result = result.replace("[[value]]", item.dataContext[key]);
                            else
                                result = result.replace("[[" + key + "]]", item.dataContext[key]);
                        }
                    }
                    result = result.replace(/\n/g, '<br />');
                    return result;
                },
                'balloonColor': color,
                'id': 'g' + id,
                'valueAxis': 'v0',
                "lineColor": "#ff0000",
                'lineThickness': this.LINE_THICKNESS,
                "negativeLineColor": color,
                "negativeBase": limit._value,
                "descriptionField": "description",
                "bulletField": "bullet",
                "bulletSizeField": "bulletSize",
                "colorField": "bulletColor",
                "bullet": "round",
                "bulletColor": "#00000000",
                "bulletSize": 0,
                'bulletHitAreaSize': 10,
                scrollbarcolors: this.SCROLLBAR_COLORS_UPPERLIMIT
            },];
    };
    ChartconfigService.prototype.getWithLowerLimitConfig = function (sensor, id, limit, color) {
        return [{
                'valueField': sensor.id + id,
                'balloonText': sensor.name + " [" + sensor.unit + "]" + ': [[value]]\n[[description]]',
                "balloonFunction": function (item, graph) {
                    var result = graph.balloonText;
                    for (var key in item.dataContext) {
                        if (item.dataContext.hasOwnProperty(key)) {
                            if (key == graph.valueField)
                                result = result.replace("[[value]]", item.dataContext[key]);
                            else
                                result = result.replace("[[" + key + "]]", item.dataContext[key]);
                        }
                    }
                    result = result.replace(/\n/g, '<br />');
                    return result;
                },
                'balloonColor': color,
                'id': 'g' + id + 1,
                'valueAxis': 'v0',
                "lineColor": color,
                'lineThickness': this.LINE_THICKNESS,
                "negativeLineColor": "#ff0000",
                "negativeBase": limit._value,
                "descriptionField": "description",
                "bulletField": "bullet",
                "bulletSizeField": "bulletSize",
                "colorField": "bulletColor",
                "bullet": "round",
                "bulletColor": "#00000000",
                "bulletSize": 0,
                'bulletHitAreaSize': 10
            },];
    };
    ChartconfigService.prototype.getInvisConfig = function (sensor) {
        return [{
                'valueField': sensor.id + "invis",
                'id': 'g' + "invis",
                'valueAxis': 'v0',
                lineAlpha: 0,
                "lineColor": "#000000",
                'lineThickness': this.LINE_THICKNESS,
                "descriptionField": "description",
                "bulletField": "bullet",
                "bulletSizeField": "bulletSize",
                "colorField": "bulletColor",
                "bullet": "round",
                "bulletColor": "#00000000",
                "bulletSize": 0,
                'bulletHitAreaSize': 10,
                scrollbarcolors: this.SCROLLBAR_COLORS_NORMAL
            }];
    };
    return ChartconfigService;
}());
export { ChartconfigService };
