var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { takeUntil } from 'rxjs/operators';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { MessageStyle } from "../../../../../domain/messageStyle";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { MatDialog } from '@angular/material';
import { MediaObserver } from '@angular/flex-layout';
import { RequestComponent } from 'app/presentation/components/request/request.component';
var MonitorAddStep1Component = /** @class */ (function () {
    function MonitorAddStep1Component(formBuilder, monitorService, route, errorTranslator, dialog, media) {
        this.dialog = dialog;
        this.media = media;
        this.hasCompletedStepOne = new EventEmitter();
        this.MESSAGE_STYLE = MessageStyle;
        this.unSubscribeAddMonitorOnDestroy = new Subject();
        this.formBuilder = formBuilder;
        this.httpMonitorsService = monitorService;
        this.route = route;
        this.errorTranslator = errorTranslator;
        this.addMonitorForm = this.formBuilder.group({
            serialNumber: [null, [Validators.required, Validators.maxLength(255)]],
            activationCode: [null, [Validators.required, Validators.maxLength(255)]]
        });
    }
    MonitorAddStep1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.route
            .queryParams.pipe(takeUntil(this.unSubscribeAddMonitorOnDestroy))
            .subscribe(function (params) {
            _this.addMonitorForm.patchValue({
                serialNumber: params.serial_number,
                activationCode: params.activation_code
            });
        });
    };
    MonitorAddStep1Component.prototype.ngOnDestroy = function () {
        this.unSubscribeAddMonitorOnDestroy.next(true);
        this.unSubscribeAddMonitorOnDestroy.complete();
    };
    MonitorAddStep1Component.prototype.validateMonitor = function (form, isValid) {
        var _this = this;
        this.form = form;
        if (isValid) {
            this.isValidating = true;
            // Reset the http error
            this.validateHttpError = null;
            this.httpMonitorsService.addMonitor(form.activationCode.trim(), form.serialNumber).pipe(takeUntil(this.unSubscribeAddMonitorOnDestroy))
                .subscribe(function (monitor) {
                // Emit the response back to the parent component
                _this.hasCompletedStepOne.emit(monitor);
            }, function (error) {
                _this.isValidating = false;
                console.log(error.error.type);
                if (error.error.type == ("MONITOR_IS_PRIVATE")) {
                    _this.isAlreadyInUseError = true;
                    return;
                }
                _this.errorTranslator.execute(error).pipe(takeUntil(_this.unSubscribeAddMonitorOnDestroy))
                    .subscribe(function (errorMessage) {
                    _this.validateHttpError = errorMessage;
                });
            });
        }
    };
    MonitorAddStep1Component.prototype.canValidate = function () {
        return this.addMonitorForm.valid && !this.isValidating;
    };
    MonitorAddStep1Component.prototype.clickError = function () {
        this.dialog.open(RequestComponent, __assign({}, this.getShareScreenVals(), { data: { id: this.form.serialNumber } }));
    };
    MonitorAddStep1Component.prototype.getShareScreenVals = function () {
        return this.media.isActive('gt-sm') ? { width: "50%", height: "70%" } : { width: "95vw", height: "95vh", 'maxWidth': "95vw!important" };
    };
    return MonitorAddStep1Component;
}());
export { MonitorAddStep1Component };
