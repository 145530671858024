var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { forkJoin as observableForkJoin, ReplaySubject, timer } from 'rxjs';
import { takeUntil, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { HttpViewsService } from "../http/view/http-views.service";
import { RequestManagerService } from "./request-manager.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as _ from "lodash";
import * as moment from "moment";
import { EventSubject } from "./EventSubject";
import { AuthenticationService } from "../http/authentication/authentication.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./request-manager.service";
import * as i3 from "../http/authentication/authentication.service";
var ViewDataService = /** @class */ (function (_super) {
    __extends(ViewDataService, _super);
    function ViewDataService(httpClient, requestManager, authenticationService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.requestManager = requestManager;
        _this.authenticationService = authenticationService;
        _this.allViews = new ReplaySubject(1);
        _this.allViewsForStatusOverview = new ReplaySubject(1);
        _this.detailOverviewSubject = new EventSubject({});
        _this.compareMeasurements = function (one, two) { return one.lastMeasuredDateTime === two.lastMeasuredDateTime; };
        _this.authenticationService.getAuthenticatedUser()
            .pipe(distinctUntilChanged(function (obj1, obj2) { return JSON.stringify(obj1) === JSON.stringify(obj2); }))
            .subscribe(function (user) { return user ? _this.initialize() : _this.clear(); });
        return _this;
    }
    ViewDataService.prototype.initialize = function () {
        var _this = this;
        setTimeout(function () { return _this.fetchStatus(); }, 200); // Prioritize monitor load. See bug 1667;
        //this.getAllForInit();
        if (environment.prefetchMeasurements) {
            this.initDetailOverviews();
        }
        this.refresh = this.refresh || setInterval(function () { return _this.fetchStatus(); }, environment.pollingIntervalInSeconds * 5000);
    };
    ViewDataService.prototype.clear = function () {
        clearInterval(this.refresh);
        this.refresh = null;
        this.allViews.next(null);
        this.allViewsForStatusOverview.next(null);
        this.detailOverviewSubject.next({});
    };
    ViewDataService.prototype.getForDetailOverview = function (id, selectedDateRange, detailStatusOverview) {
        if (ViewDataService.isDefault(selectedDateRange)) {
            // The default range is cached. We don't need to send a new request.
            this.startRefreshInterval(id);
            return this.detailOverviewSubject
                .pipe(map(function (obj) { return obj[id]; }), filter(function (obj) { return !_.isNil(obj); }), distinctUntilChanged(this.compareMeasurements));
        }
        else {
            // It's outside of the default range. We must send a new request.
            return _super.prototype.getForDetailOverview.call(this, id, selectedDateRange);
        }
    };
    ViewDataService.prototype.getAllForStatusOverview = function () {
        return this.allViewsForStatusOverview.pipe(filter(function (obj) { return !_.isNil(obj); }), distinctUntilChanged(function (one, two) { return JSON.stringify(one) === JSON.stringify(two); }));
    };
    ViewDataService.prototype.getOneForStatusOverview = function (id) {
        return this.allViewsForStatusOverview
            .pipe(filter(function (monitors) { return !_.isNil(monitors); }), distinctUntilChanged(function (one, two) { return JSON.stringify(one) === JSON.stringify(two); }), map(function (obj) { return obj.filter(function (obj) { return obj.id == id; })[0]; }), filter(function (obj) { return !_.isNil(obj); }));
    };
    ViewDataService.prototype.add = function (view) {
        var _this = this;
        return _super.prototype.add.call(this, view).pipe(tap(function () { return _this.clear(); }), tap(function () { return _this.fetchStatus(); }));
    };
    ViewDataService.prototype.deleteView = function (id) {
        var _this = this;
        return _super.prototype.deleteView.call(this, id).pipe(tap(function () { return _this.fetchStatus(); }));
    };
    ViewDataService.prototype.setChangedOrder = function (id, order) {
        return _super.prototype.setChangedOrder.call(this, id, order);
    };
    ViewDataService.prototype.fetchStatus = function () {
        var _this = this;
        this.requestManager.addToQueue({
            priority: 2,
            observable: _super.prototype.getAllForStatusOverview.call(this),
            subscriptionFunc: function (monitors) { return _this.allViewsForStatusOverview.next(monitors); }
        });
    };
    ViewDataService.prototype.startRefreshInterval = function (id) {
        var _this = this;
        timer(0, environment.pollingIntervalInSeconds * 1000).pipe(
        // We automatically unsubscribe to the timer (and thus stop updating) when we unsubscribe the measurement update source.
        takeUntil(this.detailOverviewSubject.onUnsubscribe()))
            .subscribe(function () { return _this.updateMeasurements(id); });
    };
    ViewDataService.prototype.updateMeasurements = function (id) {
        var _this = this;
        // Fetch the latest to update.
        observableForkJoin([
            this.getDetailOverviewForCache(),
            this.getOneForStatusOverview(id).pipe(take(1))
        ])
            .pipe(take(1))
            .subscribe(function (results) {
            _super.prototype.getForDetailOverview.call(_this, id, ViewDataService.defaultTime(), results[1])
                .subscribe(function (updated) {
                results[0][id] = updated;
                _this.detailOverviewSubject.next(results[0]);
            });
        });
    };
    ViewDataService.prototype.getAllForInit = function () {
        var _this = this;
        this.requestManager.addToQueue({
            priority: 8,
            observable: _super.prototype.getAll.call(this),
            subscriptionFunc: function (monitors) { return _this.allViews.next(monitors); }
        });
    };
    ViewDataService.prototype.initDetailOverviews = function () {
        var _this = this;
        this.getAllForStatusOverview().subscribe(function (views) { return views.forEach(function (view) { return _this.initDetailOverview(view.id, view); }); });
    };
    ViewDataService.prototype.initDetailOverview = function (viewId, view) {
        var _this = this;
        var subscriptionFunc = function (detailOverview) { return _this.getDetailOverviewForCache().pipe(take(1)).subscribe(function (obj) {
            obj[viewId] = detailOverview;
            _this.detailOverviewSubject.next(obj);
        }); };
        // Request for the detail overview
        var observable = _super.prototype.getForDetailOverview.call(this, viewId, ViewDataService.defaultTime(), view);
        this.requestManager.addToQueue({ priority: 10, observable: observable, subscriptionFunc: subscriptionFunc });
    };
    ViewDataService.isDefault = function (selectedDateRange) {
        return selectedDateRange.start.day() === this.defaultTime().start.day() && selectedDateRange.end.day() === this.defaultTime().end.day();
    };
    ViewDataService.prototype.getDetailOverviewForCache = function () {
        return this.detailOverviewSubject
            .pipe(take(1));
    };
    ViewDataService.defaultTime = function () { return ({ start: moment().subtract(1, 'month'), end: moment() }); };
    ViewDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ViewDataService_Factory() { return new ViewDataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.RequestManagerService), i0.ɵɵinject(i3.AuthenticationService)); }, token: ViewDataService, providedIn: "root" });
    return ViewDataService;
}(HttpViewsService));
export { ViewDataService };
