
import {map, catchError, mergeMap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, throwError} from 'rxjs';

import {environment} from '../../environments/environment';
import {AuthenticationService} from './http/authentication/authentication.service';

@Injectable()
export class AuthorizationHttpInterceptor implements HttpInterceptor {
  private readonly URL_AUTHENTICATION_TOKEN = '/oauth/token';
  private readonly URL_REGISTER = '/api/users/';
  private readonly METHOD_POST = 'POST';
  private readonly UnauthorizedErrorCode = 401;
  private readonly OAUTH_TOKEN_ENDPOINT = 'oauth/token';

  private authenticationService: AuthenticationService;

  private injector: Injector;

  private refreshTokenInProgress = false;
  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  private router: Router;


  constructor(injector: Injector, router: Router) {
    this.injector = injector;
    this.router = router;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authenticationService = this.injector.get(AuthenticationService);
    if (!this.isRegisterMethod(request.method, request.url)) {
      request = this.addAuthorizationHeader(request);
    }
    request = request.clone({
      url: this.updateUrl(request.url),
      setHeaders: {
        'Accept': 'security/json, image/*, application/json',
        'Content-Type': this.getContentType(request.url)
      }
    });

    return next.handle(request).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse && error.status === this.UnauthorizedErrorCode) {
        return this.handleUnauthorizedError(error, request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private handleUnauthorizedError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
         this.refreshTokenInProgress = false;
         this.authenticationService.logout();

      return throwError(error);
    }

    return this.refreshToken().pipe(mergeMap(() => {
      request = this.addAuthorizationHeader(request);
      this.refreshTokenInProgress = false;
      return next.handle(request);
    }));
  }

  private addAuthorizationHeader(request) {
    const authHeader = this.getAuthorizationHeader();

    return request.clone({
      setHeaders: {
        'Authorization': authHeader
      }
    });

  }

  private refreshToken(): Observable<void> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          return observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      return this.authenticationService.refreshToken().pipe(map(() => {
        this.tokenRefreshedSource.next();
      }));
    }
  }

  private updateUrl(url: string) {
    let updatedUrl: string = url;
    if (!url.startsWith('assets')) {
      updatedUrl = environment.origin + url;
    }
    return updatedUrl;
  }

  private getAuthorizationHeader(): string {
    return this.isAuthorizedRequest() ? 'Bearer ' + this.authenticationService.getToken().access_token : 'Basic ' + btoa(environment.client_id + ':' + environment.client_secret);
  }

  private getContentType(url: string): string {
    return this.isTokenMethod(url) ? 'application/x-www-form-urlencoded' : 'application/json';

  }

  private isAuthorizedRequest(): boolean {
    return this.authenticationService.hasToken();
  }

  private isTokenMethod(url: string): boolean {
    return url === this.URL_AUTHENTICATION_TOKEN;
  }

  private isRegisterMethod(method: string, url: string): boolean {
    return method === this.METHOD_POST && url === this.URL_REGISTER;
  }

}
