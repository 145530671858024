var SensorPredefinedList = /** @class */ (function () {
    function SensorPredefinedList(id, name, inputType, categoryId, sensorTypeId, unitId, parameters) {
        this._id = id;
        this._name = name;
        this._inputType = inputType;
        this._categoryId = categoryId;
        this._sensorTypeId = sensorTypeId;
        this._unitId = unitId;
        this._parameters = parameters;
    }
    SensorPredefinedList.fromJSON = function (sensorPredefinedDTO) {
        return new SensorPredefinedList(sensorPredefinedDTO.id, sensorPredefinedDTO.name, sensorPredefinedDTO.inputType, sensorPredefinedDTO.categoryId, sensorPredefinedDTO.sensorTypeId, sensorPredefinedDTO.unitId, sensorPredefinedDTO.parameters);
    };
    Object.defineProperty(SensorPredefinedList.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedList.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedList.prototype, "inputType", {
        get: function () {
            return this._inputType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedList.prototype, "categoryId", {
        get: function () {
            return this._categoryId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedList.prototype, "sensorTypeId", {
        get: function () {
            return this._sensorTypeId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedList.prototype, "unitId", {
        get: function () {
            return this._unitId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefinedList.prototype, "parameters", {
        get: function () {
            return this._parameters;
        },
        enumerable: true,
        configurable: true
    });
    return SensorPredefinedList;
}());
export { SensorPredefinedList };
