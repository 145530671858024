<div fxFlex *ngIf="message">
  <h2>
    {{message}}
  </h2>
</div>

<app-progress-spinner
    fxFlex
    [progressSpinnerText]="'ACTIONS.LOADING' | translate"
    *ngIf="!message">
  </app-progress-spinner>