var Sensor = /** @class */ (function () {
    function Sensor(channel, name, category, inputType, sensorType) {
        this._channel = channel;
        this._name = name;
        this._category = category;
        this._inputType = inputType;
        this._sensorType = sensorType;
    }
    Object.defineProperty(Sensor.prototype, "channel", {
        get: function () {
            return this._channel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sensor.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sensor.prototype, "category", {
        get: function () {
            return this._category;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sensor.prototype, "inputType", {
        get: function () {
            return this._inputType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sensor.prototype, "sensorType", {
        get: function () {
            return this._sensorType;
        },
        enumerable: true,
        configurable: true
    });
    return Sensor;
}());
export { Sensor };
