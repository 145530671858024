<div fxLayout="column" fxFlex>
    <app-navigation-toolbar [subPages]="subPages"></app-navigation-toolbar>

    <app-progress-spinner
            *ngIf="isLoading" fxFlex
            [progressSpinnerText]="'NAVIGATION.MONITORS' | translate">
    </app-progress-spinner>

    <section class="monitor-list" fxFlex>
        <app-overview-card *ngFor="let monitor of monitors" [sensors]="monitor.sensors"
                           [details]="fetchDetails(monitor)" [link]="['/monitors', monitor.id, 'detail']"
                           [monitor]="monitor"></app-overview-card>
    </section>


    <app-footer></app-footer>

</div>


