import {BehaviorSubject, Subject} from "rxjs";

/**
 * This is a BehaviorSubject that emits an event on subscription and on unsubscription.
 */

export class EventSubject<T> extends BehaviorSubject<T> {

    private subscribed: Subject<boolean> = new Subject();
    private unsubscribed: Subject<boolean> = new Subject();

    constructor(initialvalue) {
        super(initialvalue);
    }

    subscribe(next?, err?, complete?) {
        this.subscribed.next(true);

        const subscription =  super.subscribe(next, err, complete);
        const proto = subscription.unsubscribe;
        subscription.unsubscribe = () => {this.unsubscribe(); proto.bind(subscription)();};

        return subscription;
    }

    unsubscribe() {
        this.unsubscribed.next(true);
    }

    onUnsubscribe() {
        return this.unsubscribed;
    }

    onSubscribe(func) {
        return this.subscribed;
    }
}