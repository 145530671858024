import {SensorCategoryType} from './sensor-category-type';

export class SensorCategory {
  private _id: string;
  private _name: string;
  private _types: SensorCategoryType[];

  public static fromJSON(categoryDTO: any): SensorCategory {
    return new SensorCategory(
      categoryDTO.id,
      categoryDTO.name,
      categoryDTO.types
    );
  }

  constructor(id: string,
              name: string,
              types: SensorCategoryType[]) {
    this._id = id;
    this._name = name;
    this._types = types;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get types(): SensorCategoryType[] {
    return this._types;
  }
}
