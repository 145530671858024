import MonitorInputType from '../monitorInputType';
import {Sensor} from './sensor';

export class SensorDigitalOpenClosed extends Sensor {
  private _openText: string;
  private _closedText: string;
  private _pollingRate: string;
  private _cloudStorageRate: string;
  private _openTextDescription: string;
  private _closedTextDescription: string;

  constructor(channel: number,
              name: string,
              category: string,
              inputType: MonitorInputType,
              sensorType: string,
              openText: string,
              openTextDescription: string,
              closedText: string,
              closedTextDescription: string,
              pollingRate: string,
              cloudStorageRate: string) {
    super(channel, name, category, inputType, sensorType);

    this._openText = openText;
    this._openTextDescription = openTextDescription;
    this._closedTextDescription = closedTextDescription;
    this._closedText = closedText;
    this._pollingRate = pollingRate;
    this._cloudStorageRate = cloudStorageRate;
  }

  get openText(): string {
    return this._openText;
  }

  get closedText(): string {
    return this._closedText;
  }

  get pollingRate(): string {
    return this._pollingRate;
  }

  get cloudStorageRate(): string {
    return this._cloudStorageRate;
  }

  get openTextDescription(): string {
    return this._openTextDescription;
  }

  get closedTextDescription(): string {
    return this._closedTextDescription;
  }

  public toJSON(sensorDigitalOpenClosedDTO: any) {
    return {
      channel: sensorDigitalOpenClosedDTO.channel,
      name: sensorDigitalOpenClosedDTO.name,
      categoryId: sensorDigitalOpenClosedDTO.category,
      inputType: sensorDigitalOpenClosedDTO.inputType,
      sensorTypeId: sensorDigitalOpenClosedDTO.sensorType,
      openText: sensorDigitalOpenClosedDTO.openText,
      openTextDescription: sensorDigitalOpenClosedDTO.openTextDescription,
      closedText: sensorDigitalOpenClosedDTO.closedText,
      closedTextDescription: sensorDigitalOpenClosedDTO.closedTextDescription,
      pollingRate: sensorDigitalOpenClosedDTO.pollingRate,
      cloudStorageRate: sensorDigitalOpenClosedDTO.cloudStorageRate,
    }
  }
}
