import { UrlMatcher } from "@angular/router";

export class Page {
  private _name: string;
  private _icon: string;
  private _path: string;
  private _component: any;
  private _isDefault: boolean;
  private _showInMenu: boolean;
  private _securedPage: boolean;
  private _subPages: Page[];
  private _parentPage: Page;
  matcher: UrlMatcher;

  constructor(name: string,
              icon: string,
              path: string,
              component: any,
              isDefault: boolean,
              showInMenu: boolean,
              securedPage: boolean,
              subPages: Page[],
              matcher?:UrlMatcher) {
    this._name = name;
    this._icon = icon;
    this._path = path;
    this._component = component;
    this._isDefault = isDefault;
    this._showInMenu = showInMenu;
    this._securedPage = securedPage;
    this._subPages = subPages;
    this.matcher = matcher;
  }


  get name(): string {
    return this._name;
  }


  get icon(): string {
    return this._icon;
  }


  get path(): string {
    return this._path;
  }


  get component(): any {
    return this._component;
  }


  get isDefault(): boolean {
    return this._isDefault;
  }


  get showInMenu(): boolean {
    return this._showInMenu;
  }


  get securedPage(): boolean {
    return this._securedPage;
  }


  get subPages(): Page[] {
    return this._subPages;
  }


  get parentPage(): Page {
    return this._parentPage;
  }

  set parentPage(value: Page) {
    this._parentPage = value;
  }
}
