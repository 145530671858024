import { MonitorDetailStatusOverview } from '../../../../domain/monitor/monitorDetailStatusOverview';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-monitor-information-tile',
  templateUrl: './monitor-information-tile.component.html',
  styleUrls: ['./monitor-information-tile.component.scss']
})
export class MonitorInformationTileComponent implements OnInit {
  
  @Input() monitorDetailStatusOverview: MonitorDetailStatusOverview;

  constructor() { }

  ngOnInit() {  }

}
