
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../infrastructure/http/authentication/authentication.service';
import {ErrorTranslator} from '../../../../application/command/error-translator/error-translator';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  @Output() public registerRequested: EventEmitter<boolean> = new EventEmitter();
  private router: Router;
  private authenticationService: AuthenticationService;
  private loginPerforming = false;
  private errorTranslator: ErrorTranslator;
  private unSubscribeLoginOnDestroy: Subject<boolean> = new Subject<boolean>();

  constructor(router: Router,
              authenticationService: AuthenticationService,
              @Inject('ErrorTranslator') errorTranslator: ErrorTranslator) {
    this.router = router;
    this.authenticationService = authenticationService;
    this.errorTranslator = errorTranslator;
    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required)
    });
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.unSubscribeLoginOnDestroy.next(true);
    this.unSubscribeLoginOnDestroy.complete();
  }

  public login(form: any, isValid: boolean): void {
    if (isValid) {
      this.loginPerforming = true;
      this.authenticationService.login(form.email, form.password)
        .subscribe(() => {
            this.router.navigateByUrl('/monitors');
          }, (error: HttpErrorResponse) => {
            this.loginForm.reset({email: form.email});
            this.loginPerforming = false;
            this.errorTranslator.execute(error).pipe(
              takeUntil(this.unSubscribeLoginOnDestroy))
              .subscribe((errorMessage: string) => {
                throw new Error(errorMessage);
              });
          }
        );
    }
  }

  public canLogin(): boolean {
    return this.loginForm.valid && !this.loginPerforming;
  }

  public goToRegistration(): void {
    this.registerRequested.emit(true);
  }

  public goToRequestResetPassword(): void {
    this.router.navigateByUrl('/request-reset-password');
  }

}
