
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';

import {Monitor} from '../../../../../domain/monitor/monitor';
import {ErrorTranslator} from '../../../../../application/command/error-translator/error-translator';
import {MessageStyle} from "../../../../../domain/messageStyle";
import {Monitors} from "../../../../../infrastructure/http/monitor/monitors";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { MatDialog } from '@angular/material';
import { MediaObserver } from '@angular/flex-layout';
import { ShareComponent } from 'app/presentation/components/overview-card/share/share.component';
import { RequestComponent } from 'app/presentation/components/request/request.component';

@Component({
  selector: 'app-monitor-add-step-1',
  templateUrl: './monitor-add-step-1.component.html',
  styleUrls: ['./monitor-add-step-1.component.scss'],
})
export class MonitorAddStep1Component implements OnInit, OnDestroy {
  @Output() public hasCompletedStepOne = new EventEmitter<Monitor>();

  public addMonitorForm: FormGroup;
  public isValidating: boolean;
  public validateHttpError: string;
  public readonly MESSAGE_STYLE = MessageStyle;
  public isAlreadyInUseError: boolean;

  private formBuilder: FormBuilder;
  private unSubscribeAddMonitorOnDestroy = new Subject<boolean>();
  private httpMonitorsService: Monitors;
  private route: ActivatedRoute;
  private errorTranslator: ErrorTranslator;

  private form;

  constructor(formBuilder: FormBuilder,
              monitorService: MonitorDataService,
              route: ActivatedRoute,
              @Inject('ErrorTranslator') errorTranslator: ErrorTranslator, private dialog: MatDialog, private media: MediaObserver) {
    this.formBuilder = formBuilder;
    this.httpMonitorsService = monitorService;
    this.route = route;
    this.errorTranslator = errorTranslator;

    this.addMonitorForm = this.formBuilder.group({
      serialNumber: [null, [Validators.required, Validators.maxLength(255)]],
      activationCode: [null, [Validators.required, Validators.maxLength(255)]]
    });
  }

  public ngOnInit(): void {
    this.route
      .queryParams.pipe(
      takeUntil(this.unSubscribeAddMonitorOnDestroy))
      .subscribe(params => {
        this.addMonitorForm.patchValue({
          serialNumber: params.serial_number,
          activationCode: params.activation_code
        });
      });
  }

  public ngOnDestroy(): void {
    this.unSubscribeAddMonitorOnDestroy.next(true);
    this.unSubscribeAddMonitorOnDestroy.complete();
  }

  public validateMonitor(form: any, isValid: boolean): void {
    this.form = form;
    if (isValid) {
      this.isValidating = true;

      // Reset the http error
      this.validateHttpError = null;

      this.httpMonitorsService.addMonitor(form.activationCode.trim(), form.serialNumber).pipe(
        takeUntil(this.unSubscribeAddMonitorOnDestroy))
        .subscribe((monitor: Monitor) => {
          // Emit the response back to the parent component
          this.hasCompletedStepOne.emit(monitor);
        }, (error: HttpErrorResponse) => {
          this.isValidating = false;
          console.log(error.error.type)
          if(error.error.type == ("MONITOR_IS_PRIVATE")) {
            this.isAlreadyInUseError = true;
            return;
          }
          this.errorTranslator.execute(error).pipe(
            takeUntil(this.unSubscribeAddMonitorOnDestroy))
            .subscribe((errorMessage: string) => {
              this.validateHttpError = errorMessage;
            });
        });
    }
  }

  public canValidate(): boolean {
    return this.addMonitorForm.valid && !this.isValidating;
  }

  public clickError() {
    this.dialog.open(RequestComponent, {...this.getShareScreenVals(), data: {id: this.form.serialNumber}})
 }

 private getShareScreenVals() {
   return this.media.isActive('gt-sm') ? {width: "50%", height:"70%"} : {width: "95vw", height:"95vh", 'maxWidth':"95vw!important"}
 }
}
