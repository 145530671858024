import {isNil} from 'lodash';
import JobCategory from './jobCategory';

import UserRole from './userRole';

export class User {
  private _id: string;
  private _email: string;
  private _role: UserRole;
  private _firstName: string;
  private _lastName: string;
  private _phone: string;
  private _jobCategory: JobCategory;
  private _jobTitle: string;

  public static fromJSON(userDTO: any): User {
    const userRole = userDTO.role;

    if (isNil(userRole)) {
      throw new Error('User role is not defined');
    }

    const jobCategory = userDTO.jobCategory;

    if (isNil(jobCategory)) {
      throw new Error('Job category is not defined');
    }

    return new User(userDTO.id,
      userDTO.email,
      userRole,
      userDTO.firstName,
      userDTO.lastName,
      userDTO.phone,
      jobCategory,
      userDTO.jobTitle);
  }

  constructor(id: string,
              email: string,
              role: UserRole,
              firstName: string,
              lastName: string,
              phone: string,
              jobCategory: JobCategory,
              jobTitle: string) {
    this._id = id;
    this._email = email;
    this._role = role;
    this._firstName = firstName;
    this._lastName = lastName;
    this._phone = phone;
    this._jobCategory = jobCategory;
    this._jobTitle = jobTitle;
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }


  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }


  get role(): UserRole {
    return this._role;
  }

  set role(value: UserRole) {
    this._role = value;
  }


  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }


  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }


  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }


  get jobCategory(): JobCategory {
    return this._jobCategory;
  }

  set jobCategory(value: JobCategory) {
    this._jobCategory = value;
  }


  get jobTitle(): string {
    return this._jobTitle;
  }

  set jobTitle(value: string) {
    this._jobTitle = value;
  }

  public toJSON(): JSON {
    const result: any = {
      'id': this.id,
      'email': this.email,
      'role': this.role,
      'firstName': this.firstName,
      'lastName': this.lastName,
      'phone': this.phone,
      'jobCategory': this.jobCategory,
      'jobTitle': this.jobTitle
    };
    return <JSON> result;
  }
}
