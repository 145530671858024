var ErrorHttpInterceptor = /** @class */ (function () {
    function ErrorHttpInterceptor() {
    }
    ErrorHttpInterceptor.prototype.intercept = function (req, next) {
        return next.handle(req);
        // .retry(3)
    };
    return ErrorHttpInterceptor;
}());
export { ErrorHttpInterceptor };
