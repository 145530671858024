
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from 'angular-2-local-storage';
import {isUndefined, isNil} from 'lodash';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import 'rxjs/Rx';
import {environment} from '../../../../environments/environment';
import {User} from '../../../domain/user';
import {Users} from '../user/users';
import {Authentication} from './authentication';
import { HttpUsersService } from '../user/http-users.service';

@Injectable()
export class AuthenticationService implements Authentication {
  private httpClient: HttpClient;
  private localStorageService: LocalStorageService;
  private readonly Token = 'token';
  private readonly AuthenticatedUser = 'authenticatedUser';
  private router: Router;
  private authenticatedUserSubject: Subject<User>;

  constructor(httpClient: HttpClient,
              localStorageService: LocalStorageService,
              router: Router) {
    this.httpClient = httpClient;
    this.localStorageService = localStorageService;
    this.router = router;

    this.authenticatedUserSubject = new ReplaySubject(1);
  }

  public login(email: string, password: string): Observable<void> {
    const body: URLSearchParams = new URLSearchParams();
    body.set('username', email);
    body.set('password', password);
    body.set('grant_type', 'password');
    body.set('client_id', environment.client_id);
    body.set('client_secret', environment.client_secret);

    return this.httpClient
      .post('/oauth/token', body.toString()).pipe(
      map((token: Object) => {
        this.localStorageService.set(this.Token, token);
        this.getAuthenticatedUserHttp().subscribe((user: User) => {
          this.localStorageService.set(this.AuthenticatedUser, user.toJSON());
          this.setAuthenticatedUser(user);
        });
      }));
  }

  public refreshToken(): Observable<any> {
    const body: URLSearchParams = new URLSearchParams();
    body.set('grant_type', 'refresh_token');

    if (!isUndefined(this.getToken())) {
      body.set('refresh_token', this.getToken().refresh_token);
    }

    this.localStorageService.remove(this.Token);

    return this.httpClient.post('/oauth/token', body.toString()).pipe(
      map((token: Object) => {
        this.localStorageService.set(this.Token, token);
      }));
  }

  public getAuthenticatedUser(): Observable<User> {
    let result = null;
    if (this.isAuthenticated()) {
      result = User.fromJSON(this.localStorageService.get(this.AuthenticatedUser));
    }

    this.authenticatedUserSubject.next(result);
    return this.authenticatedUserSubject;
  }

  public setAuthenticatedUser(user: User): void {
    this.localStorageService.set(this.AuthenticatedUser, user.toJSON());
    this.authenticatedUserSubject.next(user);
  }

  public logout(): void {
    this.authenticatedUserSubject.next(null);
    this.localStorageService.clearAll();
    this.router.navigate(['/login']);
  }

  public hasToken(): boolean {
    return !isNil(this.localStorageService.get(this.Token));
  }

  public isAuthenticated(): boolean {
    return !isNil(this.localStorageService.get(this.AuthenticatedUser));
  }

  public getToken(): any {
    let result;
    if (this.hasToken()) {
      result = this.localStorageService.get(this.Token);
    }
    return result;
  }

  private getAuthenticatedUserHttp(): Observable<User> {
    return this.httpClient.get('/api/users/me').pipe(
      map(User.fromJSON));
  }

}
