<section fxLayout="row" fxLayoutAlign="center center">
  <article fxLayout="column" fxLayoutAlign="center center">
    <div fxFlex="87" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center start">
      <img class="resuslogo-tagline" src="assets/img/resuslogo-tagline.svg"/>
      <form class="antialiased" [formGroup]="loginForm" (ngSubmit)="login(loginForm.value, loginForm.valid)">
        <div class="input-row header">
          <p>{{'LOGIN.LOGIN' | translate}}</p>
        </div>
        <div class="input-row hint"><p>{{'LOGIN.HINT' | translate}}</p></div>
        <div class="input-row">
          <mat-form-field fxFill>
            <mat-error>{{'LOGIN.ERROR_INVALID_EMAIL' | translate}}</mat-error>
            <input matInput
                   type="email"
                   placeholder="{{'EMAIL' | translate}}"
                   email
                   class="login-email"
                   formControlName="email">
          </mat-form-field>
        </div>
        <div class="input-row">
          <mat-form-field fxFill>
            <mat-error>{{'LOGIN.ERROR_REQUIRED_PASSWORD' | translate}}</mat-error>
            <input matInput
                   type="password"
                   class="login-password"
                   placeholder="{{'PASSWORD' | translate}}"
                   formControlName="password">
          </mat-form-field>
        </div>
        <div class="input-row">
          <button fxFill mat-raised-button color="primary" #button [disabled]="!canLogin()" type="submit">
            {{'LOGIN.LOGIN' | translate}}
          </button>
        </div>
        <div fxLayout="row" class="input-row action-link">
          <a fxFlex mat-button (click)="goToRegistration()">{{'SIGNUP' | translate}}</a>
          <span>|</span>
          <a fxFlex mat-button (click)="goToRequestResetPassword()">{{'FORGOT_PASSWORD' | translate}}</a>
        </div>
      </form>
    </div>
    <div fxFlex="13"></div>

  </article>

</section>
