var SensorPredefined = /** @class */ (function () {
    function SensorPredefined(channel, name, predefinedSensorId, inputType) {
        this._channel = channel;
        this._name = name;
        this._predefinedSensorId = predefinedSensorId;
        this._inputType = inputType;
    }
    SensorPredefined.prototype.toJSON = function (sensorPredefinedDTO) {
        return {
            channel: sensorPredefinedDTO.channel,
            name: sensorPredefinedDTO.name,
            inputType: sensorPredefinedDTO.inputType,
            predefinedSensorId: sensorPredefinedDTO.predefinedSensorId
        };
    };
    Object.defineProperty(SensorPredefined.prototype, "channel", {
        get: function () {
            return this._channel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefined.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefined.prototype, "predefinedSensorId", {
        get: function () {
            return this._predefinedSensorId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorPredefined.prototype, "inputType", {
        get: function () {
            return this._inputType;
        },
        enumerable: true,
        configurable: true
    });
    return SensorPredefined;
}());
export { SensorPredefined };
