import {Component} from '@angular/core';


@Component({
  selector: 'app-user-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent {

  constructor() {

  }

}
