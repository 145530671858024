/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./monitor-add-redirect.component";
import * as i2 from "@angular/router";
var styles_MonitorAddRedirectComponent = [];
var RenderType_MonitorAddRedirectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MonitorAddRedirectComponent, data: {} });
export { RenderType_MonitorAddRedirectComponent as RenderType_MonitorAddRedirectComponent };
export function View_MonitorAddRedirectComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_MonitorAddRedirectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-monitor-add-redirect", [], null, null, null, View_MonitorAddRedirectComponent_0, RenderType_MonitorAddRedirectComponent)), i0.ɵdid(1, 180224, null, 0, i1.MonitorAddRedirectComponent, [i2.Router, i2.ActivatedRoute], null, null)], null, null); }
var MonitorAddRedirectComponentNgFactory = i0.ɵccf("app-monitor-add-redirect", i1.MonitorAddRedirectComponent, View_MonitorAddRedirectComponent_Host_0, {}, {}, []);
export { MonitorAddRedirectComponentNgFactory as MonitorAddRedirectComponentNgFactory };
