import { takeUntil } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil, find, remove, concat, isEmpty, isUndefined } from 'lodash';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../../application/notification/notification.service';
import { View } from '../../../../domain/view/view';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var ViewAddComponent = /** @class */ (function () {
    function ViewAddComponent(route, formBuilder, monitorsService, viewsService, router, notificationService, dialog) {
        this.subPages = [];
        this.monitors = [];
        this.viewSensors = [];
        this.selectedSensorsOfMonitor = [];
        this.selectedViewSensors = [];
        this.isLoading = true;
        this.unSubscribeAddViewOnDestroy = new Subject();
        this.editMode = false;
        this.dialogConfig = {
            panelClass: 'confirmation-dialog'
        };
        this.route = route;
        this.formBuilder = formBuilder;
        this.monitorsService = monitorsService;
        this.viewsService = viewsService;
        this.router = router;
        this.notificationService = notificationService;
        this.dialog = dialog;
        this.addViewDetailsForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(255)]],
            elucidation: ['', Validators.maxLength(1000)]
        });
    }
    ViewAddComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.pipe(takeUntil(this.unSubscribeAddViewOnDestroy))
            .subscribe(function (data) {
            _this.subPages = data.parentPage.subPages;
        });
        this.route.params.pipe(takeUntil(this.unSubscribeAddViewOnDestroy))
            .subscribe(function (params) {
            var viewId = params.id;
            _this.monitorsService.getAllForStatusOverview().pipe(takeUntil(_this.unSubscribeAddViewOnDestroy))
                .subscribe(function (monitors) {
                _this.monitors = monitors.filter(function (monitor) { return monitor.permissions.includes("USE_VIEW"); });
                if (!isNil(viewId)) {
                    _this.editMode = true;
                    _this.isLoading = true;
                    _this.viewsService.get(viewId).pipe(takeUntil(_this.unSubscribeAddViewOnDestroy))
                        .subscribe(function (view) {
                        _this.view = view;
                        _this.addViewDetailsForm.controls['name'].setValue(view.name);
                        _this.addViewDetailsForm.controls['elucidation'].setValue(view.elucidation);
                        _this.viewSensors = view.sensors;
                        _this.isLoading = false;
                    });
                }
                else {
                    _this.isLoading = false;
                }
            });
        });
    };
    ViewAddComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeAddViewOnDestroy.next(true);
        this.unSubscribeAddViewOnDestroy.complete();
    };
    ViewAddComponent.prototype.isEditMode = function () {
        return this.editMode;
    };
    ViewAddComponent.prototype.toggleSensorOfMonitor = function (sensor) {
        if (!this.isSensorInList(sensor, this.viewSensors)) {
            if (find(this.selectedSensorsOfMonitor, ['id', sensor.id])) {
                remove(this.selectedSensorsOfMonitor, sensor);
            }
            else {
                this.selectedSensorsOfMonitor.push(sensor);
            }
        }
    };
    ViewAddComponent.prototype.toggleAddedSensor = function (sensor) {
        if (this.isSensorInList(sensor, this.selectedViewSensors)) {
            remove(this.selectedViewSensors, sensor);
        }
        else {
            this.selectedViewSensors.push(sensor);
        }
    };
    ViewAddComponent.prototype.addSensorsToView = function (sensors) {
        this.viewSensors = concat(this.viewSensors, sensors);
        this.selectedSensorsOfMonitor = [];
    };
    ViewAddComponent.prototype.removeSensorsFromView = function (sensors) {
        remove(this.viewSensors, function (viewSensor) { return find(sensors, ['id', viewSensor.id]); });
        this.selectedViewSensors = [];
    };
    ViewAddComponent.prototype.isSensorInList = function (sensor, sensors) {
        return !isEmpty(find(sensors, ['id', sensor.id]));
    };
    ViewAddComponent.prototype.hasSensors = function (sensors) {
        return !isEmpty(sensors);
    };
    ViewAddComponent.prototype.getMonitorOfSensor = function (sensor) {
        return find(this.monitors, function (monitor) { return !isUndefined(find(monitor.sensors, ['id', sensor.id])); });
    };
    ViewAddComponent.prototype.canSaveView = function () {
        return !this.addViewDetailsForm.invalid && !isEmpty(this.viewSensors);
    };
    ViewAddComponent.prototype.saveView = function () {
        var _this = this;
        var viewToSave = new View('', this.addViewDetailsForm.controls.name.value, this.addViewDetailsForm.controls.elucidation.value, this.viewSensors);
        if (this.isEditMode()) {
            viewToSave.id = this.view.id;
            this.viewsService.edit(viewToSave).pipe(takeUntil(this.unSubscribeAddViewOnDestroy))
                .subscribe(function () {
                _this.notificationService.show('VIEW.EDITED_CONFIRMATION');
                _this.router.navigateByUrl('/playgrounds');
            });
        }
        else {
            this.viewsService.add(viewToSave).pipe(takeUntil(this.unSubscribeAddViewOnDestroy))
                .subscribe(function () {
                _this.notificationService.show('VIEW.ADDED_CONFIRMATION');
                _this.router.navigateByUrl('/playgrounds');
            });
        }
    };
    ViewAddComponent.prototype.deleteView = function () {
        var _this = this;
        var currentDialogConfig = {
            panelClass: this.dialogConfig.panelClass,
            data: {
                textContent: 'VIEW.DELETE_VIEW_QUESTION',
                confirmText: 'GENERAL.OK',
                cancelText: 'GENERAL.CANCEL'
            }
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === true) {
                _this.viewsService.deleteView(_this.view.id).pipe(takeUntil(_this.unSubscribeAddViewOnDestroy))
                    .subscribe(function () {
                    _this.notificationService.show('VIEW.DELETED_CONFIRMATION');
                    _this.router.navigateByUrl('/playgrounds');
                });
            }
        });
    };
    ViewAddComponent.prototype.cancel = function () {
        this.router.navigateByUrl('/playgrounds');
    };
    return ViewAddComponent;
}());
export { ViewAddComponent };
