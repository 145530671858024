var MonitorDetails = /** @class */ (function () {
    function MonitorDetails(name, address, system, circuit, details, comments) {
        this._name = name;
        this._address = address;
        this._system = system;
        this._circuit = circuit;
        this._details = details;
        this._comments = comments;
    }
    MonitorDetails.fromJSON = function (monitorDetailsDTO) {
        return new MonitorDetails(monitorDetailsDTO.name, monitorDetailsDTO.address, monitorDetailsDTO.system, monitorDetailsDTO.circuit, monitorDetailsDTO.details, monitorDetailsDTO.comments);
    };
    Object.defineProperty(MonitorDetails.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetails.prototype, "address", {
        get: function () {
            return this._address;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetails.prototype, "system", {
        get: function () {
            return this._system;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetails.prototype, "circuit", {
        get: function () {
            return this._circuit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetails.prototype, "details", {
        get: function () {
            return this._details;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetails.prototype, "comments", {
        get: function () {
            return this._comments;
        },
        enumerable: true,
        configurable: true
    });
    return MonitorDetails;
}());
export { MonitorDetails };
