import { takeUntil } from 'rxjs/operators';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../infrastructure/http/authentication/authentication.service';
import { Subject } from 'rxjs';
var UserLoginComponent = /** @class */ (function () {
    function UserLoginComponent(router, authenticationService, errorTranslator) {
        this.registerRequested = new EventEmitter();
        this.loginPerforming = false;
        this.unSubscribeLoginOnDestroy = new Subject();
        this.router = router;
        this.authenticationService = authenticationService;
        this.errorTranslator = errorTranslator;
        this.loginForm = new FormGroup({
            'email': new FormControl(null, [Validators.required, Validators.email]),
            'password': new FormControl(null, Validators.required)
        });
    }
    UserLoginComponent.prototype.ngOnInit = function () {
    };
    UserLoginComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeLoginOnDestroy.next(true);
        this.unSubscribeLoginOnDestroy.complete();
    };
    UserLoginComponent.prototype.login = function (form, isValid) {
        var _this = this;
        if (isValid) {
            this.loginPerforming = true;
            this.authenticationService.login(form.email, form.password)
                .subscribe(function () {
                _this.router.navigateByUrl('/monitors');
            }, function (error) {
                _this.loginForm.reset({ email: form.email });
                _this.loginPerforming = false;
                _this.errorTranslator.execute(error).pipe(takeUntil(_this.unSubscribeLoginOnDestroy))
                    .subscribe(function (errorMessage) {
                    throw new Error(errorMessage);
                });
            });
        }
    };
    UserLoginComponent.prototype.canLogin = function () {
        return this.loginForm.valid && !this.loginPerforming;
    };
    UserLoginComponent.prototype.goToRegistration = function () {
        this.registerRequested.emit(true);
    };
    UserLoginComponent.prototype.goToRequestResetPassword = function () {
        this.router.navigateByUrl('/request-reset-password');
    };
    return UserLoginComponent;
}());
export { UserLoginComponent };
