var GridEditMonitorComponent = /** @class */ (function () {
    function GridEditMonitorComponent() {
    }
    GridEditMonitorComponent.prototype.agInit = function (params) {
        this.monitor = params.data;
    };
    GridEditMonitorComponent.prototype.canEditMonitor = function () {
        return this.monitor.editableByCurrentUser && this.monitor.permissions.includes("EDIT_MON");
    };
    return GridEditMonitorComponent;
}());
export { GridEditMonitorComponent };
