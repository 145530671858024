enum MonitorInputType {
  ANALOG_MA = 'ANALOG_MA',
  ANALOG_V = 'ANALOG_V',
  DIGITAL_PULSE = 'DIGITAL_PULSE',
  DIGITAL_OPEN_CLOSED = 'DIGITAL_OPEN_CLOSED',
  PREDEFINED = 'PREDEFINED',
  AUTO='AUTO'
}

export default MonitorInputType;
