import { forkJoin as observableForkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { map as m } from 'lodash';
import { View } from '../../../domain/view/view';
import { ViewDetailStatusOverview } from '../../../domain/view/viewDetailStatusOverview';
var HttpViewsService = /** @class */ (function () {
    function HttpViewsService(http) {
        this.http = http;
    }
    HttpViewsService.prototype.getAll = function () {
        return null;
    };
    HttpViewsService.prototype.getAllForStatusOverview = function () {
        return this.http.get('/api/sensorsviews/status').pipe(map(function (response) {
            return response.map(View.fromJSON);
        }));
    };
    HttpViewsService.prototype.add = function (view) {
        var sensorIds = m(view.sensors, 'id');
        return this.http.post('/api/sensorsviews', {
            name: view.name,
            elucidation: view.elucidation,
            sensorIds: sensorIds
        }, { responseType: 'text' });
    };
    HttpViewsService.prototype.get = function (id) {
        return this.http.get("/api/sensorsviews/" + id).pipe(map(function (response) {
            return View.fromJSON(response);
        }));
    };
    HttpViewsService.prototype.edit = function (view) {
        var sensorIds = m(view.sensors, 'id');
        return this.http.put("/api/sensorsviews/" + view.id, {
            name: view.name,
            elucidation: view.elucidation,
            sensorIds: sensorIds
        }, { responseType: 'text' });
    };
    HttpViewsService.prototype.deleteView = function (id) {
        return this.http.delete("/api/sensorsviews/" + id, { responseType: 'text' });
    };
    HttpViewsService.prototype.getViewMeasurements = function (id, from, to) {
        var fromString = encodeURIComponent(from.toISOString());
        var toString = encodeURIComponent(to.toISOString());
        return this.http.get("/api/sensorsviews/" + id + "/measurements?from=" + fromString + "&to=" + toString);
    };
    /**
     * Returns either a new detail status overview, or updates the measurements if one is passed into the function.
     *
     * Always provides a copy, and does not modify in-place.
     * @param id
     * @param selectedDateRange
     * @param detailStatusOverview
     */
    HttpViewsService.prototype.getForDetailOverview = function (id, selectedDateRange, detailStatusOverview) {
        // See bug 595
        if (detailStatusOverview) {
            return this.getViewMeasurements(id, selectedDateRange.start, selectedDateRange.end).pipe(map(function (results) {
                return ViewDetailStatusOverview.detailFromJSON(detailStatusOverview, results);
            }));
        }
        else {
            return observableForkJoin([
                this.getSingleViewForStatusOverview(id),
                this.getViewMeasurements(id, selectedDateRange.start, selectedDateRange.end)
            ]).pipe(map(function (responses) {
                return ViewDetailStatusOverview.detailFromJSON(responses[0], responses[1]);
            }));
        }
    };
    HttpViewsService.prototype.getSingleViewForStatusOverview = function (id) {
        return this.http.get("/api/sensorsviews/" + id + "/status").pipe(map(function (response) {
            return View.fromJSON(response);
        }));
    };
    HttpViewsService.prototype.setChangedOrder = function (id, order) {
        return this.http.put("/api/sensorsviews/" + id + "/order", { order: order }).pipe(map(function (response) {
            return View.fromJSON(response);
        }));
    };
    return HttpViewsService;
}());
export { HttpViewsService };
