import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {omit} from 'lodash';
import {Moment} from 'moment';
import {FirmwareUpgradeStatus} from '../../../domain/monitor/firmware/firmware-upgrade-status';
import {Monitor} from '../../../domain/monitor/monitor';
import MonitorChannel from '../../../domain/monitor/monitorChannel';
import {MonitorDetails} from '../../../domain/monitor/monitorDetails';
import {MonitorDetailStatusOverview} from '../../../domain/monitor/monitorDetailStatusOverview';
import MonitorPrivacy from '../../../domain/monitor/monitorPrivacy';
import {MonitorStatusOverview} from '../../../domain/monitor/monitorStatusOverview';
import {DetailStatusOverview} from '../../../domain/monitor/sensor/DetailStatusOverview';
import {SensorCategory} from '../../../domain/monitor/sensor/sensor-category/sensor-category';
import {SensorPredefined} from '../../../domain/monitor/sensor/sensor-predefined';
import {SensorPredefinedList} from '../../../domain/monitor/sensor/sensor-predefined-list/sensor-predefined-list';
import {SensorAnalog} from '../../../domain/monitor/sensor/sensorAnalog';
import {SensorDigitalOpenClosed} from '../../../domain/monitor/sensor/sensorDigitalOpenClosed';
import {SensorDigitalPulse} from '../../../domain/monitor/sensor/sensorDigitalPulse';
import {Monitors} from './monitors';
import { UserWithRole } from 'app/domain/monitor/UserWithRole';
import { Chat } from 'app/domain/Chat';
import { SensorAuto } from 'app/domain/monitor/sensor/sensor-auto';

@Injectable()
export class HttpMonitorsService implements Monitors {
    private http: HttpClient;

    constructor(http: HttpClient) {
        this.http = http;
    }

    public getAllForStatusOverview(): Observable<MonitorStatusOverview[]> {
        return this.http.get('/api/monitors/status').pipe(
            map((response: any) => {
                return response.map(MonitorStatusOverview.fromJSON);
            }));
    }

    public addMonitor(activationCode: string, serialNumber: string): Observable<Monitor> {
        return this.http.post('/api/monitors', {
            activationCode,
            serialNumber
        }).pipe(map((response: any) => Monitor.fromJSON(response)));
    }

    public getAll(): Observable<Monitor[]> {
        return this.http.get('/api/monitors').pipe(
            map((response: any) => {
                return response.map(Monitor.fromJSON);
            }));
    }

    public updateMonitorDetails(id: string,
                                monitorDetails: MonitorDetails,
                                privacyLevel: MonitorPrivacy,
                                isEditMode: boolean): Observable<Monitor> {
        const joinedRequests = [];
        let expectedResponseIndex = 0;

        joinedRequests.push(this.http.put(`/api/monitors/${id}`, {
            name: monitorDetails.name,
            address: monitorDetails.address,
            system: monitorDetails.system,
            circuit: monitorDetails.circuit,
            details: monitorDetails.details,
            comments: monitorDetails.comments
        }));

        if (isEditMode) {
            joinedRequests.push(this.setMonitorPrivacyLevel(privacyLevel, id));
            expectedResponseIndex = 1;
        }

        return observableForkJoin(joinedRequests).pipe(
            map((responses: any) => Monitor.fromJSON(responses[expectedResponseIndex])));
    }

    public deleteMonitor(id: string): Observable<string> {
        return this.http.delete(`/api/monitors/${id}`, {
            responseType: 'text'
        });
    }

    public singleMonitor(id: number): Observable<Monitor> {
        return this.http.get(`/api/monitors/${id}`).pipe(
            map((response) => Monitor.fromJSON(response)));
    }

    public addMonitorSensor(id: string,
                            sensor: SensorAnalog | SensorDigitalPulse | SensorDigitalOpenClosed | SensorPredefined | SensorAuto,
                            canAddSensor: boolean,
                            sensorId: string = null): Observable<any> {
        if (canAddSensor) {
            return this.addSensor(id, sensor);
        }

        return this.updateSensor(id, sensor, sensorId);
    }

    public getFirmwareUpgradeStatus(id: string): Observable<FirmwareUpgradeStatus> {
        return this.http.get(`/api/monitors/${id}/upgrade-status`).pipe(
            map((response: any) => FirmwareUpgradeStatus.fromJSON(response)));
    }

    public requestFirmwareUpgrade(id: string): Observable<FirmwareUpgradeStatus> {
        return this.http.post(`/api/monitors/${id}/upgrade`, {}).pipe(
            map((response: any) => FirmwareUpgradeStatus.fromJSON(response)));
    }

    public getMonitorMeasurements(id: string, from: Moment, to: Moment): Observable<any> {
        const fromString = encodeURIComponent(from.toISOString());
        const toString = encodeURIComponent(to.toISOString());

        return this.http.get(`/api/monitors/${id}/measurements?from=${fromString}&to=${toString}`);
    }

    /**
     * Returns either a new detail status overview, or updates the measurements if one is passed into the function.
     *
     * Always provides a copy, and does not modify in-place.
     * @param id
     * @param selectedDateRange
     * @param detailStatusOverview
     */
    public getForDetailOverview(id: string, selectedDateRange: { start: Moment, end: Moment }, monitorStatusOverview?: MonitorStatusOverview): Observable<DetailStatusOverview> {
        // See bug 595
        if (monitorStatusOverview) {
            return this.getMonitorMeasurements(id, selectedDateRange.start, selectedDateRange.end).pipe(
                map(results => {
                    return MonitorDetailStatusOverview.fromJSON(monitorStatusOverview, results);
                }));
        } else {
            return observableForkJoin([
                this.getSingleMonitorForStatusOverview(id),
                this.getMonitorMeasurements(id, selectedDateRange.start, selectedDateRange.end)
            ]).pipe(map((responses: any) => {
                return MonitorDetailStatusOverview.fromJSON(responses[0], responses[1]);
            }));
        }
    }

    public listAllSensorCategories(): Observable<SensorCategory[]> {
        return this.http.get('/api/sensorcategories').pipe(
            map((response: any) => response.map(SensorCategory.fromJSON)));
    }

    public listAllPredefinedSensors(channelType: MonitorChannel): Observable<SensorPredefinedList[]> {
        return this.http.get(`/api/predefined?channelType=${channelType}`).pipe(
            map((response: any) => response.map(SensorPredefinedList.fromJSON)));
    }

    public getSingleMonitorForStatusOverview(id: string): Observable<MonitorStatusOverview> {
        return this.http.get(`/api/monitors/${id}/status`).pipe(map(MonitorStatusOverview.fromJSON));
    }

    public setMonitorPrivacyLevel(privacyLevel: MonitorPrivacy, id: string): Observable<any> {
        return this.http.put(`/api/monitors/${id}/privacy`, {privacyLevel});
    }

    public setSensorLimits(monitorId: string, sensorId: string, limits) {
        return this.http.post(`/api/monitors/${monitorId}/measurements/${sensorId}/limits`, limits);
    }

    public setPressureDelta(monitorId: string, sensorId: string, limits) {
        return this.http.post(`/api/monitors/${monitorId}/measurements/${sensorId}/pressureDelta`, limits);
    }

    public setSensorAlarm(monitorId: string, sensorId: string) {
        return this.http.post(`/api/monitors/${monitorId}/measurements/${sensorId}/ignoreAlarm`, {});
    }

    private addSensor(id: string,
                      sensor: SensorAnalog | SensorDigitalPulse | SensorDigitalOpenClosed | SensorPredefined  | SensorAuto): Observable<any> {
        return this.http.post(`/api/monitors/${id}/sensors`,
            omit(
                sensor.toJSON(sensor),
                ['categoryId']
            )
        );
    }

    public addKey(email: string, monitor: string, role: string, message: string) {
        return this.http.post(`/api/monitors/${monitor}/key`, {role, email,message})
    }

    private updateSensor(id: string,
                         sensor: SensorAnalog | SensorDigitalPulse | SensorDigitalOpenClosed | SensorPredefined  | SensorAuto,
                         sensorId: string): Observable<any> {
        return this.http.put(`/api/monitors/${id}/sensors/${sensorId}`,
            omit(
                sensor.toJSON(sensor),
                ['categoryId']
            )
        );
    }

    public setChangedOrder(id: string, order: { [key: string]: number }): Observable<Monitor> {
        return this.http.put(`/api/monitors/${id}/order`, {order}).pipe(
            map((response: any) => {
                return Monitor.fromJSON(response);
            }));
    }

    public addByKey(serial: string, role: string, key: string, email: string): Observable<void> {
        return this.http.post<void>(`/api/monitors/${serial}/add`, {email, role, key})
    }

    public getUsersOnMonitor(id: string): Observable<UserWithRole[]> {
        return this.http.get<UserWithRole[]>(`/api/monitors/${id}/users`)
    }

    public removeUserRoleFromMonitor(monitorid: string, userid: string, role: string): Observable<void> {
        return this.http.delete<void>(`/api/monitors/${monitorid}/users/${userid}/${role}`);
    }

    public addByShareRequest(serial: string, role: string, key: string, email: string): Observable<void> {
        return this.http.post<void>(`/api/monitors/${serial}/acceptShareRequest`, {email, role, key})
    }

    // Not finished: we don't know about any admins to send this to.
    public sendShareRequest(monitorid: string, role: string): Observable<void> {
        return this.http.post<void>(`/api/monitors/${monitorid}/shareRequest`, {role});
    }

    public getChatForMonitor(id: string) {
        return this.http.get<Chat[]>(`/api/monitors/${id}/chat`);
    }

    public postChatForMonitor(monitorid: string, text: string) {
        return this.http.post<Chat>(`/api/monitors/${monitorid}/chat`, {text});

    }
}
