<mat-card>
        <mat-card-header>
          <mat-card-title
            class="antialiased" fxLayout.lt-md="column" fxLayout="row" fxLayout.xs="row" fxLayoutAlign="start start">
            <h3 fxFlex>{{ 'MONITOR.STATUS' | translate }}</h3>
            <div class="monitor-status" fxLayoutAlign.xs="end center">
              <div class="monitor-status">
                <div *ngIf="statusInfo">
                  <span class="device-status" [ngClass]="getClassStatusForDeviceStatus()"></span>
                  <span>{{'MONITOR.' + statusInfo.status | translate }}</span>
                  &nbsp;&nbsp;
                  <span class="relay-status" [ngClass]="getClassStatusForRelayStatus()"></span>
                  <span>{{ 'Alarm ' + getRelayStatusString() }}</span>
                </div>
                <div>
                  <ul *ngIf="hasMonitorErrors()" class="error-list">
                    <li *ngFor="let error of statusInfo.errors">
                      <small>{{error}}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list fxLayout.gt-sm="row wrap" fxLayout.lt-md="column" fxLayout.xs="row" class="sensor-list">
            <mat-list-item *ngFor="let sensor of sensors"
                           [fxFlex]="50"
                           class="status">
              <div class="monitor-status" [fxFlex]="10" [fxFlex.xs]="20">
                <span class="device-status" [ngClass]="getClassStatusForSensorStatus(sensor)"></span>
              </div>
              <div fxFlex class="sensor-list-info">
                <p>{{sensor.name}}</p>
                <div fxLayout="column">
                  <small *ngIf="!isMonitor" class="secondaryinfo" >{{sensor.monitorId}}</small>
                </div>
                <ul *ngIf="hasSensorErrors(sensor)" class="error-list">
                  <li *ngFor="let error of sensor.errors">
                    <small>{{error}}</small>
                  </li>
                </ul>
                
                <div fxLayout="column">
                  <small *ngIf="!hasSensorErrors(sensor)">{{'MONITOR.SENSOR' | translate}} {{sensor.status}}</small>
                  <small *ngIf="sensor.sensorhealth !== undefined">{{'MONITOR.HEALTH' | translate}} {{sensor.sensorhealth}}%</small>
                </div>
              </div>
            </mat-list-item>
            <!-- 
            <mat-list-item *ngIf="statusInfo.relayState"
                           [fxFlex]="50"
                           class="status">
              <div class="monitor-status" [fxFlex]="10" [fxFlex.xs]="20">
                <span class="relay-status" [ngClass]="getClassStatusForRelayStatus(statusInfo.relayState)"></span>
              </div>
              <div fxFlex class="sensor-list-info">
                <p>Alarm status</p>
                
              </div>
            </mat-list-item>
        --> 
          </mat-list>
        </mat-card-content>
        <mat-card-footer>
          <h3>{{ 'MONITOR.CONNECTION' | translate }}</h3>
          <mat-card-subtitle class="antialiased last-measured" fxLayout="column" fxLayout.gt-xs="row">
            <span>{{'MONITOR.OVERVIEW.LAST_MEASURED' | translate}} {{lastMeasuredDateTime | amTimeAgo}}</span>
          </mat-card-subtitle>
        </mat-card-footer>
      </mat-card>