var SensorLimit = /** @class */ (function () {
    function SensorLimit(datetime, value) {
        this._datetime = datetime;
        this._value = value;
    }
    SensorLimit.fromJSON = function (sensorLimitDTO) {
        return new SensorLimit(new Date(sensorLimitDTO.datetime), sensorLimitDTO.value);
    };
    Object.defineProperty(SensorLimit.prototype, "datetime", {
        get: function () {
            return this._datetime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SensorLimit.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    return SensorLimit;
}());
export { SensorLimit };
