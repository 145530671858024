import {HttpLoaderFactory} from '../../../application/translation/httpLoaderFactory';
import {MaterialModule} from '../../../material.module';
import {SharedModule} from '../../../shared.module';
import {GridEditViewComponent} from '../../components/grid/edit-view/grid-edit-view.component';
import {GridHeaderNameComponent} from '../../components/grid/header-name/grid-header-name.component';
import {ManageViewsComponent} from './manage-views/manage-views.component';
import {ViewAddComponent} from './view-add/view-add.component';
import {ViewsComponent} from './views.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {AgGridModule} from 'ag-grid-angular';
import {MomentModule} from 'angular2-moment';
import {MatExpansionModule} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";
import {GridDeleteViewComponent} from "../../components/grid/grid-delete-view/grid-delete-view.component";


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatExpansionModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MomentModule,
        AgGridModule.withComponents([
            GridHeaderNameComponent,
            GridEditViewComponent,
            GridDeleteViewComponent
        ]),
        FlexLayoutModule

    ],
    declarations: [
        ViewsComponent,
        ViewAddComponent,
        ManageViewsComponent,
    ],
    exports: [
        ViewsComponent,
        ViewAddComponent,
        ManageViewsComponent,
    ],
    entryComponents: [
        ViewsComponent,
        ViewAddComponent,
        ManageViewsComponent,
    ],
})
export class ViewsModule {
}
