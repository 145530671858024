import { startsWith, isNil, find } from 'lodash';
import { IAppConfig } from '../../app.config';
import { convertPagesToRoutes } from '../../application/command/convert-page-to-route';
var NavigationService = /** @class */ (function () {
    function NavigationService(config) {
        this.pathSeparator = '/';
        this._pages = [];
        this.routes = [];
        this._pages = config.pages;
        this.routes = convertPagesToRoutes(this._pages);
    }
    NavigationService.prototype.getMenuPages = function () {
        return this._pages.filter(function (page) { return page.showInMenu; });
    };
    NavigationService.prototype.isSecuredPath = function (path) {
        var _this = this;
        var result = true;
        var pageMatchingPath = find(this._pages, function (page) {
            return startsWith(path, _this.pathSeparator + page.path);
        });
        if (!isNil(pageMatchingPath)) {
            result = pageMatchingPath.securedPage;
        }
        return result;
    };
    NavigationService.prototype.getRoutes = function () {
        return this.routes;
    };
    NavigationService.prototype.getSubPagesOfPath = function (path) {
        var _this = this;
        var result = [];
        var pageMatchingPath = find(this._pages, function (page) {
            return startsWith(path, _this.pathSeparator + page.path);
        });
        if (!isNil(pageMatchingPath)) {
            result = pageMatchingPath.subPages;
        }
        return result;
    };
    NavigationService.prototype.getCurrentPage = function (path) {
        path = path.split('?')[0];
        var id = path.substring(path.indexOf(this.pathSeparator), path.lastIndexOf(this.pathSeparator) + 1);
        var pattern = new RegExp('.*\\/.*\\/.*');
        if (pattern.test(path)) {
            path = path.replace(id, this.pathSeparator + ":id" + this.pathSeparator);
        }
        return find(this._pages, ['path', path]);
    };
    NavigationService.prototype.getDefaultPage = function () {
        return find(this._pages, function (page) {
            return page.isDefault;
        });
    };
    return NavigationService;
}());
export { NavigationService };
