import {HttpLoaderFactory} from '../../../application/translation/httpLoaderFactory';
import {MaterialModule} from '../../../material.module';
import {SharedModule} from '../../../shared.module';
import {GridEditMonitorComponent} from '../../components/grid/edit-monitor/grid-edit-monitor.component';
import {GridMonitorInformationComponent} from '../../components/grid/monitor-information/monitor-information.component';
import {GridMonitorSoftwareComponent} from '../../components/grid/monitor-software/monitor-software.component';
import {ManageMonitorsDesktopComponent} from './manage-monitors/desktop/manage-monitors.component';
import {ManageMonitorsComponent} from './manage-monitors/manage-monitors.component';
import {ManageMonitorsMobileComponent} from './manage-monitors/mobile/manage-monitors.component';
import {MonitorAddRedirectComponent} from './monitor-add-redirect/monitor-add-redirect.component';
import {MonitorAddStep1Component} from './monitor-add/monitor-add-step-1/monitor-add-step-1.component';
import {MonitorAddStep2Component} from './monitor-add/monitor-add-step-2/monitor-add-step-2.component';
import {MonitorAddStep3Component} from './monitor-add/monitor-add-step-3/monitor-add-step-3.component';
import {MonitorAddComponent} from './monitor-add/monitor-add.component';
import {MonitorsComponent} from './monitors.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AgGridModule} from 'ag-grid-angular';
import {MomentModule} from 'angular2-moment';
import {MonitorInformationTileComponent} from './monitor-information-tile/monitor-information-tile.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {GridDeleteMonitorComponent} from "../../components/grid/grid-delete-monitor/grid-delete-monitor.component";
import { GridMonitorSerialnumberComponent } from 'app/presentation/components/grid/monitor-serialnumber/monitor-serialnumber.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MomentModule,
        AgGridModule.withComponents([
            GridEditMonitorComponent,
            GridMonitorInformationComponent,
            GridMonitorSoftwareComponent,
            GridDeleteMonitorComponent,
            GridMonitorSerialnumberComponent

        ]),
        FlexLayoutModule
    ],
    declarations: [
        GridMonitorInformationComponent,
        GridEditMonitorComponent,
        GridMonitorSoftwareComponent,
        MonitorAddComponent,
        MonitorAddRedirectComponent,
        MonitorAddComponent,
        ManageMonitorsComponent,
        MonitorsComponent,
        MonitorAddStep1Component,
        MonitorAddStep2Component,
        MonitorAddStep3Component,
        ManageMonitorsDesktopComponent,
        ManageMonitorsMobileComponent,
        MonitorInformationTileComponent,
        GridDeleteMonitorComponent,
        GridMonitorSerialnumberComponent

    ],
    exports: [
        MonitorAddComponent,
        MonitorAddRedirectComponent,
        MonitorAddComponent,
        ManageMonitorsComponent,
        MonitorsComponent,
        MonitorInformationTileComponent
    ],
    entryComponents: [
        MonitorsComponent,
        MonitorAddComponent,
        MonitorAddRedirectComponent,
        MonitorAddStep1Component,
        MonitorAddStep2Component,
        MonitorAddStep3Component,
        ManageMonitorsComponent,
    ],
})
export class MonitorsModule {
}
