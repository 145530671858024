import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { COMMA, ENTER, TAB } from "@angular/cdk/keycodes";
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var RequestComponent = /** @class */ (function () {
    function RequestComponent(dialogRef, monitorId, monitorService) {
        this.dialogRef = dialogRef;
        this.monitorId = monitorId;
        this.monitorService = monitorService;
        this.separatorKeysCodes = [ENTER, COMMA, TAB];
        this.removable = true;
        this.model = {
            emails: [],
            role: "VIEWER",
            text: ""
        };
        this.loading = false;
        this.showError = false;
    }
    RequestComponent.prototype.ngOnInit = function () {
    };
    RequestComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.monitorService.sendShareRequest(this.monitorId.id, this.model.role).subscribe(function () {
            _this.loading = false;
            return _this.dialogRef.close();
        }, function () { return _this.dialogRef.close(); });
    };
    RequestComponent.prototype.removeStyle = function (obj) {
        obj.style.color = "";
        this.showError = false;
    };
    return RequestComponent;
}());
export { RequestComponent };
