import { map, tap, filter, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { User } from '../../../domain/user';
import { isNil } from 'lodash';
import { AuthenticationService } from '../authentication/authentication.service';
var HttpUsersService = /** @class */ (function () {
    function HttpUsersService(http, authenticationService) {
        this.authenticationService = authenticationService;
        this.notificationsSubject = new ReplaySubject(1);
        this.http = http;
        this.ngOnInit();
    }
    HttpUsersService.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticationService.getAuthenticatedUser()
            .pipe(filter(function (a) { return !isNil(a); }), distinctUntilChanged(function (obj1, obj2) { return obj1 && obj2 && obj1.id === obj2.id; }))
            .subscribe(function (user) {
            _this.clear();
            setTimeout(function () { return _this.getNotificationsDirect().subscribe(); }, 200);
            _this.interval = setInterval(function () { return _this.getNotificationsDirect().subscribe(); }, 60000);
        });
    };
    HttpUsersService.prototype.clear = function () {
        clearInterval(this.interval);
        this.notificationsSubject.next([]);
    };
    HttpUsersService.prototype.register = function (email, password, firstName, lastName, phone, jobCategory, jobTitle, agreeToTermsAndConditions) {
        return this.http.post('/api/users/', {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            jobCategory: jobCategory,
            jobTitle: jobTitle,
            agreeToTermsAndConditions: agreeToTermsAndConditions
        });
    };
    HttpUsersService.prototype.verifyEmail = function (key) {
        return this.http.post('/api/users/verify-email', { key: key }, { responseType: 'text' });
    };
    HttpUsersService.prototype.requestResetPassword = function (email) {
        return this.http.post('/api/users/password-reset', {
            email: email
        }, { responseType: 'text' });
    };
    HttpUsersService.prototype.resetPassword = function (key, password) {
        return this.http.put("/api/users/password-reset/" + key, {
            password: password
        }, { responseType: 'text' });
    };
    HttpUsersService.prototype.edit = function (user) {
        return this.http.put("/api/users/me", {
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            jobCategory: user.jobCategory,
            jobTitle: user.jobTitle
        }).pipe(map(User.fromJSON));
    };
    HttpUsersService.prototype.deleteUser = function (user) {
        var _this = this;
        return this.http.post("/api/users/me/delete", {}).pipe(tap(function () { return _this.authenticationService.logout(); }));
    };
    HttpUsersService.prototype.editPassword = function (oldPassword, newPassword) {
        return this.http.put("/api/users/me/password-change", {
            oldPassword: oldPassword,
            newPassword: newPassword
        }).pipe(map(User.fromJSON));
    };
    HttpUsersService.prototype.getNotificationsDirect = function () {
        var _this = this;
        return this.http.get("/api/notifications")
            .pipe(tap(function (obj) { return _this.notificationsSubject.next(obj); }));
    };
    HttpUsersService.prototype.getNotifications = function () {
        return this.notificationsSubject;
    };
    HttpUsersService.prototype.setNotificationRead = function (id, read) {
        var _this = this;
        return this.http.put("/api/notifications/" + id + "/read", { read: read })
            .pipe(tap(function () { return _this.getNotificationsDirect().subscribe(); }));
    };
    HttpUsersService.prototype.getSentNotifications = function () {
        return this.http.get("/api/notifications/sent");
    };
    return HttpUsersService;
}());
export { HttpUsersService };
