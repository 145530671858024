import { OnInit, EventEmitter } from '@angular/core';
import { isEqual } from 'lodash';
var ViewDndSortingComponent = /** @class */ (function () {
    function ViewDndSortingComponent() {
        this.modelSorted = new EventEmitter();
    }
    ViewDndSortingComponent.prototype.ngOnInit = function () {
        this.id = Math.floor(Math.random() * 10000);
    };
    Object.defineProperty(ViewDndSortingComponent.prototype, "model", {
        get: function () {
            return this.displayObjects.map(function (displayObject) { return displayObject.content; });
        },
        set: function (val) {
            this.displayObjects = val
                .map(function (arr) { return arr.slice(); }).filter(function (arr) { return arr.length > 0; })
                .map(this.getNewDisplayObjectFromArray);
        },
        enumerable: true,
        configurable: true
    });
    // Drag and drop event handlers for updating display model
    ViewDndSortingComponent.prototype.onDrop = function () {
        this.currentlyDraggedItem = null;
        this.modelSorted.emit(this.model);
    };
    ViewDndSortingComponent.prototype.onMainDrop = function (event) {
        // We must wrap the value in its own subcontainer.
        this.model = this.insertIntoArray(this.model, event.dropIndex, [event.value]);
        this.onDrop();
    };
    ViewDndSortingComponent.prototype.onOver = function (event) {
        var displayObj = this.getDisplayObjectById(event.container.id);
        displayObj.isBeingHovered = true;
        this.currentlyDraggedWidth = this.getWidthForContainer(displayObj);
        this.currentlyDraggedItem = event.value;
    };
    ViewDndSortingComponent.prototype.onOverMain = function (event) {
        this.currentlyDraggedWidth = "98.3%";
        this.currentlyDraggedItem = event.value;
    };
    ViewDndSortingComponent.prototype.onOut = function (event) {
        var displayObj = this.getDisplayObjectById(event.container.id);
        displayObj.isBeingHovered = false;
        this.currentlyDraggedItem = null;
    };
    ViewDndSortingComponent.prototype.onDrag = function (event) {
        var displayObj = this.getDisplayObjectById(event.source.id);
        displayObj.isBeingDragged = true;
    };
    // Translation of display model for individual containers -- referenced from html 
    ViewDndSortingComponent.prototype.getWidthOfItem = function (displayObject, item) {
        return this.isItemBeingDragged(item) ? this.currentlyDraggedWidth : this.getWidthForContainer(displayObject);
    };
    ViewDndSortingComponent.prototype.isVisible = function (displayObject) {
        return Array.isArray(displayObject.content) && displayObject.content.length > 0;
    };
    ViewDndSortingComponent.prototype.getHeight = function () {
        return this.isMonitor ? "45px" : "60px";
    };
    // Helper functions
    ViewDndSortingComponent.prototype.isItemBeingDragged = function (item) {
        return isEqual(item, this.currentlyDraggedItem);
    };
    ViewDndSortingComponent.prototype.getWidthForContainer = function (displayObject) {
        // If it is being hovered and not the source
        var isDraggedMemberIncluded = displayObject.isBeingHovered && !displayObject.isBeingDragged ? 1 : 0;
        var isOneMemberBeingDragged = !displayObject.isBeingHovered && displayObject.isBeingDragged ? -1 : 0;
        var amountInThisContainer = (displayObject.content.length + isDraggedMemberIncluded + isOneMemberBeingDragged);
        return Math.floor(100 / amountInThisContainer) - 2 + "%";
    };
    ViewDndSortingComponent.prototype.getNewDisplayObjectFromArray = function (arr) {
        return {
            id: Math.floor(Math.random() * 10000),
            isBeingDragged: false,
            isBeingHovered: false,
            content: arr
        };
    };
    ViewDndSortingComponent.prototype.getDisplayObjectById = function (id) {
        return this.displayObjects.find(function (displayObject) { return displayObject.id == id; });
    };
    ViewDndSortingComponent.prototype.insertIntoArray = function (array, index, value) {
        var toInsert = array.slice();
        toInsert.splice(index, 0, value);
        return toInsert;
    };
    return ViewDndSortingComponent;
}());
export { ViewDndSortingComponent };
