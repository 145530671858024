<app-registration-content>
  <div class="container_privacy_statement">
    <article>
      <h3>Privacy statement</h3>
      <p>Resus recognises the importance of the protection of personal information and data. To us, this means a commitment regarding the respect of privacy, as well as the respect of the practices
        applicable to gathering, updating and using personal information and data. Resus will use or apply your data exclusively in accordance with the relevant applicable statutory provisions.</p>
    </article>
    <article>
      <h3>Permission</h3>
      <p>By using this website and/or communicating your personal data, you expressly agree with the manner in which Resus gathers and processes personal data, as referred to in this privacy
        statement. Your personal data is gathered and processed on the grounds of Article 5, a of the Law of 8 December 1992/Article 6,1 a of the General Data Protection Regulation.</p>
    </article>
    <article>
      <h3>Types of personal data</h3>
      <p>In particular, personal data is information about your identity. This includes, for example, name, address, telephone number or email address. In general, you can visit the Resus website
        without us needing your personal data. Firstly, we only find out your IP address, your browser and operating system, the external website that referred you to this website and the date and
        time of every page you visit. This information is only gathered for statistical purposes and to improve the website further.</p>
      <p>Through the website you can opt to communicate your personal data (in particular: your name, address details, telephone number, email address and company) by completing the applicable contact
        form.</p>
      <p>You can register through Resus Cloud Dashboard and you need to fill in your name, email, user name, password and job description, as well as the address of the installation. If you choose not
        to provide the personal data we request, you will in this case not be able to use the service we provide. Non-personal data such as the system within which the corrosion monitor was installed
        and the serial and activation numbers are also gathered in this regard.</p>
    </article>
    <article>
      <h3>Manner in which personal data are gathered</h3>
      <p>Personal data are gathered through the website when you complete the contact form or register for the Resus Cloud Dashboard.</p>
    </article>
    <article>
      <h3>Purposes for which personal data are gathered</h3>
      <p>If you contact us by email or by way of the applicable contact form, the data that you provide are archived and used for the processing of your request and for any subsequent questions.</p>
      <p>If you register for the Resus Cloud Dashboard, the data that you provide are archived and used to read out and display the data coming from the corrosion monitor that you entered. These data
        are also archived internally and can be used for internal purposes such as data analysis and product research.</p>
      <p>Your personal data are only passed on to third parties if this is essential within the scope of the execution of the agreement, if we are obliged to do so by statutory provisions or judicial
        decisions or if you give your express permission in advance to pass the data on to third parties. Apart from this, no data are given to third parties.</p>
    </article>
    <article>
      <h3>Security</h3>
      <p>Resus undertakes to take all the appropriate technical and organisational measures to protect your personal data against destruction, loss, unintentional change or disclosure.</p>
    </article>
    <article>
      <h3>Cookies</h3>
      <p>Resus uses cookies to ease your future visit to the website. Cookies are small text files that are saved on a user’s computer. No personal data are saved in the cookies.</p>
    </article>
    <article>
      <h3>Inspection and correction</h3>
      <p>You have at all times a statutory right to supplement, correct or remove all or part of your personal data or to withdraw the permission you have given. You can exercise this right by
        contacting Resus by way of the contact data stated below, on condition that you adequately identify yourself. You can always address the competent authorities if you have any complaints.</p>
    </article>
    <article>
      <h3>Integrity and archiving period</h3>
      <p>Resus will archive your personal data for the period necessary to achieve the objectives as these are outlined in this Privacy Statement, unless a longer archiving period is required or
        allowed by law.</p>
    </article>
    <article>
      <h3>Changes</h3>
      <p>Resus reserves the right to change the privacy statement at any time.</p>
    </article>
    <article>
      <h3>Contact details</h3>
      <p>Resus is responsible for processing these personal data and monitors the confidentiality and security of the data. If you have any questions or remarks relating to this Privacy Statement or
        if you wish to inspect, change or remove your data, you can contact Resus at the following email address: <a mailto="info@resus.eu">info@resus.eu</a>.</p>
    </article>

  </div>
</app-registration-content>
