<section fxLayout="column">
  <h1>{{'USER.PROFILE' | translate}}</h1>
  <mat-card>
    <form [formGroup]="userDetailsForm" fxLayout="column" (ngSubmit)="saveUserProfile(userDetailsForm)">
      <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
        <!-- First name -->
        <mat-form-field fxFlex>
          <input matInput
                 type="text"
                 placeholder="{{'FIRSTNAME' | translate}}" formControlName="firstName">
          <mat-error *ngIf="userDetailsForm.get('firstName').errors?.required">
            {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'FIRSTNAME' | translate, min: 1} }}
          </mat-error>
          <mat-error *ngIf="userDetailsForm.get('firstName').errors?.maxlength">
            {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'FIRSTNAME' | translate, max: 255} }}
          </mat-error>
        </mat-form-field>

        <!-- Last name -->
        <mat-form-field fxFlex>
          <input matInput
                 type="text"
                 placeholder="{{'LASTNAME' | translate}}" formControlName="lastName">
          <mat-error *ngIf="userDetailsForm.get('lastName').errors?.required">
            {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'LASTNAME' | translate, min: 1} }}
          </mat-error>
          <mat-error *ngIf="userDetailsForm.get('lastName').errors?.maxlength">
            {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'LASTNAME' | translate, max: 255} }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="input-row">
        <!-- Email -->
        <mat-form-field fxFill>
          <mat-error>{{'REGISTER.ERROR_INVALID_EMAIL' | translate}}</mat-error>
          <input matInput
                 type="email"
                 placeholder="{{'EMAIL' | translate}}"
                 email
                 formControlName="email">
        </mat-form-field>
      </div>

      <div class="input-row">
        <!-- Job category -->
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'REGISTER.HINT_JOB_CATEGORY' | translate}}"
                      formControlName="jobCategory">
            <mat-option *ngFor="let key of keyJobCategories" [value]="key">
              {{ 'JOBCATEGORY.' + key | translate}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userDetailsForm.get('jobCategory').errors?.required">
            {{'REGISTER.ERROR_JOB_CATEGORY' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="input-row">
        <!-- Job title -->
        <mat-form-field fxFlex>
          <input matInput
                 type="text"
                 placeholder="{{'REGISTER.HINT_JOB_TITLE' | translate}}" formControlName="jobTitle">
          <mat-error *ngIf="userDetailsForm.get('jobTitle').errors?.required">
            {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'REGISTER.HINT_JOB_TITLE' | translate, min: 1} }}
          </mat-error>
          <mat-error *ngIf="userDetailsForm.get('jobTitle').errors?.maxlength">
            {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'REGISTER.HINT_JOB_TITLE' | translate, max: 255} }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="input-row">
        <!-- Phone -->
        <mat-form-field fxFlex>
          <input matInput
                 type="text"
                 placeholder="{{'REGISTER.HINT_PHONE' | translate}}" formControlName="phone">
          <mat-error *ngIf="userDetailsForm.get('phone').errors?.required">
            {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'REGISTER.HINT_PHONE' | translate, min: 1} }}
          </mat-error>
          <mat-error *ngIf="userDetailsForm.get('phone').errors?.maxlength">
            {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'REGISTER.HINT_PHONE' | translate, max: 255} }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="input-row submit">
        <button fxFill mat-raised-button color="primary" type="submit" [disabled]="!canSaveUserProfile()">
          {{'GENERAL.SAVE' | translate}}
        </button>
      </div>
      <div class="input-row additional-action">
        <a class="antialiased" routerLink="/change-password">
          {{'USER.CHANGE_PASSWORD' | translate}}
        </a>
        <a class="antialiased" (click)="deleteAccount()" class="float-right">
          {{'USER.DELETE' | translate}}
        </a>
      </div>
    </form>

  </mat-card>
</section>
