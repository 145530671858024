<div fxLayout="column" fxFlex>
    <app-navigation-toolbar [subPages]="subPages"></app-navigation-toolbar>

    <app-progress-spinner
            *ngIf="isLoading" fxFlex
            [progressSpinnerText]="'NAVIGATION.DETAILS' | translate">
    </app-progress-spinner>

    <section class="details" fxFlex *ngIf="!isLoading">
        <section fxLayout.lt-lg="column" fxLayoutGap="36px" fxFlex>
            <!-- Small screen -->
            <article fxHide fxShow.lt-lg [fxFlex.gt-md]="30">

                <h1 class="name">{{detailStatusOverview.name}}</h1>
                <div  class="input-group datepicker"
                daterangepicker
                [options]="datepickerOptions"
                (selected)="selectedDate($event)">
                    <span class="form-control uneditable-input" name="daterange">
                        {{ datepickerOptions.startDate | date:'dd/MM/y' }} - {{ datepickerOptions.endDate| date:'dd/MM/y' }}
                    </span>
                    <span class="input-group-btn">
                        <a type="button" class="btn btn-default"><i class="glyphicon glyphicon-calendar"></i></a>
                    </span>
                </div>

                <app-monitor-information-tile *ngIf="isMonitor"
                                              [monitorDetailStatusOverview]="detailStatusOverview"></app-monitor-information-tile>


                <app-status-tile
                        [sensors]="detailStatusOverview.sensors"
                        [lastMeasuredDateTime]="detailStatusOverview.lastMeasuredDateTime"
                        [statusInfo]="detailStatusOverview.statusInfo"
                        [isMonitor]="isMonitor"></app-status-tile>
                
                <app-details-actions *ngIf="isMonitor && canEdit()" [monitor]="monitorSensors"></app-details-actions>
                <app-details-users *ngIf="isMonitor" [monitor]="detailStatusOverview"></app-details-users>
                <app-details-chat *ngIf="isMonitor" [monitor]="detailStatusOverview"></app-details-chat>


                <mat-card *ngIf=" monitorSensors">
                    <mat-card-header>
                        <mat-card-title
                                class="antialiased" fxLayout.lt-md="column" fxLayout="row" fxLayout.xs="row"
                                fxLayoutAlign="start start">
                            <h3 fxFlex>{{ 'VIEW.SORT' | translate }}</h3>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-view-dnd-sorting [isMonitor]="isMonitor" [model]="monitorSensors" (modelSorted)="sorted($event)"
                                              [key]="'name'"></app-view-dnd-sorting>
                    </mat-card-content>
                </mat-card>
            </article>


            <article [fxFlex.gt-md]="70">
                <section *ngIf="hasSensors(detailStatusOverview.sensors)">

                    <article fxLayout="row" fxLayoutAlign="space-between">
                        <h1 fxHide.lt-lg fxShow fxFlex class="name">{{detailStatusOverview.name}}</h1>
                        <div  class="input-group datepicker"
                             daterangepicker
                             [options]="datepickerOptions"
                             (selected)="selectedDate($event)">
                    <span class="form-control uneditable-input" name="daterange">
                        {{ datepickerOptions.startDate | date:'dd/MM/y' }} - {{ datepickerOptions.endDate| date:'dd/MM/y' }}
                    </span>
                            <span class="input-group-btn">
                        <a type="button" class="btn btn-default"><i class="glyphicon glyphicon-calendar"></i></a>
                    </span>
                        </div>
                    </article>

                    <ng-container *ngIf="monitorSensors">
                        <ng-container
                                *ngFor="let monitorSensorGroup of monitorSensors trackBy: trackByMonitorSensors; let last = last; let index = index">
                            <mat-card *ngIf="monitorSensorGroup.length > 0" class="chart">
                                <mat-card-header>
                                    <mat-card-title
                                            class="antialiased" fxLayout="row">
                                        <div fxLayout="row" fxLayoutGap="20px">
                                            <div *ngFor="let monitorSensor of monitorSensorGroup" fxLayout="column">
                                                <h3>{{monitorSensor.name}}</h3>
                                                <small *ngIf="!isMonitor">{{monitorSensor.monitorId}}</small>
                                            </div>
                                        </div>
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                    <app-chart
                                            [sensors]="monitorSensorGroup"
                                            [monitorId]="id"
                                            [isFetchingNewData]="isFetchingNewData"
                                            [measurements]="detailStatusOverview.measurements"
                                            [selectedDateRange]="selectedDateRange"
                                            [selectedDateRangeUpdater]="selectedDateRangeUpdater"></app-chart>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>
                    </ng-container>
                </section>

                <mat-card *ngIf="!hasSensors(detailStatusOverview.sensors)">
                    <mat-card-content>
                        <app-message
                                [messageText]="'MONITOR.WARNINGS.NO_SENSORS' | translate"
                                [messageStyle]="MESSAGE_STYLE.WARNING">
                        </app-message>
                    </mat-card-content>
                </mat-card>
            </article>


            <article fxHide fxShow.gt-md [fxFlex.gt-md]="30">

                <app-monitor-information-tile *ngIf="isMonitor"
                                              [monitorDetailStatusOverview]="detailStatusOverview"></app-monitor-information-tile>


                <app-status-tile
                [isMonitor]="isMonitor"
                        [sensors]="detailStatusOverview.sensors"
                        [lastMeasuredDateTime]="detailStatusOverview.lastMeasuredDateTime"
                        [statusInfo]="detailStatusOverview.statusInfo"></app-status-tile>

                <app-details-actions *ngIf="isMonitor && canEdit()" [monitor]="monitorSensors"></app-details-actions>

                <app-details-users *ngIf="isMonitor" [monitor]="detailStatusOverview"></app-details-users>

                <app-details-chat *ngIf="isMonitor" [monitor]="detailStatusOverview"></app-details-chat>


                <mat-card *ngIf=" monitorSensors">
                    <mat-card-header>
                        <mat-card-title
                                class="antialiased" fxLayout.lt-md="column" fxLayout="row" fxLayout.xs="row"
                                fxLayoutAlign="start start">
                            <h3 fxFlex>{{ 'VIEW.SORT' | translate }}</h3>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-view-dnd-sorting [isMonitor]="isMonitor" [model]="monitorSensors" (modelSorted)="sorted($event)"
                                              [key]="'name'">
                        </app-view-dnd-sorting>
                    </mat-card-content>
                </mat-card>
            </article>
        </section>
    </section>

    <app-footer></app-footer>
</div>