import MonitorInputType from '../monitorInputType';
import {Sensor} from './sensor';

export class SensorAnalog extends Sensor {
  private _unit: string;
  private _measurementMinimum: number;
  private _measurementMaximum: number;
  private _valueMinimum: number;
  private _valueMaximum: number;
  private _pollingRate: string;
  private _cloudStorageRate: string;

  constructor(channel: number,
              name: string,
              category: string,
              inputType: MonitorInputType,
              sensorType: string,
              unit: string,
              measurementMinimum: number,
              measurementMaximum: number,
              valueMinimum: number,
              valueMaximum: number,
              pollingRate: string,
              cloudStorageRate: string) {
    super(channel, name, category, inputType, sensorType);

    this._unit = unit;
    this._measurementMinimum = measurementMinimum;
    this._measurementMaximum = measurementMaximum;
    this._valueMinimum = valueMinimum;
    this._valueMaximum = valueMaximum;
    this._pollingRate = pollingRate;
    this._cloudStorageRate = cloudStorageRate;
  }


  public toJSON(sensorAnalogDTO: any): {} {
    return {
      channel: sensorAnalogDTO.channel,
      name: sensorAnalogDTO.name,
      categoryId: sensorAnalogDTO.category,
      inputType: sensorAnalogDTO.inputType,
      sensorTypeId: sensorAnalogDTO.sensorType,
      unitId: sensorAnalogDTO.unit,
      measurementMinimum: sensorAnalogDTO.measurementMinimum,
      measurementMaximum: sensorAnalogDTO.measurementMaximum,
      valueMinimum: sensorAnalogDTO.valueMinimum,
      valueMaximum: sensorAnalogDTO.valueMaximum,
      pollingRate: sensorAnalogDTO.pollingRate,
      cloudStorageRate: sensorAnalogDTO.cloudStorageRate
    }
  }

  get unit(): string {
    return this._unit;
  }

  get measurementMinimum(): number {
    return this._measurementMinimum;
  }

  get measurementMaximum(): number {
    return this._measurementMaximum;
  }

  get valueMinimum(): number {
    return this._valueMinimum;
  }

  get valueMaximum(): number {
    return this._valueMaximum;
  }

  get pollingRate(): string {
    return this._pollingRate;
  }

  get cloudStorageRate(): string {
    return this._cloudStorageRate;
  }
}
