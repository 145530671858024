import MonitorInputType from './monitorInputType';
import SensorStatus from './sensor/sensor-status';

export class MonitorSensor {
    private _id: string;
    private _channel: number;
    private _lastMeasuredDateTime: Date;
    private _unit: string;
    private _value: number;
    private _name: string;
    private _openClosedStatus: string;
    private _status: SensorStatus;
    private _isConnected: boolean;
    private _valueText: string;
    private _valueDescription: string;
    private _inputType: MonitorInputType;
    private _errors: string[];
    private _order: number;
    private _isAlarmIgnored: boolean;
    private _detailsId: string;
    private _upperLimits: any[];
    private _lowerLimits: any[];
    private _unitName: string;
    private _sensorType: string;
    private _threshold: number;
    private _sensorhealth: number;
    private _pollingrate: string;
    private _monitorId: string;

    public static fromJSON(monitorSensorDTO: any, order: any): MonitorSensor {
               
        return new MonitorSensor(
            monitorSensorDTO.id,
            monitorSensorDTO.channel,
            monitorSensorDTO.dateTime ? monitorSensorDTO.dateTime : monitorSensorDTO.lastMeasuredDateTime,
            monitorSensorDTO.unit,
            monitorSensorDTO.value,
            monitorSensorDTO.name,
            monitorSensorDTO.status,
            monitorSensorDTO.openClosedStatus,
            monitorSensorDTO.valueText,
            monitorSensorDTO.valueDescription,
            monitorSensorDTO.inputType,
            monitorSensorDTO.errors,
            monitorSensorDTO.order,
            monitorSensorDTO.unitName,
            monitorSensorDTO.sensorType,
            monitorSensorDTO.isConnected,
            monitorSensorDTO.threshold,
            monitorSensorDTO.sensorhealth,
            monitorSensorDTO.pollingRate,
            monitorSensorDTO.monitorId
        );
    }

    constructor(id: string,
                channel: number,
                lastMeasuredDateTime: Date,
                unit: string,
                value: number,
                name: string,
                status: SensorStatus,
                openClosedStatus: string,
                valueText: string,
                valueDescription: string,
                inputType: MonitorInputType,
                errors: string[],
                order?: number,
                unitName?: string,
                sensorType?: string,
                isConnected?: boolean,
                threshold?: number,
                sensorhealth? : number,
                pollingRate?: string,
                monitorId?: string) {
        this._id = id;
        this._channel = channel;
        this._lastMeasuredDateTime = lastMeasuredDateTime;
        this._unit = unit;
        this._name = name;
        this._value = value;
        this._status = status;
        this._openClosedStatus = openClosedStatus;
        this._valueText = valueText;
        this._valueDescription = valueDescription;
        this._inputType = inputType;
        this._errors = errors;
        this._order = order;
        this._unitName = unitName;
        this._sensorType = sensorType;
        this._isConnected = isConnected;
        this._threshold = threshold;
        this._sensorhealth = sensorhealth;
        this._pollingrate = pollingRate;
        this._monitorId = monitorId;
    }

    get id(): string {
        return this._id;
    }

    get channel(): number {
        return this._channel;
    }

    get lastMeasuredDateTime(): Date {
        return this._lastMeasuredDateTime;
    }

    get unit(): string {
        return this._unit;
    }

    get value(): number {
        return this._value;
    }

    get name(): string {
        return this._name;
    }

    get openClosedStatus(): string {
        return this._openClosedStatus;
    }

    get status(): SensorStatus {
        return this._status;
    }

    get valueText(): string {
        return this._valueText;
    }

    get valueDescription(): string {
        return this._valueDescription;
    }

    get inputType(): MonitorInputType {
        return this._inputType;
    }

    get errors(): string[] {
        return this._errors;
    }

    get order(): number {
        return this._order;
    }

    set order(val) {
        this._order = val;
    }

    get isAlarmIgnored(): boolean {
        return this._isAlarmIgnored;
    }

    set isAlarmIgnored(val) {
        this._isAlarmIgnored = val;
    }

    get monitorId(): string {
        return this._monitorId;
    }

    set monitorId(val) {
        this._monitorId = val;
    }

    get detailsId(): string {
        return this._detailsId;
    }

    set detailsId(val) {
        this._detailsId = val;
    }


    get lowerLimits(): any[] {
        return this._lowerLimits;
    }

    set lowerLimits(val) {
        this._lowerLimits = val;
    }

    get upperLimits(): any[] {
        return this._upperLimits;
    }

    set upperLimits(val) {
        this._upperLimits = val;
    }
    get unitName(): string {
        return this._unitName;
    }

    set unitName(value: string) {
        this._unitName = value;
    }
    get sensorType(): string {
        return this._sensorType;
    }

    set sensorType(value: string) {
        this._sensorType = value;
    }

    get isConnected(): boolean {
        return this._isConnected;
    }

    set isConnected(value: boolean) {
        this._isConnected = value;
    }

    get threshold() {
        return this._threshold;
    }

    set threshold(value: number) {
        this._threshold = value;    
    }

    get sensorhealth(): number {
        return this._sensorhealth;
    }

    get pollingRate(): string {
        return this._pollingrate;
    }
}
