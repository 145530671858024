import {Component, Input} from '@angular/core';
import {MessageStyle} from "../../../domain/messageStyle";


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() public messageText: string;
  @Input() public messageStyle: MessageStyle;

  private readonly MESSAGE_STYLE = MessageStyle;

  constructor() {
  }

  public getStylingForMessage() {
    switch (this.messageStyle) {
      case this.MESSAGE_STYLE.SUCCESS:
        return 'is-success';
      case this.MESSAGE_STYLE.DANGER:
        return 'is-danger';
      case this.MESSAGE_STYLE.WARNING:
        return 'is-warning';
      case this.MESSAGE_STYLE.INFO:
        return 'is-info';
    }
  }

  public getIconForMessage(): string {
    switch (this.messageStyle) {
      case this.MESSAGE_STYLE.DANGER:
        return 'error_outline';
      case this.MESSAGE_STYLE.WARNING:
        return 'warning';
      case this.MESSAGE_STYLE.INFO:
        return 'info_outline';
    }
  }
}
