import {Component} from '@angular/core';
import {Monitor} from '../../../../domain/monitor/monitor';
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';

@Component({
  templateUrl: 'grid-edit-monitor.component.html',
  styleUrls: ['./grid-edit-monitor.component.scss']
})
export class GridEditMonitorComponent {
  public monitor: MonitorStatusOverview;

  public agInit(params: any): void {
    this.monitor = params.data;
  }

  public canEditMonitor(): boolean {
    return this.monitor.editableByCurrentUser && this.monitor.permissions.includes("EDIT_MON");
  }
}
