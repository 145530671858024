
import {combineLatest as observableCombineLatest, Observable, Subscription, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {MonitorSensor} from '../../../domain/monitor/monitorSensor';
import {DetailStatusOverview} from '../../../domain/monitor/sensor/DetailStatusOverview';
import {Page} from '../../../domain/page';
import {DetailOverviewService} from '../../../infrastructure/http/DetailOverviewService';
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import {groupBy, isEmpty, isNil} from 'lodash';
import {MessageStyle} from 'app/domain/messageStyle';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

    public subPages: Page[];
    public detailStatusOverview: DetailStatusOverview;
    public readonly MESSAGE_STYLE = MessageStyle;
    public monitorSensors: MonitorSensor[][];
    public isFetchingNewData = false;
    public isLoading = true;
    public selectedDateRange = {start: moment().subtract(1, 'month'), end: moment()};
    public datepickerOptions = {
        locale: {format: 'DD/MM/YYYY'},
        maxDate: moment(),
        startDate: this.selectedDateRange.start,
        endDate: this.selectedDateRange.end
    };
    public containsName;
    public selectedDateRangeUpdater: Subject<{start: Moment, end: Moment}> = new Subject();


    private overviewService: DetailOverviewService;
    private id: string;
    private updateInterval: number;
    private POLLING_INTERVAL = environment.pollingIntervalInSeconds * 1000;

    private measurementsSubscription: Subscription;



    constructor(private activatedRoute: ActivatedRoute,
                private monitorsService: MonitorDataService,
                @Inject('Views') private viewsService) {
    }

    // Accessors

    set isMonitor(val) {
        this.overviewService = val ? this.monitorsService : this.viewsService;
        this.containsName = val ? "monitor" : "view";
    }

    get isMonitor() {
        return this.containsName === "monitor";
    }

    // Functional methods


    ngOnInit() {
        observableCombineLatest(
            this.activatedRoute.data,
            this.activatedRoute.params)
            .subscribe((results) => {

                this.subPages = results[0].parentPage.subPages;
                this.isMonitor = results[0].contains === "monitor";

                this.id = results[1].id;
                this.getDetailOverview();
                this.startUpdateInterval();
            })
        this.selectedDateRangeUpdater.subscribe(this.selectedDate.bind(this))
    }

    private startUpdateInterval() {
        clearInterval(this.updateInterval);
        //this.updateInterval = setInterval(this.getDetailOverview.bind(this), this.POLLING_INTERVAL);
    }

    private getDetailOverview() {
        // We must manually unsubscribe for the EventSubject to work.
        this.measurementsSubscription = this.overviewService.getForDetailOverview(this.id, this.selectedDateRange)
            .subscribe((detailStatusOverview: DetailStatusOverview) => {
                this.detailStatusOverview = detailStatusOverview;                
                // We only need to initialize this once. ! Changing this causes dnd-sorting to refresh, bugging out. See bug #487
                this.monitorSensors = this.monitorSensors || this.transformInto2DArray(this.detailStatusOverview.sensors);
                this.isLoading = false;
                this.isFetchingNewData = false;
            });
            
    }

    public ngOnDestroy(): void {
        clearInterval(this.updateInterval);

        this.measurementsSubscription.unsubscribe();
    }

    // Event methods

    public selectedDate(value: { start: Moment, end: Moment }) {
        this.datepickerOptions.startDate = value.start;
        this.datepickerOptions.endDate = value.end;

        this.selectedDateRange = {start: value.start, end: value.end};
        this.measurementsSubscription.unsubscribe();

        this.isFetchingNewData = true;

        this.getDetailOverview();
    }

    public sorted(model) {
        this.overviewService.setChangedOrder(this.id, this.extractOrderFrom2DArray(model)).subscribe();
        this.monitorSensors = model;
    }

    public hasSensors(sensors): boolean {
        return !isEmpty(sensors);
    }

    public trackByMonitorSensors(index, sensor): number {
        return sensor.id;
    }

    // Helper methods
    private transformInto2DArray(sensors: MonitorSensor[]): MonitorSensor[][] {
        
        sensors.forEach(sensor => sensor.detailsId = this.id);
        const getOrderNum = sensor => sensor.order;
        if (sensors.some(sensor => isNil(getOrderNum(sensor)))) {
            // Set a default.
            sensors.forEach((sensor, index) => sensor.order = index);
        }
        const groups = groupBy<MonitorSensor>(sensors, getOrderNum);
        return Object.keys(groups).map(key => groups[key]);
    }

    private extractOrderFrom2DArray(sensors: MonitorSensor[][]) {
        const order: { [key: string]: number } = {};
        sensors.filter(sensorGroup => sensorGroup.length).forEach((sensorGroup, index) => sensorGroup.forEach(sensor => order[sensor.id] = index));
        return order;
    }

    public canEdit() {
        return this.detailStatusOverview.permissions.includes("EDIT_MON")
    }
}
