<div fxLayout="column" fxFlex="95" class="container-list">
  <ag-grid-angular
    [gridOptions]="monitorsGridOptions"
    [rowData]="monitors"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>

</div>
<div
  fxFlex="5"
  fxLayout="column">
</div>

