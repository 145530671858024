export class MonitorMeasurementData {
  private _id: number;
  private _datetime: string;
  private _measurementidx: number;
  private _rawvalue: number;
  private _measurementvalue: number;
  private _sensorstatus: string[];
  private _monitorstatus: string[];

  public static fromJSON(monitorMeasurementDataDTO: any) {
    return new MonitorMeasurementData(
      monitorMeasurementDataDTO.id,
      monitorMeasurementDataDTO.datetime,
      monitorMeasurementDataDTO.measurementidx,
      monitorMeasurementDataDTO.rawvalue,
      monitorMeasurementDataDTO.measurementvalue,
      monitorMeasurementDataDTO.sensorstatus,
      monitorMeasurementDataDTO.monitorstatus
    )
  }

  constructor(id: number,
              datetime: string,
              measurementidx: number,
              rawvalue: number,
              measurementvalue: number,
              sensorstatus: string[],
              monitorstatus: string[]) {
    this._id = id;
    this._datetime = datetime;
    this._measurementidx = measurementidx;
    this._rawvalue = rawvalue;
    this._measurementvalue = measurementvalue;
    this._sensorstatus = sensorstatus;
    this._monitorstatus = monitorstatus;
  }

  get id(): number {
    return this._id;
  }

  get datetime(): string {
    return this._datetime;
  }

  get measurementidx(): number {
    return this._measurementidx;
  }

  get rawvalue(): number {
    return this._rawvalue;
  }

  get measurementvalue(): number {
    return this._measurementvalue;
  }

  get sensorstatus(): string[] {
    return this._sensorstatus;
  }

  get monitorstatus(): string[] {
    return this._monitorstatus;
  }
}
