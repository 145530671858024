import {Location} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatIconRegistry, MatSidenav, MatDialog, MatDialogConfig} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {Event, NavigationStart, Router, RouterLinkWithHref} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {isNil} from 'lodash';
import {Subscription} from 'rxjs';
import {Page} from '../domain/page';
import {User} from '../domain/user';
import {AuthenticationService} from '../infrastructure/http/authentication/authentication.service';
import {NavigationService} from '../infrastructure/navigation/navigation.service';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
import { MediaObserver } from '@angular/flex-layout';
import { environment } from '../../environments/environment';
import { PopUpModalComponent } from "../presentation/pages/popup-modal/popup-modal.component";



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', {static: false}) public sidenav: MatSidenav;
  public authenticatedUser: User;
  public mode = 'side';
  public opened = true;
  public disableClose = true;
  public notifNumber: Number;
  private router: Router;
  private location: Location;
  private navigationService: NavigationService;
  private authenticationService: AuthenticationService;
  private watcher: Subscription;
  private currentMenuTitle: string;
  private media: MediaObserver;

  constructor(router: Router,
              location: Location,
              navigationService: NavigationService,
              translate: TranslateService,
              authenticationService: AuthenticationService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer,
      media: MediaObserver,
      public matDialog: MatDialog,
      private userService: HttpUsersService,
      @Inject("Views") viewData) {
    this.router = router;
    this.location = location;
    this.navigationService = navigationService;
    this.authenticationService = authenticationService;
    this.media = media;

    router.events.subscribe((value: Event) => {
      if (value instanceof NavigationStart) {
        this.setCurrentMenuTitle(value);
      }
    });

    this.userService.getNotifications().subscribe((s) => {
      return this.notifNumber = s.length;
    })

    this.setAuthenticatedUser();

    this.setTranslations(translate);

    this.setSvgIcons(iconRegistry, sanitizer);

    this.watcher = this.media.asObservable().subscribe(() => {
      this.updateNavigationParametersByMediaChanged();
    });

  }

    ngOnInit() {
        const dialogConfig = new MatDialogConfig();
        let userAgent = navigator.userAgent;
        var isIE = userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;

        if (isIE) {
            const modalDialog = this.matDialog.open(PopUpModalComponent, dialogConfig);
        }
        else {
            this.router.resetConfig(this.navigationService.getRoutes());
        }
    }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }

  public canShowMenu(): boolean {
    return this.authenticationService.isAuthenticated() && this.authenticationService.hasToken();
  }

  public toggleSidenav(): void {
    this.sidenav.toggle();
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public chooseMenuItem(): void {
    if (!this.disableClose) {
      this.toggleSidenav();
    }
  }

  public canShowHeader(): boolean {
    return !isNil(this.currentMenuTitle);
  }

  public getHeaderTitle(): string {
    let result = '';
    if (this.canShowHeader()) {
      result = this.currentMenuTitle;
    }

    return result;
  }

    private setCurrentMenuTitle(event: NavigationStart): void {
        const currentPath = event.url.replace('/', '');
        const currentPage: Page = this.navigationService.getCurrentPage(currentPath);
        if (!isNil(currentPage)) {
            if (currentPage.showInMenu) {
                this.currentMenuTitle = currentPage.name;
            } else {
                if (!isNil(currentPage.parentPage) && currentPage.parentPage.showInMenu) {
                    this.currentMenuTitle = currentPage.name;
                } else {
                    this.currentMenuTitle = null;
                }
            }
        } else {
            this.currentMenuTitle = null;
        }

    }

    private setAuthenticatedUser(): void {
        this.authenticationService.getAuthenticatedUser().subscribe((user: User) => {
            this.authenticatedUser = user;
        });
    }

    private setTranslations(translate: TranslateService): void {
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
    }

    private setSvgIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer): void {
        iconRegistry
            .addSvgIcon('logo', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo.svg'))
            .addSvgIcon('monitor', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor.svg'))
            .addSvgIcon('monitor-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor.svg'))
            .addSvgIcon('monitor-white', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-white.svg'))
            .addSvgIcon('monitor-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-add.svg'))
            .addSvgIcon('monitor-manage', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-manage.svg'))
            .addSvgIcon('monitor-list', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitor-list.svg'))
            .addSvgIcon('setup', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-setup.svg'))
            .addSvgIcon('sensor-open', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sensor-open.svg'))
            .addSvgIcon('sensor-closed', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sensor-closed.svg'))
            .addSvgIcon('location', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-location.svg'))
            .addSvgIcon('card-details', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-card-details.svg'))
            .addSvgIcon('card-label', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-card-label.svg'))
            .addSvgIcon('card-comment', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-card-comment.svg'))
            .addSvgIcon('menu', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-menu.svg'))
            .addSvgIcon('user-account', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-user-account.svg'))
            .addSvgIcon('user-account-mobile', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-user-account-mobile.svg'))
            .addSvgIcon('chevron-down', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-chevron-down.svg'))
            .addSvgIcon('view', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view.svg'))
            .addSvgIcon('view-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-blue.svg'))
            .addSvgIcon('view-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-add.svg'))
            .addSvgIcon('view-manage', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-manage.svg'))
            .addSvgIcon('view-add-action-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-add-action-add.svg'))
            .addSvgIcon('view-add-action-remove', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-view-add-action-remove.svg'))
            .addSvgIcon('sensor', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sensor.svg'))
            .addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-add.svg'))
            .addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-delete.svg'))
            .addSvgIcon('handle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-handle.svg'))
            .addSvgIcon('clock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-clock.svg'))
            .addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-close.svg'))
            .addSvgIcon('open-with', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/open_with.svg'));

    }

    private updateNavigationParametersByMediaChanged(): void {
        this.mode = this.getNavigationMode();
        this.opened = this.getNavigationOpened();
        this.disableClose = this.getDisableCloseNavigation();
    }

    private getNavigationMode(): string {
        return this.media.isActive('gt-sm') ? 'side' : 'over';
    }

    private getNavigationOpened(): boolean {
        return this.media.isActive('gt-sm');
    }

    private getDisableCloseNavigation(): boolean {
        return this.media.isActive('gt-sm');
    }
}
