import { isNil } from 'lodash';
var User = /** @class */ (function () {
    function User(id, email, role, firstName, lastName, phone, jobCategory, jobTitle) {
        this._id = id;
        this._email = email;
        this._role = role;
        this._firstName = firstName;
        this._lastName = lastName;
        this._phone = phone;
        this._jobCategory = jobCategory;
        this._jobTitle = jobTitle;
    }
    User.fromJSON = function (userDTO) {
        var userRole = userDTO.role;
        if (isNil(userRole)) {
            throw new Error('User role is not defined');
        }
        var jobCategory = userDTO.jobCategory;
        if (isNil(jobCategory)) {
            throw new Error('Job category is not defined');
        }
        return new User(userDTO.id, userDTO.email, userRole, userDTO.firstName, userDTO.lastName, userDTO.phone, jobCategory, userDTO.jobTitle);
    };
    Object.defineProperty(User.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "email", {
        get: function () {
            return this._email;
        },
        set: function (value) {
            this._email = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "role", {
        get: function () {
            return this._role;
        },
        set: function (value) {
            this._role = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "firstName", {
        get: function () {
            return this._firstName;
        },
        set: function (value) {
            this._firstName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "lastName", {
        get: function () {
            return this._lastName;
        },
        set: function (value) {
            this._lastName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "phone", {
        get: function () {
            return this._phone;
        },
        set: function (value) {
            this._phone = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "jobCategory", {
        get: function () {
            return this._jobCategory;
        },
        set: function (value) {
            this._jobCategory = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "jobTitle", {
        get: function () {
            return this._jobTitle;
        },
        set: function (value) {
            this._jobTitle = value;
        },
        enumerable: true,
        configurable: true
    });
    User.prototype.toJSON = function () {
        var result = {
            'id': this.id,
            'email': this.email,
            'role': this.role,
            'firstName': this.firstName,
            'lastName': this.lastName,
            'phone': this.phone,
            'jobCategory': this.jobCategory,
            'jobTitle': this.jobTitle
        };
        return result;
    };
    return User;
}());
export { User };
