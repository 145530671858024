import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
var PopUpModalComponent = /** @class */ (function () {
    function PopUpModalComponent(matDialog) {
        this.matDialog = matDialog;
    }
    PopUpModalComponent.prototype.ngOnInit = function () {
    };
    PopUpModalComponent.prototype.closeModal = function () {
        debugger;
        this.matDialog.closeAll();
    };
    return PopUpModalComponent;
}());
export { PopUpModalComponent };
