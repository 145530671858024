import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
import { MonitorStatusOverview } from 'app/domain/monitor/monitorStatusOverview';
import { AuthenticationService } from 'app/infrastructure/http/authentication/authentication.service';
import { FormControl, Validators } from '@angular/forms';
var DetailsChatComponent = /** @class */ (function () {
    function DetailsChatComponent(monitorsService, userSerivce) {
        this.monitorsService = monitorsService;
        this.userSerivce = userSerivce;
        this.messages = [];
        this.chatFormControl = new FormControl("", [Validators.maxLength(300)]);
    }
    DetailsChatComponent.prototype.ngAfterViewInit = function () {
        console.log(this.element);
        this.element.nativeElement.parentNode.scrollTop = this.element.nativeElement.offsetTop;
    };
    DetailsChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.monitorsService.getChatForMonitor(this.monitor.id).subscribe(function (mons) { return _this.messages = (mons || []); });
        this.userSerivce.getAuthenticatedUser().subscribe(function (user) { return _this.user = user; });
    };
    DetailsChatComponent.prototype.isYours = function (message) {
        return message.sourceemail && this.user && this.user.email == message.sourceemail;
    };
    DetailsChatComponent.prototype.sendMessage = function (text) {
        return this.monitorsService.postChatForMonitor(this.monitor.id, text.value).subscribe(function () {
            text.value = "";
        });
    };
    DetailsChatComponent.prototype.textTooLong = function (text) {
        return text.value.length > 300;
    };
    return DetailsChatComponent;
}());
export { DetailsChatComponent };
