import { Page } from '../../../domain/page';
import {Location} from '@angular/common';
import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-navigation-toolbar',
  templateUrl: './navigation-toolbar.component.html',
  styleUrls: ['./navigation-toolbar.component.scss']
})
export class NavigationToolbarComponent {
  @Input() public subPages: Page[] = [];
  private location: Location;

  constructor(location: Location) {
    this.location = location;
  }

  public isActive(path: string): boolean {
    return this.location.path() === `/${path}`;
  }
}
