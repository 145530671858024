import MonitorInputType from '../monitorInputType';

export class Sensor {
  private _channel: number;
  private _name: string;
  private _category: string;
  private _inputType: MonitorInputType;
  private _sensorType: string;

  constructor(channel: number,
              name: string,
              category: string,
              inputType: MonitorInputType,
              sensorType: string,) {
    this._channel = channel;
    this._name = name;
    this._category = category;
    this._inputType = inputType;
    this._sensorType = sensorType;
  }

  get channel(): number {
    return this._channel;
  }

  get name(): string {
    return this._name;
  }

  get category(): string {
    return this._category;
  }

  get inputType(): MonitorInputType {
    return this._inputType;
  }

  get sensorType(): string {
    return this._sensorType;
  }
}
