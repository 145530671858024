import { combineLatest as observableCombineLatest, Subscription, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { groupBy, isEmpty, isNil } from 'lodash';
import { MessageStyle } from 'app/domain/messageStyle';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var DetailsComponent = /** @class */ (function () {
    function DetailsComponent(activatedRoute, monitorsService, viewsService) {
        this.activatedRoute = activatedRoute;
        this.monitorsService = monitorsService;
        this.viewsService = viewsService;
        this.MESSAGE_STYLE = MessageStyle;
        this.isFetchingNewData = false;
        this.isLoading = true;
        this.selectedDateRange = { start: moment().subtract(1, 'month'), end: moment() };
        this.datepickerOptions = {
            locale: { format: 'DD/MM/YYYY' },
            maxDate: moment(),
            startDate: this.selectedDateRange.start,
            endDate: this.selectedDateRange.end
        };
        this.selectedDateRangeUpdater = new Subject();
        this.POLLING_INTERVAL = environment.pollingIntervalInSeconds * 1000;
    }
    Object.defineProperty(DetailsComponent.prototype, "isMonitor", {
        get: function () {
            return this.containsName === "monitor";
        },
        // Accessors
        set: function (val) {
            this.overviewService = val ? this.monitorsService : this.viewsService;
            this.containsName = val ? "monitor" : "view";
        },
        enumerable: true,
        configurable: true
    });
    // Functional methods
    DetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        observableCombineLatest(this.activatedRoute.data, this.activatedRoute.params)
            .subscribe(function (results) {
            _this.subPages = results[0].parentPage.subPages;
            _this.isMonitor = results[0].contains === "monitor";
            _this.id = results[1].id;
            _this.getDetailOverview();
            _this.startUpdateInterval();
        });
        this.selectedDateRangeUpdater.subscribe(this.selectedDate.bind(this));
    };
    DetailsComponent.prototype.startUpdateInterval = function () {
        clearInterval(this.updateInterval);
        //this.updateInterval = setInterval(this.getDetailOverview.bind(this), this.POLLING_INTERVAL);
    };
    DetailsComponent.prototype.getDetailOverview = function () {
        var _this = this;
        // We must manually unsubscribe for the EventSubject to work.
        this.measurementsSubscription = this.overviewService.getForDetailOverview(this.id, this.selectedDateRange)
            .subscribe(function (detailStatusOverview) {
            _this.detailStatusOverview = detailStatusOverview;
            // We only need to initialize this once. ! Changing this causes dnd-sorting to refresh, bugging out. See bug #487
            _this.monitorSensors = _this.monitorSensors || _this.transformInto2DArray(_this.detailStatusOverview.sensors);
            _this.isLoading = false;
            _this.isFetchingNewData = false;
        });
    };
    DetailsComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.updateInterval);
        this.measurementsSubscription.unsubscribe();
    };
    // Event methods
    DetailsComponent.prototype.selectedDate = function (value) {
        this.datepickerOptions.startDate = value.start;
        this.datepickerOptions.endDate = value.end;
        this.selectedDateRange = { start: value.start, end: value.end };
        this.measurementsSubscription.unsubscribe();
        this.isFetchingNewData = true;
        this.getDetailOverview();
    };
    DetailsComponent.prototype.sorted = function (model) {
        this.overviewService.setChangedOrder(this.id, this.extractOrderFrom2DArray(model)).subscribe();
        this.monitorSensors = model;
    };
    DetailsComponent.prototype.hasSensors = function (sensors) {
        return !isEmpty(sensors);
    };
    DetailsComponent.prototype.trackByMonitorSensors = function (index, sensor) {
        return sensor.id;
    };
    // Helper methods
    DetailsComponent.prototype.transformInto2DArray = function (sensors) {
        var _this = this;
        sensors.forEach(function (sensor) { return sensor.detailsId = _this.id; });
        var getOrderNum = function (sensor) { return sensor.order; };
        if (sensors.some(function (sensor) { return isNil(getOrderNum(sensor)); })) {
            // Set a default.
            sensors.forEach(function (sensor, index) { return sensor.order = index; });
        }
        var groups = groupBy(sensors, getOrderNum);
        return Object.keys(groups).map(function (key) { return groups[key]; });
    };
    DetailsComponent.prototype.extractOrderFrom2DArray = function (sensors) {
        var order = {};
        sensors.filter(function (sensorGroup) { return sensorGroup.length; }).forEach(function (sensorGroup, index) { return sensorGroup.forEach(function (sensor) { return order[sensor.id] = index; }); });
        return order;
    };
    DetailsComponent.prototype.canEdit = function () {
        return this.detailStatusOverview.permissions.includes("EDIT_MON");
    };
    return DetailsComponent;
}());
export { DetailsComponent };
