import { OnInit } from "@angular/core";
import { MonitorDataService } from "app/infrastructure/data/monitor-data.service";
var DetailsActionsComponent = /** @class */ (function () {
    function DetailsActionsComponent(monitorService) {
        this.monitorService = monitorService;
    }
    Object.defineProperty(DetailsActionsComponent.prototype, "monitor", {
        set: function (val) {
            this.sensors = val.reduce(function (end, curr) { return end.concat(curr); }, []).filter(function (sensor) {
                return (sensor.sensorType != "TYPE_PCXI" && sensor.sensorType != "TYPE_RISYLOG");
            });
        },
        enumerable: true,
        configurable: true
    });
    DetailsActionsComponent.prototype.ngOnInit = function () {
    };
    return DetailsActionsComponent;
}());
export { DetailsActionsComponent };
