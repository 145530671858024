/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dnd-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/list";
import * as i6 from "@angular/material/icon";
import * as i7 from "./dnd-item.component";
var styles_DnDItemComponent = [i0.styles];
var RenderType_DnDItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DnDItemComponent, data: {} });
export { RenderType_DnDItemComponent as RenderType_DnDItemComponent };
export function View_DnDItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["id", "item-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "small", [["class", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "last mat-list-icon mat-icon notranslate"], ["mat-list-icon", ""], ["role", "img"], ["svgIcon", "open-with"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(8, 16384, null, 0, i5.MatListIconCssMatStyler, [], null, null), i1.ɵdid(9, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_5 = "open-with"; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.item2; _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 9).inline; var currVal_4 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 7, 0, currVal_3, currVal_4); }); }
export function View_DnDItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dnd-item", [], null, null, null, View_DnDItemComponent_0, RenderType_DnDItemComponent)), i1.ɵdid(1, 114688, null, 0, i7.DnDItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DnDItemComponentNgFactory = i1.ɵccf("app-dnd-item", i7.DnDItemComponent, View_DnDItemComponent_Host_0, { item: "item", item2: "item2", width: "width" }, {}, []);
export { DnDItemComponentNgFactory as DnDItemComponentNgFactory };
