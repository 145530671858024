import { Injectable } from '@angular/core';
import * as _ from "lodash";


@Injectable()
export class ChartconfigService {
    public readonly LINE_THICKNESS = 2;
    public readonly SCROLL_BAR_HEIGHT = 60;
    public readonly SCROLL_BAR_OFFSET = 50;
    public readonly PREPEND_APPEND_PERIOD = 1800;
    public SCROLLBAR_COLORS_NORMAL = {
        "selectedBackgroundColor": '#FFFFFF',
        "backgroundColor": '#FFFFFF',
        "selectedGraphFillColor": '#ddd',
        "graphFillColor": '#f5f5f5',
    };
    public SCROLLBAR_COLORS_UPPERLIMIT = {
        "selectedBackgroundColor": '#d5d5d5',
        "backgroundColor": '#d5d5d5',
        "selectedGraphFillColor": '#FFFFFF',
        "graphFillColor": '#FFFFFF',
    };


    constructor() {
    }


    // CONFIG DISPENSER FUNCTIONS
    public getStandardChartConfig(dataprovider, valueAxes, graphs, enableScrollbar) {
        // We can only show one piece of possibly combined graphs when this is involved.
        const scrollbarGraph = graphs[0];
        const scrollbarColors = !_.isNil(scrollbarGraph) ? scrollbarGraph.scrollbarcolors : {};
        (graphs || []).forEach(x => x.precision = -1);
        return {
            'type': 'serial',
            'theme': 'light',
            'dataProvider': dataprovider,
            'autoMarginOffset': 30,
            'valueAxes': valueAxes,
            'graphs': graphs,
            "zoomOutText": "Zoom out",
            "numberFormatter": {
                precision: -1
            },
            'chartScrollbar': {
                'graph': _.isNil(scrollbarGraph) ? "" : scrollbarGraph.id,
                'scrollbarHeight': this.SCROLL_BAR_HEIGHT,
                'autoGridCount': true,
                'oppositeAxis': false,
                'enabled': enableScrollbar,
                'offset': this.SCROLL_BAR_OFFSET,
                'scrollDuration': 0,
                'color': '#000000',
                "graphLineColor": "#ddebf3",
                "selectedGraphLineColor": "#ddebf3",
                ...scrollbarColors
            },
            'balloon': {
                'animationDuration': 0
            },
            "valueScrollbar": {
                "autoGridCount": true,
                "color": "#000000",
                "scrollbarHeight": 50
            },
            'categoryField': 'date',
            'categoryAxis': {
                'minPeriod': 'ss',
                'parseDates': true,
                'gridAlpha': 0,
                'prependPeriods': this.PREPEND_APPEND_PERIOD,
                'appendPeriods': this.PREPEND_APPEND_PERIOD
            },
            'zoomOutOnDataUpdate': false
        };
    }


    public getDigitalOpenClosedConfig(sensor, id, openClosedText) {
        return {
            'balloonText': sensor.name + ': [[value]]',
            'balloonFunction': (graphDataItem) => {                
                const value = graphDataItem.values.value;
                return sensor.name + ': ' + openClosedText(value);
            },
            'valueField': sensor.id + id,
            'type': 'step',
            'connect': false,
            'fillAlphas': 1,
            'lineThickness': this.LINE_THICKNESS,
            'balloonColor': '#6BC441',
            'id': 'g' + id,
            'valueAxis': 'v0',
            "bullet": "round"
        }
    }

    public getGraphConfig(sensor, id, limits, color) {
        if (limits.upperLimit && limits.lowerLimit) {
            return this.getWithBothLimitsConfig(sensor, id, limits.lowerLimit, limits.upperLimit, color)
        } else if (limits.upperLimit) {
            return this.getWithUpperLimitConfig(sensor, id, limits.upperLimit, color);
        } else if (limits.lowerLimit) {
            return this.getWithLowerLimitConfig(sensor, id, limits.lowerLimit, color);
        } else {
            return this.getNormalConfig(sensor, id, color);
        }
    }

    public getNormalConfig(sensor, id, color) {
        return [{
            'valueField': sensor.id + id,
            'balloonText': `${sensor.name} [${sensor.unit}]` + ': [[value]]\n[[description]]',
            "balloonFunction": function (item, graph) {
                let result = graph.balloonText;                
                for (let key in item.dataContext) {                    
                    if (item.dataContext.hasOwnProperty(key)) {
                        if (key == graph.valueField)
                            result = result.replace("[[value]]", item.dataContext[key]);
                        else
                            result = result.replace("[[" + key + "]]", item.dataContext[key]);
                    }
                }
                result = result.replace(/\n/g, '<br />');
                return result;
            },            
            'balloonColor': color,
            'id': 'g' + sensor.id + id,
            'valueAxis': 'v0',
            "lineColor": color,
            'lineThickness': this.LINE_THICKNESS,
            "descriptionField": "description",
            "bulletField": "bullet",
            "bulletSizeField": "bulletSize",
            "colorField": "bulletColor",
            "bullet": "round",
            "bulletColor": "#00000000",
            "bulletSize": 0,
            'bulletHitAreaSize': 10,
            scrollbarcolors: this.SCROLLBAR_COLORS_NORMAL
        }];
    }

    public getWithBothLimitsConfig(sensor, id, lowerLimit, upperLimit, color) {
        return [{
            "valueField": sensor.id + id,
            "balloonText": `${sensor.name} [${sensor.unit}]` + ': [[value]]\n[[description]]',
            "balloonFunction": function (item, graph) {
                let result = graph.balloonText;                
                for (let key in item.dataContext) {                    
                    if (item.dataContext.hasOwnProperty(key)) {
                        if (key == graph.valueField)
                            result = result.replace("[[value]]", item.dataContext[key]);
                        else
                            result = result.replace("[[" + key + "]]", item.dataContext[key]);
                    }
                }
                result = result.replace(/\n/g, '<br />');
                return result;
            },            
            "balloonColor": color,
            "id": 'g' + id + 1,
            'valueAxis': 'v0',
            "lineColor": "#ff0000",
            'lineThickness': this.LINE_THICKNESS,

            "negativeLineColor": color,
            "negativeBase": upperLimit._value,
            "descriptionField": "description",
            "bulletField": "bullet",
            "bulletSizeField": "bulletSize",
            "colorField": "bulletColor",
            "bullet": "round",
            "bulletColor": "#00000000",
            "bulletSize": 0,
            'bulletHitAreaSize': 10,
            scrollbarcolors: this.SCROLLBAR_COLORS_UPPERLIMIT
        },
        {
            "showBalloon": true,
            "balloonText": `${sensor.name} [${sensor.unit}]` + ': [[value]]\n[[description]]',
            "balloonFunction": function (item, graph) {
                let result = graph.balloonText;                
                for (let key in item.dataContext) {                    
                    if (item.dataContext.hasOwnProperty(key)) {
                        if (key == graph.valueField)
                            result = result.replace("[[value]]", item.dataContext[key]);
                        else
                            result = result.replace("[[" + key + "]]", item.dataContext[key]);
                    }
                }
                result = result.replace(/\n/g, '<br />');
                return result;
            },            
            "balloonColor": color,
            "valueField": sensor.id + id,
            "id": 'g' + id + 2,
            "valueAxis": 'v0',
            "lineAlpha": 0,
            "lineColor": color,
            'lineThickness': this.LINE_THICKNESS,
            "negativeLineColor": "#ff0000",
            "negativeBase": lowerLimit._value,
            "negativeLineAlpha": 1,
            "descriptionField": "description",
            "bulletField": "bullet",
            "bulletSizeField": "bulletSize",
            "colorField": "bulletColor",
            "bullet": "round",
            "bulletColor": "#00000000",
            "bulletSize": 0,
            'bulletHitAreaSize': 10
        }
        ];
    }

    public getWithUpperLimitConfig(sensor, id, limit, color) {
        return [{
            'valueField': sensor.id + id,
            'balloonText': `${sensor.name} [${sensor.unit}]` + ': [[value]]\n[[description]]',
            "balloonFunction": function (item, graph) {
                let result = graph.balloonText;                
                for (let key in item.dataContext) {                    
                    if (item.dataContext.hasOwnProperty(key)) {
                        if (key == graph.valueField)
                            result = result.replace("[[value]]", item.dataContext[key]);
                        else
                            result = result.replace("[[" + key + "]]", item.dataContext[key]);
                    }
                }
                result = result.replace(/\n/g, '<br />');
                return result;
            },            
            'balloonColor': color,
            'id': 'g' + id,
            'valueAxis': 'v0',
            "lineColor": "#ff0000",
            'lineThickness': this.LINE_THICKNESS,

            "negativeLineColor": color,
            "negativeBase": limit._value,
            "descriptionField": "description",
            "bulletField": "bullet",
            "bulletSizeField": "bulletSize",
            "colorField": "bulletColor",
            "bullet": "round",
            "bulletColor": "#00000000",
            "bulletSize": 0,
            'bulletHitAreaSize': 10,
            scrollbarcolors: this.SCROLLBAR_COLORS_UPPERLIMIT
        },]
    }

    public getWithLowerLimitConfig(sensor, id, limit, color) {
        return [{
            'valueField': sensor.id + id,
            'balloonText': `${sensor.name} [${sensor.unit}]` + ': [[value]]\n[[description]]',
            "balloonFunction": function (item, graph) {
                let result = graph.balloonText;                
                for (let key in item.dataContext) {
                    if (item.dataContext.hasOwnProperty(key)) {
                        if (key == graph.valueField)
                            result = result.replace("[[value]]", item.dataContext[key]);
                        else
                            result = result.replace("[[" + key + "]]", item.dataContext[key]);
                    }
                }
                result = result.replace(/\n/g, '<br />');
                return result;
            },            
            'balloonColor': color,
            'id': 'g' + id + 1,
            'valueAxis': 'v0',
            "lineColor": color,
            'lineThickness': this.LINE_THICKNESS,

            "negativeLineColor": "#ff0000",
            "negativeBase": limit._value,
            "descriptionField": "description",
            "bulletField": "bullet",
            "bulletSizeField": "bulletSize",
            "colorField": "bulletColor",
            "bullet": "round",
            "bulletColor": "#00000000",
            "bulletSize": 0,
            'bulletHitAreaSize': 10
        },]
    }

    public getInvisConfig(sensor) {
        return [{
            'valueField': sensor.id + "invis",
            'id': 'g' + "invis",
            'valueAxis': 'v0',
            lineAlpha: 0,
            "lineColor": "#000000",
            'lineThickness': this.LINE_THICKNESS,
            "descriptionField": "description",
            "bulletField": "bullet",
            "bulletSizeField": "bulletSize",
            "colorField": "bulletColor",
            "bullet": "round",
            "bulletColor": "#00000000",
            "bulletSize": 0,
            'bulletHitAreaSize': 10,
            scrollbarcolors: this.SCROLLBAR_COLORS_NORMAL
        }];
    }
}