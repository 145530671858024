import {MonitorSensor} from '../../../domain/monitor/monitorSensor';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {includes, isEqual, isNil, isUndefined, size} from 'lodash';
import DeviceStatus from '../../../domain/monitor/deviceStatus';
import {MonitorStatusOverview} from '../../../domain/monitor/monitorStatusOverview';
import SensorStatus from '../../../domain/monitor/sensor/sensor-status';
import {ActivatedRoute} from '@angular/router';
import * as moment from "moment";
import RelayStatus from 'app/domain/monitor/relayStatus';
import { MatDialog } from '@angular/material';
import { ShareComponent } from './share/share.component';
import { MediaObserver } from '@angular/flex-layout';


@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewCardComponent implements OnInit {
  @Input() details;
  @Input() info: string;
  @Input() sensors: MonitorSensor[];
  @Input() link: string[];
  @Input() monitor: MonitorStatusOverview;
  
  public isMonitor: boolean;
  

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog, private media: MediaObserver
    ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
       this.isMonitor = data.contains === "monitor";
    }) 
    this.sensors = this.sensors.sort((a,b) => a.order - b.order)
  }

  
  public canShowIconForSensor(sensorState: string): boolean {
    return includes([
      'OPEN',
      'CLOSED'
    ], sensorState);
  }
  
  public getTooltipText(sensor: MonitorSensor): string {
    return sensor.valueDescription;
  }
  
    public getIconForSensor(sensorState: string): string {
    const stateMap = {
    'OPEN': 'sensor-open',
    'CLOSED': 'sensor-closed'
    }

    return stateMap[sensorState];
  }
  
    public getClassStatusForDeviceStatus(deviceStatus: DeviceStatus | SensorStatus): string {
    return (isEqual(deviceStatus, DeviceStatus.Online) || isEqual(deviceStatus, SensorStatus.Ok)) ? 'on' : 'off';
  }

  public getClassStatusForSensorStatus(sensor: MonitorSensor): string {
    return (!sensor.isConnected) ? 'disc':this.getClassStatusForDeviceStatus(sensor.status);
  }

  public getClassStatusForRelayStatus(relayStatus?: RelayStatus): string {
    relayStatus = relayStatus;   
    return relayStatus && (isEqual(relayStatus, RelayStatus.Ok) || isEqual(relayStatus, RelayStatus.Ignored)) ? 'on' : 'off';
  }

  public getRelayStatusString(relayStatus?: RelayStatus): string {
    relayStatus = relayStatus;
    return !relayStatus || isEqual(relayStatus, RelayStatus.Alarm) ? '' : relayStatus.toLowerCase();
  }
  public getBubbleTooltip(sensor) {
    const errors = sensor.errors.join('\n');
    if(size(sensor.errors) > 0) {
      return errors;
    }
    if(!sensor.isConnected) {
      return moment(sensor.lastMeasuredDateTime).fromNow()
    }
    return size(sensor.errors) > 0 ? errors : null;
  }

  public getErrors() {
    return this.monitor.statusInfo.errors.join("; ")
  }
  
    public canShowLastMeasuredDateTime(): boolean {
    return !isNil(this.monitor.lastMeasuredDateTime);
  }

  public hasSensorValue(sensor: MonitorSensor) {
    return !isUndefined(sensor.value) && !this.canShowIconForSensor(sensor.openClosedStatus);
  }

  public hasMonitorErrors() {
    const errors = this.monitor.statusInfo.errors.join('\n');
    return size(this.monitor.statusInfo.errors) > 0 ? errors : null;
  }
  
  public getKind() {
  return this.isMonitor ? "monitor":"view"
  }

  public showShareScreen() {
     this.dialog.open(ShareComponent, {...this.getShareScreenVals(), data: this.monitor})
  }

  private getShareScreenVals() {
    return this.media.isActive('gt-sm') ? {width: "50%", height:"70%"} : {width: "95vw", height:"95vh", 'maxWidth':"95vw!important"}
  }
}
