import { MatDialogRef } from '@angular/material';
var InformationDialogComponent = /** @class */ (function () {
    function InformationDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    InformationDialogComponent.prototype.keyDownFunction = function (event) {
        if (event.key === 'Enter') {
            this.dialogRef.close();
        }
    };
    return InformationDialogComponent;
}());
export { InformationDialogComponent };
