import MonitorInputType from './monitorInputType';
import {MonitorMeasurementData} from './monitorMeasurementData';
import {SensorLimit} from './sensor/sensor-limit';

export class MonitorMeasurement {
  private _sensorId: string;
  private _backendId: number;
  private _channel: number;
  private _inputType: MonitorInputType;
  private _name: string;
  private _measurements: MonitorMeasurementData[];
  private _upperLimits: SensorLimit[];
  private _lowerLimits: SensorLimit[];
  private _openText: string;
  private _closedText: string;

  public static fromJSON(monitorMeasurementDTO: any) {
    return new MonitorMeasurement(
      monitorMeasurementDTO.id,
      monitorMeasurementDTO.backendId,
      monitorMeasurementDTO.channel,
      monitorMeasurementDTO.inputType,
      monitorMeasurementDTO.name,
      monitorMeasurementDTO.openText,
      monitorMeasurementDTO.closedText,
      monitorMeasurementDTO.measurements.map(MonitorMeasurementData.fromJSON),
      monitorMeasurementDTO.upperlimits.map(SensorLimit.fromJSON),
      monitorMeasurementDTO.lowerlimits.map(SensorLimit.fromJSON)
    );
  }

  constructor(sensorId: string,
              backendId: number,
              channel: number,
              inputType: MonitorInputType,
              name: string,
              openText: string,
              closedText: string,
              measurements: MonitorMeasurementData[],
              upperLimits: SensorLimit[],
              lowerLimits: SensorLimit[]) {
    this._sensorId = sensorId;
    this._backendId = backendId;
    this._channel = channel;
    this._inputType = inputType;
    this._name = name;
    this._openText = openText;
    this._closedText = closedText;
    this._measurements = measurements;
    this._upperLimits = upperLimits;
    this._lowerLimits = lowerLimits;
  }

  get sensorId(): string {
    return this._sensorId;
  }

  get backendId(): number {
    return this._backendId;
  }

  get channel(): number {
    return this._channel;
  }

  get inputType(): MonitorInputType {
    return this._inputType;
  }

  get name(): string {
    return this._name;
  }

  get measurements(): MonitorMeasurementData[] {
    return this._measurements;
  }

  get openText(): string {
    return this._openText;
  }

  get closedText(): string {
    return this._closedText;
  }

  get upperLimits(): SensorLimit[] {
    return this._upperLimits;
  }

  get lowerLimits(): SensorLimit[] {
    return this._lowerLimits;
  }
}
