var Monitor = /** @class */ (function () {
    function Monitor(id, backendId, serialNumber, monitorType, channels, privacy, dateTimeAdded, details, sensors, editableByCurrentUser, numberOfLinkedUsers) {
        this._id = id;
        this._backendId = backendId;
        this._serialNumber = serialNumber;
        this._monitorType = monitorType;
        this._channels = channels;
        this._privacy = privacy;
        this._dateTimeAdded = dateTimeAdded;
        this._firmwareUpgradeStatus = null;
        this._details = details;
        this._sensors = sensors;
        this._editableByCurrentUser = editableByCurrentUser;
        this._numberOfLinkedUsers = numberOfLinkedUsers;
    }
    Monitor.fromJSON = function (monitorDTO) {
        return new Monitor(monitorDTO.id, monitorDTO.backendId, monitorDTO.serialNumber, monitorDTO.monitorType, monitorDTO.channels, monitorDTO.privacy, monitorDTO.dateTimeAdded, monitorDTO.details, monitorDTO.sensors, monitorDTO.editableByCurrentUser, monitorDTO.numberOfLinkedUsers);
    };
    Object.defineProperty(Monitor.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "backendId", {
        get: function () {
            return this._backendId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "serialNumber", {
        get: function () {
            return this._serialNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "monitorType", {
        get: function () {
            return this._monitorType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "channels", {
        get: function () {
            return this._channels;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "privacy", {
        get: function () {
            return this._privacy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "dateTimeAdded", {
        get: function () {
            return this._dateTimeAdded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "firmwareUpgradeStatus", {
        get: function () {
            return this._firmwareUpgradeStatus;
        },
        set: function (value) {
            this._firmwareUpgradeStatus = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "details", {
        get: function () {
            return this._details;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "sensors", {
        get: function () {
            return this._sensors;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "editableByCurrentUser", {
        get: function () {
            return this._editableByCurrentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Monitor.prototype, "numberOfLinkedUsers", {
        get: function () {
            return this._numberOfLinkedUsers;
        },
        enumerable: true,
        configurable: true
    });
    return Monitor;
}());
export { Monitor };
