import { Component, OnInit } from '@angular/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public notifications: SystemNotification[];

  constructor(public userService: HttpUsersService) { }

  ngOnInit() {
    this.userService.getNotifications().subscribe(not => this.notifications = not);
  }

}
