import {FirmwareUpgradeStatus} from './firmware/firmware-upgrade-status';
import MonitorChannel from './monitorChannel';
import {MonitorDetails} from './monitorDetails';
import MonitorPrivacy from './monitorPrivacy';
import {MonitorSensor} from './monitorSensor';

export class Monitor {
  private _id: string;
  private _backendId: number;
  private _serialNumber: number;
  private _monitorType: string;
  private _channels: MonitorChannel[];
  private _privacy: MonitorPrivacy;
  private _dateTimeAdded: string;
  private _firmwareUpgradeStatus: FirmwareUpgradeStatus;
  private _details: MonitorDetails;
  private _sensors: MonitorSensor[];
  private _editableByCurrentUser: boolean;
  private _numberOfLinkedUsers: number;

  public static fromJSON(monitorDTO: any): Monitor {
    return new Monitor(
      monitorDTO.id,
      monitorDTO.backendId,
      monitorDTO.serialNumber,
      monitorDTO.monitorType,
      monitorDTO.channels,
      monitorDTO.privacy,
      monitorDTO.dateTimeAdded,
      monitorDTO.details,
      monitorDTO.sensors,
      monitorDTO.editableByCurrentUser,
      monitorDTO.numberOfLinkedUsers
    );
  }

  constructor(id: string,
              backendId: number,
              serialNumber: number,
              monitorType: string,
              channels: MonitorChannel[],
              privacy: MonitorPrivacy,
              dateTimeAdded: string,
              details: MonitorDetails,
              sensors: MonitorSensor[],
              editableByCurrentUser: boolean,
              numberOfLinkedUsers: number) {
    this._id = id;
    this._backendId = backendId;
    this._serialNumber = serialNumber;
    this._monitorType = monitorType;
    this._channels = channels;
    this._privacy = privacy;
    this._dateTimeAdded = dateTimeAdded;
    this._firmwareUpgradeStatus = null;
    this._details = details;
    this._sensors = sensors;
    this._editableByCurrentUser = editableByCurrentUser;
    this._numberOfLinkedUsers = numberOfLinkedUsers;
  }


  get id(): string {
    return this._id;
  }


  get backendId(): number {
    return this._backendId;
  }


  get serialNumber(): number {
    return this._serialNumber;
  }


  get monitorType(): string {
    return this._monitorType;
  }


  get channels(): MonitorChannel[] {
    return this._channels;
  }


  get privacy(): MonitorPrivacy {
    return this._privacy;
  }


  get dateTimeAdded(): string {
    return this._dateTimeAdded;
  }


  get firmwareUpgradeStatus(): FirmwareUpgradeStatus {
    return this._firmwareUpgradeStatus;
  }

  set firmwareUpgradeStatus(value: FirmwareUpgradeStatus) {
    this._firmwareUpgradeStatus = value;
  }


  get details(): MonitorDetails {
    return this._details;
  }


  get sensors(): MonitorSensor[] {
    return this._sensors;
  }

  get editableByCurrentUser(): boolean {
    return this._editableByCurrentUser;
  }

  get numberOfLinkedUsers(): number {
    return this._numberOfLinkedUsers;
  }
}
