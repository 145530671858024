
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Users} from '../../../../infrastructure/http/user/users';
import {TranslateService} from '@ngx-translate/core';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html'
})
export class RequestResetPasswordComponent implements OnDestroy {

  public requestResetPasswordForm: FormGroup;
  public requestedPasswordReset = false;
  public userEmail: string;

  private formBuilder: FormBuilder;
  private resetPerforming = false;
  private userService: Users;
  private unSubscribeUsersOnDestroy = new Subject<void>();
  private router: Router;
  private translateService: TranslateService;

  constructor(formBuilder: FormBuilder,
              userService: HttpUsersService,
              router: Router,
              translateService: TranslateService) {
    this.formBuilder = formBuilder;
    this.userService = userService;
    this.router = router;
    this.translateService = translateService;

    this.requestResetPasswordForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.email]]
    });
  }

  public ngOnDestroy(): void {
    this.unSubscribeUsersOnDestroy.next();
    this.unSubscribeUsersOnDestroy.complete();
  }

  public requestResetPassword(form: any, isValid: boolean): void {
    if (isValid) {
      this.resetPerforming = true;
      this.userEmail = form.email;
      this.userService.requestResetPassword(
        form.email).pipe(
        takeUntil(this.unSubscribeUsersOnDestroy))
        .subscribe(() => {
          this.requestedPasswordReset = true;
        }, (error: HttpErrorResponse) => {
          this.resetPerforming = false;
          const parsedError = JSON.parse(error.error);
          let errorMessage = parsedError.message;
          this.translateService.get(`ERRORS.${error.error.type}`).pipe(
            takeUntil(this.unSubscribeUsersOnDestroy))
            .subscribe((translation: any) => {
              if (translation !== `ERRORS.${error.error.type}`) {
                errorMessage = translation;
              }
            });
          throw new Error(errorMessage);
        });
    }
  }

  public canRequestResetPassword(): boolean {
    return this.requestResetPasswordForm.valid && !this.resetPerforming;
  }

}
