/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./monitor-software.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/flex-layout/flex";
import * as i8 from "@angular/flex-layout/core";
import * as i9 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/common";
import * as i12 from "./monitor-software.component";
import * as i13 from "../../../../infrastructure/data/monitor-data.service";
var styles_GridMonitorSoftwareComponent = [i0.styles];
var RenderType_GridMonitorSoftwareComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GridMonitorSoftwareComponent, data: {} });
export { RenderType_GridMonitorSoftwareComponent as RenderType_GridMonitorSoftwareComponent };
function View_GridMonitorSoftwareComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "antialiased"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.requestFirmwareUpgrade() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.canUpgradeFirmware() || _co.requestInProgress); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MONITOR.UPDATE_AVAILABLE")); _ck(_v, 2, 0, currVal_3); }); }
function View_GridMonitorSoftwareComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("MONITOR.SOFTWARE_UPTODATE")); _ck(_v, 1, 0, currVal_0); }); }
function View_GridMonitorSoftwareComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "update-pending"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "clock"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_3 = "clock"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("MONITOR.UPDATE_PENDING")); _ck(_v, 6, 0, currVal_4); }); }
function View_GridMonitorSoftwareComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "update-pending"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "clock"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_3 = "clock"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("MONITOR.UPDATE_BUSY")); _ck(_v, 6, 0, currVal_4); }); }
function View_GridMonitorSoftwareComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("MONITOR.FIRMWARE_STATUS_UNKNOWN")); _ck(_v, 1, 0, currVal_0); }); }
function View_GridMonitorSoftwareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridMonitorSoftwareComponent_2)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridMonitorSoftwareComponent_3)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridMonitorSoftwareComponent_4)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridMonitorSoftwareComponent_5)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridMonitorSoftwareComponent_6)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFirmwareUpgradeAvailable(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isFirmwareUpToDate(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isFirmwareUpgradePlanned(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isFirmwareUpgradeBusy(); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.isFirmwareUnknown(); _ck(_v, 10, 0, currVal_4); }, null); }
function View_GridMonitorSoftwareComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("MONITOR.FIRMWARE_STATUS_REQUESTING")); _ck(_v, 1, 0, currVal_0); }); }
export function View_GridMonitorSoftwareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridMonitorSoftwareComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridMonitorSoftwareComponent_7)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFirmwareInfoAvailable(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isFirmwareInfoAvailable(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GridMonitorSoftwareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-monitor-software", [], null, null, null, View_GridMonitorSoftwareComponent_0, RenderType_GridMonitorSoftwareComponent)), i1.ɵdid(1, 49152, null, 0, i12.GridMonitorSoftwareComponent, [i13.MonitorDataService], null, null)], null, null); }
var GridMonitorSoftwareComponentNgFactory = i1.ɵccf("app-monitor-software", i12.GridMonitorSoftwareComponent, View_GridMonitorSoftwareComponent_Host_0, { monitor: "monitor" }, {}, []);
export { GridMonitorSoftwareComponentNgFactory as GridMonitorSoftwareComponentNgFactory };
