
import {of as observableOf, Observable, Subject} from 'rxjs';

import {map, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isUndefined} from 'lodash';
import {Injectable} from '@angular/core';
import {ErrorTranslator} from './error-translator';

@Injectable()
export class ErrorTranslatorService implements ErrorTranslator {
  private translateService: TranslateService;
  private unSubscribeErrorTranslatorOnDestroy: Subject<boolean> = new Subject<boolean>();

  constructor(translateService: TranslateService) {
    this.translateService = translateService;
  }

  public execute(httpErrorResponse: HttpErrorResponse, interpolateParams: any = {}): Observable<string> {
    let errorType: string;
    let errorMessage: string;

    let error = httpErrorResponse.error;

    if (typeof error === 'string') {
      error = JSON.parse(error);
    }

    if (!isUndefined(error.type) && !isUndefined(error.message)) {
      errorType = error.type;
      errorMessage = error.message;
    } else if (!isUndefined(error.error_description)) {
      errorType = error.error;
      errorMessage = error.error_description;
    }

    if (!isUndefined(errorType) && !isUndefined(errorMessage)) {
      return this.translateService.get(`ERRORS.${errorType}`, interpolateParams).pipe(
        takeUntil(this.unSubscribeErrorTranslatorOnDestroy),
        map((translation: any) => {
          if (translation !== `ERRORS.${errorType}`) {
            errorMessage = translation;
          }
          return errorMessage;
        }),);
    } else {
      return observableOf(httpErrorResponse.message);
    }
  }
}
