import { Routes } from '@angular/router';
import { AppConfig } from './app.config';
import { HttpLoaderFactory } from './application/translation/httpLoaderFactory';
var appRoutes = [];
var ɵ0 = HttpLoaderFactory, ɵ1 = AppConfig;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
