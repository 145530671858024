import {Inject, Injectable} from '@angular/core';
import {Routes} from '@angular/router';
import {startsWith, filter, isNil, find, isEqual, endsWith, size} from 'lodash';
import {APP_CONFIG, IAppConfig} from '../../app.config';
import {convertPagesToRoutes} from '../../application/command/convert-page-to-route';

import {Page} from '../../domain/page';

@Injectable()
export class NavigationService {
  private readonly pathSeparator = '/';
  private _pages: Page[] = [];
  private routes: Routes = [];

  public constructor(@Inject(APP_CONFIG) config: IAppConfig) {
    this._pages = config.pages;
    this.routes = convertPagesToRoutes(this._pages);
  }


  public getMenuPages(): Array<Page> {
    return this._pages.filter(page => page.showInMenu);
  }

  public isSecuredPath(path: string): boolean {
    let result = true;
    const pageMatchingPath: Page = find(this._pages, (page: Page) => {
      return startsWith(path, this.pathSeparator + page.path);
    });
    if (!isNil(pageMatchingPath)) {
      result = pageMatchingPath.securedPage;
    }
    return result;
  }

  public getRoutes(): Routes {
    return this.routes;
  }

  public getSubPagesOfPath(path: string) {
    let result: Page[] = [];
    const pageMatchingPath: Page = find(this._pages, (page: Page) => {
      return startsWith(path, this.pathSeparator + page.path);
    });
    if (!isNil(pageMatchingPath)) {
      result = pageMatchingPath.subPages;
    }
    return result;
  }

  public getCurrentPage(path: string): Page {

    path = path.split('?')[0];

    const id = path.substring(path.indexOf(this.pathSeparator), path.lastIndexOf(this.pathSeparator) + 1);

    const pattern = new RegExp('.*\\/.*\\/.*');

    if (pattern.test(path)) {
      path = path.replace(id, `${this.pathSeparator}:id${this.pathSeparator}`);
    }

    return find(this._pages, ['path', path]);
  }

  private getDefaultPage(): Page {
    return find(this._pages, function (page: Page) {
      return page.isDefault;
    });
  }
}
