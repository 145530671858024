import { ElementRef } from '@angular/core';
var GridHeaderNameComponent = /** @class */ (function () {
    function GridHeaderNameComponent(elementRef) {
        this.elementRef = elementRef;
    }
    GridHeaderNameComponent.prototype.agInit = function (params) {
        this.params = params;
        this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.onSortChanged();
    };
    GridHeaderNameComponent.prototype.onOwnSortRequested = function (event) {
        switch (this.sorted) {
            case '':
                this.params.setSort('asc', event.shiftKey);
                break;
            case 'asc':
                this.params.setSort('desc', event.shiftKey);
                break;
            case 'desc':
                this.params.setSort('', event.shiftKey);
        }
    };
    GridHeaderNameComponent.prototype.onSortChanged = function () {
        if (this.params.column.isSortAscending()) {
            this.sorted = 'asc';
        }
        else if (this.params.column.isSortDescending()) {
            this.sorted = 'desc';
        }
        else {
            this.sorted = '';
        }
    };
    ;
    return GridHeaderNameComponent;
}());
export { GridHeaderNameComponent };
