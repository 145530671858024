import { ActivatedRoute } from '@angular/router';
import { tap } from "rxjs/operators";
var ViewsComponent = /** @class */ (function () {
    function ViewsComponent(route, viewsService) {
        this.subPages = [];
        this.isLoading = true;
        this.subPages = route.snapshot.data.subPages;
        this.viewsService = viewsService;
    }
    ViewsComponent.prototype.fetchDetails = function (view) {
        var details = { _name: view.name, info: view.elucidation };
        return details;
    };
    ViewsComponent.prototype.getViews = function () {
        var _this = this;
        // Timeout to prevent 'changed after checked' bug
        var tapFunction = tap(function () { return setTimeout(function () { return _this.isLoading = false; }, 1); });
        this.viewObservable = this.viewObservable || this.viewsService.getAllForStatusOverview().pipe(tapFunction);
        return this.viewObservable;
    };
    return ViewsComponent;
}());
export { ViewsComponent };
