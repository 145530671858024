import { map, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './http/authentication/authentication.service';
var AuthorizationHttpInterceptor = /** @class */ (function () {
    function AuthorizationHttpInterceptor(injector, router) {
        this.URL_AUTHENTICATION_TOKEN = '/oauth/token';
        this.URL_REGISTER = '/api/users/';
        this.METHOD_POST = 'POST';
        this.UnauthorizedErrorCode = 401;
        this.OAUTH_TOKEN_ENDPOINT = 'oauth/token';
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource = new Subject();
        this.tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
        this.injector = injector;
        this.router = router;
    }
    AuthorizationHttpInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        this.authenticationService = this.injector.get(AuthenticationService);
        if (!this.isRegisterMethod(request.method, request.url)) {
            request = this.addAuthorizationHeader(request);
        }
        request = request.clone({
            url: this.updateUrl(request.url),
            setHeaders: {
                'Accept': 'security/json, image/*, application/json',
                'Content-Type': this.getContentType(request.url)
            }
        });
        return next.handle(request).pipe(catchError(function (error) {
            if (error instanceof HttpErrorResponse && error.status === _this.UnauthorizedErrorCode) {
                return _this.handleUnauthorizedError(error, request, next);
            }
            else {
                return throwError(error);
            }
        }));
    };
    AuthorizationHttpInterceptor.prototype.handleUnauthorizedError = function (error, request, next) {
        var _this = this;
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = false;
            this.authenticationService.logout();
            return throwError(error);
        }
        return this.refreshToken().pipe(mergeMap(function () {
            request = _this.addAuthorizationHeader(request);
            _this.refreshTokenInProgress = false;
            return next.handle(request);
        }));
    };
    AuthorizationHttpInterceptor.prototype.addAuthorizationHeader = function (request) {
        var authHeader = this.getAuthorizationHeader();
        return request.clone({
            setHeaders: {
                'Authorization': authHeader
            }
        });
    };
    AuthorizationHttpInterceptor.prototype.refreshToken = function () {
        var _this = this;
        if (this.refreshTokenInProgress) {
            return new Observable(function (observer) {
                _this.tokenRefreshed$.subscribe(function () {
                    observer.next();
                    return observer.complete();
                });
            });
        }
        else {
            this.refreshTokenInProgress = true;
            return this.authenticationService.refreshToken().pipe(map(function () {
                _this.tokenRefreshedSource.next();
            }));
        }
    };
    AuthorizationHttpInterceptor.prototype.updateUrl = function (url) {
        var updatedUrl = url;
        if (!url.startsWith('assets')) {
            updatedUrl = environment.origin + url;
        }
        return updatedUrl;
    };
    AuthorizationHttpInterceptor.prototype.getAuthorizationHeader = function () {
        return this.isAuthorizedRequest() ? 'Bearer ' + this.authenticationService.getToken().access_token : 'Basic ' + btoa(environment.client_id + ':' + environment.client_secret);
    };
    AuthorizationHttpInterceptor.prototype.getContentType = function (url) {
        return this.isTokenMethod(url) ? 'application/x-www-form-urlencoded' : 'application/json';
    };
    AuthorizationHttpInterceptor.prototype.isAuthorizedRequest = function () {
        return this.authenticationService.hasToken();
    };
    AuthorizationHttpInterceptor.prototype.isTokenMethod = function (url) {
        return url === this.URL_AUTHENTICATION_TOKEN;
    };
    AuthorizationHttpInterceptor.prototype.isRegisterMethod = function (method, url) {
        return method === this.METHOD_POST && url === this.URL_REGISTER;
    };
    return AuthorizationHttpInterceptor;
}());
export { AuthorizationHttpInterceptor };
