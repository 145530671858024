import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {InformationDialogComponent} from '../../presentation/pages/information-dialog/information-dialog.component';
import {LoggerService} from '../logger/logger.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../infrastructure/http/authentication/authentication.service';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private injector: Injector;
    private ngZone: NgZone;
    private dialogConfig: MatDialogConfig = <MatDialogConfig>{
        panelClass: 'api-error-dialog'
    };
    private logger: LoggerService;
    private readonly UnauthorizedErrorCode = 401;
    private isOpen: Boolean;

    constructor(injector: Injector, ngZone: NgZone, logger: LoggerService/*, router: Router*/) {
        this.injector = injector;
        this.ngZone = ngZone;
        this.logger = logger;

    }

    handleError(error: Error) {
        this.logger.error(error);
        if(this.isOpen) {
            return;
        }
        if (error instanceof HttpErrorResponse && error.status === this.UnauthorizedErrorCode) {
            const router: Router = this.injector.get(Router);
            router.navigateByUrl('/login');
        } else if (error instanceof HttpErrorResponse) {
            this.ngZone.run(() => {
                const dialog = this.injector.get(MatDialog);
                const translation = this.injector.get(TranslateService);
                translation.get("ERRORS.HTTPERROR")
                    .subscribe(text => {
                        this.isOpen = true;
                        const dialogRef: MatDialogRef<InformationDialogComponent> = dialog.open(InformationDialogComponent, this.dialogConfig);
                        dialogRef.componentInstance.textContent = text;
                        dialogRef.afterClosed().subscribe(() => this.isOpen = false);
                    })
            });
        } else {
            this.ngZone.run(() => {
                this.isOpen = true;
                const dialog = this.injector.get(MatDialog);
                const dialogRef: MatDialogRef<InformationDialogComponent> = dialog.open(InformationDialogComponent, this.dialogConfig);
                dialogRef.componentInstance.textContent = error.message;
                dialogRef.afterClosed().subscribe(() => this.isOpen = false);
            });
        }

    }

}
