
import {takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ColDef, GridOptions} from 'ag-grid';
import {isNil} from 'lodash';
import {Subject, Subscription} from 'rxjs';
import {StringComparator} from '../../../../application/command/string-comparator';
import {Page} from '../../../../domain/page';
import {View} from '../../../../domain/view/view';
import {Views} from '../../../../infrastructure/http/view/views';
import {GridEditViewComponent} from '../../../components/grid/edit-view/grid-edit-view.component';
import {GridHeaderNameComponent} from '../../../components/grid/header-name/grid-header-name.component';
import {GridDeleteViewComponent} from "../../../components/grid/grid-delete-view/grid-delete-view.component";
import { MediaObserver } from '@angular/flex-layout';

@Component({
    selector: 'app-manage-views',
    templateUrl: './manage-views.component.html',
    styleUrls: ['./manage-views.component.scss']
})
export class ManageViewsComponent implements OnInit, OnDestroy {
    public views: View[] = [];
    public subPages: Page[] = [];
    public viewsGridOptions: GridOptions;
    private viewsService: Views;
    private unSubscribeViewsOnDestroy: Subject<boolean> = new Subject<boolean>();
    private media: MediaObserver;
    private mediaSubscriber: Subscription;
    private gridMode: boolean;

    constructor(route: ActivatedRoute,
                @Inject('Views') viewsService: Views,
                media: MediaObserver) {
        this.subPages = route.snapshot.data.parentPage.subPages;
        this.viewsService = viewsService;
        this.media = media;

        this.createViewsGridOptions();

        this.updateGridParametersByMediaChanged();

        this.mediaSubscriber = this.media.asObservable().subscribe(() => {
            this.updateGridParametersByMediaChanged();
        });
    }

    public ngOnInit() {

        this.viewsService.getAllForStatusOverview().pipe(
            takeUntil(this.unSubscribeViewsOnDestroy))
            .subscribe((views: View[]) => {
                this.views = views;
            });
    }

    public ngOnDestroy() {
        this.unSubscribeViewsOnDestroy.next(true);
        this.unSubscribeViewsOnDestroy.complete();
    }

    public canShowGrid(): boolean {
        return this.gridMode;
    }

    private createViewsGridOptions(): void {
        this.viewsGridOptions = <GridOptions>{
            rowHeight: 70,
            headerHeight: 33,
            enableSorting: true,
            onGridSizeChanged: () => {
                this.resizeGrid();
            },
            icons: {
                sortAscending: '<i class="fa fa-angle-down"/>',
                sortDescending: '<i class="fa fa-angle-up"/>'
            },
            columnDefs: this.getColumnDefs()
        };
    }

    private getColumnDefs(): ColDef[] {
        return [
            {
                headerName: 'VIEW.NAME',
                headerComponentFramework: <{ new(): GridHeaderNameComponent }>GridHeaderNameComponent,
                field: 'name',
                comparator: StringComparator
            },
            {
                headerName: '',
                cellRendererFramework: GridEditViewComponent,
                width: 108
            },
            {
                headerName: '',
                cellRendererFramework: GridDeleteViewComponent,
                width: 108
            }
        ];
    }

    private resizeGrid(): void {
        if (!isNil(this.viewsGridOptions.api)) {
            this.viewsGridOptions.api.sizeColumnsToFit();
        }

    }

    private updateGridParametersByMediaChanged() {
        this.gridMode = this.media.isActive('gt-xs');
    }

}
