import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitorDataService } from 'app/infrastructure/data/monitor-data.service';
var ActionComponent = /** @class */ (function () {
    function ActionComponent(route, router, monitorService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.monitorService = monitorService;
        this.actions = {
            "share": function (segments) { return _this.acceptMonitorShare(segments); },
            "request": function (segments) { return _this.acceptShareRequest(segments); }
        };
        this.route.url.subscribe(function (segments) {
            var action = segments[1];
            if (action && _this.actions[action.path]) {
                _this.actions[action.path](segments);
            }
            else if (action) {
                _this.message = "The action " + action.path + " is not known.";
            }
        });
    }
    ActionComponent.prototype.acceptShareRequest = function (segments) {
        var _this = this;
        if (segments.length < 6) {
            throw new Error("Illegal amount of arguments");
        }
        var serial = segments[2].path;
        var key = segments[5].path;
        var mail = segments[3].path;
        var role = segments[4].path;
        this.monitorService.addByShareRequest(serial, key, mail, role).subscribe(function () { return _this.redirectToNotif(); });
    };
    ActionComponent.prototype.acceptMonitorShare = function (segments) {
        var _this = this;
        if (segments.length < 6) {
            throw new Error("Illegal amount of arguments");
        }
        var serial = segments[2].path;
        var role = segments[5].path;
        var key = segments[3].path;
        var mail = atob(segments[4].path);
        this.monitorService.addByKey(serial, role, key, mail).subscribe(function () { return _this.redirectToOverview(); });
    };
    ActionComponent.prototype.redirectToOverview = function () {
        var _this = this;
        setTimeout(function () { return _this.router.navigateByUrl("/monitors"); }, 1);
    };
    ActionComponent.prototype.redirectToNotif = function () {
        var _this = this;
        setTimeout(function () { return _this.router.navigateByUrl("/notifications"); }, 1);
    };
    ActionComponent.prototype.ngOnInit = function () { };
    return ActionComponent;
}());
export { ActionComponent };
