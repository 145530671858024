import { last, sortBy, isNil } from 'lodash';
import { MonitorDetails } from './monitorDetails';
import { MonitorMeasurement } from './monitorMeasurement';
import { MonitorSensor } from './monitorSensor';
import { isUndefined } from 'lodash';
var MonitorDetailStatusOverview = /** @class */ (function () {
    function MonitorDetailStatusOverview(id, serialNumber, monitorType, statusInfo, monitorDetails, sensors, measurements, order, isAlarmIgnored, permissions) {
        var _this = this;
        this._id = id;
        this._serialNumber = serialNumber;
        this._monitorType = monitorType;
        this._statusInfo = statusInfo;
        this._details = monitorDetails;
        this._sensors = sensors;
        this._measurements = measurements;
        this._sensors.forEach(function (sensor) {
            var tempMeasurement = (_this._measurements || []).filter(function (m) { return m.name === sensor.name; })[0];
            if (tempMeasurement) {
                sensor.upperLimits = tempMeasurement.upperLimits;
                sensor.lowerLimits = tempMeasurement.lowerLimits;
            }
        });
        this._permissions = permissions;
        if (!isUndefined(isAlarmIgnored)) {
            this._sensors.forEach(function (sensor, index) { return sensor.isAlarmIgnored = isAlarmIgnored[index]; });
        }
        if (order) {
            var _loop_1 = function (key) {
                var sensor = sensors.find(function (sensor) { return sensor.id === key; });
                if (sensor) {
                    sensor.order = order[key];
                }
            };
            for (var key in order) {
                _loop_1(key);
            }
        }
    }
    MonitorDetailStatusOverview.fromJSON = function (monitor, monitorMeasurementDTO) {
        return new MonitorDetailStatusOverview(monitor.id, monitor.serialNumber, monitor.monitorType, monitor.statusInfo, MonitorDetails.fromJSON(monitor.details), monitor.sensors.map(MonitorSensor.fromJSON), monitorMeasurementDTO.map(MonitorMeasurement.fromJSON), monitor.order, monitorMeasurementDTO.map(function (x) { return x.alarmIgnored; }), monitor.permissions);
    };
    MonitorDetailStatusOverview.copy = function (monitorOverview) {
        var original = monitorOverview;
        return new MonitorDetailStatusOverview(original.id, original.serialNumber, original.monitorType, original.statusInfo, original.details, original.sensors, original.measurements);
    };
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "serialNumber", {
        get: function () {
            return this._serialNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "monitorType", {
        get: function () {
            return this._monitorType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "statusInfo", {
        get: function () {
            return this._statusInfo;
        },
        set: function (value) {
            this._statusInfo = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "details", {
        get: function () {
            return this._details;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "sensors", {
        get: function () {
            return this._sensors;
        },
        set: function (value) {
            this._sensors = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "measurements", {
        get: function () {
            return this._measurements;
        },
        set: function (value) {
            this._measurements = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "lastMeasuredDateTime", {
        get: function () {
            var sensorOfLastMeasuredDateTime = last(sortBy(this.sensors, ['lastMeasuredDateTime']).filter(function (x) { return x.lastMeasuredDateTime != null; }));
            if (isNil(sensorOfLastMeasuredDateTime)) {
                return null;
            }
            else {
                return sensorOfLastMeasuredDateTime.lastMeasuredDateTime;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "name", {
        get: function () {
            return this.details.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MonitorDetailStatusOverview.prototype, "permissions", {
        get: function () {
            return this._permissions;
        },
        enumerable: true,
        configurable: true
    });
    return MonitorDetailStatusOverview;
}());
export { MonitorDetailStatusOverview };
