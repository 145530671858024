var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { takeUntil } from 'rxjs/operators';
import { TruncateDigitsPipe } from '../../../application/truncate-digits/truncate-digits.pipe';
import { ChartService } from '../../../infrastructure/chart/chart.service';
import { DetailsChartComponent } from '../../../infrastructure/chart/details-chart/details-chart.component';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChartconfigService } from './chartconfig.service';
import MonitorInputType from "../../../domain/monitor/monitorInputType";
import { MessageStyle } from "../../../domain/messageStyle";
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
var ChartComponent = /** @class */ (function (_super) {
    __extends(ChartComponent, _super);
    function ChartComponent(translateService, truncateDigitsPipe, amChartsService, zoomService, configService) {
        var _this = _super.call(this, translateService, truncateDigitsPipe, amChartsService) || this;
        _this.zoomService = zoomService;
        _this.configService = configService;
        _this.enableScrollbar = false;
        _this.MESSAGE_STYLE = MessageStyle;
        _this.COLORS = ["#4CA3D3", "#6BC441", "#878786", "#69a470"];
        _this.isFirstLoad = true;
        _this.isDateSelected = false;
        return _this;
    }
    ChartComponent.prototype.ngOnInit = function () {
        this.setId();
        this.currentDates = this.selectedDateRange;
    };
    ChartComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.selectedDateRange) {
            this.zoomService.pickedANewDate(); // For zooming purposes
            this.currentDates = changes.selectedDateRange.currentValue;
            this.isDateSelected = true;
            this.isFetchingNewData = true;
        }
        if (changes && changes.measurements) {
            this.isFetchingNewData = false;
        }
        if (!this.isFetchingNewData ||
            !this.isShowingOnlyLatest) {
            /*Not required to destroy the chart all time, makechart recreate same chart with new option values
            As we are using single chart component to each chart rendering, So Only distory when date range change*/
            if (this.isDateSelected && !this.isFetchingNewData) {
                this.destroyChart();
                this.isDateSelected = false;
            }
            this.makeChart(true);
        }
        if (!this.hasMeasurements() && this.sensors.length === 1) {
            this.canShowLastAvailable = true;
        }
        this.isFirstLoad = false;
    };
    ChartComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ChartComponent.prototype.destroyChart = function () {
        this.zoomService.removeChart(this.amChart);
        _super.prototype.destroyChart.call(this);
    };
    // START graph construction
    ChartComponent.prototype.makeChart = function (zoom) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.translateService.get([
                'MONITOR.LOWER_LIMIT',
                'MONITOR.UPPER_LIMIT',
                'CHART.NO_MEASUREMENTS_FOR_SELECTED_PERIOD'
            ]).pipe(takeUntil(_this.unSubscribeMonitorDetailsChartOnDestroy))
                .subscribe(function (translations) {
                _this.noDataTranslation = translations['CHART.NO_MEASUREMENTS_FOR_SELECTED_PERIOD'];
                var values = _this.getChartValues();
                var chartConfig = _this.configService.getStandardChartConfig(values.data, values.axes, values.graphs, _this.enableScrollbar);
                if (_this.amChart && _this.isChartExist("chartdiv_" + _this.chart_id)) {
                    _this.destroyChart();
                    _this.amChart = _this.amChartsService.makeChart("chartdiv_" + _this.chart_id, chartConfig, 1);
                    _this.amChart = Object.assign(_this.amChart, chartConfig);
                    _this.amChart.validateData();
                    _this.amChart.animateAgain();
                    zoom && _this.updateChart();
                    console.log("howmanyarecalled?");
                }
                else {
                    _this.amChart = _this.amChartsService.makeChart("chartdiv_" + _this.chart_id, chartConfig, 1);
                    _this.amChart.chartCustomId = "chartdiv_" + _this.chart_id;
                    zoom && _this.addChart();
                }
                resolve();
            });
        });
    };
    ChartComponent.prototype.addChart = function () {
        this.zoomService.addChart(this.amChart);
    };
    ChartComponent.prototype.isChartExist = function (chartCustomId) {
        return this.zoomService.isChartExist(this.amChart, chartCustomId);
    };
    ChartComponent.prototype.updateChart = function () {
        this.zoomService.updateChart(this.amChart);
    };
    ChartComponent.prototype.toggleScrollbar = function () {
        this.enableScrollbar = !this.enableScrollbar;
        this.ngOnChanges();
    };
    ChartComponent.prototype.getChartValues = function () {
        var _this = this;
        var graphs = [this.getInvisibleGraphForOneSensor(this.sensors[0])];
        var data = [];
        var hasNegatives = false;
        // The extra date makes sure the graph is displayed from startdate to enddate, as chosen in datepicker.
        data.push({ date: this.currentDates.start.toDate() });
        this.sensors.forEach(function (sensor, index) {
            _this.addPerSensor(sensor, index, graphs, data);
            hasNegatives = hasNegatives || _this.checkForNegativeValues(sensor);
        });
        data.push({ date: this.currentDates.end.toDate() });
        //We need to sort all items of data based on the date, regardless of sensor, else graph zooming will break
        data.sort(function (a, b) {
            if (a.date < b.date)
                return -1;
            else if (a.date > b.date)
                return 1;
            else
                return 0;
        });
        var axes = [ChartComponent.getAxisConfig(this.sensors[0], hasNegatives)];
        return { graphs: graphs, axes: axes, data: data };
    };
    ChartComponent.prototype.getInvisibleGraphForOneSensor = function (sensor) {
        if (!sensor)
            return;
        return this.configService.getInvisConfig(sensor)[0];
    };
    ChartComponent.prototype.checkForNegativeValues = function (sensor) {
        var measurements = this.getMeasurementForSensor(sensor).measurements;
        return measurements.some(function (measurement) { return measurement.measurementvalue < 0; });
    };
    ChartComponent.prototype.addPerSensor = function (sensor, index, graphs, data) {
        var _this = this;
        var _a;
        var measurements = this.getMeasurementForSensor(sensor);
        var graphSettings = function (measurement) {
            var date = new Date(measurement.datetime);
            var description = "Date: " + date.toLocaleString() + "\n";
            var status = measurement.sensorstatus.concat(measurement.monitorstatus);
            if (status.length > 0) {
                description += "[!] " + status.map(function (status) { return status.errorEnum; }).join(", ");
            }
            return {
                bullet: status.length > 0 ? "triangleUp" : "round",
                bulletSize: status.length > 0 ? 6 : 0,
                bulletColor: status.length > 0 ? "#fbc02d" : "#00000000",
                description: description
            };
        };
        if (sensor.inputType === MonitorInputType.DIGITAL_OPEN_CLOSED) {
            data.push.apply(data, measurements.measurements.map(function (measurement) {
                var _a;
                return (__assign((_a = { date: moment(measurement.datetime, moment.ISO_8601).toDate() }, _a[sensor.id + 0] = _this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits), _a), graphSettings(measurement)));
            }));
            graphs.push(this.configService.getDigitalOpenClosedConfig(sensor, 0, ChartComponent.getOpenOrClosedTextForValue(measurements)));
            return;
        }
        data.push.apply(data, measurements.measurements.map(function (measurement) {
            var _a;
            return (_a = {
                    date: moment(measurement.datetime, moment.ISO_8601).toDate()
                },
                _a[sensor.id + "invis"] = _this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits),
                _a);
        }));
        var grouped = this.groupMeasurementByLimit(measurements.measurements, sensor);
        var color = this.COLORS[index % this.COLORS.length];
        var id = 0;
        for (var _i = 0, _b = Object.keys(grouped); _i < _b.length; _i++) {
            var limits = _b[_i];
            // Get the graph config for this group
            graphs.push.apply(graphs, (this.configService.getGraphConfig(sensor, id, JSON.parse(limits), color)));
            // To connect the graphs, they must share one measurement.
            // If you are not the first graph, you add your first measurement to the previous graph.
            if (id > 0) {
                var measurement = grouped[limits][0];
                data.push(__assign((_a = { date: moment(measurement.datetime, moment.ISO_8601).toDate() }, _a[sensor.id + (id - 1)] = this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits), _a), graphSettings(measurement)));
            }
            // Get the appropriately set data for this group
            data.push.apply(data, (grouped[limits].map(function (measurement) {
                var _a;
                return (__assign((_a = { date: moment(measurement.datetime, moment.ISO_8601).toDate() }, _a[sensor.id + id] = _this.truncateDigitsPipe.transform(measurement.measurementvalue, environment.digits), _a), graphSettings(measurement)));
            })));
            id += 1;
        }
    };
    ChartComponent.prototype.groupMeasurementByLimit = function (measurements, sensor) {
        return _.groupBy(measurements, function (measurement) { return JSON.stringify(ChartComponent.getLimitAtTimestamp(moment(measurement.datetime).toDate(), sensor)); });
    };
    ChartComponent.getAxisConfig = function (sensor, hasNegatives) {
        return {
            "position": "left",
            'id': 'v0',
            'title': sensor.unit && sensor.unitName ? sensor.unitName + " [" + sensor.unit + "]" : null,
            'minimum': hasNegatives ? undefined : 0
        };
    };
    ChartComponent.getLimitAtTimestamp = function (time, sensor) {
        var upperLimits = sensor.upperLimits;
        var lowerLimits = sensor.lowerLimits;
        if (sensor.name === "Throughput") {
            upperLimits = [{ datetime: moment("2018-07-17T14:13:05Z").toDate(), value: 10.2 }];
            lowerLimits = [{ datetime: moment("2018-07-17T14:13:05Z").toDate(), value: 10.15 }];
        }
        var lowerLimit = ChartComponent.getLimitFromArray(time, lowerLimits);
        var upperLimit = ChartComponent.getLimitFromArray(time, upperLimits);
        return { lowerLimit: lowerLimit, upperLimit: upperLimit };
    };
    ChartComponent.getLimitFromArray = function (time, limits) {
        if (!limits || limits.length === 0)
            return null;
        var i = 0;
        while (i < limits.length && limits[i].datetime < time) {
            i += 1;
        }
        return limits[i - 1];
    };
    // END graph construction
    ChartComponent.prototype.setId = function () {
        this.chart_id = Math.floor(Math.random() * 10000);
        console.log(this.chart_id);
    };
    ChartComponent.prototype.getMeasurementForSensor = function (sensor) {
        return this.measurements.find(function (measurement) { return measurement.sensorId === sensor.id; });
    };
    ChartComponent.getOpenOrClosedTextForValue = function (measurement) {
        return function (value) { return value === 0 ? measurement.closedText : measurement.openText; };
    };
    ChartComponent.prototype.hasMeasurements = function () {
        var _this = this;
        return this.measurements.filter(function (measurement) { return _this.sensors.some(function (sensor) { return sensor.id === measurement.sensorId; }); }).some(function (measurement) { return measurement.measurements.length > 0; });
    };
    ChartComponent.prototype.canShowChart = function () {
        return this.hasMeasurements() && !this.isFetchingNewData && Array.isArray(this.sensors) && this.sensors.length > 0;
    };
    ChartComponent.prototype.showMostRecent = function () {
        var sensor = this.sensors[0];
        var lastmeasured = moment(sensor.lastMeasuredDateTime);
        var minusMonth = lastmeasured.clone().subtract(1, 'month');
        this.selectedDateRangeUpdater.next({ start: minusMonth, end: lastmeasured });
        this.isFetchingNewData = true;
    };
    return ChartComponent;
}(DetailsChartComponent));
export { ChartComponent };
