import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
var MonitorAddRedirectComponent = /** @class */ (function () {
    function MonitorAddRedirectComponent(router, route) {
        this.unSubscribeMonitorAddComponent = new Subject();
        route
            .queryParams.pipe(takeUntil(this.unSubscribeMonitorAddComponent))
            .subscribe(function (params) {
            router.navigate(['/monitor/add'], { queryParams: { serial_number: params.serial_number, activation_code: params.activation_code } });
        });
    }
    MonitorAddRedirectComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeMonitorAddComponent.next(true);
        this.unSubscribeMonitorAddComponent.complete();
    };
    return MonitorAddRedirectComponent;
}());
export { MonitorAddRedirectComponent };
