import { ElementRef, OnInit, AfterContentInit } from '@angular/core';
import { TruncateDigitsPipe } from 'app/application/truncate-digits/truncate-digits.pipe';
var valueToDecimalDirective = /** @class */ (function () {
    function valueToDecimalDirective(el, pip) {
        this.el = el;
        this.pip = pip;
        this.round = 2;
    }
    valueToDecimalDirective.prototype.ngOnInit = function () {
        this.onBlur();
    };
    valueToDecimalDirective.prototype.ngAfterContentInit = function () {
        this.onChange();
    };
    valueToDecimalDirective.prototype.onBlur = function () {
        this.el.nativeElement.value = this.pip.transform(this.el.nativeElement.value, this.round);
    };
    valueToDecimalDirective.prototype.onChange = function () {
        this.el.nativeElement.value = this.pip.transform(this.el.nativeElement.value, this.round);
    };
    return valueToDecimalDirective;
}());
export { valueToDecimalDirective };
