import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../../application/notification/notification.service';
import { validatePasswordMatch } from '../../../../application/validators/validate-password-match';
import { HttpUsersService } from 'app/infrastructure/http/user/http-users.service';
var ResetOwnPasswordComponent = /** @class */ (function () {
    function ResetOwnPasswordComponent(usersService, formBuilder, notificationService, route, errorTranslator) {
        this.unSubscribeUsersOnDestroy = new Subject();
        this.updatePerforming = false;
        this.usersService = usersService;
        this.formBuilder = formBuilder;
        this.notificationService = notificationService;
        this.route = route;
        this.errorTranslator = errorTranslator;
        this.resetPasswordForm = this.formBuilder.group({
            oldPassword: ['', [
                    Validators.required,
                    Validators.minLength(6)
                ]],
            password: ['', [
                    Validators.required,
                    Validators.minLength(6)
                ]],
            passwordConfirm: ['', [
                    Validators.required,
                    Validators.minLength(6)
                ]],
        }, {
            validator: validatePasswordMatch
        });
    }
    ResetOwnPasswordComponent.prototype.ngOnDestroy = function () {
        this.unSubscribeUsersOnDestroy.next(true);
        this.unSubscribeUsersOnDestroy.complete();
    };
    ResetOwnPasswordComponent.prototype.saveNewPassword = function (form) {
        var _this = this;
        if (form.valid) {
            this.updatePerforming = true;
            var formValues = form.value;
            this.usersService.editPassword(formValues.oldPassword, formValues.password).pipe(takeUntil(this.unSubscribeUsersOnDestroy))
                .subscribe(function () {
                _this.notificationService.show('USER.PASWORD_CHANGED_CONFIRMATION');
                _this.route.navigateByUrl('/user-profile');
            }, function (error) {
                _this.updatePerforming = false;
                _this.errorTranslator.execute(error).pipe(takeUntil(_this.unSubscribeUsersOnDestroy))
                    .subscribe(function (errorMessage) {
                    throw new Error(errorMessage);
                });
            });
        }
    };
    ResetOwnPasswordComponent.prototype.canSaveNewPassword = function () {
        return this.resetPasswordForm.valid && !this.updatePerforming;
    };
    ResetOwnPasswordComponent.prototype.goBackToUserProfile = function () {
        this.route.navigateByUrl('/user-profile');
    };
    return ResetOwnPasswordComponent;
}());
export { ResetOwnPasswordComponent };
