var SensorPredefinedListDigitalPulse = /** @class */ (function () {
    function SensorPredefinedListDigitalPulse(value) {
        this._value = value;
    }
    Object.defineProperty(SensorPredefinedListDigitalPulse.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    return SensorPredefinedListDigitalPulse;
}());
export { SensorPredefinedListDigitalPulse };
