/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-dnd-sorting.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./view-dnd-item/dnd-item.component.ngfactory";
import * as i3 from "@swimlane/ngx-dnd";
import * as i4 from "./view-dnd-item/dnd-item.component";
import * as i5 from "@angular/common";
import * as i6 from "./view-dnd-sorting.component";
var styles_ViewDndSortingComponent = [i0.styles];
var RenderType_ViewDndSortingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewDndSortingComponent, data: {} });
export { RenderType_ViewDndSortingComponent as RenderType_ViewDndSortingComponent };
function View_ViewDndSortingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-dnd-item", [], [[4, "width", null], [4, "height", null]], [[null, "touchmove"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("touchmove" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMove($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDown() !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onUp() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_DnDItemComponent_0, i2.RenderType_DnDItemComponent)), i1.ɵdid(1, 475136, null, 0, i3.DraggableDirective, [i1.ElementRef, i3.DrakeStoreService, i3.DroppableDirective], { ngxDraggable: [0, "ngxDraggable"], model: [1, "model"] }, null), i1.ɵpad(2, 2), i1.ɵdid(3, 114688, null, 0, i4.DnDItemComponent, [], { item: [0, "item"], item2: [1, "item2"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, _co.id, _v.context.$implicit.unit); var currVal_3 = _v.context.$implicit; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit[_co.key]; var currVal_5 = (_co.isMonitor ? "" : _v.context.$implicit.monitorId); _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getWidthOfItem(_v.parent.parent.context.$implicit, _v.context.$implicit); var currVal_1 = _co.getHeight(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ViewDndSortingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ngx-dnd-container sub-container"]], [[8, "id", 0]], [[null, "drop"], [null, "over"], [null, "drag"], [null, "out"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("over" === en)) {
        var pd_1 = (_co.onOver($event) !== false);
        ad = (pd_1 && ad);
    } if (("drag" === en)) {
        var pd_2 = (_co.onDrag($event) !== false);
        ad = (pd_2 && ad);
    } if (("out" === en)) {
        var pd_3 = (_co.onOut($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4407296, null, 0, i3.DroppableDirective, [i1.ElementRef, i1.Renderer2, i3.DrakeStoreService], { model: [0, "model"], ngxDroppable: [1, "ngxDroppable"] }, { drop: "drop", drag: "drag", over: "over", out: "out" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewDndSortingComponent_3)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.content; var currVal_2 = _v.parent.context.$implicit.content[0].unit; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit.content; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ViewDndSortingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[2, "first-item", null], [2, "last-item", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewDndSortingComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isVisible(_v.context.$implicit); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.first; var currVal_1 = _v.context.last; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ViewDndSortingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ngx-dnd-container main-container"]], null, [[null, "drop"], [null, "over"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.onMainDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("over" === en)) {
        var pd_1 = (_co.onOverMain($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4407296, null, 0, i3.DroppableDirective, [i1.ElementRef, i1.Renderer2, i3.DrakeStoreService], { model: [0, "model"], ngxDroppable: [1, "ngxDroppable"] }, { drop: "drop", over: "over" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewDndSortingComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = _co.id; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.displayObjects; _ck(_v, 3, 0, currVal_2); }, null); }
export function View_ViewDndSortingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-dnd-sorting", [], null, null, null, View_ViewDndSortingComponent_0, RenderType_ViewDndSortingComponent)), i1.ɵdid(1, 114688, null, 0, i6.ViewDndSortingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewDndSortingComponentNgFactory = i1.ɵccf("app-view-dnd-sorting", i6.ViewDndSortingComponent, View_ViewDndSortingComponent_Host_0, { key: "key", isMonitor: "isMonitor", model: "model" }, { modelSorted: "modelSorted" }, []);
export { ViewDndSortingComponentNgFactory as ViewDndSortingComponentNgFactory };
