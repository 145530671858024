<app-registration-content>
  <div fxLayout="column" fxLayoutGap="40px" *ngIf="!hasRegistered">
    <form class="register__content antialiased" [formGroup]="registerForm" (ngSubmit)="register(registerForm.value, registerForm.valid)">
      <div fxLayout="column">
        <div class="input-row header">
          <p>{{'REGISTER.REGISTER_NOW' | translate}}</p>
        </div>
        <div class="input-row hint">
          <p>{{'REGISTER.HINT' | translate}}</p>
        </div>

        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
          <!-- First name -->
          <mat-form-field fxFlex>
            <input matInput
                   type="text"
                   placeholder="{{'FIRSTNAME' | translate}}" formControlName="firstName">
            <mat-error *ngIf="registerForm.get('firstName').errors?.required">
              {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'FIRSTNAME' | translate, min: 1} }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('firstName').errors?.maxlength">
              {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'FIRSTNAME' | translate, max: 255} }}
            </mat-error>
          </mat-form-field>

          <!-- Last name -->
          <mat-form-field fxFlex>
            <input matInput
                   type="text"
                   placeholder="{{'LASTNAME' | translate}}" formControlName="lastName">
            <mat-error *ngIf="registerForm.get('lastName').errors?.required">
              {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'LASTNAME' | translate, min: 1} }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('lastName').errors?.maxlength">
              {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'LASTNAME' | translate, max: 255} }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input-row">
          <!-- Email -->
          <mat-form-field fxFill>

            <input matInput
                   type="email"
                   placeholder="{{'EMAIL' | translate}}"
                   email
                   formControlName="email">
            <mat-error *ngIf="registerForm.get('email').errors?.email">
              {{'REGISTER.ERROR_INVALID_EMAIL' | translate}}
            </mat-error>
            <mat-error *ngIf="registerForm.get('email').errors?.maxlength">
              {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'EMAIL' | translate, max: 255} }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
          <!-- Password -->
          <mat-form-field fxFlex>
            <!--Required error -->
            <mat-error *ngIf="registerForm.get('password').errors?.required">
              {{'REGISTER.ERROR_PASSWORD' | translate}}
            </mat-error>

            <!-- MinLength error -->
            <mat-error *ngIf="registerForm.get('password').errors?.minlength">
              {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'PASSWORD' | translate, min: 6} }}
            </mat-error>

            <input matInput
                   type="password"
                   placeholder="{{'PASSWORD' | translate}}" formControlName="password">
          </mat-form-field>

          <!-- Repeat Password -->
          <mat-form-field fxFlex>
            <!--Required error -->
            <mat-error *ngIf="registerForm.get('passwordConfirm').errors?.required">
              {{'REGISTER.ERROR_PASSWORD_REPEAT' | translate}}
            </mat-error>

            <!-- MinLength error -->
            <mat-error *ngIf="registerForm.get('passwordConfirm').errors?.minlength">
              {{ 'ERRORS.MIN_LENGTH' | translate: {attribute: 'PASSWORD' | translate, min: 6} }}
            </mat-error>

            <!-- Match error -->
            <mat-error *ngIf="registerForm.get('passwordConfirm').errors?.matchPassword">
              {{'REGISTER.ERROR_PASSWORDS_NOT_MATCHING' | translate}}
            </mat-error>

            <input matInput
                   type="password"
                   placeholder="{{'REGISTER.ERROR_PASSWORD_REPEAT' | translate}}" formControlName="passwordConfirm">
          </mat-form-field>
        </div>

        <div class="input-row">
          <!-- Job category -->
          <mat-form-field fxFlex>
            <mat-select placeholder="{{'REGISTER.HINT_JOB_CATEGORY' | translate}}"
                        formControlName="jobCategory">
              <mat-option *ngFor="let key of keyJobCategories" [value]="key">
                {{ 'JOBCATEGORY.' + key | translate}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registerForm.get('jobCategory').errors?.required">
              {{'REGISTER.ERROR_JOB_CATEGORY' | translate}}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input-row">
          <!-- Job title -->
          <mat-form-field fxFlex>
            <input matInput
                   type="text"
                   placeholder="{{'REGISTER.HINT_JOB_TITLE' | translate}}" formControlName="jobTitle">
            <mat-error *ngIf="registerForm.get('jobTitle').errors?.maxlength">
              {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'REGISTER.HINT_JOB_TITLE' | translate, max: 255} }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input-row">
          <!-- Phone -->
          <mat-form-field fxFlex>
            <input matInput
                   type="text"
                   placeholder="{{'REGISTER.HINT_PHONE' | translate}}" formControlName="phone">
            <mat-error *ngIf="registerForm.get('phone').errors?.maxlength">
              {{ 'ERRORS.MAX_LENGTH' | translate: {attribute: 'REGISTER.HINT_PHONE' | translate, max: 255} }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input-row">
          <!-- Terms & Conditions -->
          <mat-checkbox class="wrapped-checkbox" formControlName="agreeToTermsAndConditions" required="true" checked="false">
            {{'REGISTER.AGREE_TO' | translate}} <a routerLink="/privacy-statement" target="_blank"> {{'REGISTER.TERMS_AND_CONDITIONS' | translate}}</a>
          </mat-checkbox>
        </div>

        <div class="input-row">
          <div class="g-recaptcha">
            <re-captcha (captchaExpired)="resetCaptcha()" (captchaResponse)="handleCorrectCaptcha()" [site_key]="recaptchaKey"
                        [language]="currentLanguageCode"></re-captcha>
          </div>

        </div>

        <div class="input-row submit">
          <button fxFill mat-raised-button color="primary" type="submit" [disabled]="!canRegister()">
            {{'REGISTER.REGISTER' | translate}}
          </button>
        </div>
      </div>

    </form>

    <div class="link-action" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch">
      <p class="antialiased">
        {{'REGISTER.ALREADY_HAVE_ACCOUNT' | translate}}
      </p>
      <p>
        <button mat-raised-button color="primary" (click)="goToLogin()">{{'LOGIN.LOGIN' | translate}}</button>
      </p>
    </div>
  </div>


  <div *ngIf="hasRegistered" class="register__content registered">
    <div class="input-row">
      <h2>{{ 'REGISTER.ACCOUNT_CREATED' | translate }}</h2>
    </div>
    <div class="input-row">
      <p>{{ 'REGISTER.EMAIL_SENT_TO' | translate: {email: userEmail} }}</p>
    </div>
  </div>
</app-registration-content>
