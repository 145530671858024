export class SensorPredefinedListAnalog {
  private _measurementMinimum: number;
  private _measurementMaximum: number;
  private _valueMinimum: number;
  private _valueMaximum: number;
  private _pollingRate: string;
  private _cloudStorageRate: string;

  constructor(measurementMinimum: number,
              measurementMaximum: number,
              valueMinimum: number,
              valueMaximum: number,
              pollingRate: string,
              cloudStorageRate: string) {
    this._measurementMinimum = measurementMinimum;
    this._measurementMaximum = measurementMaximum;
    this._valueMinimum = valueMinimum;
    this._valueMaximum = valueMaximum;
    this._pollingRate = pollingRate;
    this._cloudStorageRate = cloudStorageRate;
  }

  get measurementMinimum(): number {
    return this._measurementMinimum;
  }

  get measurementMaximum(): number {
    return this._measurementMaximum;
  }

  get valueMinimum(): number {
    return this._valueMinimum;
  }

  get valueMaximum(): number {
    return this._valueMaximum;
  }

  get pollingRate(): string {
    return this._pollingRate;
  }

  get cloudStorageRate(): string {
    return this._cloudStorageRate;
  }
}
