import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-view-dnd-sorting',
  templateUrl: './view-dnd-sorting.component.html',
  styleUrls: ['./view-dnd-sorting.component.scss']
})
export class ViewDndSortingComponent<T> implements OnInit {

  /**
   * The items that must be sorted are stored in DisplayObjects, along with metadata.
   * 
   * The double array form can be gotten by using this.model. WARNING: this returns a copy.
   * 
   * DisplayObject interface on the bottom.
   */
  public displayObjects: DisplayObject<T>[];
  @Input() public key: string;
  @Input() public isMonitor: boolean;
  @Output() public modelSorted = new EventEmitter<Array<T | Array<T>>>();
    
  private currentlyDraggedWidth;
  private currentlyDraggedItem;
  public id;
  
  ngOnInit(): void {
    this.id = Math.floor(Math.random() * 10000);
  }
  
  @Input()
  public set model(val) {
    this.displayObjects = val
      .map(arr => arr.slice()).filter(arr => arr.length > 0)
      .map(this.getNewDisplayObjectFromArray)
  }

  public get model(): T[][] {
    return this.displayObjects.map(displayObject => displayObject.content);
  }
  
  
  
  // Drag and drop event handlers for updating display model
  
  public onDrop(): void {
    this.currentlyDraggedItem = null;
    this.modelSorted.emit(this.model);
  }

  public onMainDrop(event): void {
    // We must wrap the value in its own subcontainer.
    this.model = this.insertIntoArray(this.model, event.dropIndex, [event.value])
    this.onDrop();
  }
  
  public onOver(event): void {
      const displayObj = this.getDisplayObjectById(event.container.id)
      displayObj.isBeingHovered = true;
    
      this.currentlyDraggedWidth = this.getWidthForContainer(displayObj);
      this.currentlyDraggedItem = event.value;
  }
  
  public onOverMain(event) {
      this.currentlyDraggedWidth = "98.3%";
      this.currentlyDraggedItem = event.value;
 }
  
  public onOut(event): void {
      const displayObj = this.getDisplayObjectById(event.container.id)
      displayObj.isBeingHovered = false;
    
      this.currentlyDraggedItem = null;
  }
  
  public onDrag(event): void {
      const displayObj = this.getDisplayObjectById(event.source.id)
      displayObj.isBeingDragged = true;
  }
  
  
  
  
  // Translation of display model for individual containers -- referenced from html 
  
  public getWidthOfItem(displayObject, item) {
    return this.isItemBeingDragged(item) ? this.currentlyDraggedWidth:this.getWidthForContainer(displayObject);
  }
  
  public isVisible(displayObject) {
      return Array.isArray(displayObject.content) && displayObject.content.length > 0;
  }

  public getHeight() {
    return this.isMonitor ? "45px":"60px"
  }

  
  
  
  // Helper functions
  
  private isItemBeingDragged(item) {
    return isEqual(item, this.currentlyDraggedItem);
  }
  
  private getWidthForContainer(displayObject) {
    // If it is being hovered and not the source
    const isDraggedMemberIncluded = displayObject.isBeingHovered && !displayObject.isBeingDragged ? 1:0;
    const isOneMemberBeingDragged = !displayObject.isBeingHovered && displayObject.isBeingDragged ? -1:0;
    const amountInThisContainer = (displayObject.content.length + isDraggedMemberIncluded + isOneMemberBeingDragged);
    return Math.floor(100 / amountInThisContainer) - 2 + "%";
  }
  
  private getNewDisplayObjectFromArray(arr) {
    return {
      id: Math.floor(Math.random() * 10000),
      isBeingDragged: false,
      isBeingHovered: false,
      content: arr
    }
  }
  
  private getDisplayObjectById(id) {
      return this.displayObjects.find(displayObject =>  displayObject.id == id);
  }
  
  private insertIntoArray(array, index, value){
    const toInsert = array.slice();
    toInsert.splice(index, 0, value);
    return toInsert;
  }

}

/**
 * A DisplayObject is the model for a subcontainer. It contains extra info.
 */
interface DisplayObject<T> {
  id: number;
  isBeingDragged: boolean;
  isBeingHovered: boolean;
  content: T[];
}
