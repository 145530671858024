import MonitorInputType from '../monitorInputType';
import {Sensor} from './sensor';

export class SensorDigitalPulse extends Sensor {
  private _unit: string;
  private _value: number;

  constructor(channel: number,
              name: string,
              category: string,
              inputType: MonitorInputType,
              sensorType: string,
              unit: string,
              value: number) {
    super(channel, name, category, inputType, sensorType);

    this._unit = unit;
    this._value = value;
  }

  get unit(): string {
    return this._unit;
  }

  get value(): number {
    return this._value;
  }

  public toJSON(sensorDigitalPulseDTO: any) {
    return {
      channel: sensorDigitalPulseDTO.channel,
      name: sensorDigitalPulseDTO.name,
      inputType: sensorDigitalPulseDTO.inputType,
      categoryId: sensorDigitalPulseDTO.category,
      sensorTypeId: sensorDigitalPulseDTO.sensorType,
      unitId: sensorDigitalPulseDTO.unit,
      value: sensorDigitalPulseDTO.value
    }
  }
}
